import { lazy } from 'react';

import DefaultLayout from '@app/components/DefaultLayout';
import type { RouteDefinition } from '@app/routes/routes.types';
import {
  SUBSCRIPTION_CHECKOUT_PATHS,
  SUBSCRIPTION_PATHS,
} from '@config/routes.config';

const Cart = lazy(
  () => import(/*webpackChunkName: 'Checkout' */ '../pages/Cart/Cart'),
);
const AccountLayout = lazy(
  () =>
    import(/*webpackChunkName: 'Account' */ '@app/pages/Account/AccountLayout'),
);
const SubscriptionLayout = lazy(
  () =>
    import(
      /*webpackChunkName: 'Account' */ '@app/pages/Account/Subscription/components/SubscriptionLayout/SubscriptionLayout'
    ),
);
const SubscriptionCheckoutLayout = lazy(
  () =>
    import(
      /*webpackChunkName: 'Account' */ '@app/pages/Account/Subscription/components/SubscriptionLayout/SubscriptionCheckoutLayout'
    ),
);
const Account = lazy(
  () => import(/*webpackChunkName: 'Account' */ '../pages/Account/Account'),
);
const OrderHistory = lazy(
  () =>
    import(
      /*webpackChunkName: 'Account' */ '../pages/Account/OrderHistory/OrderHistory'
    ),
);
const OrderHistoryDetail = lazy(
  () =>
    import(
      /*webpackChunkName: 'Account' */
      '../pages/Account/OrderHistory/OrderHistoryDetail/OrderHistoryDetail'
    ),
);
const CheckoutLayout = lazy(
  () =>
    import(
      /*webpackChunkName: 'Checkout' */ '../pages/Checkout/CheckoutLayout'
    ),
);
const Address = lazy(
  () =>
    import(
      /*webpackChunkName: 'Checkout' */ '../pages/Checkout/Address/Address'
    ),
);
const Review = lazy(
  () =>
    import(/*webpackChunkName: 'Checkout' */ '../pages/Checkout/Review/Review'),
);
const Delivery = lazy(
  () =>
    import(
      /*webpackChunkName: 'Checkout' */ '../pages/Checkout/Delivery/Delivery'
    ),
);
const Payment = lazy(
  () =>
    import(
      /*webpackChunkName: 'Checkout' */ '../pages/Checkout/Payment/Payment'
    ),
);
const Response = lazy(
  () =>
    import(
      /*webpackChunkName: 'Checkout' */ '../pages/Checkout/Response/Response'
    ),
);
const ResponseLayout = lazy(
  () =>
    import(
      /*webpackChunkName: 'Checkout' */ '../pages/Checkout/Response/ResponseLayout'
    ),
);
const Success = lazy(
  () =>
    import(
      /*webpackChunkName: 'Checkout' */ '../pages/Checkout/Success/Success'
    ),
);
const SuccessLayout = lazy(
  () =>
    import(
      /*webpackChunkName: 'Checkout' */ '../pages/Checkout/Success/SuccessLayout'
    ),
);

const Subscription = lazy(
  () =>
    import(
      /*webpackChunkName: 'Subscription' */ '../pages/Account/Subscription/Subscription'
    ),
);

const SubscriptionPlans = lazy(
  () =>
    import(
      /*webpackChunkName: 'SubscriptionPlans' */ '../pages/Account/Subscription/SubscriptionPlans/SubscriptionPlans'
    ),
);

const SubscriptionCheckoutAccount = lazy(
  () =>
    import(
      /*webpackChunkName: 'SubscriptionCheckoutAccount' */ '../pages/Account/Subscription/SubscriptionCheckout/Account/AccountPage'
    ),
);

const SubscriptionCheckoutAddress = lazy(
  () =>
    import(
      /*webpackChunkName: 'SubscriptionCheckoutAddress' */ '../pages/Account/Subscription/SubscriptionCheckout/Address/AddressPage'
    ),
);

const SubscriptionCheckoutPayment = lazy(
  () =>
    import(
      /*webpackChunkName: 'SubscriptionCheckoutPayment' */ '../pages/Account/Subscription/SubscriptionCheckout/Payment/PaymentPage'
    ),
);

const SubscriptionCheckoutSuccess = lazy(
  () =>
    import(
      /*webpackChunkName: 'SubscriptionCheckoutSuccess' */ '../pages/Account/Subscription/SubscriptionCheckout/Success/SuccessPage'
    ),
);

const SubscriptionCheckoutReview = lazy(
  () =>
    import(
      /*webpackChunkName: 'SubscriptionReview' */ '../pages/Account/Subscription/SubscriptionCheckout/Review/ReviewPage'
    ),
);

export const routes: RouteDefinition[] = [
  {
    path: '/:language/shop/',
    redirect: '/:language/shop/cart/',
    exact: true,
  },
  {
    path: '/:language/shop/cart/',
    name: 'cart',
    component: Cart,
    layout: DefaultLayout,
  },
  {
    path: '/:language/account/',
    name: 'account/index',
    component: Account,
    layout: AccountLayout,
    exact: true,
  },
  {
    path: '/:language/account/orders/',
    name: 'account/orders',
    component: OrderHistory,
    layout: AccountLayout,
    exact: true,
  },
  {
    path: '/:language/account/orders/:orderNumber',
    name: 'account/order-detail',
    component: OrderHistoryDetail,
    layout: AccountLayout,
  },
  {
    path: SUBSCRIPTION_PATHS.changePlan,
    name: 'subscription/change-plan',
    component: SubscriptionPlans,
    layout: SubscriptionCheckoutLayout,
  },
  {
    path: SUBSCRIPTION_CHECKOUT_PATHS.account,
    name: 'subscription/checkout/account',
    component: SubscriptionCheckoutAccount,
    layout: SubscriptionCheckoutLayout,
  },
  {
    path: SUBSCRIPTION_CHECKOUT_PATHS.address,
    name: 'subscription/checkout/address',
    component: SubscriptionCheckoutAddress,
    layout: SubscriptionCheckoutLayout,
  },
  {
    path: SUBSCRIPTION_CHECKOUT_PATHS.payment,
    name: 'subscription/checkout/payment',
    component: SubscriptionCheckoutPayment,
    layout: SubscriptionCheckoutLayout,
  },
  {
    path: SUBSCRIPTION_CHECKOUT_PATHS.success,
    name: 'subscription/checkout/success',
    component: SubscriptionCheckoutSuccess,
    layout: SubscriptionCheckoutLayout,
  },
  {
    path: SUBSCRIPTION_CHECKOUT_PATHS.review,
    name: 'subscription/checkout/review',
    component: SubscriptionCheckoutReview,
    layout: SubscriptionCheckoutLayout,
  },
  {
    path: SUBSCRIPTION_PATHS.subscriptionList,
    name: 'subscription',
    component: Subscription,
    layout: SubscriptionLayout,
  },
  {
    path: `/:language/shop/checkout/`,
    name: `shop/checkout`,
    exact: true,
    redirect: `/:language/shop/checkout/address/`,
  },
  {
    path: `/:language/shop/checkout/address/`,
    name: `shop/checkout/address`,
    component: Address,
    layout: CheckoutLayout,
  },
  {
    path: `/:language/shop/checkout/review/`,
    name: `shop/checkout/review`,
    component: Review,
    layout: CheckoutLayout,
  },
  {
    path: `/:language/shop/checkout/delivery/`,
    name: `shop/checkout/delivery`,
    component: Delivery,
    layout: CheckoutLayout,
  },
  {
    path: `/:language/shop/checkout/payment/:status?/`,
    name: `shop/checkout/payment`,
    component: Payment,
    layout: CheckoutLayout,
  },
  {
    path: `/:language/shop/checkout/response/:cartId/`,
    name: `shop/checkout/response`,
    component: Response,
    layout: ResponseLayout,
  },
  {
    path: `/:language/shop/checkout/success/:orderNumber`,
    name: `shop/checkout/success`,
    component: Success,
    layout: SuccessLayout,
  },
];
