import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Flex } from '@theme-ui/components';

import { getButtonStyles } from '@app/components/Button';
import type { AppTheme } from '@app/styles/theme';

import {
  getIntlValidLocale,
  getLocaleFromPathname,
} from '../../../helpers/locale';
import { productListWith360Tile, TITLE_360 } from '../360TileABTest';
import type { ProductsSection as ContentSection } from '../CatalogContentPage/useContentPage';
import ProductPlaceholders from '../ProductPlaceholders';
import ProductTile from '../ProductTile';
import { useGetProductsBySkuQuery } from './__graphql__/getProductsBySku.query';
import { Wrapper } from './FeaturedProducts.styles';

export type FeaturedProductsProps = Omit<ContentSection, '__typename'>;

const FeaturedProducts: FC<FeaturedProductsProps> = ({
  content,
  ctaButtons,
  skus,
}) => {
  const location = useLocation();
  const language = getIntlValidLocale(getLocaleFromPathname(location.pathname));

  const { data, loading } = useGetProductsBySkuQuery({
    skip: skus.length === 0,
    variables: {
      language,
      condition: { in: { key: 'objectID', value: skus } },
    },
  });

  const productItems = data?.getProducts?.result?.items ?? [];

  const products = productItems
    .slice()
    .sort((a, b) => skus.indexOf(a.sku ?? '') - skus.indexOf(b.sku ?? ''));

  const filteredProducts = productListWith360Tile(
    products,
    language,
    location.pathname,
  );

  if (loading) {
    return <ProductPlaceholders count={4} />;
  }

  return filteredProducts?.length > 0 ? (
    <Box>
      <Wrapper>
        {content && (
          <div
            data-testid="body-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}

        {ctaButtons && (
          <Flex sx={{ justifyContent: 'center' }}>
            {ctaButtons.map(({ text, link }, index) => (
              <a
                data-testid={`cta-button-${index}`}
                key={index}
                href={link}
                css={(theme: AppTheme) =>
                  getButtonStyles({ type: 'secondary', theme })
                }
              >
                {text}
              </a>
            ))}
          </Flex>
        )}
      </Wrapper>

      <Flex sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {!loading &&
          filteredProducts.map((product, index) => (
            <Box
              data-testid="featured-product"
              key={index}
              px={[0, 2]}
              py={3}
              sx={{
                width: ['100%', '50%', '33.3%', '25%'],
                display: product.id === TITLE_360 ? 'none' : 'flex'
              }}
              className={
                // @ts-expect-error attributes ts error
                product.attributes?.find((x) => x.key === 'abTestClassName')
                  ?.value
              }
            >
              {/* @ts-expect-error @FIXME [WWW-11971] NI codegen migration: conflicting autogenerated types*/}
              <ProductTile product={product} />
            </Box>
          ))}
      </Flex>
    </Box>
  ) : null;
};

export default FeaturedProducts;
