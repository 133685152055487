export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** ISO8601 Date values */
  Date: any;
  /** BigInt value */
  GraphQLBigInt: any;
  /** A Float or a String */
  GraphQLStringOrFloat: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  Language: any;
  Value: any;
};

export type AccountDetailsOutput = {
  __typename?: 'AccountDetailsOutput';
  companyName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AddproductBySerialNumberOutput = {
  __typename?: 'AddproductBySerialNumberOutput';
  licenseId?: Maybe<Scalars['Int']>;
  orderItemId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  purchaseId?: Maybe<Scalars['Int']>;
  serialNumber?: Maybe<Scalars['String']>;
  supportsCr?: Maybe<Scalars['Boolean']>;
  supportsiLok?: Maybe<Scalars['Boolean']>;
};

export type AnonymousUser = UserV2 & {
  __typename?: 'AnonymousUser';
  auth: AuthData;
};

export type Attribute = {
  __typename?: 'Attribute';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Value']>;
};

export type Auth = {
  __typename?: 'Auth';
  accessToken?: Maybe<Scalars['String']>;
  accessTokenInfo?: Maybe<TokenInfo>;
  state: AuthState;
};

export type AuthData = {
  __typename?: 'AuthData';
  accessToken?: Maybe<Scalars['String']>;
  state: AuthState;
};

export enum AuthState {
  Anonymous = 'ANONYMOUS',
  Authenticated = 'AUTHENTICATED',
  AuthenticationFailed = 'AUTHENTICATION_FAILED',
}

export type AuthenticatedUser = UserV2 & {
  __typename?: 'AuthenticatedUser';
  auth: AuthData;
  ecommerce_status: EcommerceStatus;
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  licenses: Array<Maybe<License>>;
  licenses_ids?: Maybe<Array<Scalars['String']>>;
  native_id: Scalars['String'];
  product_upgrade?: Maybe<ProductUpgrade>;
  subscriptions: Array<SubscriptionItem>;
  upgradePaths: Array<UpgradePathBase>;
};

export type AuthenticatedUserLicensesArgs = {
  include_fields?: InputMaybe<Scalars['String']>;
};

export type AuthenticatedUserProduct_UpgradeArgs = {
  sku: Scalars['String'];
};

export type AuthenticatedUserSubscriptionsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  licensedProductId?: InputMaybe<Scalars['Int']>;
};

export type AuthorizeIlokOutput = {
  __typename?: 'AuthorizeIlokOutput';
  authDate?: Maybe<Scalars['String']>;
  orderItemId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type AvailableSerialNumberOutput = {
  __typename?: 'AvailableSerialNumberOutput';
  serialNumber?: Maybe<Scalars['String']>;
};

export type BooleanFilter = {
  __typename?: 'BooleanFilter';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BundleMember = {
  __typename?: 'BundleMember';
  licensedproduct_ids: Array<Scalars['Int']>;
  product_upid: Scalars['String'];
};

export type BundlePrice = {
  __typename?: 'BundlePrice';
  diffPrice?: Maybe<Scalars['String']>;
  minPrice?: Maybe<Scalars['String']>;
  originalPrice?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Comparison = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Value']>;
};

export type Condition = {
  and?: InputMaybe<Array<InputMaybe<Condition>>>;
  eq?: InputMaybe<Comparison>;
  gt?: InputMaybe<Comparison>;
  gteq?: InputMaybe<Comparison>;
  in?: InputMaybe<InComparison>;
  lt?: InputMaybe<Comparison>;
  lteq?: InputMaybe<Comparison>;
  neq?: InputMaybe<Comparison>;
  nin?: InputMaybe<NinComparison>;
  not?: InputMaybe<Condition>;
  or?: InputMaybe<Array<InputMaybe<Condition>>>;
};

export type Customer = {
  __typename?: 'Customer';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
};

export type DigitalRiverSessionOutput = {
  __typename?: 'DigitalRiverSessionOutput';
  sessionId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DirectusSubscription = {
  __typename?: 'DirectusSubscription';
  catalog_content_pages_featured_product_sections_mutated?: Maybe<Catalog_Content_Pages_Featured_Product_Sections_Mutated>;
  catalog_content_pages_mutated?: Maybe<Catalog_Content_Pages_Mutated>;
  category_banners_mutated?: Maybe<Category_Banners_Mutated>;
  category_banners_translations_mutated?: Maybe<Category_Banners_Translations_Mutated>;
  content_elements_1col_mutated?: Maybe<Content_Elements_1col_Mutated>;
  content_elements_1col_translations_mutated?: Maybe<Content_Elements_1col_Translations_Mutated>;
  content_elements_2_col_mutated?: Maybe<Content_Elements_2_Col_Mutated>;
  content_elements_2_col_translations_mutated?: Maybe<Content_Elements_2_Col_Translations_Mutated>;
  content_elements_2col_grid_mutated?: Maybe<Content_Elements_2col_Grid_Mutated>;
  content_elements_2col_grid_translations_mutated?: Maybe<Content_Elements_2col_Grid_Translations_Mutated>;
  cta_buttons_mutated?: Maybe<Cta_Buttons_Mutated>;
  cta_buttons_translations_mutated?: Maybe<Cta_Buttons_Translations_Mutated>;
  directus_files_mutated?: Maybe<Directus_Files_Mutated>;
  featured_product_sections_cta_buttons_mutated?: Maybe<Featured_Product_Sections_Cta_Buttons_Mutated>;
  featured_product_sections_mutated?: Maybe<Featured_Product_Sections_Mutated>;
  featured_product_sections_products_mutated?: Maybe<Featured_Product_Sections_Products_Mutated>;
  featured_product_sections_translations_mutated?: Maybe<Featured_Product_Sections_Translations_Mutated>;
  languages_mutated?: Maybe<Languages_Mutated>;
  menu_categories_content_elements_mutated?: Maybe<Menu_Categories_Content_Elements_Mutated>;
  menu_categories_menu_categories_mutated?: Maybe<Menu_Categories_Menu_Categories_Mutated>;
  menu_categories_mutated?: Maybe<Menu_Categories_Mutated>;
  menu_categories_translations_mutated?: Maybe<Menu_Categories_Translations_Mutated>;
  menu_menu_categories_mutated?: Maybe<Menu_Menu_Categories_Mutated>;
  menu_mutated?: Maybe<Menu_Mutated>;
  pricing_page_multi_layout_links_mutated?: Maybe<Pricing_Page_Multi_Layout_Links_Mutated>;
  pricing_page_multi_layout_links_translations_mutated?: Maybe<Pricing_Page_Multi_Layout_Links_Translations_Mutated>;
  pricing_pages_layout_mutated?: Maybe<Pricing_Pages_Layout_Mutated>;
  pricing_pages_multi_layout_mutated?: Maybe<Pricing_Pages_Multi_Layout_Mutated>;
  pricing_pages_multi_layout_products_mutated?: Maybe<Pricing_Pages_Multi_Layout_Products_Mutated>;
  pricing_pages_multi_layout_translations_mutated?: Maybe<Pricing_Pages_Multi_Layout_Translations_Mutated>;
  pricing_pages_mutated?: Maybe<Pricing_Pages_Mutated>;
  pricing_pages_single_layout_mutated?: Maybe<Pricing_Pages_Single_Layout_Mutated>;
  pricing_pages_single_layout_translations_mutated?: Maybe<Pricing_Pages_Single_Layout_Translations_Mutated>;
  pricing_pages_translations_mutated?: Maybe<Pricing_Pages_Translations_Mutated>;
  products_links_mutated?: Maybe<Products_Links_Mutated>;
  products_links_translations_mutated?: Maybe<Products_Links_Translations_Mutated>;
  products_mutated?: Maybe<Products_Mutated>;
  products_products_links_mutated?: Maybe<Products_Products_Links_Mutated>;
  products_system_requirements_mutated?: Maybe<Products_System_Requirements_Mutated>;
  products_translations_mutated?: Maybe<Products_Translations_Mutated>;
  products_translations_product_features_mutated?: Maybe<Products_Translations_Product_Features_Mutated>;
  products_translations_products_links_mutated?: Maybe<Products_Translations_Products_Links_Mutated>;
  required_base_products_mutated?: Maybe<Required_Base_Products_Mutated>;
  required_base_products_translations_mutated?: Maybe<Required_Base_Products_Translations_Mutated>;
  system_requirements_mutated?: Maybe<System_Requirements_Mutated>;
  system_requirements_translations_mutated?: Maybe<System_Requirements_Translations_Mutated>;
};

export type DirectusSubscriptionCatalog_Content_Pages_Featured_Product_Sections_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionCatalog_Content_Pages_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionCategory_Banners_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionCategory_Banners_Translations_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionContent_Elements_1col_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionContent_Elements_1col_Translations_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionContent_Elements_2_Col_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionContent_Elements_2_Col_Translations_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionContent_Elements_2col_Grid_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionContent_Elements_2col_Grid_Translations_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionCta_Buttons_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionCta_Buttons_Translations_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionDirectus_Files_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionFeatured_Product_Sections_Cta_Buttons_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionFeatured_Product_Sections_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionFeatured_Product_Sections_Products_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionFeatured_Product_Sections_Translations_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionLanguages_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionMenu_Categories_Content_Elements_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionMenu_Categories_Menu_Categories_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionMenu_Categories_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionMenu_Categories_Translations_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionMenu_Menu_Categories_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionMenu_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionPricing_Page_Multi_Layout_Links_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionPricing_Page_Multi_Layout_Links_Translations_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionPricing_Pages_Layout_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionPricing_Pages_Multi_Layout_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionPricing_Pages_Multi_Layout_Products_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionPricing_Pages_Multi_Layout_Translations_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionPricing_Pages_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionPricing_Pages_Single_Layout_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionPricing_Pages_Single_Layout_Translations_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionPricing_Pages_Translations_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionProducts_Links_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionProducts_Links_Translations_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionProducts_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionProducts_Products_Links_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionProducts_System_Requirements_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionProducts_Translations_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionProducts_Translations_Product_Features_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionProducts_Translations_Products_Links_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionRequired_Base_Products_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionRequired_Base_Products_Translations_MutatedArgs =
  {
    event?: InputMaybe<EventEnum>;
  };

export type DirectusSubscriptionSystem_Requirements_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type DirectusSubscriptionSystem_Requirements_Translations_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export enum EcommerceStatus {
  New = 'new',
  NoPurchases = 'no_purchases',
  Returning = 'returning',
  Unknown = 'unknown',
}

export enum EventEnum {
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
}

export type Filter =
  | BooleanFilter
  | MenuFilter
  | MultiSelectFilter
  | RangeFilter;

export type FiltersConfigItem = {
  __typename?: 'FiltersConfigItem';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type FinderProduct = {
  __typename?: 'FinderProduct';
  attributes?: Maybe<Array<Maybe<Attribute>>>;
  audioId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  price?: Maybe<ProductPrice>;
  quickAddToCart?: Maybe<Scalars['Boolean']>;
  quickAddToCartSku?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type FinderProductAttributesArgs = {
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetSubscriptionResult = {
  __typename?: 'GetSubscriptionResult';
  id: Scalars['ID'];
  plans: Array<Maybe<PricePoint>>;
  title: Scalars['String'];
};

export type InComparison = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Array<InputMaybe<Scalars['Value']>>>;
};

export type IncludedProducts = {
  __typename?: 'IncludedProducts';
  items: Array<Magento2_RestApi_Product>;
  page_info: IncludedProductsPageInfo;
};

export type IncludedProductsPageInfo = {
  __typename?: 'IncludedProductsPageInfo';
  current_page: Scalars['Int'];
  has_next_page: Scalars['Boolean'];
  page_size: Scalars['Int'];
};

export type IzoOrderAddress = {
  __typename?: 'IzoOrderAddress';
  addresTwo?: Maybe<Scalars['String']>;
  addressOne?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type IzoPageInfo = {
  __typename?: 'IzoPageInfo';
  currentPage?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalHits?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type IzoUser = {
  __typename?: 'IzoUser';
  email?: Maybe<Scalars['String']>;
  hasMembership?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type IzotopeSubscription = {
  __typename?: 'IzotopeSubscription';
  activationDate?: Maybe<Scalars['String']>;
  autoRenewal?: Maybe<Scalars['Boolean']>;
  cancellationDate?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['String']>;
  currentQuantity?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['Int']>;
  graceDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  nextRenewalDate?: Maybe<Scalars['String']>;
  product?: Maybe<SubscriptionProduct>;
  renewalProduct?: Maybe<SubscriptionProduct>;
  renewalQuantity?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  term?: Maybe<SubscriptionTerm>;
};

export type License = {
  __typename?: 'License';
  bundle_members?: Maybe<Array<BundleMember>>;
  license: Scalars['String'];
  licenseclass: Scalars['String'];
  licensedproduct_id: Scalars['Int'];
  licensedproduct_title: Scalars['String'];
  product_upid: Scalars['String'];
  registration_date?: Maybe<Scalars['String']>;
  registration_id?: Maybe<Scalars['Int']>;
  serial_number: Scalars['String'];
  serialprefix: Scalars['String'];
  transfer_token?: Maybe<Scalars['String']>;
  transfer_type?: Maybe<Scalars['String']>;
};

export type LicenseArgs = {
  include_fields?: InputMaybe<Scalars['String']>;
};

export type LicenseExtended = {
  __typename?: 'LicenseExtended';
  bundle: Scalars['Boolean'];
  bundle_member: Scalars['Boolean'];
  implicit: Scalars['Boolean'];
  licensedproduct_id: Scalars['Int'];
  licensedproduct_title: Scalars['String'];
  member_licensedproduct_ids: Array<Scalars['Int']>;
  product_title: Scalars['String'];
  product_upid: Scalars['String'];
  registered: Scalars['Boolean'];
};

export type LicensedProduct = {
  __typename?: 'LicensedProduct';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type LoyaltyOffers = {
  __typename?: 'LoyaltyOffers';
  addToCartSku?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type LoyaltyOffersOutput = {
  __typename?: 'LoyaltyOffersOutput';
  items?: Maybe<Array<Maybe<LoyaltyOffers>>>;
  pageInfo?: Maybe<IzoPageInfo>;
};

export type Mp3Chapter = {
  __typename?: 'MP3Chapter';
  description?: Maybe<Scalars['String']>;
  displayTime?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  mediaHigh?: Maybe<Scalars['String']>;
  mediaLow?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  waveform?: Maybe<Scalars['String']>;
};

export type Magento2 = {
  __typename?: 'Magento2';
  /** Get a list of available store views and their config information. */
  availableStores?: Maybe<Array<Maybe<Magento2_StoreConfig>>>;
  /** Return information about the specified shopping cart. */
  cart?: Maybe<Magento2_Cart>;
  /** Return a list of categories that match the specified filter. */
  categories?: Maybe<Magento2_CategoryResult>;
  /**
   * Search for categories that match the criteria specified in the `search` and `filter` attributes.
   * @deprecated Use `categories` instead.
   */
  category?: Maybe<Magento2_CategoryTree>;
  /**
   * Return an array of categories based on the specified filters.
   * @deprecated Use `categories` instead.
   */
  categoryList?: Maybe<Array<Maybe<Magento2_CategoryTree>>>;
  /** Get status for redirectAndPlaceOrder mutation: info about created order or errors. */
  checkPlaceOrderStatus?: Maybe<Magento2_CheckPlaceOrderStatusOutput>;
  /** Send request to VAT validation service and return validation result */
  checkVatNumber?: Maybe<Magento2_VatNumberValidationResult>;
  /** Return Terms and Conditions configuration information. */
  checkoutAgreements?: Maybe<Array<Maybe<Magento2_CheckoutAgreement>>>;
  /** Return information about CMS blocks. */
  cmsBlocks?: Maybe<Magento2_CmsBlocks>;
  /** Return details about a CMS page. */
  cmsPage?: Maybe<Magento2_CmsPage>;
  /** Return products that have been added to the specified compare list. */
  compareList?: Maybe<Magento2_CompareList>;
  /** The countries query provides information for all countries. */
  countries?: Maybe<Array<Maybe<Magento2_Country>>>;
  /** The countries query provides information for a single country. */
  country?: Maybe<Magento2_Country>;
  /** Query by GraphQL enpoint to receive mapping between Country Code => Store View Code */
  countryStoreCode?: Maybe<Magento2_CountryStoreCode>;
  /** Return information about the store's currency. */
  currency?: Maybe<Magento2_Currency>;
  /** Return the attribute type, given an attribute code and entity type. */
  customAttributeMetadata?: Maybe<Magento2_CustomAttributeMetadata>;
  /** Return detailed information about a customer account. */
  customer?: Maybe<Magento2_Customer>;
  /** Return information about the customer's shopping cart. */
  customerCart: Magento2_Cart;
  /** Return a list of downloadable products the customer has purchased. */
  customerDownloadableProducts?: Maybe<Magento2_CustomerDownloadableProducts>;
  /** @deprecated Use the `customer` query instead. */
  customerOrders?: Maybe<Magento2_CustomerOrders>;
  /** Return a list of customer payment tokens stored in the vault. */
  customerPaymentTokens?: Maybe<Magento2_CustomerPaymentTokens>;
  /** Return a list of dynamic blocks filtered by type, location, or UIDs. */
  dynamicBlocks: Magento2_DynamicBlocks;
  /** Send request to receive coupons which customer has */
  getCustomerCoupons?: Maybe<Magento2_CustomerCouponResult>;
  /** Retrieve the secure PayPal URL for a Payments Pro Hosted Solution transaction. */
  getHostedProUrl?: Maybe<Magento2_HostedProUrl>;
  /** Get Module Configuration to initialize Stripe Elements. */
  getModuleConfiguration?: Maybe<Magento2_ModuleConfiguration>;
  /** Retrieve payment credentials for a transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken?: Maybe<Magento2_PayflowLinkToken>;
  /** Return details about a specific gift card. */
  giftCardAccount?: Maybe<Magento2_GiftCardAccount>;
  /** Return the specified gift registry. Some details will not be available to guests. */
  giftRegistry?: Maybe<Magento2_GiftRegistry>;
  /** Search for gift registries by specifying a registrant email address. */
  giftRegistryEmailSearch?: Maybe<
    Array<Maybe<Magento2_GiftRegistrySearchResult>>
  >;
  /** Search for gift registries by specifying a registry URL key. */
  giftRegistryIdSearch?: Maybe<Array<Maybe<Magento2_GiftRegistrySearchResult>>>;
  /** Search for gift registries by specifying the registrant name and registry type ID. */
  giftRegistryTypeSearch?: Maybe<
    Array<Maybe<Magento2_GiftRegistrySearchResult>>
  >;
  /** Get a list of available gift registry types. */
  giftRegistryTypes?: Maybe<Array<Maybe<Magento2_GiftRegistryType>>>;
  /** Check whether the specified email has already been used to create a customer account. */
  isEmailAvailable?: Maybe<Magento2_IsEmailAvailableOutput>;
  /** The pickup locations query searches for locations that match the search request requirements. */
  pickupLocations?: Maybe<Magento2_PickupLocations>;
  /** Return the active ratings attributes and the values each rating can have. */
  productReviewRatingsMetadata: Magento2_ProductReviewRatingsMetadata;
  /** Search for products that match the criteria specified in the `search` and `filter` attributes. */
  products?: Maybe<Magento2_Products>;
  /** Initiates a buyer's request to return items for replacement or refund. */
  returnRequest?: Maybe<Magento2_ReturnRequestOutput>;
  /** Return the full details for a specified product, category, or CMS page. */
  route?: Maybe<Magento2_RoutableInterface>;
  /** Return details about the store's configuration. */
  storeConfig?: Maybe<Magento2_StoreConfig>;
  /** Query to Get Stripe Params. */
  stripeParams?: Maybe<Magento2_StripeParams>;
  /**
   * Return the relative URL for a specified product, category or CMS page.
   * @deprecated Use the `route` query instead.
   */
  urlResolver?: Maybe<Magento2_EntityUrl>;
  /**
   * Return the contents of a customer's wish list.
   * @deprecated Moved under `Customer.wishlist`.
   */
  wishlist?: Maybe<Magento2_WishlistOutput>;
};

export type Magento2AvailableStoresArgs = {
  useCurrentGroup?: InputMaybe<Scalars['Boolean']>;
};

export type Magento2CartArgs = {
  cart_id: Scalars['String'];
};

export type Magento2CategoriesArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Magento2_CategoryFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type Magento2CategoryArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Magento2CategoryListArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Magento2_CategoryFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type Magento2CheckPlaceOrderStatusArgs = {
  cart_id: Scalars['String'];
};

export type Magento2CheckVatNumberArgs = {
  country_code?: InputMaybe<Scalars['String']>;
  vat_number?: InputMaybe<Scalars['String']>;
};

export type Magento2CmsBlocksArgs = {
  identifiers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Magento2CmsPageArgs = {
  id?: InputMaybe<Scalars['Int']>;
  identifier?: InputMaybe<Scalars['String']>;
};

export type Magento2CompareListArgs = {
  uid: Scalars['ID'];
};

export type Magento2CountryArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type Magento2CountryStoreCodeArgs = {
  country_code?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
};

export type Magento2CustomAttributeMetadataArgs = {
  attributes: Array<Magento2_AttributeInput>;
};

export type Magento2DynamicBlocksArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<Magento2_DynamicBlocksFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type Magento2GetHostedProUrlArgs = {
  input: Magento2_HostedProUrlInput;
};

export type Magento2GetPayflowLinkTokenArgs = {
  input: Magento2_PayflowLinkTokenInput;
};

export type Magento2GiftCardAccountArgs = {
  input: Magento2_GiftCardAccountInput;
};

export type Magento2GiftRegistryArgs = {
  giftRegistryUid: Scalars['ID'];
};

export type Magento2GiftRegistryEmailSearchArgs = {
  email: Scalars['String'];
};

export type Magento2GiftRegistryIdSearchArgs = {
  giftRegistryUid: Scalars['ID'];
};

export type Magento2GiftRegistryTypeSearchArgs = {
  firstName: Scalars['String'];
  giftRegistryTypeUid?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
};

export type Magento2IsEmailAvailableArgs = {
  email: Scalars['String'];
};

export type Magento2PickupLocationsArgs = {
  area?: InputMaybe<Magento2_AreaInput>;
  currentPage?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Magento2_PickupLocationFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  productsInfo?: InputMaybe<Array<InputMaybe<Magento2_ProductInfoInput>>>;
  sort?: InputMaybe<Magento2_PickupLocationSortInput>;
};

export type Magento2ProductsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Magento2_ProductAttributeFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Magento2_ProductAttributeSortInput>;
};

export type Magento2ReturnRequestArgs = {
  comment_text?: InputMaybe<Scalars['String']>;
  contact_email: Scalars['String'];
  items: Array<InputMaybe<Magento2_ReturnRequestItemInput>>;
  order_uid: Scalars['String'];
  return_reason: Scalars['String'];
};

export type Magento2RouteArgs = {
  url: Scalars['String'];
};

export type Magento2UrlResolverArgs = {
  url: Scalars['String'];
};

export type Magento2Mutation = {
  __typename?: 'Magento2Mutation';
  /** Add one or more bundle products to the specified cart. We recommend using `addProductsToCart` instead. */
  addBundleProductsToCart?: Maybe<Magento2_AddBundleProductsToCartOutput>;
  /** Add one or more configurable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addConfigurableProductsToCart?: Maybe<Magento2_AddConfigurableProductsToCartOutput>;
  /** Add one or more downloadable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addDownloadableProductsToCart?: Maybe<Magento2_AddDownloadableProductsToCartOutput>;
  /** Add registrants to the specified gift registry. */
  addGiftRegistryRegistrants?: Maybe<Magento2_AddGiftRegistryRegistrantsOutput>;
  /** Add product to cart and return result */
  addProductToCart?: Maybe<Magento2_AddToCartOutput>;
  /** Add any type of product to the cart. */
  addProductsToCart?: Maybe<Magento2_AddProductsToCartOutput>;
  /** Add products to the specified compare list. */
  addProductsToCompareList?: Maybe<Magento2_CompareList>;
  /** Add one or more products to the specified wish list. This mutation supports all product types. */
  addProductsToWishlist?: Maybe<Magento2_AddProductsToWishlistOutput>;
  /** Add a comment to an existing return. */
  addReturnComment?: Maybe<Magento2_AddReturnCommentOutput>;
  /** Add tracking information to the return. */
  addReturnTracking?: Maybe<Magento2_AddReturnTrackingOutput>;
  /** Add one or more simple products to the specified cart. We recommend using `addProductsToCart` instead. */
  addSimpleProductsToCart?: Maybe<Magento2_AddSimpleProductsToCartOutput>;
  /** Saves a payment method on the logged in customer */
  addStripePaymentMethod?: Maybe<Magento2_StripePaymentMethod>;
  /** Add one or more virtual products to the specified cart. We recommend using `addProductsToCart` instead. */
  addVirtualProductsToCart?: Maybe<Magento2_AddVirtualProductsToCartOutput>;
  /** Add items in the specified wishlist to the customer's cart. */
  addWishlistItemsToCart?: Maybe<Magento2_AddWishlistItemsToCartOutput>;
  /** Apply a pre-defined coupon code to the specified cart. */
  applyCouponToCart?: Maybe<Magento2_ApplyCouponToCartOutput>;
  /** Apply a pre-defined gift card code to the specified cart. */
  applyGiftCardToCart?: Maybe<Magento2_ApplyGiftCardToCartOutput>;
  /** Apply store credit to the specified cart. */
  applyStoreCreditToCart?: Maybe<Magento2_ApplyStoreCreditToCartOutput>;
  /** Assign the specified compare list to the logged in customer. */
  assignCompareListToCustomer?: Maybe<Magento2_AssignCompareListToCustomerOutput>;
  /** Assign a logged-in customer to the specified guest shopping cart. */
  assignCustomerToGuestCart: Magento2_Cart;
  /** Capture Stripe Payment */
  captureStripePayment?: Maybe<Magento2_CaptureStripePaymentOutput>;
  /** Change the password for the logged-in customer. */
  changeCustomerPassword?: Maybe<Magento2_Customer>;
  /** Copy products from one wish list to another. The original wish list is unchanged. */
  copyProductsBetweenWishlists?: Maybe<Magento2_CopyProductsBetweenWishlistsOutput>;
  /** Create a new compare list. The compare list is saved for logged in customers. */
  createCompareList?: Maybe<Magento2_CompareList>;
  /** Use `createCustomerV2` instead. */
  createCustomer?: Maybe<Magento2_CustomerOutput>;
  /** Create a billing or shipping address for a customer or guest. */
  createCustomerAddress?: Maybe<Magento2_CustomerAddress>;
  /** Create a customer account. */
  createCustomerV2?: Maybe<Magento2_CustomerOutput>;
  /** Create an empty shopping cart for a guest or logged in user */
  createEmptyCart?: Maybe<Scalars['String']>;
  /** Create a gift registry on behalf of the customer. */
  createGiftRegistry?: Maybe<Magento2_CreateGiftRegistryOutput>;
  /** Initiate a transaction and receive a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken?: Maybe<Magento2_CreatePayflowProTokenOutput>;
  /** Initiate an Express Checkout transaction and receive a token. Use this mutation for Express Checkout and Payments Standard payment methods. */
  createPaypalExpressToken?: Maybe<Magento2_PaypalExpressTokenOutput>;
  /** Create a product review for the specified product. */
  createProductReview: Magento2_CreateProductReviewOutput;
  /** Create a new wish list. */
  createWishlist?: Maybe<Magento2_CreateWishlistOutput>;
  /** Delete the specified compare list. */
  deleteCompareList?: Maybe<Magento2_DeleteCompareListOutput>;
  /** Delete customer account */
  deleteCustomer?: Maybe<Scalars['Boolean']>;
  /** Delete the billing or shipping address of a customer. */
  deleteCustomerAddress?: Maybe<Scalars['Boolean']>;
  /** Delete a customer's payment token. */
  deletePaymentToken?: Maybe<Magento2_DeletePaymentTokenOutput>;
  /** Deletes a saved payment method from a logged in customer */
  deleteStripePaymentMethod?: Maybe<Scalars['String']>;
  /** Delete the specified wish list. You cannot delete the customer's default (first) wish list. */
  deleteWishlist?: Maybe<Magento2_DeleteWishlistOutput>;
  /** Generate a token for specified customer. */
  generateCustomerToken?: Maybe<Magento2_CustomerToken>;
  /** Handle a payment response and save the payment in Quote. Use this mutation for Payflow Pro and Payments Pro payment methods. */
  handlePayflowProResponse?: Maybe<Magento2_PayflowProResponseOutput>;
  /** List all saved payment methods of a logged in customer */
  listStripePaymentMethods?: Maybe<Array<Maybe<Magento2_StripePaymentMethod>>>;
  /** Transfer the contents of a guest cart into the cart of a logged-in customer. */
  mergeCarts: Magento2_Cart;
  /** Move all items from the cart to a gift registry. */
  moveCartItemsToGiftRegistry?: Maybe<Magento2_MoveCartItemsToGiftRegistryOutput>;
  /** Move products from one wish list to another. */
  moveProductsBetweenWishlists?: Maybe<Magento2_MoveProductsBetweenWishlistsOutput>;
  /** Convert the quote into an order. */
  placeOrder?: Maybe<Magento2_PlaceOrderOutput>;
  /** Place Stripe Order */
  placeStripeOrder?: Maybe<Magento2_PlaceStripeOrderOutput>;
  /** Redeem a gift card for store credit. */
  redeemGiftCardBalanceAsStoreCredit?: Maybe<Magento2_GiftCardAccount>;
  /** Set requried data required for redirect customer to payment page. */
  redirectAndPlaceOrder?: Maybe<Magento2_RedirectAndPlaceOrderOutput>;
  /** Remove a previously-applied coupon from the cart. The cart must contain at least one item in order to remove the coupon. */
  removeCouponFromCart?: Maybe<Magento2_RemoveCouponFromCartOutput>;
  /** Removes a gift card from the cart. */
  removeGiftCardFromCart?: Maybe<Magento2_RemoveGiftCardFromCartOutput>;
  /** Delete the specified gift registry. */
  removeGiftRegistry?: Maybe<Magento2_RemoveGiftRegistryOutput>;
  /** Delete the specified items from a gift registry. */
  removeGiftRegistryItems?: Maybe<Magento2_RemoveGiftRegistryItemsOutput>;
  /** Removes registrants from a gift registry. */
  removeGiftRegistryRegistrants?: Maybe<Magento2_RemoveGiftRegistryRegistrantsOutput>;
  /** Delete the entire quantity of a specified item from the cart. If you remove all items from the cart, the cart continues to exist. */
  removeItemFromCart?: Maybe<Magento2_RemoveItemFromCartOutput>;
  /** Remove products from the specified compare list. */
  removeProductsFromCompareList?: Maybe<Magento2_CompareList>;
  /** Remove one or more products from the specified wish list. */
  removeProductsFromWishlist?: Maybe<Magento2_RemoveProductsFromWishlistOutput>;
  /** Remove a tracked shipment from a return. */
  removeReturnTracking?: Maybe<Magento2_RemoveReturnTrackingOutput>;
  /** Remove store credit that has been applied to the specified cart. */
  removeStoreCreditFromCart?: Maybe<Magento2_RemoveStoreCreditFromCartOutput>;
  /** Add all products from a customer's previous order to the cart. */
  reorderItems?: Maybe<Magento2_ReorderItemsOutput>;
  /** Request an email with a reset password token for the registered customer identified by the specified email. */
  requestPasswordResetEmail?: Maybe<Scalars['Boolean']>;
  /** Initiates a buyer's request to return items for replacement or refund. */
  requestReturn?: Maybe<Magento2_RequestReturnOutput>;
  /** Reset a customer's password using the reset password token that the customer received in an email after requesting it using `requestPasswordResetEmail`. */
  resetPassword?: Maybe<Scalars['Boolean']>;
  /** Revoke the customer token. */
  revokeCustomerToken?: Maybe<Magento2_RevokeCustomerTokenOutput>;
  /** Send a message on behalf of a customer to the specified email addresses. */
  sendEmailToFriend?: Maybe<Magento2_SendEmailToFriendOutput>;
  /** Set the billing address on a specific cart. */
  setBillingAddressOnCart?: Maybe<Magento2_SetBillingAddressOnCartOutput>;
  /** Set gift options, including gift messages, gift wrapping, gift receipts, and printed cards. */
  setGiftOptionsOnCart?: Maybe<Magento2_SetGiftOptionsOnCartOutput>;
  /** Assign the email address of a guest to the cart. */
  setGuestEmailOnCart?: Maybe<Magento2_SetGuestEmailOnCartOutput>;
  /**
   * Set the cart payment method and convert the cart into an order.
   * @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request.
   */
  setPaymentMethodAndPlaceOrder?: Maybe<Magento2_PlaceOrderOutput>;
  /** Apply a payment method to the cart. */
  setPaymentMethodOnCart?: Maybe<Magento2_SetPaymentMethodOnCartOutput>;
  /** Set one or more shipping addresses on a specific cart. */
  setShippingAddressesOnCart?: Maybe<Magento2_SetShippingAddressesOnCartOutput>;
  /** Set one or more delivery methods on a cart. */
  setShippingMethodsOnCart?: Maybe<Magento2_SetShippingMethodsOnCartOutput>;
  /** Send an email about the gift registry to a list of invitees. */
  shareGiftRegistry?: Maybe<Magento2_ShareGiftRegistryOutput>;
  /** Generate Stripe Payment Intent */
  stripePaymentIntent?: Maybe<Magento2_StripePaymentIntentOutput>;
  /** Modify items in the cart. */
  updateCartItems?: Maybe<Magento2_UpdateCartItemsOutput>;
  /** Use `updateCustomerV2` instead. */
  updateCustomer?: Maybe<Magento2_CustomerOutput>;
  /** Update the billing or shipping address of a customer or guest. */
  updateCustomerAddress?: Maybe<Magento2_CustomerAddress>;
  /** Change the email address for the logged-in customer. */
  updateCustomerEmail?: Maybe<Magento2_CustomerOutput>;
  /** Update the customer's personal information. */
  updateCustomerV2?: Maybe<Magento2_CustomerOutput>;
  /** Update the specified gift registry. */
  updateGiftRegistry?: Maybe<Magento2_UpdateGiftRegistryOutput>;
  /** Update the specified items in the gift registry. */
  updateGiftRegistryItems?: Maybe<Magento2_UpdateGiftRegistryItemsOutput>;
  /** Modify the properties of one or more gift registry registrants. */
  updateGiftRegistryRegistrants?: Maybe<Magento2_UpdateGiftRegistryRegistrantsOutput>;
  /** Update one or more products in the specified wish list. */
  updateProductsInWishlist?: Maybe<Magento2_UpdateProductsInWishlistOutput>;
  /** Change the name and visibility of the specified wish list. */
  updateWishlist?: Maybe<Magento2_UpdateWishlistOutput>;
};

export type Magento2MutationAddBundleProductsToCartArgs = {
  input?: InputMaybe<Magento2_AddBundleProductsToCartInput>;
};

export type Magento2MutationAddConfigurableProductsToCartArgs = {
  input?: InputMaybe<Magento2_AddConfigurableProductsToCartInput>;
};

export type Magento2MutationAddDownloadableProductsToCartArgs = {
  input?: InputMaybe<Magento2_AddDownloadableProductsToCartInput>;
};

export type Magento2MutationAddGiftRegistryRegistrantsArgs = {
  giftRegistryUid: Scalars['ID'];
  registrants: Array<Magento2_AddGiftRegistryRegistrantInput>;
};

export type Magento2MutationAddProductToCartArgs = {
  input: Magento2_AddProductToCartInput;
};

export type Magento2MutationAddProductsToCartArgs = {
  cartId: Scalars['String'];
  cartItems: Array<Magento2_CartItemInput>;
};

export type Magento2MutationAddProductsToCompareListArgs = {
  input?: InputMaybe<Magento2_AddProductsToCompareListInput>;
};

export type Magento2MutationAddProductsToWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<Magento2_WishlistItemInput>;
};

export type Magento2MutationAddReturnCommentArgs = {
  input: Magento2_AddReturnCommentInput;
};

export type Magento2MutationAddReturnTrackingArgs = {
  input: Magento2_AddReturnTrackingInput;
};

export type Magento2MutationAddSimpleProductsToCartArgs = {
  input?: InputMaybe<Magento2_AddSimpleProductsToCartInput>;
};

export type Magento2MutationAddStripePaymentMethodArgs = {
  input: Magento2_StripePaymentMethodId;
};

export type Magento2MutationAddVirtualProductsToCartArgs = {
  input?: InputMaybe<Magento2_AddVirtualProductsToCartInput>;
};

export type Magento2MutationAddWishlistItemsToCartArgs = {
  wishlistId: Scalars['ID'];
  wishlistItemIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Magento2MutationApplyCouponToCartArgs = {
  input?: InputMaybe<Magento2_ApplyCouponToCartInput>;
};

export type Magento2MutationApplyGiftCardToCartArgs = {
  input?: InputMaybe<Magento2_ApplyGiftCardToCartInput>;
};

export type Magento2MutationApplyStoreCreditToCartArgs = {
  input: Magento2_ApplyStoreCreditToCartInput;
};

export type Magento2MutationAssignCompareListToCustomerArgs = {
  uid: Scalars['ID'];
};

export type Magento2MutationAssignCustomerToGuestCartArgs = {
  cart_id: Scalars['String'];
};

export type Magento2MutationCaptureStripePaymentArgs = {
  input: Magento2_CaptureStripePaymentInput;
};

export type Magento2MutationChangeCustomerPasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Magento2MutationCopyProductsBetweenWishlistsArgs = {
  destinationWishlistUid: Scalars['ID'];
  sourceWishlistUid: Scalars['ID'];
  wishlistItems: Array<Magento2_WishlistItemCopyInput>;
};

export type Magento2MutationCreateCompareListArgs = {
  input?: InputMaybe<Magento2_CreateCompareListInput>;
};

export type Magento2MutationCreateCustomerArgs = {
  input: Magento2_CustomerInput;
};

export type Magento2MutationCreateCustomerAddressArgs = {
  input: Magento2_CustomerAddressInput;
};

export type Magento2MutationCreateCustomerV2Args = {
  input: Magento2_CustomerCreateInput;
};

export type Magento2MutationCreateEmptyCartArgs = {
  input?: InputMaybe<Magento2_CreateEmptyCartInput>;
};

export type Magento2MutationCreateGiftRegistryArgs = {
  giftRegistry: Magento2_CreateGiftRegistryInput;
};

export type Magento2MutationCreatePayflowProTokenArgs = {
  input: Magento2_PayflowProTokenInput;
};

export type Magento2MutationCreatePaypalExpressTokenArgs = {
  input: Magento2_PaypalExpressTokenInput;
};

export type Magento2MutationCreateProductReviewArgs = {
  input: Magento2_CreateProductReviewInput;
};

export type Magento2MutationCreateWishlistArgs = {
  input: Magento2_CreateWishlistInput;
};

export type Magento2MutationDeleteCompareListArgs = {
  uid: Scalars['ID'];
};

export type Magento2MutationDeleteCustomerAddressArgs = {
  id: Scalars['Int'];
};

export type Magento2MutationDeletePaymentTokenArgs = {
  public_hash: Scalars['String'];
};

export type Magento2MutationDeleteStripePaymentMethodArgs = {
  input: Magento2_StripePaymentMethodId;
};

export type Magento2MutationDeleteWishlistArgs = {
  wishlistId: Scalars['ID'];
};

export type Magento2MutationGenerateCustomerTokenArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Magento2MutationHandlePayflowProResponseArgs = {
  input: Magento2_PayflowProResponseInput;
};

export type Magento2MutationMergeCartsArgs = {
  destination_cart_id?: InputMaybe<Scalars['String']>;
  source_cart_id: Scalars['String'];
};

export type Magento2MutationMoveCartItemsToGiftRegistryArgs = {
  cartUid: Scalars['ID'];
  giftRegistryUid: Scalars['ID'];
};

export type Magento2MutationMoveProductsBetweenWishlistsArgs = {
  destinationWishlistUid: Scalars['ID'];
  sourceWishlistUid: Scalars['ID'];
  wishlistItems: Array<Magento2_WishlistItemMoveInput>;
};

export type Magento2MutationPlaceOrderArgs = {
  input?: InputMaybe<Magento2_PlaceOrderInput>;
};

export type Magento2MutationPlaceStripeOrderArgs = {
  input: Magento2_PlaceStripeOrderInput;
};

export type Magento2MutationRedeemGiftCardBalanceAsStoreCreditArgs = {
  input: Magento2_GiftCardAccountInput;
};

export type Magento2MutationRedirectAndPlaceOrderArgs = {
  input: Magento2_RedirectAndPlaceOrderInput;
};

export type Magento2MutationRemoveCouponFromCartArgs = {
  input?: InputMaybe<Magento2_RemoveCouponFromCartInput>;
};

export type Magento2MutationRemoveGiftCardFromCartArgs = {
  input?: InputMaybe<Magento2_RemoveGiftCardFromCartInput>;
};

export type Magento2MutationRemoveGiftRegistryArgs = {
  giftRegistryUid: Scalars['ID'];
};

export type Magento2MutationRemoveGiftRegistryItemsArgs = {
  giftRegistryUid: Scalars['ID'];
  itemsUid: Array<Scalars['ID']>;
};

export type Magento2MutationRemoveGiftRegistryRegistrantsArgs = {
  giftRegistryUid: Scalars['ID'];
  registrantsUid: Array<Scalars['ID']>;
};

export type Magento2MutationRemoveItemFromCartArgs = {
  input?: InputMaybe<Magento2_RemoveItemFromCartInput>;
};

export type Magento2MutationRemoveProductsFromCompareListArgs = {
  input?: InputMaybe<Magento2_RemoveProductsFromCompareListInput>;
};

export type Magento2MutationRemoveProductsFromWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItemsIds: Array<Scalars['ID']>;
};

export type Magento2MutationRemoveReturnTrackingArgs = {
  input: Magento2_RemoveReturnTrackingInput;
};

export type Magento2MutationRemoveStoreCreditFromCartArgs = {
  input: Magento2_RemoveStoreCreditFromCartInput;
};

export type Magento2MutationReorderItemsArgs = {
  orderNumber: Scalars['String'];
};

export type Magento2MutationRequestPasswordResetEmailArgs = {
  email: Scalars['String'];
};

export type Magento2MutationRequestReturnArgs = {
  input: Magento2_RequestReturnInput;
};

export type Magento2MutationResetPasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type Magento2MutationSendEmailToFriendArgs = {
  input?: InputMaybe<Magento2_SendEmailToFriendInput>;
};

export type Magento2MutationSetBillingAddressOnCartArgs = {
  input?: InputMaybe<Magento2_SetBillingAddressOnCartInput>;
};

export type Magento2MutationSetGiftOptionsOnCartArgs = {
  input?: InputMaybe<Magento2_SetGiftOptionsOnCartInput>;
};

export type Magento2MutationSetGuestEmailOnCartArgs = {
  input?: InputMaybe<Magento2_SetGuestEmailOnCartInput>;
};

export type Magento2MutationSetPaymentMethodAndPlaceOrderArgs = {
  input?: InputMaybe<Magento2_SetPaymentMethodAndPlaceOrderInput>;
};

export type Magento2MutationSetPaymentMethodOnCartArgs = {
  input?: InputMaybe<Magento2_SetPaymentMethodOnCartInput>;
};

export type Magento2MutationSetShippingAddressesOnCartArgs = {
  input?: InputMaybe<Magento2_SetShippingAddressesOnCartInput>;
};

export type Magento2MutationSetShippingMethodsOnCartArgs = {
  input?: InputMaybe<Magento2_SetShippingMethodsOnCartInput>;
};

export type Magento2MutationShareGiftRegistryArgs = {
  giftRegistryUid: Scalars['ID'];
  invitees: Array<Magento2_ShareGiftRegistryInviteeInput>;
  sender: Magento2_ShareGiftRegistrySenderInput;
};

export type Magento2MutationStripePaymentIntentArgs = {
  input: Magento2_StripePaymentIntentInput;
};

export type Magento2MutationUpdateCartItemsArgs = {
  input?: InputMaybe<Magento2_UpdateCartItemsInput>;
};

export type Magento2MutationUpdateCustomerArgs = {
  input: Magento2_CustomerInput;
};

export type Magento2MutationUpdateCustomerAddressArgs = {
  id: Scalars['Int'];
  input?: InputMaybe<Magento2_CustomerAddressInput>;
};

export type Magento2MutationUpdateCustomerEmailArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Magento2MutationUpdateCustomerV2Args = {
  input: Magento2_CustomerUpdateInput;
};

export type Magento2MutationUpdateGiftRegistryArgs = {
  giftRegistry: Magento2_UpdateGiftRegistryInput;
  giftRegistryUid: Scalars['ID'];
};

export type Magento2MutationUpdateGiftRegistryItemsArgs = {
  giftRegistryUid: Scalars['ID'];
  items: Array<Magento2_UpdateGiftRegistryItemInput>;
};

export type Magento2MutationUpdateGiftRegistryRegistrantsArgs = {
  giftRegistryUid: Scalars['ID'];
  registrants: Array<Magento2_UpdateGiftRegistryRegistrantInput>;
};

export type Magento2MutationUpdateProductsInWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<Magento2_WishlistItemUpdateInput>;
};

export type Magento2MutationUpdateWishlistArgs = {
  name?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Magento2_WishlistVisibilityEnum>;
  wishlistId: Scalars['ID'];
};

/** Defines the bundle products to add to the cart. */
export type Magento2_AddBundleProductsToCartInput = {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of bundle products to add. */
  cart_items: Array<InputMaybe<Magento2_BundleProductCartItemInput>>;
};

/** Contains details about the cart after adding bundle products. */
export type Magento2_AddBundleProductsToCartOutput = {
  __typename?: 'Magento2_AddBundleProductsToCartOutput';
  /** The cart after adding products. */
  cart: Magento2_Cart;
};

/** Defines the configurable products to add to the cart. */
export type Magento2_AddConfigurableProductsToCartInput = {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of configurable products to add. */
  cart_items: Array<InputMaybe<Magento2_ConfigurableProductCartItemInput>>;
};

/** Contains details about the cart after adding configurable products. */
export type Magento2_AddConfigurableProductsToCartOutput = {
  __typename?: 'Magento2_AddConfigurableProductsToCartOutput';
  /** The cart after adding products. */
  cart: Magento2_Cart;
};

export type Magento2_AddDownloadableProductsToCartInput = {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of downloadable products to add. */
  cart_items: Array<InputMaybe<Magento2_DownloadableProductCartItemInput>>;
};

/** Contains details about the cart after adding downloadable products. */
export type Magento2_AddDownloadableProductsToCartOutput = {
  __typename?: 'Magento2_AddDownloadableProductsToCartOutput';
  /** The cart after adding products. */
  cart: Magento2_Cart;
};

/** Defines an item to add to the gift registry. */
export type Magento2_AddGiftRegistryItemInput = {
  /** An array of options the customer has entered. */
  entered_options?: InputMaybe<Array<InputMaybe<Magento2_EnteredOptionInput>>>;
  /** A brief note about the item. */
  note?: InputMaybe<Scalars['String']>;
  /** For complex product types, the SKU of the parent product. */
  parent_sku?: InputMaybe<Scalars['String']>;
  /** The quantity of the product to add. */
  quantity: Scalars['Float'];
  /** An array of strings corresponding to options the customer has selected. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The SKU of the product to add to the gift registry. */
  sku: Scalars['String'];
};

/** Defines a new registrant. */
export type Magento2_AddGiftRegistryRegistrantInput = {
  /** Additional attributes specified as a code-value pair. */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<Magento2_GiftRegistryDynamicAttributeInput>>
  >;
  /** The email address of the registrant. */
  email: Scalars['String'];
  /** The first name of the registrant. */
  firstname: Scalars['String'];
  /** The last name of the registrant. */
  lastname: Scalars['String'];
};

/** Contains the results of a request to add registrants. */
export type Magento2_AddGiftRegistryRegistrantsOutput = {
  __typename?: 'Magento2_AddGiftRegistryRegistrantsOutput';
  /** The gift registry after adding registrants. */
  giftRegistry?: Maybe<Magento2_GiftRegistry>;
};

export type Magento2_AddProductToCartInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String'];
  /** Product Qty */
  qty: Scalars['Int'];
  /** Product SKU */
  sku: Scalars['String'];
};

/** Contains details about the cart after adding products to it. */
export type Magento2_AddProductsToCartOutput = {
  __typename?: 'Magento2_AddProductsToCartOutput';
  /** The cart after products have been added. */
  cart: Magento2_Cart;
  /** Contains errors encountered while adding an item to the cart. */
  userErrors: Array<Maybe<Magento2_CartUserInputError>>;
};

/** Contains products to add to an existing compare list. */
export type Magento2_AddProductsToCompareListInput = {
  /** An array of product IDs to add to the compare list. */
  products: Array<InputMaybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID'];
};

/** Contains the customer's wish list and any errors encountered. */
export type Magento2_AddProductsToWishlistOutput = {
  __typename?: 'Magento2_AddProductsToWishlistOutput';
  /** An array of errors encountered while adding products to a wish list. */
  userErrors: Array<Maybe<Magento2_WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Magento2_Wishlist;
};

/** Defines a return comment. */
export type Magento2_AddReturnCommentInput = {
  /** The text added to the return request. */
  comment_text: Scalars['String'];
  /** The unique ID for a `Return` object. */
  return_uid: Scalars['ID'];
};

/** Contains details about the return request. */
export type Magento2_AddReturnCommentOutput = {
  __typename?: 'Magento2_AddReturnCommentOutput';
  /** The modified return. */
  return?: Maybe<Magento2_Return>;
};

/** Defines tracking information to be added to the return. */
export type Magento2_AddReturnTrackingInput = {
  /** The unique ID for a `ReturnShippingCarrier` object. */
  carrier_uid: Scalars['ID'];
  /** The unique ID for a `Returns` object. */
  return_uid: Scalars['ID'];
  /** The shipping tracking number for this return request. */
  tracking_number: Scalars['String'];
};

/** Contains the response after adding tracking information. */
export type Magento2_AddReturnTrackingOutput = {
  __typename?: 'Magento2_AddReturnTrackingOutput';
  /** Details about the modified return. */
  return?: Maybe<Magento2_Return>;
  /** Details about shipping for a return. */
  returnShippingTracking?: Maybe<Magento2_ReturnShippingTracking>;
};

/** Defines the simple and group products to add to the cart. */
export type Magento2_AddSimpleProductsToCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of simple and group items to add. */
  cart_items: Array<InputMaybe<Magento2_SimpleProductCartItemInput>>;
};

/** Contains details about the cart after adding simple or group products. */
export type Magento2_AddSimpleProductsToCartOutput = {
  __typename?: 'Magento2_AddSimpleProductsToCartOutput';
  /** The cart after adding products. */
  cart: Magento2_Cart;
};

export type Magento2_AddToCartOutput = {
  __typename?: 'Magento2_AddToCartOutput';
  /** Payment page where customer should be redirected to pay and place order. */
  message?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Payment page where customer should be redirected to pay and place order. */
  success?: Maybe<Scalars['Boolean']>;
};

/** Defines the virtual products to add to the cart. */
export type Magento2_AddVirtualProductsToCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of virtual products to add. */
  cart_items: Array<InputMaybe<Magento2_VirtualProductCartItemInput>>;
};

/** Contains details about the cart after adding virtual products. */
export type Magento2_AddVirtualProductsToCartOutput = {
  __typename?: 'Magento2_AddVirtualProductsToCartOutput';
  /** The cart after adding products. */
  cart: Magento2_Cart;
};

/** Contains the resultant wish list and any error information. */
export type Magento2_AddWishlistItemsToCartOutput = {
  __typename?: 'Magento2_AddWishlistItemsToCartOutput';
  /** An array of errors encountered while adding products to the customer's cart. */
  addWishlistItemsToCartUserErrors: Array<
    Maybe<Magento2_WishlistCartUserInputError>
  >;
  /** Indicates whether the attempt to add items to the customer's cart was successful. */
  status: Scalars['Boolean'];
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Magento2_Wishlist;
};

/** Contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export type Magento2_Aggregation = {
  __typename?: 'Magento2_Aggregation';
  /** Attribute code of the aggregation group. */
  attributeCode: Scalars['String'];
  /** The number of options in the aggregation group. */
  count?: Maybe<Scalars['Int']>;
  /** The aggregation display name. */
  label?: Maybe<Scalars['String']>;
  /** Array of options for the aggregation. */
  options?: Maybe<Array<Maybe<Magento2_AggregationOption>>>;
  /** The relative position of the attribute in a layered navigation block. */
  position?: Maybe<Scalars['Int']>;
};

/** An implementation of `AggregationOptionInterface`. */
export type Magento2_AggregationOption = Magento2_AggregationOptionInterface & {
  __typename?: 'Magento2_AggregationOption';
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>;
  /** The display label for an aggregation option. */
  label?: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
};

/** Defines aggregation option fields. */
export type Magento2_AggregationOptionInterface = {
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>;
  /** The display label for an aggregation option. */
  label?: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
};

/** Filter category aggregations in layered navigation. */
export type Magento2_AggregationsCategoryFilterInput = {
  /** Indicates whether to include only direct subcategories or all children categories at all levels. */
  includeDirectChildrenOnly?: InputMaybe<Scalars['Boolean']>;
};

/** An input object that specifies the filters used in product aggregations. */
export type Magento2_AggregationsFilterInput = {
  /** Filter category aggregations in layered navigation. */
  category?: InputMaybe<Magento2_AggregationsCategoryFilterInput>;
};

/** Contains the applied coupon code. */
export type Magento2_AppliedCoupon = {
  __typename?: 'Magento2_AppliedCoupon';
  /** The coupon code the shopper applied to the card. */
  code: Scalars['String'];
};

/** Contains an applied gift card with applied and remaining balance. */
export type Magento2_AppliedGiftCard = {
  __typename?: 'Magento2_AppliedGiftCard';
  /** The amount applied to the current cart. */
  appliedBalance?: Maybe<Magento2_Money>;
  /** The gift card account code. */
  code?: Maybe<Scalars['String']>;
  /** The remaining balance on the gift card. */
  currentBalance?: Maybe<Magento2_Money>;
  /** The expiration date of the gift card. */
  expirationDate?: Maybe<Scalars['String']>;
};

/** Contains the applied and current balances. */
export type Magento2_AppliedStoreCredit = {
  __typename?: 'Magento2_AppliedStoreCredit';
  /** The applied store credit balance to the current cart. */
  appliedBalance?: Maybe<Magento2_Money>;
  /** The current balance remaining on store credit. */
  currentBalance?: Maybe<Magento2_Money>;
  /** Indicates whether store credits are enabled. If the feature is disabled, then the current balance will not be returned. */
  enabled?: Maybe<Scalars['Boolean']>;
};

/** Specifies the coupon code to apply to the cart. */
export type Magento2_ApplyCouponToCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** A valid coupon code. */
  coupon_code: Scalars['String'];
};

/** Contains details about the cart after applying a coupon. */
export type Magento2_ApplyCouponToCartOutput = {
  __typename?: 'Magento2_ApplyCouponToCartOutput';
  /** The cart after applying a coupon. */
  cart: Magento2_Cart;
};

/** Defines the input required to run the `applyGiftCardToCart` mutation. */
export type Magento2_ApplyGiftCardToCartInput = {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
  /** The gift card code to be applied to the cart. */
  gift_card_code: Scalars['String'];
};

/** Defines the possible output for the `applyGiftCardToCart` mutation. */
export type Magento2_ApplyGiftCardToCartOutput = {
  __typename?: 'Magento2_ApplyGiftCardToCartOutput';
  /** Describes the contents of the specified shopping cart. */
  cart: Magento2_Cart;
};

/** Defines the input required to run the `applyStoreCreditToCart` mutation. */
export type Magento2_ApplyStoreCreditToCartInput = {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
};

/** Defines the possible output for the `applyStoreCreditToCart` mutation. */
export type Magento2_ApplyStoreCreditToCartOutput = {
  __typename?: 'Magento2_ApplyStoreCreditToCartOutput';
  /** The contents of the specified shopping cart. */
  cart: Magento2_Cart;
};

/** AreaInput defines the parameters which will be used for filter by specified location. */
export type Magento2_AreaInput = {
  /** The radius for the search in KM. */
  radius: Scalars['Int'];
  /** The country code where search must be performed. Required parameter together with region, city or postcode. */
  search_term: Scalars['String'];
};

/** Contains the results of the request to assign a compare list. */
export type Magento2_AssignCompareListToCustomerOutput = {
  __typename?: 'Magento2_AssignCompareListToCustomerOutput';
  /** The contents of the customer's compare list. */
  compareList?: Maybe<Magento2_CompareList>;
  /** Indicates whether the compare list was successfully assigned to the customer. */
  result: Scalars['Boolean'];
};

/** Contains details about the attribute, including the code and type. */
export type Magento2_Attribute = {
  __typename?: 'Magento2_Attribute';
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attributeCode?: Maybe<Scalars['String']>;
  /** Attribute options list. */
  attributeOptions?: Maybe<Array<Maybe<Magento2_AttributeOption>>>;
  /** The data type of the attribute. */
  attributeType?: Maybe<Scalars['String']>;
  /** The type of entity that defines the attribute. */
  entityType?: Maybe<Scalars['String']>;
  /** The frontend input type of the attribute. */
  inputType?: Maybe<Scalars['String']>;
  /** Details about the storefront properties configured for the attribute. */
  storefrontProperties?: Maybe<Magento2_StorefrontProperties>;
};

/** Defines the attribute characteristics to search for the `attribute_code` and `entity_type` to search. */
export type Magento2_AttributeInput = {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: InputMaybe<Scalars['String']>;
  /** The type of entity that defines the attribute. */
  entity_type?: InputMaybe<Scalars['String']>;
};

/** Defines an attribute option. */
export type Magento2_AttributeOption = {
  __typename?: 'Magento2_AttributeOption';
  /** The label assigned to the attribute option. */
  label?: Maybe<Scalars['String']>;
  /** The attribute option value. */
  value?: Maybe<Scalars['String']>;
};

export type Magento2_AvailableAgreement = {
  __typename?: 'Magento2_AvailableAgreement';
  /** Checkout Agreement checkbox text */
  checkboxText: Scalars['String'];
  /** Checkout Agreement content */
  content: Scalars['String'];
  /** Checkout Agreement content height */
  contentHeight?: Maybe<Scalars['String']>;
  /** Checkout Agreement identifier */
  id: Scalars['Int'];
  /** Is Checkout Agreement content in HTML format */
  isHtml: Scalars['Boolean'];
  mode: Magento2_AvailableCheckoutAgreementMode;
  /** Checkout Agreement name */
  name: Scalars['String'];
};

export enum Magento2_AvailableCheckoutAgreementMode {
  /** @deprecated  */
  Auto = 'AUTO',
  /** @deprecated  */
  Manual = 'MANUAL',
}

/** Describes a payment method that the shopper can use to pay for the order. */
export type Magento2_AvailablePaymentMethod = {
  __typename?: 'Magento2_AvailablePaymentMethod';
  /** The payment method code. */
  code: Scalars['String'];
  /** If the payment method is an online integration */
  isDeferred: Scalars['Boolean'];
  /** The payment method title. */
  title: Scalars['String'];
};

/** Contains details about the possible shipping methods and carriers. */
export type Magento2_AvailableShippingMethod = {
  __typename?: 'Magento2_AvailableShippingMethod';
  /** The cost of shipping using this shipping method. */
  amount: Magento2_Money;
  /** Indicates whether this shipping method can be applied to the cart. */
  available: Scalars['Boolean'];
  /** @deprecated The field should not be used on the storefront. */
  baseAmount?: Maybe<Magento2_Money>;
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrierCode: Scalars['String'];
  /** The label for the carrier code. */
  carrierTitle: Scalars['String'];
  /** Describes an error condition. */
  errorMessage?: Maybe<Scalars['String']>;
  /** A shipping method code associated with a carrier. The value could be null if no method is available. */
  methodCode?: Maybe<Scalars['String']>;
  /** The label for the shipping method code. The value could be null if no method is available. */
  methodTitle?: Maybe<Scalars['String']>;
  /** The cost of shipping using this shipping method, excluding tax. */
  priceExclTax: Magento2_Money;
  /** The cost of shipping using this shipping method, including tax. */
  priceInclTax: Magento2_Money;
};

export enum Magento2_BatchMutationStatus {
  /** @deprecated  */
  Failure = 'FAILURE',
  /** @deprecated  */
  MixedResults = 'MIXED_RESULTS',
  /** @deprecated  */
  Success = 'SUCCESS',
}

/** Defines the billing address. */
export type Magento2_BillingAddressInput = {
  /** Defines a billing address. */
  address?: InputMaybe<Magento2_CartAddressInput>;
  /** An ID from the customer's address book that uniquely identifies the address to be used for billing. */
  customer_address_id?: InputMaybe<Scalars['Int']>;
  /** Indicates whether to set the billing address to be the same as the existing shipping address on the cart. */
  same_as_shipping?: InputMaybe<Scalars['Boolean']>;
  /** Indicates whether to set the shipping address to be the same as this billing address. */
  use_for_shipping?: InputMaybe<Scalars['Boolean']>;
};

/** Contains details about the billing address. */
export type Magento2_BillingCartAddress = Magento2_CartAddressInterface & {
  __typename?: 'Magento2_BillingCartAddress';
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country label and code. */
  country: Magento2_CartAddressCountry;
  /** @deprecated The field is used only in shipping address. */
  customerNotes?: Maybe<Scalars['String']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  /** An object containing the region label and code. */
  region?: Maybe<Magento2_CartAddressRegion>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
  /** The unique id of the customer address. */
  uid: Scalars['String'];
  /** The VAT company number for billing or shipping address. */
  vatId?: Maybe<Scalars['String']>;
};

/** Contains details about an individual category that comprises a breadcrumb. */
export type Magento2_Breadcrumb = {
  __typename?: 'Magento2_Breadcrumb';
  /**
   * The ID of the category.
   * @deprecated Use `category_uid` instead.
   */
  categoryId?: Maybe<Scalars['Int']>;
  /** The category level. */
  categoryLevel?: Maybe<Scalars['Int']>;
  /** The display name of the category. */
  categoryName?: Maybe<Scalars['String']>;
  /** The unique ID for a `Breadcrumb` object. */
  categoryUid: Scalars['ID'];
  /** The URL key of the category. */
  categoryUrlKey?: Maybe<Scalars['String']>;
  /** The URL path of the category. */
  categoryUrlPath?: Maybe<Scalars['String']>;
};

/** An implementation for bundle product cart items. */
export type Magento2_BundleCartItem = Magento2_CartItemInterface & {
  __typename?: 'Magento2_BundleCartItem';
  /** The list of available gift wrapping options for the cart item. */
  availableGiftWrapping: Array<Maybe<Magento2_GiftWrapping>>;
  /** An array containing the bundle options the shopper selected. */
  bundleOptions: Array<Maybe<Magento2_SelectedBundleOption>>;
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<Magento2_CartItemError>>>;
  /** The entered gift message for the cart item */
  giftMessage?: Maybe<Magento2_GiftMessage>;
  /** The selected gift wrapping for the cart item. */
  giftWrapping?: Maybe<Magento2_GiftWrapping>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<Magento2_CartItemPrices>;
  /** Details about an item in the cart. */
  product: Magento2_ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines bundle product options for `CreditMemoItemInterface`. */
export type Magento2_BundleCreditMemoItem = Magento2_CreditMemoItemInterface & {
  __typename?: 'Magento2_BundleCreditMemoItem';
  /** A list of bundle options that are assigned to a bundle product that is part of a credit memo. */
  bundleOptions?: Maybe<Array<Maybe<Magento2_ItemSelectedBundleOption>>>;
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of refunded items. */
  quantityRefunded?: Maybe<Scalars['Float']>;
};

/** Defines bundle product options for `InvoiceItemInterface`. */
export type Magento2_BundleInvoiceItem = Magento2_InvoiceItemInterface & {
  __typename?: 'Magento2_BundleInvoiceItem';
  /** A list of bundle options that are assigned to an invoiced bundle product. */
  bundleOptions?: Maybe<Array<Maybe<Magento2_ItemSelectedBundleOption>>>;
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of invoiced items. */
  quantityInvoiced?: Maybe<Scalars['Float']>;
};

/** Defines an individual item within a bundle product. */
export type Magento2_BundleItem = {
  __typename?: 'Magento2_BundleItem';
  /**
   * An ID assigned to each type of item in a bundle product.
   * @deprecated Use `uid` instead
   */
  optionId?: Maybe<Scalars['Int']>;
  /** An array of additional options for this bundle item. */
  options?: Maybe<Array<Maybe<Magento2_BundleItemOption>>>;
  /** A number indicating the sequence order of this item compared to the other bundle items. */
  position?: Maybe<Scalars['Int']>;
  /** The range of prices for the product */
  priceRange: Magento2_PriceRange;
  /** Indicates whether the item must be included in the bundle. */
  required?: Maybe<Scalars['Boolean']>;
  /** The SKU of the bundle product. */
  sku?: Maybe<Scalars['String']>;
  /** The display name of the item. */
  title?: Maybe<Scalars['String']>;
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type?: Maybe<Scalars['String']>;
  /** The unique ID for a `BundleItem` object. */
  uid?: Maybe<Scalars['ID']>;
};

/** Defines the characteristics that comprise a specific bundle item and its options. */
export type Magento2_BundleItemOption = {
  __typename?: 'Magento2_BundleItemOption';
  /** Indicates whether the customer can change the number of items for this option. */
  canChangeQuantity?: Maybe<Scalars['Boolean']>;
  /**
   * The ID assigned to the bundled item option.
   * @deprecated Use `uid` instead
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether this option is the default option. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** The text that identifies the bundled item option. */
  label?: Maybe<Scalars['String']>;
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position?: Maybe<Scalars['Int']>;
  /** The price of the selected option. */
  price?: Maybe<Scalars['Float']>;
  /** One of FIXED, PERCENT, or DYNAMIC. */
  priceType?: Maybe<Magento2_PriceTypeEnum>;
  /** Contains details about this product option. */
  product?: Maybe<Magento2_ProductInterface>;
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated Use `quantity` instead.
   */
  qty?: Maybe<Scalars['Float']>;
  /** The quantity of this specific bundle item. */
  quantity?: Maybe<Scalars['Float']>;
  /** The unique ID for a `BundleItemOption` object. */
  uid: Scalars['ID'];
};

/** Defines the input for a bundle option. */
export type Magento2_BundleOptionInput = {
  /** The ID of the option. */
  id: Scalars['Int'];
  /** The number of the selected item to add to the cart. */
  quantity: Scalars['Float'];
  /** An array with the chosen value of the option. */
  value: Array<InputMaybe<Scalars['String']>>;
};

/** Defines bundle product options for `OrderItemInterface`. */
export type Magento2_BundleOrderItem = Magento2_OrderItemInterface & {
  __typename?: 'Magento2_BundleOrderItem';
  /** A list of bundle options that are assigned to the bundle product. */
  bundleOptions?: Maybe<Array<Maybe<Magento2_ItemSelectedBundleOption>>>;
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn?: Maybe<Scalars['Boolean']>;
  /** The entered option for the base product, such as a logo or image */
  enteredOptions?: Maybe<Array<Maybe<Magento2_OrderItemOption>>>;
  /** The selected gift message for the order item */
  giftMessage?: Maybe<Magento2_GiftMessage>;
  /** The selected gift wrapping for the order item. */
  giftWrapping?: Maybe<Magento2_GiftWrapping>;
  /** The unique identifier of the order item */
  id: Scalars['ID'];
  /** The path to product thumbnail */
  productImage?: Maybe<Scalars['String']>;
  /** The name of the base product */
  productName?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  productSalePrice: Magento2_Money;
  /** The sale price of the base product including tax, including selected options */
  productSalePriceIncludingTax: Magento2_Money;
  /** The serial system of the product, such as SDBS, IZO_DB, etc. */
  productSerialSystem?: Maybe<Scalars['String']>;
  /** The SKU of the base product */
  productSku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  productType?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  productUrlKey?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantityCanceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantityInvoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantityOrdered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantityRefunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantityReturned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantityShipped?: Maybe<Scalars['Float']>;
  /** The return item status */
  returnStatus?: Maybe<Scalars['String']>;
  /** The row total of the order item. */
  rowTotal: Magento2_Money;
  /** The row total including tax of the order item. */
  rowTotalIncludingTax: Magento2_Money;
  /** The selected options for the base product, such as color or size */
  selectedOptions?: Maybe<Array<Maybe<Magento2_OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
};

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type Magento2_BundleProduct = Magento2_CustomizableProductInterface &
  Magento2_PhysicalProductInterface &
  Magento2_ProductInterface &
  Magento2_RoutableInterface & {
    __typename?: 'Magento2_BundleProduct';
    /** Additional information about variant */
    additionalInfo?: Maybe<Scalars['String']>;
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    brand?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    bundleTotalPrice?: Maybe<Scalars['Float']>;
    /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
    canonicalUrl?: Maybe<Scalars['String']>;
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<Magento2_CategoryInterface>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    color?: Maybe<Scalars['Int']>;
    /** The product's country of origin. */
    countryOfManufacture?: Maybe<Scalars['String']>;
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt?: Maybe<Scalars['String']>;
    /** An array of cross-sell products. */
    crosssellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<Magento2_ComplexTextValue>;
    /** @deprecated Use the `custom_attributes` field instead. */
    detailPage?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    dimension?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    downloadPage?: Maybe<Scalars['String']>;
    /** Indicates whether the bundle product has a dynamic price. */
    dynamicPrice?: Maybe<Scalars['Boolean']>;
    /** Indicates whether the bundle product has a dynamic SKU. */
    dynamicSku?: Maybe<Scalars['Boolean']>;
    /** Indicates whether the bundle product has a dynamically calculated weight. */
    dynamicWeight?: Maybe<Scalars['Boolean']>;
    /** Indicates whether a gift message is available. */
    giftMessageAvailable?: Maybe<Scalars['String']>;
    /** Hide qualify message */
    hideQualifyMessage?: Maybe<Scalars['Boolean']>;
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']>;
    /** The relative path to the main image on the product page. */
    image?: Maybe<Magento2_ProductImage>;
    /** Check default variant of configurable products. */
    isDefaultWithinAllVariants?: Maybe<Scalars['Boolean']>;
    /** Indicates whether the product can be returned. */
    isReturnable?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    itemType?: Maybe<Scalars['Int']>;
    /** An array containing information about individual bundle items. */
    items?: Maybe<Array<Maybe<Magento2_BundleItem>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    label?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseClass?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseCode?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseType?: Maybe<Scalars['Int']>;
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer?: Maybe<Scalars['Int']>;
    /** An array of media gallery objects. */
    mediaGallery?: Maybe<Array<Maybe<Magento2_MediaGalleryInterface>>>;
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries?: Maybe<Array<Maybe<Magento2_MediaGalleryEntry>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    mediaPage?: Maybe<Scalars['String']>;
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription?: Maybe<Scalars['String']>;
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword?: Maybe<Scalars['String']>;
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle?: Maybe<Scalars['String']>;
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    nativeAccess?: Maybe<Scalars['Int']>;
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate?: Maybe<Scalars['String']>;
    /** The end date for new product listings. */
    newToDate?: Maybe<Scalars['String']>;
    /** Product stock only x left count */
    onlyXLeftInStock?: Maybe<Scalars['Float']>;
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<Magento2_CustomizableOptionInterface>>>;
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer?: Maybe<Scalars['String']>;
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price?: Maybe<Magento2_ProductPrices>;
    /** The range of prices for the product */
    priceRange: Magento2_PriceRange;
    /** An array of `TierPrice` objects. */
    priceTiers?: Maybe<Array<Maybe<Magento2_TierPrice>>>;
    /** One of PRICE_RANGE or AS_LOW_AS. */
    priceView?: Maybe<Magento2_PriceViewEnum>;
    /** @deprecated Use the `custom_attributes` field instead. */
    pricingPage?: Maybe<Scalars['String']>;
    /** An array of `ProductLinks` objects. */
    productLinks?: Maybe<Array<Maybe<Magento2_ProductLinksInterface>>>;
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float'];
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
    redirectCode: Scalars['Int'];
    /** An array of related products. */
    relatedProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    releaseDate?: Maybe<Scalars['String']>;
    /** Required Products Information about the product. The value can include simple HTML tags. */
    requiredProductsInformation?: Maybe<Magento2_ComplexTextValue>;
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int'];
    /** The list of products reviews. */
    reviews: Magento2_ProductReviews;
    /** @deprecated Use the `custom_attributes` field instead. */
    sdbsId?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    serialSystem?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    serialSystemReference?: Maybe<Scalars['String']>;
    /** Indicates whether to ship bundle items together or individually. */
    shipBundleItems?: Maybe<Magento2_ShipBundleItemsEnum>;
    /** A short description of the product. Its use depends on the theme. */
    shortDescription?: Maybe<Magento2_ComplexTextValue>;
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>;
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage?: Maybe<Magento2_ProductImage>;
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate?: Maybe<Scalars['String']>;
    /** The discounted price of the product. */
    specialPrice?: Maybe<Scalars['Float']>;
    /** The end date for a product with a special price. */
    specialToDate?: Maybe<Scalars['String']>;
    /** Indicates whether the product is staged for a future campaign. */
    staged: Scalars['Boolean'];
    /** Stock availability label. */
    stockAvailabilityLabel?: Maybe<Scalars['String']>;
    /** Stock status of the product */
    stockStatus?: Maybe<Magento2_ProductStockStatus>;
    /** The file name of a swatch image. */
    swatchImage?: Maybe<Scalars['String']>;
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<Magento2_ProductImage>;
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice?: Maybe<Scalars['Float']>;
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices?: Maybe<Array<Maybe<Magento2_ProductTierPrices>>>;
    /** Time to stock custom attribute. */
    timeToStock?: Maybe<Scalars['String']>;
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId?: Maybe<Scalars['String']>;
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID'];
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt?: Maybe<Scalars['String']>;
    /** An array of up-sell products. */
    upsellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** The part of the URL that identifies the product */
    urlKey?: Maybe<Scalars['String']>;
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath?: Maybe<Scalars['String']>;
    /** URL rewrites list */
    urlRewrites?: Maybe<Array<Maybe<Magento2_UrlRewrite>>>;
    /** The part of the product URL that is appended after the url key */
    urlSuffix?: Maybe<Scalars['String']>;
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Magento2_Website>>>;
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']>;
  };

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type Magento2_BundleProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Defines a single bundle product. */
export type Magento2_BundleProductCartItemInput = {
  /** A mandatory array of options for the bundle product, including each chosen option and specified quantity. */
  bundle_options: Array<InputMaybe<Magento2_BundleOptionInput>>;
  /** The ID and value of the option. */
  customizable_options?: InputMaybe<
    Array<InputMaybe<Magento2_CustomizableOptionInput>>
  >;
  /** The quantity and SKU of the bundle product. */
  data: Magento2_CartItemInput;
};

/** Defines bundle product options for `ShipmentItemInterface`. */
export type Magento2_BundleShipmentItem = Magento2_ShipmentItemInterface & {
  __typename?: 'Magento2_BundleShipmentItem';
  /** A list of bundle options that are assigned to a shipped product. */
  bundleOptions?: Maybe<Array<Maybe<Magento2_ItemSelectedBundleOption>>>;
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float'];
};

/** Defines bundle product options for `WishlistItemInterface`. */
export type Magento2_BundleWishlistItem = Magento2_WishlistItemInterface & {
  __typename?: 'Magento2_BundleWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** An array containing information about the selected bundle items. */
  bundleOptions?: Maybe<Array<Maybe<Magento2_SelectedBundleOption>>>;
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<Magento2_ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

export type Magento2_CaptureStripePaymentInput = {
  /** Stripe Payment Intent ID */
  pi_id: Scalars['String'];
};

export type Magento2_CaptureStripePaymentOutput = {
  __typename?: 'Magento2_CaptureStripePaymentOutput';
  /** Payment Status */
  status?: Maybe<Scalars['String']>;
};

/** Contains the contents and other details about a guest or customer cart. */
export type Magento2_Cart = {
  __typename?: 'Magento2_Cart';
  /** @deprecated Use `applied_coupons` instead. */
  appliedCoupon?: Maybe<Magento2_AppliedCoupon>;
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code. */
  appliedCoupons?: Maybe<Array<Maybe<Magento2_AppliedCoupon>>>;
  /** An array of gift card items applied to the cart. */
  appliedGiftCards?: Maybe<Array<Maybe<Magento2_AppliedGiftCard>>>;
  /** Store credit information applied to the cart. */
  appliedStoreCredit?: Maybe<Magento2_AppliedStoreCredit>;
  /** Available Checkout Agreements information */
  availableAgreements?: Maybe<Array<Maybe<Magento2_AvailableAgreement>>>;
  /** The list of available gift wrapping options for the cart. */
  availableGiftWrappings: Array<Maybe<Magento2_GiftWrapping>>;
  /** An array of available payment methods. */
  availablePaymentMethods?: Maybe<
    Array<Maybe<Magento2_AvailablePaymentMethod>>
  >;
  /** The billing address assigned to the cart. */
  billingAddress?: Maybe<Magento2_BillingCartAddress>;
  /** The email address of the guest or customer. */
  email?: Maybe<Scalars['String']>;
  /** The entered gift message for the cart */
  giftMessage?: Maybe<Magento2_GiftMessage>;
  /** Indicates whether the shopper requested gift receipt for the cart. */
  giftReceiptIncluded: Scalars['Boolean'];
  /** The selected gift wrapping for the cart. */
  giftWrapping?: Maybe<Magento2_GiftWrapping>;
  /** History of the cart. */
  history?: Maybe<Array<Maybe<Magento2_CartHistoryItem>>>;
  /** The unique ID for a `Cart` object. */
  id: Scalars['ID'];
  /** Indicates whether the cart contains only virtual products. */
  isVirtual: Scalars['Boolean'];
  /** An array of products that have been added to the cart. */
  items?: Maybe<Array<Maybe<Magento2_CartItemInterface>>>;
  /** Pricing details for the quote. */
  prices?: Maybe<Magento2_CartPrices>;
  /** Indicates whether the shopper requested a printed card for the cart. */
  printedCardIncluded: Scalars['Boolean'];
  /** Indicates which payment method was applied to the cart. */
  selectedPaymentMethod?: Maybe<Magento2_SelectedPaymentMethod>;
  /** An array of shipping addresses assigned to the cart. */
  shippingAddresses: Array<Maybe<Magento2_ShippingCartAddress>>;
  /** The total number of items in the cart. */
  totalQuantity: Scalars['Float'];
};

/** Contains details the country in a billing or shipping address. */
export type Magento2_CartAddressCountry = {
  __typename?: 'Magento2_CartAddressCountry';
  /** The country code. */
  code: Scalars['String'];
  /** The display label for the country. */
  label: Scalars['String'];
};

/** Defines the billing or shipping address to be applied to the cart. */
export type Magento2_CartAddressInput = {
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company?: InputMaybe<Scalars['String']>;
  /** The country code and label for the billing or shipping address. */
  country_code: Scalars['String'];
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: InputMaybe<Scalars['String']>;
  /** A string that defines the state or province of the billing or shipping address. */
  region?: InputMaybe<Scalars['String']>;
  /** An integer that defines the state or province of the billing or shipping address. */
  region_id?: InputMaybe<Scalars['Int']>;
  /** Determines whether to save the address in the customer's address book. The default value is true. */
  save_in_address_book?: InputMaybe<Scalars['Boolean']>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<InputMaybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: InputMaybe<Scalars['String']>;
  /** The VAT company number for billing or shipping address. */
  vat_id?: InputMaybe<Scalars['String']>;
};

export type Magento2_CartAddressInterface = {
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country label and code. */
  country: Magento2_CartAddressCountry;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  /** An object containing the region label and code. */
  region?: Maybe<Magento2_CartAddressRegion>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
  /** The unique id of the customer address. */
  uid: Scalars['String'];
  /** The VAT company number for billing or shipping address. */
  vatId?: Maybe<Scalars['String']>;
};

/** Contains details about the region in a billing or shipping address. */
export type Magento2_CartAddressRegion = {
  __typename?: 'Magento2_CartAddressRegion';
  /** The state or province code. */
  code?: Maybe<Scalars['String']>;
  /** The display label for the region. */
  label?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  regionId?: Maybe<Scalars['Int']>;
};

/** Contains information about discounts applied to the cart. */
export type Magento2_CartDiscount = {
  __typename?: 'Magento2_CartDiscount';
  /** The amount of the discount applied to the item. */
  amount: Magento2_Money;
  /** The description of the discount. */
  label: Array<Maybe<Scalars['String']>>;
};

/** Contains details about a comment. */
export type Magento2_CartHistoryItem = {
  __typename?: 'Magento2_CartHistoryItem';
  /** The text of the comment. */
  comment: Scalars['String'];
  /** The status of the quote */
  status: Scalars['String'];
  /** The ID of the quote store */
  storeId: Scalars['Int'];
  /** The timestamp of the comment. */
  timestamp: Scalars['String'];
};

export type Magento2_CartItemError = {
  __typename?: 'Magento2_CartItemError';
  /** An error code that describes the error encountered */
  code: Magento2_CartItemErrorType;
  /** A localized error message */
  message: Scalars['String'];
};

export enum Magento2_CartItemErrorType {
  /** @deprecated  */
  ItemIncrements = 'ITEM_INCREMENTS',
  /** @deprecated  */
  ItemQty = 'ITEM_QTY',
  /** @deprecated  */
  Undefined = 'UNDEFINED',
}

/** Defines an item to be added to the cart. */
export type Magento2_CartItemInput = {
  /** An array of entered options for the base product, such as personalization text. */
  entered_options?: InputMaybe<Array<InputMaybe<Magento2_EnteredOptionInput>>>;
  /** For a child product, the SKU of its parent product. */
  parent_sku?: InputMaybe<Scalars['String']>;
  /** The amount or number of an item to add. */
  quantity: Scalars['Float'];
  /** The selected options for the base product, such as color or size, using the unique ID for an object such as `CustomizableRadioOption`, `CustomizableDropDownOption`, or `ConfigurableProductOptionsValues`. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The SKU of the product. */
  sku: Scalars['String'];
};

/** An interface for products in a cart. */
export type Magento2_CartItemInterface = {
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<Magento2_CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<Magento2_CartItemPrices>;
  /** Details about an item in the cart. */
  product: Magento2_ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Contains details about the price of the item, including taxes and discounts. */
export type Magento2_CartItemPrices = {
  __typename?: 'Magento2_CartItemPrices';
  /** An array of discounts to be applied to the cart item. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** An array of FPTs applied to the cart item. */
  fixedProductTaxes?: Maybe<Array<Maybe<Magento2_FixedProductTax>>>;
  /** The price of the item before any discounts were applied. The price that might include tax, depending on the configured display settings for cart. */
  price: Magento2_Money;
  /** The price of the item before any discounts were applied. The price that might include tax, depending on the configured display settings for cart. */
  priceIncludingTax: Magento2_Money;
  /** The value of the price multiplied by the quantity of the item. */
  rowTotal: Magento2_Money;
  /** The value of `row_total` plus the tax applied to the item. */
  rowTotalIncludingTax: Magento2_Money;
  /** The total of all discounts applied to the item. */
  totalItemDiscount?: Maybe<Magento2_Money>;
};

/** Deprecated: The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
export type Magento2_CartItemQuantity = {
  __typename?: 'Magento2_CartItemQuantity';
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  cartItemId: Scalars['Int'];
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  quantity: Scalars['Float'];
};

/** Contains details about the price of a selected customizable value. */
export type Magento2_CartItemSelectedOptionValuePrice = {
  __typename?: 'Magento2_CartItemSelectedOptionValuePrice';
  /** Indicates whether the price type is fixed, percent, or dynamic. */
  type: Magento2_PriceTypeEnum;
  /** A string that describes the unit of the value. */
  units: Scalars['String'];
  /** A price value. */
  value: Scalars['Float'];
};

/** A single item to be updated. */
export type Magento2_CartItemUpdateInput = {
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: InputMaybe<Scalars['Int']>;
  /** The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: InputMaybe<Scalars['ID']>;
  /** An array that defines customizable options for the product. */
  customizable_options?: InputMaybe<
    Array<InputMaybe<Magento2_CustomizableOptionInput>>
  >;
  /** Gift message details for the cart item */
  gift_message?: InputMaybe<Magento2_GiftMessageInput>;
  /** The unique ID for a `GiftWrapping` object to be used for the cart item. */
  gift_wrapping_id?: InputMaybe<Scalars['ID']>;
  /** The new quantity of the item. */
  quantity?: InputMaybe<Scalars['Float']>;
};

/** Contains details about the final price of items in the cart, including discount and tax information. */
export type Magento2_CartPrices = {
  __typename?: 'Magento2_CartPrices';
  /** An array containing the names and amounts of taxes applied to each item in the cart. */
  appliedTaxes?: Maybe<Array<Maybe<Magento2_CartTaxItem>>>;
  /** @deprecated Use discounts instead. */
  discount?: Maybe<Magento2_CartDiscount>;
  /** An array containing cart rule discounts, store credit and gift cards applied to the cart. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** The list of prices for the selected gift options. */
  giftOptions?: Maybe<Magento2_GiftOptionsPrices>;
  /** The total, including discounts, taxes, shipping, and other fees. */
  grandTotal?: Maybe<Magento2_Money>;
  /** The subtotal without any applied taxes. */
  subtotalExcludingTax?: Maybe<Magento2_Money>;
  /** The subtotal including any applied taxes. */
  subtotalIncludingTax?: Maybe<Magento2_Money>;
  /** The subtotal with any discounts applied, but not taxes. */
  subtotalWithDiscountExcludingTax?: Maybe<Magento2_Money>;
};

/** Contains tax information about an item in the cart. */
export type Magento2_CartTaxItem = {
  __typename?: 'Magento2_CartTaxItem';
  /** The amount of tax applied to the item. */
  amount: Magento2_Money;
  /** The description of the tax. */
  label: Scalars['String'];
};

/** An error encountered while adding an item to the the cart. */
export type Magento2_CartUserInputError = {
  __typename?: 'Magento2_CartUserInputError';
  /** A cart-specific error code. */
  code: Magento2_CartUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
};

export enum Magento2_CartUserInputErrorType {
  /** @deprecated  */
  InsufficientStock = 'INSUFFICIENT_STOCK',
  /** @deprecated  */
  NotSalable = 'NOT_SALABLE',
  /** @deprecated  */
  PermissionDenied = 'PERMISSION_DENIED',
  /** @deprecated  */
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  /** @deprecated  */
  Undefined = 'UNDEFINED',
}

/** Defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type Magento2_CategoryFilterInput = {
  /** Filter by the unique category ID for a `CategoryInterface` object. */
  category_uid?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
  ids?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Filter by the display name of the category. */
  name?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_category_uid?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_id?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Filter by the part of the URL that identifies the category. */
  url_key?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Filter by the URL path for the category. */
  url_path?: InputMaybe<Magento2_FilterEqualTypeInput>;
};

/** Contains the full set of attributes that can be returned in a category search. */
export type Magento2_CategoryInterface = {
  automaticSorting?: Maybe<Scalars['String']>;
  availableSortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of breadcrumb items. */
  breadcrumbs?: Maybe<Array<Maybe<Magento2_Breadcrumb>>>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled. */
  canonicalUrl?: Maybe<Scalars['String']>;
  childrenCount?: Maybe<Scalars['String']>;
  /** Contains a category CMS block. */
  cmsBlock?: Maybe<Magento2_CmsBlock>;
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt?: Maybe<Scalars['String']>;
  customLayoutUpdateFile?: Maybe<Scalars['String']>;
  /** The attribute to use for sorting. */
  defaultSortBy?: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>;
  displayMode?: Maybe<Scalars['String']>;
  filterPriceRange?: Maybe<Scalars['Float']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  includeInMenu?: Maybe<Scalars['Int']>;
  isAnchor?: Maybe<Scalars['Int']>;
  landingPage?: Maybe<Scalars['Int']>;
  /** The depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>;
  /** The full category path. */
  path?: Maybe<Scalars['String']>;
  /** The category path within the store. */
  pathInStore?: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  productCount?: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products?: Maybe<Magento2_CategoryProducts>;
  /** Indicates whether the category is staged for a future campaign. */
  staged: Scalars['Boolean'];
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt?: Maybe<Scalars['String']>;
  /** The URL key assigned to the category. */
  urlKey?: Maybe<Scalars['String']>;
  /** The URL path assigned to the category. */
  urlPath?: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  urlSuffix?: Maybe<Scalars['String']>;
};

/** Contains the full set of attributes that can be returned in a category search. */
export type Magento2_CategoryInterfaceProductsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Magento2_ProductAttributeSortInput>;
};

/** Contains details about the products assigned to a category. */
export type Magento2_CategoryProducts = {
  __typename?: 'Magento2_CategoryProducts';
  /** An array of products that are assigned to the category. */
  items?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
  /** Pagination metadata. */
  pageInfo?: Maybe<Magento2_SearchResultPageInfo>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Contains a collection of `CategoryTree` objects and pagination information. */
export type Magento2_CategoryResult = {
  __typename?: 'Magento2_CategoryResult';
  /** A list of categories that match the filter criteria. */
  items?: Maybe<Array<Maybe<Magento2_CategoryTree>>>;
  /** An object that includes the `page_info` and `currentPage` values specified in the query. */
  pageInfo?: Maybe<Magento2_SearchResultPageInfo>;
  /** The total number of categories that match the criteria. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Contains the hierarchy of categories. */
export type Magento2_CategoryTree = Magento2_CategoryInterface &
  Magento2_RoutableInterface & {
    __typename?: 'Magento2_CategoryTree';
    automaticSorting?: Maybe<Scalars['String']>;
    availableSortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** An array of breadcrumb items. */
    breadcrumbs?: Maybe<Array<Maybe<Magento2_Breadcrumb>>>;
    /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled. */
    canonicalUrl?: Maybe<Scalars['String']>;
    /** A tree of child categories. */
    children?: Maybe<Array<Maybe<Magento2_CategoryTree>>>;
    childrenCount?: Maybe<Scalars['String']>;
    /** Contains a category CMS block. */
    cmsBlock?: Maybe<Magento2_CmsBlock>;
    /**
     * The timestamp indicating when the category was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt?: Maybe<Scalars['String']>;
    customLayoutUpdateFile?: Maybe<Scalars['String']>;
    /** The attribute to use for sorting. */
    defaultSortBy?: Maybe<Scalars['String']>;
    /** An optional description of the category. */
    description?: Maybe<Scalars['String']>;
    displayMode?: Maybe<Scalars['String']>;
    filterPriceRange?: Maybe<Scalars['Float']>;
    /**
     * An ID that uniquely identifies the category.
     * @deprecated Use `uid` instead.
     */
    id?: Maybe<Scalars['Int']>;
    image?: Maybe<Scalars['String']>;
    includeInMenu?: Maybe<Scalars['Int']>;
    isAnchor?: Maybe<Scalars['Int']>;
    landingPage?: Maybe<Scalars['Int']>;
    /** The depth of the category within the tree. */
    level?: Maybe<Scalars['Int']>;
    metaDescription?: Maybe<Scalars['String']>;
    metaKeywords?: Maybe<Scalars['String']>;
    metaTitle?: Maybe<Scalars['String']>;
    /** The display name of the category. */
    name?: Maybe<Scalars['String']>;
    /** The full category path. */
    path?: Maybe<Scalars['String']>;
    /** The category path within the store. */
    pathInStore?: Maybe<Scalars['String']>;
    /** The position of the category relative to other categories at the same level in tree. */
    position?: Maybe<Scalars['Int']>;
    /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
    productCount?: Maybe<Scalars['Int']>;
    /** The list of products assigned to the category. */
    products?: Maybe<Magento2_CategoryProducts>;
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
    redirectCode: Scalars['Int'];
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl?: Maybe<Scalars['String']>;
    /** Indicates whether the category is staged for a future campaign. */
    staged: Scalars['Boolean'];
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
    /** The unique ID for a `CategoryInterface` object. */
    uid: Scalars['ID'];
    /**
     * The timestamp indicating when the category was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt?: Maybe<Scalars['String']>;
    /** The URL key assigned to the category. */
    urlKey?: Maybe<Scalars['String']>;
    /** The URL path assigned to the category. */
    urlPath?: Maybe<Scalars['String']>;
    /** The part of the category URL that is appended after the url key */
    urlSuffix?: Maybe<Scalars['String']>;
  };

/** Contains the hierarchy of categories. */
export type Magento2_CategoryTreeProductsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Magento2_ProductAttributeSortInput>;
};

export type Magento2_CheckPlaceOrderStatusOutput = {
  __typename?: 'Magento2_CheckPlaceOrderStatusOutput';
  /** Errors during creating orders or payment action. */
  errors?: Maybe<Scalars['String']>;
  /** Created order id. */
  orderId?: Maybe<Scalars['Int']>;
  /** Created order increment number. */
  orderIncrementId?: Maybe<Scalars['String']>;
  /** Order status. */
  orderStatus?: Maybe<Scalars['String']>;
};

/** Defines details about an individual checkout agreement. */
export type Magento2_CheckoutAgreement = {
  __typename?: 'Magento2_CheckoutAgreement';
  /** The ID for a checkout agreement. */
  agreementId: Scalars['Int'];
  /** The checkbox text for the checkout agreement. */
  checkboxText: Scalars['String'];
  /** Required. The text of the agreement. */
  content: Scalars['String'];
  /** The height of the text box where the Terms and Conditions statement appears during checkout. */
  contentHeight?: Maybe<Scalars['String']>;
  /** Indicates whether the `content` text is in HTML format. */
  isHtml: Scalars['Boolean'];
  /** Indicates whether agreements are accepted automatically or manually. */
  mode: Magento2_CheckoutAgreementMode;
  /** The name given to the condition. */
  name: Scalars['String'];
};

/** Indicates how agreements are accepted. */
export enum Magento2_CheckoutAgreementMode {
  /**
   * Conditions are automatically accepted upon checkout.
   * @deprecated
   */
  Auto = 'AUTO',
  /**
   * Shoppers must manually accept the conditions to place an order.
   * @deprecated
   */
  Manual = 'MANUAL',
}

/** An error encountered while adding an item to the cart. */
export type Magento2_CheckoutUserInputError = {
  __typename?: 'Magento2_CheckoutUserInputError';
  /** An error code that is specific to Checkout. */
  code: Magento2_CheckoutUserInputErrorCodes;
  /** A localized error message. */
  message: Scalars['String'];
  /** The path to the input field that caused an error. See the GraphQL specification about path errors for details: http://spec.graphql.org/draft/#sec-Errors */
  path: Array<Maybe<Scalars['String']>>;
};

export enum Magento2_CheckoutUserInputErrorCodes {
  /** @deprecated  */
  InsufficientStock = 'INSUFFICIENT_STOCK',
  /** @deprecated  */
  NotSalable = 'NOT_SALABLE',
  /** @deprecated  */
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  /** @deprecated  */
  ReorderNotAvailable = 'REORDER_NOT_AVAILABLE',
  /** @deprecated  */
  Undefined = 'UNDEFINED',
}

/** Contains details about a specific CMS block. */
export type Magento2_CmsBlock = {
  __typename?: 'Magento2_CmsBlock';
  /** The content of the CMS block in raw HTML. */
  content?: Maybe<Scalars['String']>;
  /** The CMS block identifier. */
  identifier?: Maybe<Scalars['String']>;
  /** The title assigned to the CMS block. */
  title?: Maybe<Scalars['String']>;
};

/** Contains an array CMS block items. */
export type Magento2_CmsBlocks = {
  __typename?: 'Magento2_CmsBlocks';
  /** An array of CMS blocks. */
  items?: Maybe<Array<Maybe<Magento2_CmsBlock>>>;
};

/** Contains details about a CMS page. */
export type Magento2_CmsPage = Magento2_RoutableInterface & {
  __typename?: 'Magento2_CmsPage';
  /** The content of the CMS page in raw HTML. */
  content?: Maybe<Scalars['String']>;
  /** The heading that displays at the top of the CMS page. */
  contentHeading?: Maybe<Scalars['String']>;
  /** The ID of a CMS page. */
  identifier?: Maybe<Scalars['String']>;
  /** A brief description of the page for search results listings. */
  metaDescription?: Maybe<Scalars['String']>;
  /** A brief description of the page for search results listings. */
  metaKeywords?: Maybe<Scalars['String']>;
  /** A page title that is indexed by search engines and appears in search results listings. */
  metaTitle?: Maybe<Scalars['String']>;
  /** The design layout of the page, indicating the number of columns and navigation features used on the page. */
  pageLayout?: Maybe<Scalars['String']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl?: Maybe<Scalars['String']>;
  /** The name that appears in the breadcrumb trail navigation and in the browser title bar and tab. */
  title?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
  /** The URL key of the CMS page, which is often based on the `content_heading`. */
  urlKey?: Maybe<Scalars['String']>;
};

export type Magento2_ColorSwatchData = Magento2_SwatchDataInterface & {
  __typename?: 'Magento2_ColorSwatchData';
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

/** Contains an attribute code that is used for product comparisons. */
export type Magento2_ComparableAttribute = {
  __typename?: 'Magento2_ComparableAttribute';
  /** An attribute code that is enabled for product comparisons. */
  code: Scalars['String'];
  /** The label of the attribute code. */
  label: Scalars['String'];
};

/** Defines an object used to iterate through items for product comparisons. */
export type Magento2_ComparableItem = {
  __typename?: 'Magento2_ComparableItem';
  /** An array of product attributes that can be used to compare products. */
  attributes: Array<Maybe<Magento2_ProductAttribute>>;
  /** Details about a product in a compare list. */
  product: Magento2_ProductInterface;
  /** The unique ID of an item in a compare list. */
  uid: Scalars['ID'];
};

/** Contains iterable information such as the array of items, the count, and attributes that represent the compare list. */
export type Magento2_CompareList = {
  __typename?: 'Magento2_CompareList';
  /** An array of attributes that can be used for comparing products. */
  attributes?: Maybe<Array<Maybe<Magento2_ComparableAttribute>>>;
  /** The number of items in the compare list. */
  itemCount: Scalars['Int'];
  /** An array of products to compare. */
  items?: Maybe<Array<Maybe<Magento2_ComparableItem>>>;
  /** The unique ID assigned to the compare list. */
  uid: Scalars['ID'];
};

export type Magento2_ComplexTextValue = {
  __typename?: 'Magento2_ComplexTextValue';
  /** Text that can contain HTML tags. */
  html: Scalars['String'];
};

/** ConfigurableAttributeOption contains the value_index (and other related information) assigned to a configurable product option */
export type Magento2_ConfigurableAttributeOption = {
  __typename?: 'Magento2_ConfigurableAttributeOption';
  /** A string that describes the configurable attribute option of admin */
  adminLabel?: Maybe<Scalars['String']>;
  /** The ID assigned to the attribute. */
  code?: Maybe<Scalars['String']>;
  /** A string that describes the configurable attribute option. */
  label?: Maybe<Scalars['String']>;
  /** The unique ID for a `ConfigurableAttributeOption` object. */
  uid: Scalars['ID'];
  /** A unique index number assigned to the configurable product option. */
  valueIndex?: Maybe<Scalars['Int']>;
};

/** An implementation for configurable product cart items. */
export type Magento2_ConfigurableCartItem = Magento2_CartItemInterface & {
  __typename?: 'Magento2_ConfigurableCartItem';
  /** The list of available gift wrapping options for the cart item. */
  availableGiftWrapping: Array<Maybe<Magento2_GiftWrapping>>;
  /** An array containing the configuranle options the shopper selected. */
  configurableOptions: Array<Maybe<Magento2_SelectedConfigurableOption>>;
  /** Product details of the cart item. */
  configuredVariant: Magento2_ProductInterface;
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<Magento2_CartItemError>>>;
  /** The entered gift message for the cart item */
  giftMessage?: Maybe<Magento2_GiftMessage>;
  /** The selected gift wrapping for the cart item. */
  giftWrapping?: Maybe<Magento2_GiftWrapping>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<Magento2_CartItemPrices>;
  /** Details about an item in the cart. */
  product: Magento2_ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Describes configurable options that have been selected and can be selected as a result of the previous selections. */
export type Magento2_ConfigurableOptionAvailableForSelection = {
  __typename?: 'Magento2_ConfigurableOptionAvailableForSelection';
  /** An attribute code that uniquely identifies a configurable option. */
  attributeCode: Scalars['String'];
  /** An array of selectable option value IDs. */
  optionValueUids: Array<Maybe<Scalars['ID']>>;
};

/** Defines basic features of a configurable product and its simple product variants. */
export type Magento2_ConfigurableProduct =
  Magento2_CustomizableProductInterface &
    Magento2_PhysicalProductInterface &
    Magento2_ProductInterface &
    Magento2_RoutableInterface & {
      __typename?: 'Magento2_ConfigurableProduct';
      /** Additional information about variant */
      additionalInfo?: Maybe<Scalars['String']>;
      /**
       * The attribute set assigned to the product.
       * @deprecated The field should not be used on the storefront.
       */
      attributeSetId?: Maybe<Scalars['Int']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      brand?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      bundleTotalPrice?: Maybe<Scalars['Float']>;
      /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
      canonicalUrl?: Maybe<Scalars['String']>;
      /** The categories assigned to a product. */
      categories?: Maybe<Array<Maybe<Magento2_CategoryInterface>>>;
      /** @deprecated Use the `custom_attributes` field instead. */
      color?: Maybe<Scalars['Int']>;
      /** An array of options for the configurable product. */
      configurableOptions?: Maybe<
        Array<Maybe<Magento2_ConfigurableProductOptions>>
      >;
      /** An array of media gallery items and other details about selected configurable product options as well as details about remaining selectable options. */
      configurableProductOptionsSelection?: Maybe<Magento2_ConfigurableProductOptionsSelection>;
      /** The product's country of origin. */
      countryOfManufacture?: Maybe<Scalars['String']>;
      /**
       * Timestamp indicating when the product was created.
       * @deprecated The field should not be used on the storefront.
       */
      createdAt?: Maybe<Scalars['String']>;
      /** An array of cross-sell products. */
      crosssellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
      /** Detailed information about the product. The value can include simple HTML tags. */
      description?: Maybe<Magento2_ComplexTextValue>;
      /** @deprecated Use the `custom_attributes` field instead. */
      detailPage?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      dimension?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      downloadPage?: Maybe<Scalars['String']>;
      /** Indicates whether a gift message is available. */
      giftMessageAvailable?: Maybe<Scalars['String']>;
      /** Hide qualify message */
      hideQualifyMessage?: Maybe<Scalars['Boolean']>;
      /**
       * The ID number assigned to the product.
       * @deprecated Use the `uid` field instead.
       */
      id?: Maybe<Scalars['Int']>;
      /** The relative path to the main image on the product page. */
      image?: Maybe<Magento2_ProductImage>;
      /** Check default variant of configurable products. */
      isDefaultWithinAllVariants?: Maybe<Scalars['Boolean']>;
      /** Indicates whether the product can be returned. */
      isReturnable?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      itemType?: Maybe<Scalars['Int']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      label?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      licenseClass?: Maybe<Scalars['Int']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      licenseCode?: Maybe<Scalars['Int']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      licenseType?: Maybe<Scalars['Int']>;
      /**
       * A number representing the product's manufacturer.
       * @deprecated Use the `custom_attributes` field instead.
       */
      manufacturer?: Maybe<Scalars['Int']>;
      /** An array of media gallery objects. */
      mediaGallery?: Maybe<Array<Maybe<Magento2_MediaGalleryInterface>>>;
      /**
       * An array of MediaGalleryEntry objects.
       * @deprecated Use `media_gallery` instead.
       */
      mediaGalleryEntries?: Maybe<Array<Maybe<Magento2_MediaGalleryEntry>>>;
      /** @deprecated Use the `custom_attributes` field instead. */
      mediaPage?: Maybe<Scalars['String']>;
      /** A brief overview of the product for search results listings, maximum 255 characters. */
      metaDescription?: Maybe<Scalars['String']>;
      /** A comma-separated list of keywords that are visible only to search engines. */
      metaKeyword?: Maybe<Scalars['String']>;
      /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
      metaTitle?: Maybe<Scalars['String']>;
      /** The product name. Customers use this name to identify the product. */
      name?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      nativeAccess?: Maybe<Scalars['Int']>;
      /** The beginning date for new product listings, and determines if the product is featured as a new product. */
      newFromDate?: Maybe<Scalars['String']>;
      /** The end date for new product listings. */
      newToDate?: Maybe<Scalars['String']>;
      /** Product stock only x left count */
      onlyXLeftInStock?: Maybe<Scalars['Float']>;
      /** An array of options for a customizable product. */
      options?: Maybe<Array<Maybe<Magento2_CustomizableOptionInterface>>>;
      /** If the product has multiple options, determines where they appear on the product page. */
      optionsContainer?: Maybe<Scalars['String']>;
      /**
       * Indicates the price of an item.
       * @deprecated Use `price_range` for product price information.
       */
      price?: Maybe<Magento2_ProductPrices>;
      /** The range of prices for the product */
      priceRange: Magento2_PriceRange;
      /** An array of `TierPrice` objects. */
      priceTiers?: Maybe<Array<Maybe<Magento2_TierPrice>>>;
      /** @deprecated Use the `custom_attributes` field instead. */
      pricingPage?: Maybe<Scalars['String']>;
      /** An array of `ProductLinks` objects. */
      productLinks?: Maybe<Array<Maybe<Magento2_ProductLinksInterface>>>;
      /** The average of all the ratings given to the product. */
      ratingSummary: Scalars['Float'];
      /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
      redirectCode: Scalars['Int'];
      /** An array of related products. */
      relatedProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
      /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
      relativeUrl?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      releaseDate?: Maybe<Scalars['String']>;
      /** Required Products Information about the product. The value can include simple HTML tags. */
      requiredProductsInformation?: Maybe<Magento2_ComplexTextValue>;
      /** The total count of all the reviews given to the product. */
      reviewCount: Scalars['Int'];
      /** The list of products reviews. */
      reviews: Magento2_ProductReviews;
      /** @deprecated Use the `custom_attributes` field instead. */
      sdbsId?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      serialSystem?: Maybe<Scalars['Int']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      serialSystemReference?: Maybe<Scalars['String']>;
      /** A short description of the product. Its use depends on the theme. */
      shortDescription?: Maybe<Magento2_ComplexTextValue>;
      /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
      sku?: Maybe<Scalars['String']>;
      /** The relative path to the small image, which is used on catalog pages. */
      smallImage?: Maybe<Magento2_ProductImage>;
      /**
       * The beginning date that a product has a special price.
       * @deprecated The field should not be used on the storefront.
       */
      specialFromDate?: Maybe<Scalars['String']>;
      /** The discounted price of the product. */
      specialPrice?: Maybe<Scalars['Float']>;
      /** The end date for a product with a special price. */
      specialToDate?: Maybe<Scalars['String']>;
      /** Indicates whether the product is staged for a future campaign. */
      staged: Scalars['Boolean'];
      /** Stock availability label. */
      stockAvailabilityLabel?: Maybe<Scalars['String']>;
      /** Stock status of the product */
      stockStatus?: Maybe<Magento2_ProductStockStatus>;
      /** The file name of a swatch image. */
      swatchImage?: Maybe<Scalars['String']>;
      /** The relative path to the product's thumbnail image. */
      thumbnail?: Maybe<Magento2_ProductImage>;
      /**
       * The price when tier pricing is in effect and the items purchased threshold has been reached.
       * @deprecated Use `price_tiers` for product tier price information.
       */
      tierPrice?: Maybe<Scalars['Float']>;
      /**
       * An array of ProductTierPrices objects.
       * @deprecated Use `price_tiers` for product tier price information.
       */
      tierPrices?: Maybe<Array<Maybe<Magento2_ProductTierPrices>>>;
      /** Time to stock custom attribute. */
      timeToStock?: Maybe<Scalars['String']>;
      /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
      type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
      /**
       * One of simple, virtual, bundle, downloadable, grouped, or configurable.
       * @deprecated Use `__typename` instead.
       */
      typeId?: Maybe<Scalars['String']>;
      /** The unique ID for a `ProductInterface` object. */
      uid: Scalars['ID'];
      /**
       * Timestamp indicating when the product was updated.
       * @deprecated The field should not be used on the storefront.
       */
      updatedAt?: Maybe<Scalars['String']>;
      /** An array of up-sell products. */
      upsellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
      /** The part of the URL that identifies the product */
      urlKey?: Maybe<Scalars['String']>;
      /** @deprecated Use product's `canonical_url` or url rewrites instead */
      urlPath?: Maybe<Scalars['String']>;
      /** URL rewrites list */
      urlRewrites?: Maybe<Array<Maybe<Magento2_UrlRewrite>>>;
      /** The part of the product URL that is appended after the url key */
      urlSuffix?: Maybe<Scalars['String']>;
      /** An array of simple product variants. */
      variants?: Maybe<Array<Maybe<Magento2_ConfigurableVariant>>>;
      /**
       * An array of websites in which the product is available.
       * @deprecated The field should not be used on the storefront.
       */
      websites?: Maybe<Array<Maybe<Magento2_Website>>>;
      /** The weight of the item, in units defined by the store. */
      weight?: Maybe<Scalars['Float']>;
    };

/** Defines basic features of a configurable product and its simple product variants. */
export type Magento2_ConfigurableProductConfigurableProductOptionsSelectionArgs =
  {
    configurableOptionValueUids?: InputMaybe<Array<Scalars['ID']>>;
  };

/** Defines basic features of a configurable product and its simple product variants. */
export type Magento2_ConfigurableProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type Magento2_ConfigurableProductCartItemInput = {
  /** The ID and value of the option. */
  customizable_options?: InputMaybe<
    Array<InputMaybe<Magento2_CustomizableOptionInput>>
  >;
  /** The quantity and SKU of the configurable product. */
  data: Magento2_CartItemInput;
  /** The SKU of the parent configurable product. */
  parent_sku?: InputMaybe<Scalars['String']>;
  /** Deprecated. Use `CartItemInput.sku` instead. */
  variant_sku?: InputMaybe<Scalars['String']>;
};

/** Contains details about configurable product options. */
export type Magento2_ConfigurableProductOption = {
  __typename?: 'Magento2_ConfigurableProductOption';
  /** An attribute code that uniquely identifies a configurable option. */
  attributeCode: Scalars['String'];
  /** The display name of the option. */
  label: Scalars['String'];
  /** The unique ID of the configurable option. */
  uid: Scalars['ID'];
  /** An array of values that are applicable for this option. */
  values?: Maybe<Array<Maybe<Magento2_ConfigurableProductOptionValue>>>;
};

/** Defines a value for a configurable product option. */
export type Magento2_ConfigurableProductOptionValue = {
  __typename?: 'Magento2_ConfigurableProductOptionValue';
  /** Indicates whether the product is available with this selected option. */
  isAvailable: Scalars['Boolean'];
  /** Indicates whether the value is the default. */
  isUseDefault: Scalars['Boolean'];
  /** The display name of the value. */
  label: Scalars['String'];
  /** The URL assigned to the thumbnail of the swatch image. */
  swatch?: Maybe<Magento2_SwatchDataInterface>;
  /** The unique ID of the value. */
  uid: Scalars['ID'];
};

/** Defines configurable attributes for the specified product. */
export type Magento2_ConfigurableProductOptions = {
  __typename?: 'Magento2_ConfigurableProductOptions';
  /** A string that identifies the attribute. */
  attributeCode?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attributeId?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attributeIdV2?: Maybe<Scalars['Int']>;
  /** The unique ID for an `Attribute` object. */
  attributeUid: Scalars['ID'];
  /**
   * The configurable option ID number assigned by the system.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** A displayed string that describes the configurable product option. */
  label?: Maybe<Scalars['String']>;
  /** A number that indicates the order in which the attribute is displayed. */
  position?: Maybe<Scalars['Int']>;
  /**
   * This is the same as a product's `id` field.
   * @deprecated `product_id` is not needed and can be obtained from its parent.
   */
  productId?: Maybe<Scalars['Int']>;
  /** The unique ID for a `ConfigurableProductOptions` object. */
  uid: Scalars['ID'];
  /** Indicates whether the option is the default. */
  useDefault?: Maybe<Scalars['Boolean']>;
  /** An array that defines the `value_index` codes assigned to the configurable product. */
  values?: Maybe<Array<Maybe<Magento2_ConfigurableProductOptionsValues>>>;
};

/** Contains metadata corresponding to the selected configurable options. */
export type Magento2_ConfigurableProductOptionsSelection = {
  __typename?: 'Magento2_ConfigurableProductOptionsSelection';
  /** An array of all possible configurable options. */
  configurableOptions?: Maybe<Array<Maybe<Magento2_ConfigurableProductOption>>>;
  /** Product images and videos corresponding to the specified configurable options selection. */
  mediaGallery?: Maybe<Array<Maybe<Magento2_MediaGalleryInterface>>>;
  /** The configurable options available for further selection based on the current selection. */
  optionsAvailableForSelection?: Maybe<
    Array<Maybe<Magento2_ConfigurableOptionAvailableForSelection>>
  >;
  /** A variant represented by the specified configurable options selection. The value is expected to be null until selections are made for each configurable option. */
  variant?: Maybe<Magento2_SimpleProduct>;
};

/** ConfigurableProductOptionsValues contains the index number assigned to a configurable product option */
export type Magento2_ConfigurableProductOptionsValues = {
  __typename?: 'Magento2_ConfigurableProductOptionsValues';
  /** The label of the product on the admin */
  adminLabel?: Maybe<Scalars['String']>;
  /** The label of the product on the default store. */
  defaultLabel?: Maybe<Scalars['String']>;
  /** The label of the product. */
  label?: Maybe<Scalars['String']>;
  /** The label of the product on the current store. */
  storeLabel?: Maybe<Scalars['String']>;
  /** Swatch data for a configurable product option. */
  swatchData?: Maybe<Magento2_SwatchDataInterface>;
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  uid?: Maybe<Scalars['ID']>;
  /** Indicates whether to use the default_label. */
  useDefaultValue?: Maybe<Scalars['Boolean']>;
  /**
   * A unique index number assigned to the configurable product option.
   * @deprecated Use `uid` instead.
   */
  valueIndex?: Maybe<Scalars['Int']>;
};

/** Contains all the simple product variants of a configurable product. */
export type Magento2_ConfigurableVariant = {
  __typename?: 'Magento2_ConfigurableVariant';
  /** An array of configurable attribute options. */
  attributes?: Maybe<Array<Maybe<Magento2_ConfigurableAttributeOption>>>;
  /** An array of linked simple products. */
  product?: Maybe<Magento2_SimpleProduct>;
};

/** A configurable product wish list item. */
export type Magento2_ConfigurableWishlistItem =
  Magento2_WishlistItemInterface & {
    __typename?: 'Magento2_ConfigurableWishlistItem';
    /** The date and time the item was added to the wish list. */
    addedAt: Scalars['String'];
    /**
     * The SKU of the simple product corresponding to a set of selected configurable options.
     * @deprecated Use `ConfigurableWishlistItem.configured_variant.sku` instead.
     */
    childSku: Scalars['String'];
    /** An array of selected configurable options. */
    configurableOptions?: Maybe<
      Array<Maybe<Magento2_SelectedConfigurableOption>>
    >;
    /** Product details of the selected variant. The value is null if some options are not configured. */
    configuredVariant?: Maybe<Magento2_ProductInterface>;
    /** Custom options selected for the wish list item. */
    customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
    /** The description of the item. */
    description?: Maybe<Scalars['String']>;
    /** The unique ID for a `WishlistItemInterface` object. */
    id: Scalars['ID'];
    /** Product details of the wish list item. */
    product?: Maybe<Magento2_ProductInterface>;
    /** The quantity of this wish list item. */
    quantity: Scalars['Float'];
  };

/** Contains the source and target wish lists after copying products. */
export type Magento2_CopyProductsBetweenWishlistsOutput = {
  __typename?: 'Magento2_CopyProductsBetweenWishlistsOutput';
  /** The destination wish list containing the copied products. */
  destinationWishlist: Magento2_Wishlist;
  /** The wish list that the products were copied from. */
  sourceWishlist: Magento2_Wishlist;
  /** An array of errors encountered while copying products in a wish list. */
  userErrors: Array<Maybe<Magento2_WishListUserInputError>>;
};

export type Magento2_Country = {
  __typename?: 'Magento2_Country';
  /** An array of regions within a particular country. */
  availableRegions?: Maybe<Array<Maybe<Magento2_Region>>>;
  /** The name of the country in English. */
  fullNameEnglish?: Maybe<Scalars['String']>;
  /** The name of the country in the current locale. */
  fullNameLocale?: Maybe<Scalars['String']>;
  /** The unique ID for a `Country` object. */
  id?: Maybe<Scalars['String']>;
  /** The three-letter abbreviation of the country, such as USA. */
  threeLetterAbbreviation?: Maybe<Scalars['String']>;
  /** The two-letter abbreviation of the country, such as US. */
  twoLetterAbbreviation?: Maybe<Scalars['String']>;
};

/** The list of countries codes */
export enum Magento2_CountryCodeEnum {
  /**
   * Andorra
   * @deprecated
   */
  Ad = 'AD',
  /**
   * United Arab Emirates
   * @deprecated
   */
  Ae = 'AE',
  /**
   * Afghanistan
   * @deprecated
   */
  Af = 'AF',
  /**
   * Antigua & Barbuda
   * @deprecated
   */
  Ag = 'AG',
  /**
   * Anguilla
   * @deprecated
   */
  Ai = 'AI',
  /**
   * Albania
   * @deprecated
   */
  Al = 'AL',
  /**
   * Armenia
   * @deprecated
   */
  Am = 'AM',
  /**
   * Netherlands Antilles
   * @deprecated
   */
  An = 'AN',
  /**
   * Angola
   * @deprecated
   */
  Ao = 'AO',
  /**
   * Antarctica
   * @deprecated
   */
  Aq = 'AQ',
  /**
   * Argentina
   * @deprecated
   */
  Ar = 'AR',
  /**
   * American Samoa
   * @deprecated
   */
  As = 'AS',
  /**
   * Austria
   * @deprecated
   */
  At = 'AT',
  /**
   * Australia
   * @deprecated
   */
  Au = 'AU',
  /**
   * Aruba
   * @deprecated
   */
  Aw = 'AW',
  /**
   * Åland Islands
   * @deprecated
   */
  Ax = 'AX',
  /**
   * Azerbaijan
   * @deprecated
   */
  Az = 'AZ',
  /**
   * Bosnia & Herzegovina
   * @deprecated
   */
  Ba = 'BA',
  /**
   * Barbados
   * @deprecated
   */
  Bb = 'BB',
  /**
   * Bangladesh
   * @deprecated
   */
  Bd = 'BD',
  /**
   * Belgium
   * @deprecated
   */
  Be = 'BE',
  /**
   * Burkina Faso
   * @deprecated
   */
  Bf = 'BF',
  /**
   * Bulgaria
   * @deprecated
   */
  Bg = 'BG',
  /**
   * Bahrain
   * @deprecated
   */
  Bh = 'BH',
  /**
   * Burundi
   * @deprecated
   */
  Bi = 'BI',
  /**
   * Benin
   * @deprecated
   */
  Bj = 'BJ',
  /**
   * St. Barthélemy
   * @deprecated
   */
  Bl = 'BL',
  /**
   * Bermuda
   * @deprecated
   */
  Bm = 'BM',
  /**
   * Brunei
   * @deprecated
   */
  Bn = 'BN',
  /**
   * Bolivia
   * @deprecated
   */
  Bo = 'BO',
  /**
   * Brazil
   * @deprecated
   */
  Br = 'BR',
  /**
   * Bahamas
   * @deprecated
   */
  Bs = 'BS',
  /**
   * Bhutan
   * @deprecated
   */
  Bt = 'BT',
  /**
   * Bouvet Island
   * @deprecated
   */
  Bv = 'BV',
  /**
   * Botswana
   * @deprecated
   */
  Bw = 'BW',
  /**
   * Belarus
   * @deprecated
   */
  By = 'BY',
  /**
   * Belize
   * @deprecated
   */
  Bz = 'BZ',
  /**
   * Canada
   * @deprecated
   */
  Ca = 'CA',
  /**
   * Cocos (Keeling) Islands
   * @deprecated
   */
  Cc = 'CC',
  /**
   * Congo-Kinshasa
   * @deprecated
   */
  Cd = 'CD',
  /**
   * Central African Republic
   * @deprecated
   */
  Cf = 'CF',
  /**
   * Congo-Brazzaville
   * @deprecated
   */
  Cg = 'CG',
  /**
   * Switzerland
   * @deprecated
   */
  Ch = 'CH',
  /**
   * Côte d’Ivoire
   * @deprecated
   */
  Ci = 'CI',
  /**
   * Cook Islands
   * @deprecated
   */
  Ck = 'CK',
  /**
   * Chile
   * @deprecated
   */
  Cl = 'CL',
  /**
   * Cameroon
   * @deprecated
   */
  Cm = 'CM',
  /**
   * China
   * @deprecated
   */
  Cn = 'CN',
  /**
   * Colombia
   * @deprecated
   */
  Co = 'CO',
  /**
   * Costa Rica
   * @deprecated
   */
  Cr = 'CR',
  /**
   * Cuba
   * @deprecated
   */
  Cu = 'CU',
  /**
   * Cape Verde
   * @deprecated
   */
  Cv = 'CV',
  /**
   * Christmas Island
   * @deprecated
   */
  Cx = 'CX',
  /**
   * Cyprus
   * @deprecated
   */
  Cy = 'CY',
  /**
   * Czech Republic
   * @deprecated
   */
  Cz = 'CZ',
  /**
   * Germany
   * @deprecated
   */
  De = 'DE',
  /**
   * Djibouti
   * @deprecated
   */
  Dj = 'DJ',
  /**
   * Denmark
   * @deprecated
   */
  Dk = 'DK',
  /**
   * Dominica
   * @deprecated
   */
  Dm = 'DM',
  /**
   * Dominican Republic
   * @deprecated
   */
  Do = 'DO',
  /**
   * Algeria
   * @deprecated
   */
  Dz = 'DZ',
  /**
   * Ecuador
   * @deprecated
   */
  Ec = 'EC',
  /**
   * Estonia
   * @deprecated
   */
  Ee = 'EE',
  /**
   * Egypt
   * @deprecated
   */
  Eg = 'EG',
  /**
   * Western Sahara
   * @deprecated
   */
  Eh = 'EH',
  /**
   * Eritrea
   * @deprecated
   */
  Er = 'ER',
  /**
   * Spain
   * @deprecated
   */
  Es = 'ES',
  /**
   * Ethiopia
   * @deprecated
   */
  Et = 'ET',
  /**
   * Finland
   * @deprecated
   */
  Fi = 'FI',
  /**
   * Fiji
   * @deprecated
   */
  Fj = 'FJ',
  /**
   * Falkland Islands
   * @deprecated
   */
  Fk = 'FK',
  /**
   * Micronesia
   * @deprecated
   */
  Fm = 'FM',
  /**
   * Faroe Islands
   * @deprecated
   */
  Fo = 'FO',
  /**
   * France
   * @deprecated
   */
  Fr = 'FR',
  /**
   * Gabon
   * @deprecated
   */
  Ga = 'GA',
  /**
   * United Kingdom
   * @deprecated
   */
  Gb = 'GB',
  /**
   * Grenada
   * @deprecated
   */
  Gd = 'GD',
  /**
   * Georgia
   * @deprecated
   */
  Ge = 'GE',
  /**
   * French Guiana
   * @deprecated
   */
  Gf = 'GF',
  /**
   * Guernsey
   * @deprecated
   */
  Gg = 'GG',
  /**
   * Ghana
   * @deprecated
   */
  Gh = 'GH',
  /**
   * Gibraltar
   * @deprecated
   */
  Gi = 'GI',
  /**
   * Greenland
   * @deprecated
   */
  Gl = 'GL',
  /**
   * Gambia
   * @deprecated
   */
  Gm = 'GM',
  /**
   * Guinea
   * @deprecated
   */
  Gn = 'GN',
  /**
   * Guadeloupe
   * @deprecated
   */
  Gp = 'GP',
  /**
   * Equatorial Guinea
   * @deprecated
   */
  Gq = 'GQ',
  /**
   * Greece
   * @deprecated
   */
  Gr = 'GR',
  /**
   * South Georgia & South Sandwich Islands
   * @deprecated
   */
  Gs = 'GS',
  /**
   * Guatemala
   * @deprecated
   */
  Gt = 'GT',
  /**
   * Guam
   * @deprecated
   */
  Gu = 'GU',
  /**
   * Guinea-Bissau
   * @deprecated
   */
  Gw = 'GW',
  /**
   * Guyana
   * @deprecated
   */
  Gy = 'GY',
  /**
   * Hong Kong SAR China
   * @deprecated
   */
  Hk = 'HK',
  /**
   * Heard &amp; McDonald Islands
   * @deprecated
   */
  Hm = 'HM',
  /**
   * Honduras
   * @deprecated
   */
  Hn = 'HN',
  /**
   * Croatia
   * @deprecated
   */
  Hr = 'HR',
  /**
   * Haiti
   * @deprecated
   */
  Ht = 'HT',
  /**
   * Hungary
   * @deprecated
   */
  Hu = 'HU',
  /**
   * Canary Islands
   * @deprecated
   */
  Ic = 'IC',
  /**
   * Indonesia
   * @deprecated
   */
  Id = 'ID',
  /**
   * Ireland
   * @deprecated
   */
  Ie = 'IE',
  /**
   * Israel
   * @deprecated
   */
  Il = 'IL',
  /**
   * Isle of Man
   * @deprecated
   */
  Im = 'IM',
  /**
   * India
   * @deprecated
   */
  In = 'IN',
  /**
   * British Indian Ocean Territory
   * @deprecated
   */
  Io = 'IO',
  /**
   * Iraq
   * @deprecated
   */
  Iq = 'IQ',
  /**
   * Iran
   * @deprecated
   */
  Ir = 'IR',
  /**
   * Iceland
   * @deprecated
   */
  Is = 'IS',
  /**
   * Italy
   * @deprecated
   */
  It = 'IT',
  /**
   * Jersey
   * @deprecated
   */
  Je = 'JE',
  /**
   * Jamaica
   * @deprecated
   */
  Jm = 'JM',
  /**
   * Jordan
   * @deprecated
   */
  Jo = 'JO',
  /**
   * Japan
   * @deprecated
   */
  Jp = 'JP',
  /**
   * Kenya
   * @deprecated
   */
  Ke = 'KE',
  /**
   * Kyrgyzstan
   * @deprecated
   */
  Kg = 'KG',
  /**
   * Cambodia
   * @deprecated
   */
  Kh = 'KH',
  /**
   * Kiribati
   * @deprecated
   */
  Ki = 'KI',
  /**
   * Comoros
   * @deprecated
   */
  Km = 'KM',
  /**
   * St. Kitts & Nevis
   * @deprecated
   */
  Kn = 'KN',
  /**
   * North Korea
   * @deprecated
   */
  Kp = 'KP',
  /**
   * South Korea
   * @deprecated
   */
  Kr = 'KR',
  /**
   * Kuwait
   * @deprecated
   */
  Kw = 'KW',
  /**
   * Cayman Islands
   * @deprecated
   */
  Ky = 'KY',
  /**
   * Kazakhstan
   * @deprecated
   */
  Kz = 'KZ',
  /**
   * Laos
   * @deprecated
   */
  La = 'LA',
  /**
   * Lebanon
   * @deprecated
   */
  Lb = 'LB',
  /**
   * St. Lucia
   * @deprecated
   */
  Lc = 'LC',
  /**
   * Liechtenstein
   * @deprecated
   */
  Li = 'LI',
  /**
   * Sri Lanka
   * @deprecated
   */
  Lk = 'LK',
  /**
   * Liberia
   * @deprecated
   */
  Lr = 'LR',
  /**
   * Lesotho
   * @deprecated
   */
  Ls = 'LS',
  /**
   * Lithuania
   * @deprecated
   */
  Lt = 'LT',
  /**
   * Luxembourg
   * @deprecated
   */
  Lu = 'LU',
  /**
   * Latvia
   * @deprecated
   */
  Lv = 'LV',
  /**
   * Libya
   * @deprecated
   */
  Ly = 'LY',
  /**
   * Morocco
   * @deprecated
   */
  Ma = 'MA',
  /**
   * Monaco
   * @deprecated
   */
  Mc = 'MC',
  /**
   * Moldova
   * @deprecated
   */
  Md = 'MD',
  /**
   * Montenegro
   * @deprecated
   */
  Me = 'ME',
  /**
   * St. Martin
   * @deprecated
   */
  Mf = 'MF',
  /**
   * Madagascar
   * @deprecated
   */
  Mg = 'MG',
  /**
   * Marshall Islands
   * @deprecated
   */
  Mh = 'MH',
  /**
   * Macedonia
   * @deprecated
   */
  Mk = 'MK',
  /**
   * Mali
   * @deprecated
   */
  Ml = 'ML',
  /**
   * Myanmar (Burma)
   * @deprecated
   */
  Mm = 'MM',
  /**
   * Mongolia
   * @deprecated
   */
  Mn = 'MN',
  /**
   * Macau SAR China
   * @deprecated
   */
  Mo = 'MO',
  /**
   * Northern Mariana Islands
   * @deprecated
   */
  Mp = 'MP',
  /**
   * Martinique
   * @deprecated
   */
  Mq = 'MQ',
  /**
   * Mauritania
   * @deprecated
   */
  Mr = 'MR',
  /**
   * Montserrat
   * @deprecated
   */
  Ms = 'MS',
  /**
   * Malta
   * @deprecated
   */
  Mt = 'MT',
  /**
   * Mauritius
   * @deprecated
   */
  Mu = 'MU',
  /**
   * Maldives
   * @deprecated
   */
  Mv = 'MV',
  /**
   * Malawi
   * @deprecated
   */
  Mw = 'MW',
  /**
   * Mexico
   * @deprecated
   */
  Mx = 'MX',
  /**
   * Malaysia
   * @deprecated
   */
  My = 'MY',
  /**
   * Mozambique
   * @deprecated
   */
  Mz = 'MZ',
  /**
   * Namibia
   * @deprecated
   */
  Na = 'NA',
  /**
   * New Caledonia
   * @deprecated
   */
  Nc = 'NC',
  /**
   * Niger
   * @deprecated
   */
  Ne = 'NE',
  /**
   * Norfolk Island
   * @deprecated
   */
  Nf = 'NF',
  /**
   * Nigeria
   * @deprecated
   */
  Ng = 'NG',
  /**
   * Nicaragua
   * @deprecated
   */
  Ni = 'NI',
  /**
   * Netherlands
   * @deprecated
   */
  Nl = 'NL',
  /**
   * Norway
   * @deprecated
   */
  No = 'NO',
  /**
   * Nepal
   * @deprecated
   */
  Np = 'NP',
  /**
   * Nauru
   * @deprecated
   */
  Nr = 'NR',
  /**
   * Niue
   * @deprecated
   */
  Nu = 'NU',
  /**
   * New Zealand
   * @deprecated
   */
  Nz = 'NZ',
  /**
   * Oman
   * @deprecated
   */
  Om = 'OM',
  /**
   * Panama
   * @deprecated
   */
  Pa = 'PA',
  /**
   * Peru
   * @deprecated
   */
  Pe = 'PE',
  /**
   * French Polynesia
   * @deprecated
   */
  Pf = 'PF',
  /**
   * Papua New Guinea
   * @deprecated
   */
  Pg = 'PG',
  /**
   * Philippines
   * @deprecated
   */
  Ph = 'PH',
  /**
   * Pakistan
   * @deprecated
   */
  Pk = 'PK',
  /**
   * Poland
   * @deprecated
   */
  Pl = 'PL',
  /**
   * St. Pierre & Miquelon
   * @deprecated
   */
  Pm = 'PM',
  /**
   * Pitcairn Islands
   * @deprecated
   */
  Pn = 'PN',
  /**
   * Puerto Rico
   * @deprecated
   */
  Pr = 'PR',
  /**
   * Palestinian Territories
   * @deprecated
   */
  Ps = 'PS',
  /**
   * Portugal
   * @deprecated
   */
  Pt = 'PT',
  /**
   * Palau
   * @deprecated
   */
  Pw = 'PW',
  /**
   * Paraguay
   * @deprecated
   */
  Py = 'PY',
  /**
   * Qatar
   * @deprecated
   */
  Qa = 'QA',
  /**
   * Réunion
   * @deprecated
   */
  Re = 'RE',
  /**
   * Romania
   * @deprecated
   */
  Ro = 'RO',
  /**
   * Serbia
   * @deprecated
   */
  Rs = 'RS',
  /**
   * Russia
   * @deprecated
   */
  Ru = 'RU',
  /**
   * Rwanda
   * @deprecated
   */
  Rw = 'RW',
  /**
   * Saudi Arabia
   * @deprecated
   */
  Sa = 'SA',
  /**
   * Solomon Islands
   * @deprecated
   */
  Sb = 'SB',
  /**
   * Seychelles
   * @deprecated
   */
  Sc = 'SC',
  /**
   * Sudan
   * @deprecated
   */
  Sd = 'SD',
  /**
   * Sweden
   * @deprecated
   */
  Se = 'SE',
  /**
   * Singapore
   * @deprecated
   */
  Sg = 'SG',
  /**
   * St. Helena
   * @deprecated
   */
  Sh = 'SH',
  /**
   * Slovenia
   * @deprecated
   */
  Si = 'SI',
  /**
   * Svalbard & Jan Mayen
   * @deprecated
   */
  Sj = 'SJ',
  /**
   * Slovakia
   * @deprecated
   */
  Sk = 'SK',
  /**
   * Sierra Leone
   * @deprecated
   */
  Sl = 'SL',
  /**
   * San Marino
   * @deprecated
   */
  Sm = 'SM',
  /**
   * Senegal
   * @deprecated
   */
  Sn = 'SN',
  /**
   * Somalia
   * @deprecated
   */
  So = 'SO',
  /**
   * Suriname
   * @deprecated
   */
  Sr = 'SR',
  /**
   * South Sudan
   * @deprecated
   */
  Ss = 'SS',
  /**
   * São Tomé & Príncipe
   * @deprecated
   */
  St = 'ST',
  /**
   * El Salvador
   * @deprecated
   */
  Sv = 'SV',
  /**
   * Syria
   * @deprecated
   */
  Sy = 'SY',
  /**
   * Swaziland
   * @deprecated
   */
  Sz = 'SZ',
  /**
   * Turks & Caicos Islands
   * @deprecated
   */
  Tc = 'TC',
  /**
   * Chad
   * @deprecated
   */
  Td = 'TD',
  /**
   * French Southern Territories
   * @deprecated
   */
  Tf = 'TF',
  /**
   * Togo
   * @deprecated
   */
  Tg = 'TG',
  /**
   * Thailand
   * @deprecated
   */
  Th = 'TH',
  /**
   * Tajikistan
   * @deprecated
   */
  Tj = 'TJ',
  /**
   * Tokelau
   * @deprecated
   */
  Tk = 'TK',
  /**
   * Timor-Leste
   * @deprecated
   */
  Tl = 'TL',
  /**
   * Turkmenistan
   * @deprecated
   */
  Tm = 'TM',
  /**
   * Tunisia
   * @deprecated
   */
  Tn = 'TN',
  /**
   * Tonga
   * @deprecated
   */
  To = 'TO',
  /**
   * Turkey
   * @deprecated
   */
  Tr = 'TR',
  /**
   * Trinidad & Tobago
   * @deprecated
   */
  Tt = 'TT',
  /**
   * Tuvalu
   * @deprecated
   */
  Tv = 'TV',
  /**
   * Taiwan
   * @deprecated
   */
  Tw = 'TW',
  /**
   * Tanzania
   * @deprecated
   */
  Tz = 'TZ',
  /**
   * Ukraine
   * @deprecated
   */
  Ua = 'UA',
  /**
   * Uganda
   * @deprecated
   */
  Ug = 'UG',
  /**
   * U.S. Outlying Islands
   * @deprecated
   */
  Um = 'UM',
  /**
   * United States
   * @deprecated
   */
  Us = 'US',
  /**
   * Uruguay
   * @deprecated
   */
  Uy = 'UY',
  /**
   * Uzbekistan
   * @deprecated
   */
  Uz = 'UZ',
  /**
   * Vatican City
   * @deprecated
   */
  Va = 'VA',
  /**
   * St. Vincent & Grenadines
   * @deprecated
   */
  Vc = 'VC',
  /**
   * Venezuela
   * @deprecated
   */
  Ve = 'VE',
  /**
   * British Virgin Islands
   * @deprecated
   */
  Vg = 'VG',
  /**
   * U.S. Virgin Islands
   * @deprecated
   */
  Vi = 'VI',
  /**
   * Vietnam
   * @deprecated
   */
  Vn = 'VN',
  /**
   * Vanuatu
   * @deprecated
   */
  Vu = 'VU',
  /**
   * Wallis & Futuna
   * @deprecated
   */
  Wf = 'WF',
  /**
   * Samoa
   * @deprecated
   */
  Ws = 'WS',
  /**
   * Yemen
   * @deprecated
   */
  Ye = 'YE',
  /**
   * Mayotte
   * @deprecated
   */
  Yt = 'YT',
  /**
   * South Africa
   * @deprecated
   */
  Za = 'ZA',
  /**
   * Zambia
   * @deprecated
   */
  Zm = 'ZM',
  /**
   * Zimbabwe
   * @deprecated
   */
  Zw = 'ZW',
}

/** Country Code => Store View Code collection */
export type Magento2_CountryStoreCode = {
  __typename?: 'Magento2_CountryStoreCode';
  /** StoreView record with info */
  allStores?: Maybe<Array<Maybe<Magento2_StoreRecord>>>;
};

export type Magento2_CouponRecord = {
  __typename?: 'Magento2_CouponRecord';
  /** Coupon code */
  code?: Maybe<Scalars['String']>;
  /** Cart Price Rule description */
  description?: Maybe<Scalars['String']>;
  /** Discount Amount */
  discountAmount?: Maybe<Scalars['String']>;
  /** The first date the coupon can be used */
  fromDate?: Maybe<Scalars['String']>;
  /** Cart Price Rule ID */
  id?: Maybe<Scalars['String']>;
  /** If the rule is currently active in the store. */
  isActive?: Maybe<Scalars['String']>;
  /** Cart Price Rule name */
  name?: Maybe<Scalars['String']>;
  /** Rules sort order */
  sortOrder?: Maybe<Scalars['String']>;
  /** The last date the coupon can be used. */
  toDate?: Maybe<Scalars['String']>;
  /** How many times the coupon code can be used by the same registered customer */
  usesPerCustomer?: Maybe<Scalars['String']>;
};

/** Contains an array of product IDs to use for creating a compare list. */
export type Magento2_CreateCompareListInput = {
  /** An array of product IDs to add to the compare list. */
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** Defines a new gift registry. */
export type Magento2_CreateGiftRegistryInput = {
  /** Additional attributes specified as a code-value pair. */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<Magento2_GiftRegistryDynamicAttributeInput>>
  >;
  /** The name of the event. */
  event_name: Scalars['String'];
  /** The ID of the selected event type. */
  gift_registry_type_uid: Scalars['ID'];
  /** A message describing the event. */
  message: Scalars['String'];
  /** Indicates whether the registry is PRIVATE or PUBLIC. */
  privacy_settings: Magento2_GiftRegistryPrivacySettings;
  /** The list of people who receive notifications about the registry. */
  registrants: Array<InputMaybe<Magento2_AddGiftRegistryRegistrantInput>>;
  /** The shipping address for all gift registry items. */
  shipping_address?: InputMaybe<Magento2_GiftRegistryShippingAddressInput>;
  /** Indicates whether the registry is ACTIVE or INACTIVE. */
  status: Magento2_GiftRegistryStatus;
};

/** Contains the results of a request to create a gift registry. */
export type Magento2_CreateGiftRegistryOutput = {
  __typename?: 'Magento2_CreateGiftRegistryOutput';
  /** The newly-created gift registry. */
  giftRegistry?: Maybe<Magento2_GiftRegistry>;
};

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type Magento2_CreatePayflowProTokenOutput = {
  __typename?: 'Magento2_CreatePayflowProTokenOutput';
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  responseMessage: Scalars['String'];
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int'];
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  resultCode: Scalars['Int'];
  /** A secure token generated by PayPal. */
  secureToken: Scalars['String'];
  /** A secure token ID generated by PayPal. */
  secureTokenId: Scalars['String'];
};

/** Defines a new product review. */
export type Magento2_CreateProductReviewInput = {
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String'];
  /** The ratings details by category. For example, Price: 5 stars, Quality: 4 stars, etc. */
  ratings: Array<InputMaybe<Magento2_ProductReviewRatingInput>>;
  /** The SKU of the reviewed product. */
  sku: Scalars['String'];
  /** The summary (title) of the review. */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
};

/** Contains the completed product review. */
export type Magento2_CreateProductReviewOutput = {
  __typename?: 'Magento2_CreateProductReviewOutput';
  /** Product review details. */
  review: Magento2_ProductReview;
};

/** Defines the name and visibility of a new wish list. */
export type Magento2_CreateWishlistInput = {
  /** The name of the new wish list. */
  name: Scalars['String'];
  /** Indicates whether the wish list is public or private. */
  visibility: Magento2_WishlistVisibilityEnum;
};

/** Contains the wish list. */
export type Magento2_CreateWishlistOutput = {
  __typename?: 'Magento2_CreateWishlistOutput';
  /** The newly-created wish list */
  wishlist: Magento2_Wishlist;
};

/** Required fields for Payflow Pro and Payments Pro credit card payments. */
export type Magento2_CreditCardDetailsInput = {
  /** The credit card expiration month. */
  cc_exp_month: Scalars['Int'];
  /** The credit card expiration year. */
  cc_exp_year: Scalars['Int'];
  /** The last 4 digits of the credit card. */
  cc_last_4: Scalars['Int'];
  /** The credit card type. */
  cc_type: Scalars['String'];
};

/** Contains credit memo details. */
export type Magento2_CreditMemo = {
  __typename?: 'Magento2_CreditMemo';
  /** Comments on the credit memo. */
  comments?: Maybe<Array<Maybe<Magento2_SalesCommentItem>>>;
  /** The unique ID for a `CreditMemo` object. */
  id: Scalars['ID'];
  /** An array containing details about refunded items. */
  items?: Maybe<Array<Maybe<Magento2_CreditMemoItemInterface>>>;
  /** The sequential credit memo number. */
  number: Scalars['String'];
  /** Details about the total refunded amount. */
  total?: Maybe<Magento2_CreditMemoTotal>;
};

export type Magento2_CreditMemoItem = Magento2_CreditMemoItemInterface & {
  __typename?: 'Magento2_CreditMemoItem';
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of refunded items. */
  quantityRefunded?: Maybe<Scalars['Float']>;
};

/** Credit memo item details. */
export type Magento2_CreditMemoItemInterface = {
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of refunded items. */
  quantityRefunded?: Maybe<Scalars['Float']>;
};

/** Contains credit memo price details. */
export type Magento2_CreditMemoTotal = {
  __typename?: 'Magento2_CreditMemoTotal';
  /** An adjustment manually applied to the order. */
  adjustment: Magento2_Money;
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Magento2_Money;
  /** The applied discounts to the credit memo. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Magento2_Money;
  /** Details about the shipping and handling costs for the credit memo. */
  shippingHandling?: Maybe<Magento2_ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Magento2_Money;
  /** The credit memo tax details. */
  taxes?: Maybe<Array<Maybe<Magento2_TaxItem>>>;
  /** The shipping amount for the credit memo. */
  totalShipping: Magento2_Money;
  /** The amount of tax applied to the credit memo. */
  totalTax: Magento2_Money;
};

export type Magento2_Currency = {
  __typename?: 'Magento2_Currency';
  /** An array of three-letter currency codes accepted by the store, such as USD and EUR. */
  availableCurrencyCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The base currency set for the store, such as USD. */
  baseCurrencyCode?: Maybe<Scalars['String']>;
  /** The symbol for the specified base currency, such as $. */
  baseCurrencySymbol?: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  defaultDisplayCurrecyCode?: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  defaultDisplayCurrecySymbol?: Maybe<Scalars['String']>;
  /** The currency that is displayed by default, such as USD. */
  defaultDisplayCurrencyCode?: Maybe<Scalars['String']>;
  /** The currency symbol that is displayed by default, such as $. */
  defaultDisplayCurrencySymbol?: Maybe<Scalars['String']>;
  /** An array of exchange rates for currencies defined in the store. */
  exchangeRates?: Maybe<Array<Maybe<Magento2_ExchangeRate>>>;
};

/** The list of available currency codes. */
export enum Magento2_CurrencyEnum {
  /** @deprecated  */
  Aed = 'AED',
  /** @deprecated  */
  Afn = 'AFN',
  /** @deprecated  */
  All = 'ALL',
  /** @deprecated  */
  Amd = 'AMD',
  /** @deprecated  */
  Ang = 'ANG',
  /** @deprecated  */
  Aoa = 'AOA',
  /** @deprecated  */
  Ars = 'ARS',
  /** @deprecated  */
  Aud = 'AUD',
  /** @deprecated  */
  Awg = 'AWG',
  /** @deprecated  */
  Azm = 'AZM',
  /** @deprecated  */
  Azn = 'AZN',
  /** @deprecated  */
  Bam = 'BAM',
  /** @deprecated  */
  Bbd = 'BBD',
  /** @deprecated  */
  Bdt = 'BDT',
  /** @deprecated  */
  Bgn = 'BGN',
  /** @deprecated  */
  Bhd = 'BHD',
  /** @deprecated  */
  Bif = 'BIF',
  /** @deprecated  */
  Bmd = 'BMD',
  /** @deprecated  */
  Bnd = 'BND',
  /** @deprecated  */
  Bob = 'BOB',
  /** @deprecated  */
  Brl = 'BRL',
  /** @deprecated  */
  Bsd = 'BSD',
  /** @deprecated  */
  Btn = 'BTN',
  /** @deprecated  */
  Buk = 'BUK',
  /** @deprecated  */
  Bwp = 'BWP',
  /** @deprecated  */
  Byn = 'BYN',
  /** @deprecated  */
  Bzd = 'BZD',
  /** @deprecated  */
  Cad = 'CAD',
  /** @deprecated  */
  Cdf = 'CDF',
  /** @deprecated  */
  Che = 'CHE',
  /** @deprecated  */
  Chf = 'CHF',
  /** @deprecated  */
  Chw = 'CHW',
  /** @deprecated  */
  Clp = 'CLP',
  /** @deprecated  */
  Cny = 'CNY',
  /** @deprecated  */
  Cop = 'COP',
  /** @deprecated  */
  Crc = 'CRC',
  /** @deprecated  */
  Cup = 'CUP',
  /** @deprecated  */
  Cve = 'CVE',
  /** @deprecated  */
  Czk = 'CZK',
  /** @deprecated  */
  Djf = 'DJF',
  /** @deprecated  */
  Dkk = 'DKK',
  /** @deprecated  */
  Dop = 'DOP',
  /** @deprecated  */
  Dzd = 'DZD',
  /** @deprecated  */
  Eek = 'EEK',
  /** @deprecated  */
  Egp = 'EGP',
  /** @deprecated  */
  Ern = 'ERN',
  /** @deprecated  */
  Etb = 'ETB',
  /** @deprecated  */
  Eur = 'EUR',
  /** @deprecated  */
  Fjd = 'FJD',
  /** @deprecated  */
  Fkp = 'FKP',
  /** @deprecated  */
  Gbp = 'GBP',
  /** @deprecated  */
  Gek = 'GEK',
  /** @deprecated  */
  Gel = 'GEL',
  /** @deprecated  */
  Ghs = 'GHS',
  /** @deprecated  */
  Gip = 'GIP',
  /** @deprecated  */
  Gmd = 'GMD',
  /** @deprecated  */
  Gnf = 'GNF',
  /** @deprecated  */
  Gqe = 'GQE',
  /** @deprecated  */
  Gtq = 'GTQ',
  /** @deprecated  */
  Gyd = 'GYD',
  /** @deprecated  */
  Hkd = 'HKD',
  /** @deprecated  */
  Hnl = 'HNL',
  /** @deprecated  */
  Hrk = 'HRK',
  /** @deprecated  */
  Htg = 'HTG',
  /** @deprecated  */
  Huf = 'HUF',
  /** @deprecated  */
  Idr = 'IDR',
  /** @deprecated  */
  Ils = 'ILS',
  /** @deprecated  */
  Inr = 'INR',
  /** @deprecated  */
  Iqd = 'IQD',
  /** @deprecated  */
  Irr = 'IRR',
  /** @deprecated  */
  Isk = 'ISK',
  /** @deprecated  */
  Jmd = 'JMD',
  /** @deprecated  */
  Jod = 'JOD',
  /** @deprecated  */
  Jpy = 'JPY',
  /** @deprecated  */
  Kes = 'KES',
  /** @deprecated  */
  Kgs = 'KGS',
  /** @deprecated  */
  Khr = 'KHR',
  /** @deprecated  */
  Kmf = 'KMF',
  /** @deprecated  */
  Kpw = 'KPW',
  /** @deprecated  */
  Krw = 'KRW',
  /** @deprecated  */
  Kwd = 'KWD',
  /** @deprecated  */
  Kyd = 'KYD',
  /** @deprecated  */
  Kzt = 'KZT',
  /** @deprecated  */
  Lak = 'LAK',
  /** @deprecated  */
  Lbp = 'LBP',
  /** @deprecated  */
  Lkr = 'LKR',
  /** @deprecated  */
  Lrd = 'LRD',
  /** @deprecated  */
  Lsl = 'LSL',
  /** @deprecated  */
  Lsm = 'LSM',
  /** @deprecated  */
  Ltl = 'LTL',
  /** @deprecated  */
  Lvl = 'LVL',
  /** @deprecated  */
  Lyd = 'LYD',
  /** @deprecated  */
  Mad = 'MAD',
  /** @deprecated  */
  Mdl = 'MDL',
  /** @deprecated  */
  Mga = 'MGA',
  /** @deprecated  */
  Mkd = 'MKD',
  /** @deprecated  */
  Mmk = 'MMK',
  /** @deprecated  */
  Mnt = 'MNT',
  /** @deprecated  */
  Mop = 'MOP',
  /** @deprecated  */
  Mro = 'MRO',
  /** @deprecated  */
  Mur = 'MUR',
  /** @deprecated  */
  Mvr = 'MVR',
  /** @deprecated  */
  Mwk = 'MWK',
  /** @deprecated  */
  Mxn = 'MXN',
  /** @deprecated  */
  Myr = 'MYR',
  /** @deprecated  */
  Mzn = 'MZN',
  /** @deprecated  */
  Nad = 'NAD',
  /** @deprecated  */
  Ngn = 'NGN',
  /** @deprecated  */
  Nic = 'NIC',
  /** @deprecated  */
  Nok = 'NOK',
  /** @deprecated  */
  Npr = 'NPR',
  /** @deprecated  */
  Nzd = 'NZD',
  /** @deprecated  */
  Omr = 'OMR',
  /** @deprecated  */
  Pab = 'PAB',
  /** @deprecated  */
  Pen = 'PEN',
  /** @deprecated  */
  Pgk = 'PGK',
  /** @deprecated  */
  Php = 'PHP',
  /** @deprecated  */
  Pkr = 'PKR',
  /** @deprecated  */
  Pln = 'PLN',
  /** @deprecated  */
  Pyg = 'PYG',
  /** @deprecated  */
  Qar = 'QAR',
  /** @deprecated  */
  Rhd = 'RHD',
  /** @deprecated  */
  Rol = 'ROL',
  /** @deprecated  */
  Ron = 'RON',
  /** @deprecated  */
  Rsd = 'RSD',
  /** @deprecated  */
  Rub = 'RUB',
  /** @deprecated  */
  Rwf = 'RWF',
  /** @deprecated  */
  Sar = 'SAR',
  /** @deprecated  */
  Sbd = 'SBD',
  /** @deprecated  */
  Scr = 'SCR',
  /** @deprecated  */
  Sdg = 'SDG',
  /** @deprecated  */
  Sek = 'SEK',
  /** @deprecated  */
  Sgd = 'SGD',
  /** @deprecated  */
  Shp = 'SHP',
  /** @deprecated  */
  Skk = 'SKK',
  /** @deprecated  */
  Sll = 'SLL',
  /** @deprecated  */
  Sos = 'SOS',
  /** @deprecated  */
  Srd = 'SRD',
  /** @deprecated  */
  Std = 'STD',
  /** @deprecated  */
  Svc = 'SVC',
  /** @deprecated  */
  Syp = 'SYP',
  /** @deprecated  */
  Szl = 'SZL',
  /** @deprecated  */
  Thb = 'THB',
  /** @deprecated  */
  Tjs = 'TJS',
  /** @deprecated  */
  Tmm = 'TMM',
  /** @deprecated  */
  Tnd = 'TND',
  /** @deprecated  */
  Top = 'TOP',
  /** @deprecated  */
  Trl = 'TRL',
  /** @deprecated  */
  Try = 'TRY',
  /** @deprecated  */
  Ttd = 'TTD',
  /** @deprecated  */
  Twd = 'TWD',
  /** @deprecated  */
  Tzs = 'TZS',
  /** @deprecated  */
  Uah = 'UAH',
  /** @deprecated  */
  Ugx = 'UGX',
  /** @deprecated  */
  Usd = 'USD',
  /** @deprecated  */
  Uyu = 'UYU',
  /** @deprecated  */
  Uzs = 'UZS',
  /** @deprecated  */
  Veb = 'VEB',
  /** @deprecated  */
  Vef = 'VEF',
  /** @deprecated  */
  Vnd = 'VND',
  /** @deprecated  */
  Vuv = 'VUV',
  /** @deprecated  */
  Wst = 'WST',
  /** @deprecated  */
  Xcd = 'XCD',
  /** @deprecated  */
  Xof = 'XOF',
  /** @deprecated  */
  Xpf = 'XPF',
  /** @deprecated  */
  Yer = 'YER',
  /** @deprecated  */
  Ytl = 'YTL',
  /** @deprecated  */
  Zar = 'ZAR',
  /** @deprecated  */
  Zmk = 'ZMK',
  /** @deprecated  */
  Zwd = 'ZWD',
}

/** Defines an array of custom attributes. */
export type Magento2_CustomAttributeMetadata = {
  __typename?: 'Magento2_CustomAttributeMetadata';
  /** An array of attributes. */
  items?: Maybe<Array<Maybe<Magento2_Attribute>>>;
};

/** Defines the customer name, addresses, and other details. */
export type Magento2_Customer = {
  __typename?: 'Magento2_Customer';
  /** An array containing the customer's shipping and billing addresses. */
  addresses?: Maybe<Array<Maybe<Magento2_CustomerAddress>>>;
  /** The contents of the customer's compare list. */
  compareList?: Maybe<Magento2_CompareList>;
  /** Timestamp indicating when the account was created. */
  createdAt?: Maybe<Scalars['String']>;
  /** The customer's date of birth. */
  dateOfBirth?: Maybe<Scalars['String']>;
  /** The ID assigned to the billing address. */
  defaultBilling?: Maybe<Scalars['String']>;
  /** The ID assigned to the shipping address. */
  defaultShipping?: Maybe<Scalars['String']>;
  /**
   * The customer's date of birth.
   * @deprecated Use `date_of_birth` instead.
   */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required. */
  email?: Maybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** Details about all of the customer's gift registries. */
  giftRegistries?: Maybe<Array<Maybe<Magento2_GiftRegistry>>>;
  /** Details about a specific gift registry. */
  giftRegistry?: Maybe<Magento2_GiftRegistry>;
  globalOrders?: Maybe<Magento2_GlobalCustomerOrders>;
  /** @deprecated Customer group should not be exposed in the storefront scenarios. */
  groupId?: Maybe<Scalars['Int']>;
  /**
   * The ID assigned to the customer.
   * @deprecated `id` is not needed as part of `Customer`, because on the server side, it can be identified based on the customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  isSubscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer Native ID. */
  nativeId?: Maybe<Scalars['String']>;
  orders?: Maybe<Magento2_CustomerOrders>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** Details about the specified return request from the unique ID for a `Return` object. */
  return?: Maybe<Magento2_Return>;
  /** Information about the customer's return requests. */
  returns?: Maybe<Magento2_Returns>;
  /** Contains the customer's product reviews. */
  reviews: Magento2_ProductReviews;
  /** Store credit information applied for the logged in customer. */
  storeCredit?: Maybe<Magento2_CustomerStoreCredit>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
  /** Defines if Customer is test user or not. */
  testuser?: Maybe<Scalars['Boolean']>;
  /**
   * Return a customer's wish lists.
   * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2` instead.
   */
  wishlist: Magento2_Wishlist;
  /** Retrieve the wish list identified by the unique ID for a `Wishlist` object. */
  wishlistV2?: Maybe<Magento2_Wishlist>;
  /** An array of wishlists. In Magento Open Source, customers are limited to one wish list. The number of wish lists is configurable for Adobe Commerce. */
  wishlists: Array<Maybe<Magento2_Wishlist>>;
};

/** Defines the customer name, addresses, and other details. */
export type Magento2_CustomerGiftRegistryArgs = {
  giftRegistryUid: Scalars['ID'];
};

/** Defines the customer name, addresses, and other details. */
export type Magento2_CustomerGlobalOrdersArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Magento2_GlobalCustomerOrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Defines the customer name, addresses, and other details. */
export type Magento2_CustomerOrdersArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Magento2_CustomerOrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  scope?: InputMaybe<Magento2_ScopeTypeEnum>;
  sort?: InputMaybe<Magento2_CustomerOrderSortInput>;
};

/** Defines the customer name, addresses, and other details. */
export type Magento2_CustomerReturnArgs = {
  uid: Scalars['ID'];
};

/** Defines the customer name, addresses, and other details. */
export type Magento2_CustomerReturnsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Defines the customer name, addresses, and other details. */
export type Magento2_CustomerReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Defines the customer name, addresses, and other details. */
export type Magento2_CustomerWishlistV2Args = {
  id: Scalars['ID'];
};

/** Defines the customer name, addresses, and other details. */
export type Magento2_CustomerWishlistsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Contains detailed information about a customer's billing or shipping address. */
export type Magento2_CustomerAddress = {
  __typename?: 'Magento2_CustomerAddress';
  /** The customer's city or town. */
  city?: Maybe<Scalars['String']>;
  /** The customer's company. */
  company?: Maybe<Scalars['String']>;
  /** The customer's country. */
  countryCode?: Maybe<Magento2_CountryCodeEnum>;
  /**
   * The customer's country.
   * @deprecated Use `country_code` instead.
   */
  countryId?: Maybe<Scalars['String']>;
  /** @deprecated Custom attributes should not be put into a container. */
  customAttributes?: Maybe<Array<Maybe<Magento2_CustomerAddressAttribute>>>;
  /**
   * The customer ID
   * @deprecated `customer_id` is not needed as part of `CustomerAddress`. The `id` is a unique identifier for the addresses.
   */
  customerId?: Maybe<Scalars['Int']>;
  /** Indicates whether the address is the customer's default billing address. */
  defaultBilling?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the address is the customer's default shipping address. */
  defaultShipping?: Maybe<Scalars['Boolean']>;
  /** Contains any extension attributes for the address. */
  extensionAttributes?: Maybe<Array<Maybe<Magento2_CustomerAddressAttribute>>>;
  /** The customer's fax number. */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address. */
  firstname?: Maybe<Scalars['String']>;
  /** The ID of a `CustomerAddress` object. */
  id?: Maybe<Scalars['Int']>;
  /** The family name of the person associated with the shipping/billing address. */
  lastname?: Maybe<Scalars['String']>;
  /** The middle name of the person associated with the shipping/billing address. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID. */
  region?: Maybe<Magento2_CustomerAddressRegion>;
  /** The unique ID for a pre-defined region. */
  regionId?: Maybe<Scalars['Int']>;
  /** An array of strings that define the street number and name. */
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's telephone number. */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vatId?: Maybe<Scalars['String']>;
};

/** Specifies the attribute code and value of a customer address attribute. */
export type Magento2_CustomerAddressAttribute = {
  __typename?: 'Magento2_CustomerAddressAttribute';
  /** The name assigned to the customer address attribute. */
  attributeCode?: Maybe<Scalars['String']>;
  /** The valuue assigned to the customer address attribute. */
  value?: Maybe<Scalars['String']>;
};

/** Specifies the attribute code and value of a customer attribute. */
export type Magento2_CustomerAddressAttributeInput = {
  /** The name assigned to the attribute. */
  attribute_code: Scalars['String'];
  /** The value assigned to the attribute. */
  value: Scalars['String'];
};

/** Contains details about a billing or shipping address. */
export type Magento2_CustomerAddressInput = {
  /** The customer's city or town. */
  city?: InputMaybe<Scalars['String']>;
  /** The customer's company. */
  company?: InputMaybe<Scalars['String']>;
  /** The two-letter code representing the customer's country. */
  country_code?: InputMaybe<Magento2_CountryCodeEnum>;
  /** Deprecated: use `country_code` instead. */
  country_id?: InputMaybe<Magento2_CountryCodeEnum>;
  /** Deprecated: Custom attributes should not be put into container. */
  custom_attributes?: InputMaybe<
    Array<InputMaybe<Magento2_CustomerAddressAttributeInput>>
  >;
  /** Indicates whether the address is the default billing address. */
  default_billing?: InputMaybe<Scalars['Boolean']>;
  /** Indicates whether the address is the default shipping address. */
  default_shipping?: InputMaybe<Scalars['Boolean']>;
  /** The customer's fax number. */
  fax?: InputMaybe<Scalars['String']>;
  /** The first name of the person associated with the billing/shipping address. */
  firstname?: InputMaybe<Scalars['String']>;
  /** The family name of the person associated with the billing/shipping address. */
  lastname?: InputMaybe<Scalars['String']>;
  /** The middle name of the person associated with the billing/shipping address. */
  middlename?: InputMaybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode?: InputMaybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID. */
  region?: InputMaybe<Magento2_CustomerAddressRegionInput>;
  /** An array of strings that define the street number and name. */
  street?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']>;
  /** The customer's telephone number. */
  telephone?: InputMaybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  vat_id?: InputMaybe<Scalars['String']>;
};

/** CustomerAddressRegion defines the customer's state or province */
export type Magento2_CustomerAddressRegion = {
  __typename?: 'Magento2_CustomerAddressRegion';
  /** The state or province name */
  region?: Maybe<Scalars['String']>;
  /** The address region code */
  regionCode?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region */
  regionId?: Maybe<Scalars['Int']>;
};

/** Defines the customer's state or province. */
export type Magento2_CustomerAddressRegionInput = {
  /** The state or province name. */
  region?: InputMaybe<Scalars['String']>;
  /** The address region code. */
  region_code?: InputMaybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  region_id?: InputMaybe<Scalars['Int']>;
};

/** returns customer coupon list and discounts he has */
export type Magento2_CustomerCouponResult = {
  __typename?: 'Magento2_CustomerCouponResult';
  /** Coupon record with info */
  allCoupons?: Maybe<Array<Maybe<Magento2_CouponRecord>>>;
};

/** An input object for creating a customer. */
export type Magento2_CustomerCreateInput = {
  /** The customer's date of birth. */
  date_of_birth?: InputMaybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']>;
  /** The customer's email address. */
  email: Scalars['String'];
  /** The customer's first name. */
  firstname: Scalars['String'];
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: InputMaybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname: Scalars['String'];
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']>;
  /** The customer Native ID. */
  native_id?: InputMaybe<Scalars['String']>;
  /** The customer's password. */
  password?: InputMaybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']>;
};

/** Contains details about a single downloadable product. */
export type Magento2_CustomerDownloadableProduct = {
  __typename?: 'Magento2_CustomerDownloadableProduct';
  /** The date and time the purchase was made. */
  date?: Maybe<Scalars['String']>;
  /** The fully qualified URL to the download file. */
  downloadUrl?: Maybe<Scalars['String']>;
  /** The unique ID assigned to the item. */
  orderIncrementId?: Maybe<Scalars['String']>;
  /** The remaining number of times the customer can download the product. */
  remainingDownloads?: Maybe<Scalars['String']>;
  /** Indicates when the product becomes available for download. Options are `Pending` and `Invoiced`. */
  status?: Maybe<Scalars['String']>;
};

/** Contains a list of downloadable products. */
export type Magento2_CustomerDownloadableProducts = {
  __typename?: 'Magento2_CustomerDownloadableProducts';
  /** An array of purchased downloadable items. */
  items?: Maybe<Array<Maybe<Magento2_CustomerDownloadableProduct>>>;
};

/** An input object that assigns or updates customer attributes. */
export type Magento2_CustomerInput = {
  /** The customer's date of birth. */
  date_of_birth?: InputMaybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']>;
  /** The customer's email address. Required when creating a customer. */
  email?: InputMaybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: InputMaybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: InputMaybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname?: InputMaybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']>;
  /** The customer Native ID. */
  native_id?: InputMaybe<Scalars['String']>;
  /** The customer's password. */
  password?: InputMaybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']>;
};

/** Contains details about each of the customer's orders. */
export type Magento2_CustomerOrder = {
  __typename?: 'Magento2_CustomerOrder';
  /** The billing address for the order. */
  billingAddress?: Maybe<Magento2_OrderAddress>;
  /** The shipping carrier for the order delivery. */
  carrier?: Maybe<Scalars['String']>;
  /** Comments about the order. */
  comments?: Maybe<Array<Maybe<Magento2_SalesCommentItem>>>;
  /** @deprecated Use the `order_date` field instead. */
  createdAt?: Maybe<Scalars['String']>;
  /** A list of credit memos. */
  creditMemos?: Maybe<Array<Maybe<Magento2_CreditMemo>>>;
  /** The entered gift message for the order */
  giftMessage?: Maybe<Magento2_GiftMessage>;
  /** Indicates whether the customer requested a gift receipt for the order. */
  giftReceiptIncluded: Scalars['Boolean'];
  /** The selected gift wrapping for the order. */
  giftWrapping?: Maybe<Magento2_GiftWrapping>;
  /** @deprecated Use the `totals.grand_total` field instead. */
  grandTotal?: Maybe<Scalars['Float']>;
  /** The unique ID for a `CustomerOrder` object. */
  id: Scalars['ID'];
  /** @deprecated Use the `id` field instead. */
  incrementId?: Maybe<Scalars['String']>;
  /** A list of invoices for the order. */
  invoices: Array<Maybe<Magento2_Invoice>>;
  /** An array containing the items purchased in this order. */
  items?: Maybe<Array<Maybe<Magento2_OrderItemInterface>>>;
  /** A list of order items eligible to be in a return request. */
  itemsEligibleForReturn?: Maybe<Array<Maybe<Magento2_OrderItemInterface>>>;
  /** The order number. */
  number: Scalars['String'];
  /** The date the order was placed. */
  orderDate: Scalars['String'];
  /** @deprecated Use the `number` field instead. */
  orderNumber: Scalars['String'];
  /** Payment details for the order. */
  paymentMethods?: Maybe<Array<Maybe<Magento2_OrderPaymentMethod>>>;
  /** Indicates whether the customer requested a printed card for the order. */
  printedCardIncluded: Scalars['Boolean'];
  /** Return requests associated with this order. */
  returns?: Maybe<Magento2_Returns>;
  /** A list of shipments for the order. */
  shipments?: Maybe<Array<Maybe<Magento2_OrderShipment>>>;
  /** The shipping address for the order. */
  shippingAddress?: Maybe<Magento2_OrderAddress>;
  /** The delivery method for the order. */
  shippingMethod?: Maybe<Scalars['String']>;
  /** The current status of the order. */
  status: Scalars['String'];
  /** Details about the calculated totals for this order. */
  total?: Maybe<Magento2_OrderTotal>;
};

/** Contains details about each of the customer's orders. */
export type Magento2_CustomerOrderReturnsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** CustomerOrderSortInput specifies the field to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type Magento2_CustomerOrderSortInput = {
  /** This enumeration indicates whether to return results in ascending or descending order */
  sort_direction: Magento2_SortEnum;
  /** Specifies the field to use for sorting */
  sort_field: Magento2_CustomerOrderSortableField;
};

/** Specifies the field to use for sorting */
export enum Magento2_CustomerOrderSortableField {
  /**
   * Sorts customer orders by created_at field
   * @deprecated
   */
  CreatedAt = 'CREATED_AT',
  /**
   * Sorts customer orders by number
   * @deprecated
   */
  Number = 'NUMBER',
}

/** The collection of orders that match the conditions defined in the filter. */
export type Magento2_CustomerOrders = {
  __typename?: 'Magento2_CustomerOrders';
  /** An array of customer orders. */
  items: Array<Maybe<Magento2_CustomerOrder>>;
  /** Contains pagination metadata. */
  pageInfo?: Maybe<Magento2_SearchResultPageInfo>;
  /** The total count of customer orders. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Identifies the filter to use for filtering orders. */
export type Magento2_CustomerOrdersFilterInput = {
  /** Filters by order number. */
  number?: InputMaybe<Magento2_FilterStringTypeInput>;
};

/** Contains details about a newly-created or updated customer. */
export type Magento2_CustomerOutput = {
  __typename?: 'Magento2_CustomerOutput';
  /** Customer details after creating or updating a customer. */
  customer: Magento2_Customer;
};

/** Contains payment tokens stored in the customer's vault. */
export type Magento2_CustomerPaymentTokens = {
  __typename?: 'Magento2_CustomerPaymentTokens';
  /** An array of payment tokens. */
  items: Array<Maybe<Magento2_PaymentToken>>;
};

/** Contains store credit information with balance and history. */
export type Magento2_CustomerStoreCredit = {
  __typename?: 'Magento2_CustomerStoreCredit';
  /** Contains the customer's store credit balance history. If the history or store credit feature is disabled, then a null value will be returned. */
  balanceHistory?: Maybe<Magento2_CustomerStoreCreditHistory>;
  /** The current balance of store credit. */
  currentBalance?: Maybe<Magento2_Money>;
  /** Indicates whether store credits are enabled. If the feature is disabled, then the balance will not be returned. */
  enabled?: Maybe<Scalars['Boolean']>;
};

/** Contains store credit information with balance and history. */
export type Magento2_CustomerStoreCreditBalanceHistoryArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Lists changes to the amount of store credit available to the customer. */
export type Magento2_CustomerStoreCreditHistory = {
  __typename?: 'Magento2_CustomerStoreCreditHistory';
  /** An array containing information about changes to the store credit available to the customer. */
  items?: Maybe<Array<Maybe<Magento2_CustomerStoreCreditHistoryItem>>>;
  /** Metadata for pagination rendering. */
  pageInfo?: Maybe<Magento2_SearchResultPageInfo>;
  /** The number of items returned. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Contains store credit history information. */
export type Magento2_CustomerStoreCreditHistoryItem = {
  __typename?: 'Magento2_CustomerStoreCreditHistoryItem';
  /** The action that was made on the store credit. */
  action?: Maybe<Scalars['String']>;
  /** The store credit available to the customer as a result of this action.  */
  actualBalance?: Maybe<Magento2_Money>;
  /** The amount added to or subtracted from the store credit as a result of this action. */
  balanceChange?: Maybe<Magento2_Money>;
  /** The date and time when the store credit change was made. */
  dateTimeChanged?: Maybe<Scalars['String']>;
};

/** Contains a customer authorization token. */
export type Magento2_CustomerToken = {
  __typename?: 'Magento2_CustomerToken';
  /** The customer authorization token. */
  token?: Maybe<Scalars['String']>;
};

/** An input object for updating a customer. */
export type Magento2_CustomerUpdateInput = {
  /** The customer's date of birth. */
  date_of_birth?: InputMaybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: InputMaybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: InputMaybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname?: InputMaybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']>;
  /** The customer Native ID. */
  native_id?: InputMaybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']>;
};

/** Contains information about a text area that is defined as part of a customizable option. */
export type Magento2_CustomizableAreaOption =
  Magento2_CustomizableOptionInterface & {
    __typename?: 'Magento2_CustomizableAreaOption';
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId?: Maybe<Scalars['Int']>;
    /** The Stock Keeping Unit of the base product. */
    productSku?: Maybe<Scalars['String']>;
    /** Indicates whether the option is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The order in which the option is displayed. */
    sortOrder?: Maybe<Scalars['Int']>;
    /** The display name for this option. */
    title?: Maybe<Scalars['String']>;
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID'];
    /** An object that defines a text area. */
    value?: Maybe<Magento2_CustomizableAreaValue>;
  };

/** Defines the price and sku of a product whose page contains a customized text area. */
export type Magento2_CustomizableAreaValue = {
  __typename?: 'Magento2_CustomizableAreaValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  maxCharacters?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType?: Maybe<Magento2_PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableAreaValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a set of checkbox values that are defined as part of a customizable option. */
export type Magento2_CustomizableCheckboxOption =
  Magento2_CustomizableOptionInterface & {
    __typename?: 'Magento2_CustomizableCheckboxOption';
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId?: Maybe<Scalars['Int']>;
    /** Indicates whether the option is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The order in which the option is displayed. */
    sortOrder?: Maybe<Scalars['Int']>;
    /** The display name for this option. */
    title?: Maybe<Scalars['String']>;
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID'];
    /** An array that defines a set of checkbox values. */
    value?: Maybe<Array<Maybe<Magento2_CustomizableCheckboxValue>>>;
  };

/** Defines the price and sku of a product whose page contains a customized set of checkbox values. */
export type Magento2_CustomizableCheckboxValue = {
  __typename?: 'Magento2_CustomizableCheckboxValue';
  /** The ID assigned to the value. */
  optionTypeId?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType?: Maybe<Magento2_PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the checkbox value is displayed. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableCheckboxValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a date picker that is defined as part of a customizable option. */
export type Magento2_CustomizableDateOption =
  Magento2_CustomizableOptionInterface & {
    __typename?: 'Magento2_CustomizableDateOption';
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId?: Maybe<Scalars['Int']>;
    /** The Stock Keeping Unit of the base product. */
    productSku?: Maybe<Scalars['String']>;
    /** Indicates whether the option is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The order in which the option is displayed. */
    sortOrder?: Maybe<Scalars['Int']>;
    /** The display name for this option. */
    title?: Maybe<Scalars['String']>;
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID'];
    /** An object that defines a date field in a customizable option. */
    value?: Maybe<Magento2_CustomizableDateValue>;
  };

/** Defines the customizable date type. */
export enum Magento2_CustomizableDateTypeEnum {
  /** @deprecated  */
  Date = 'DATE',
  /** @deprecated  */
  DateTime = 'DATE_TIME',
  /** @deprecated  */
  Time = 'TIME',
}

/** Defines the price and sku of a product whose page contains a customized date picker. */
export type Magento2_CustomizableDateValue = {
  __typename?: 'Magento2_CustomizableDateValue';
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType?: Maybe<Magento2_PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** DATE, DATE_TIME or TIME */
  type?: Maybe<Magento2_CustomizableDateTypeEnum>;
  /** The unique ID for a `CustomizableDateValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a drop down menu that is defined as part of a customizable option. */
export type Magento2_CustomizableDropDownOption =
  Magento2_CustomizableOptionInterface & {
    __typename?: 'Magento2_CustomizableDropDownOption';
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId?: Maybe<Scalars['Int']>;
    /** Indicates whether the option is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The order in which the option is displayed. */
    sortOrder?: Maybe<Scalars['Int']>;
    /** The display name for this option. */
    title?: Maybe<Scalars['String']>;
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID'];
    /** An array that defines the set of options for a drop down menu. */
    value?: Maybe<Array<Maybe<Magento2_CustomizableDropDownValue>>>;
  };

/** Defines the price and sku of a product whose page contains a customized drop down menu. */
export type Magento2_CustomizableDropDownValue = {
  __typename?: 'Magento2_CustomizableDropDownValue';
  /** The ID assigned to the value. */
  optionTypeId?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType?: Maybe<Magento2_PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableDropDownValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a text field that is defined as part of a customizable option. */
export type Magento2_CustomizableFieldOption =
  Magento2_CustomizableOptionInterface & {
    __typename?: 'Magento2_CustomizableFieldOption';
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId?: Maybe<Scalars['Int']>;
    /** The Stock Keeping Unit of the base product. */
    productSku?: Maybe<Scalars['String']>;
    /** Indicates whether the option is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The order in which the option is displayed. */
    sortOrder?: Maybe<Scalars['Int']>;
    /** The display name for this option. */
    title?: Maybe<Scalars['String']>;
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID'];
    /** An object that defines a text field. */
    value?: Maybe<Magento2_CustomizableFieldValue>;
  };

/** Defines the price and sku of a product whose page contains a customized text field. */
export type Magento2_CustomizableFieldValue = {
  __typename?: 'Magento2_CustomizableFieldValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  maxCharacters?: Maybe<Scalars['Int']>;
  /** The price of the custom value. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType?: Maybe<Magento2_PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFieldValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a file picker that is defined as part of a customizable option. */
export type Magento2_CustomizableFileOption =
  Magento2_CustomizableOptionInterface & {
    __typename?: 'Magento2_CustomizableFileOption';
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId?: Maybe<Scalars['Int']>;
    /** The Stock Keeping Unit of the base product. */
    productSku?: Maybe<Scalars['String']>;
    /** Indicates whether the option is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The order in which the option is displayed. */
    sortOrder?: Maybe<Scalars['Int']>;
    /** The display name for this option. */
    title?: Maybe<Scalars['String']>;
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID'];
    /** An object that defines a file value. */
    value?: Maybe<Magento2_CustomizableFileValue>;
  };

/** Defines the price and sku of a product whose page contains a customized file picker. */
export type Magento2_CustomizableFileValue = {
  __typename?: 'Magento2_CustomizableFileValue';
  /** The file extension to accept. */
  fileExtension?: Maybe<Scalars['String']>;
  /** The maximum width of an image. */
  imageSizeX?: Maybe<Scalars['Int']>;
  /** The maximum height of an image. */
  imageSizeY?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType?: Maybe<Magento2_PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFileValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a multiselect that is defined as part of a customizable option. */
export type Magento2_CustomizableMultipleOption =
  Magento2_CustomizableOptionInterface & {
    __typename?: 'Magento2_CustomizableMultipleOption';
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId?: Maybe<Scalars['Int']>;
    /** Indicates whether the option is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The order in which the option is displayed. */
    sortOrder?: Maybe<Scalars['Int']>;
    /** The display name for this option. */
    title?: Maybe<Scalars['String']>;
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID'];
    /** An array that defines the set of options for a multiselect. */
    value?: Maybe<Array<Maybe<Magento2_CustomizableMultipleValue>>>;
  };

/** Defines the price and sku of a product whose page contains a customized multiselect. */
export type Magento2_CustomizableMultipleValue = {
  __typename?: 'Magento2_CustomizableMultipleValue';
  /** The ID assigned to the value. */
  optionTypeId?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType?: Maybe<Magento2_PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableMultipleValue` object. */
  uid: Scalars['ID'];
};

/** Defines a customizable option. */
export type Magento2_CustomizableOptionInput = {
  /** The customizable option ID of the product. */
  id?: InputMaybe<Scalars['Int']>;
  /** The string value of the option. */
  value_string: Scalars['String'];
};

/** Contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export type Magento2_CustomizableOptionInterface = {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
};

/** Contains information about customizable product options. */
export type Magento2_CustomizableProductInterface = {
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<Magento2_CustomizableOptionInterface>>>;
};

/** Contains information about a set of radio buttons that are defined as part of a customizable option. */
export type Magento2_CustomizableRadioOption =
  Magento2_CustomizableOptionInterface & {
    __typename?: 'Magento2_CustomizableRadioOption';
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId?: Maybe<Scalars['Int']>;
    /** Indicates whether the option is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The order in which the option is displayed. */
    sortOrder?: Maybe<Scalars['Int']>;
    /** The display name for this option. */
    title?: Maybe<Scalars['String']>;
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID'];
    /** An array that defines a set of radio buttons. */
    value?: Maybe<Array<Maybe<Magento2_CustomizableRadioValue>>>;
  };

/** Defines the price and sku of a product whose page contains a customized set of radio buttons. */
export type Magento2_CustomizableRadioValue = {
  __typename?: 'Magento2_CustomizableRadioValue';
  /** The ID assigned to the value. */
  optionTypeId?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType?: Maybe<Magento2_PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the radio button is displayed. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableRadioValue` object. */
  uid: Scalars['ID'];
};

/** Contains the results of the request to delete a compare list. */
export type Magento2_DeleteCompareListOutput = {
  __typename?: 'Magento2_DeleteCompareListOutput';
  /** Indicates whether the compare list was successfully deleted. */
  result: Scalars['Boolean'];
};

/** Indicates whether the request succeeded and returns the remaining customer payment tokens. */
export type Magento2_DeletePaymentTokenOutput = {
  __typename?: 'Magento2_DeletePaymentTokenOutput';
  /** A container for the customer's remaining payment tokens. */
  customerPaymentTokens?: Maybe<Magento2_CustomerPaymentTokens>;
  /** Indicates whether the request succeeded. */
  result: Scalars['Boolean'];
};

/** Contains the status of the request to delete a wish list and an array of the customer's remaining wish lists. */
export type Magento2_DeleteWishlistOutput = {
  __typename?: 'Magento2_DeleteWishlistOutput';
  /** Indicates whether the wish list was deleted. */
  status: Scalars['Boolean'];
  /** A list of undeleted wish lists. */
  wishlists: Array<Maybe<Magento2_Wishlist>>;
};

/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item. */
export type Magento2_Discount = {
  __typename?: 'Magento2_Discount';
  /** The amount of the discount. */
  amount: Magento2_Money;
  /** A description of the discount. */
  label: Scalars['String'];
};

/** An implementation for downloadable product cart items. */
export type Magento2_DownloadableCartItem = Magento2_CartItemInterface & {
  __typename?: 'Magento2_DownloadableCartItem';
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<Magento2_CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** An array containing information about the links for the downloadable product added to the cart. */
  links?: Maybe<Array<Maybe<Magento2_DownloadableProductLinks>>>;
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<Magento2_CartItemPrices>;
  /** Details about an item in the cart. */
  product: Magento2_ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** An array containing information about samples of the selected downloadable product. */
  samples?: Maybe<Array<Maybe<Magento2_DownloadableProductSamples>>>;
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines downloadable product options for `CreditMemoItemInterface`. */
export type Magento2_DownloadableCreditMemoItem =
  Magento2_CreditMemoItemInterface & {
    __typename?: 'Magento2_DownloadableCreditMemoItem';
    /** Details about the final discount amount for the base product, including discounts on options. */
    discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
    /** A list of downloadable links that are refunded from the downloadable product. */
    downloadableLinks?: Maybe<Array<Maybe<Magento2_DownloadableItemsLinks>>>;
    /** The unique ID for a `CreditMemoItemInterface` object. */
    id: Scalars['ID'];
    /** The order item the credit memo is applied to. */
    orderItem?: Maybe<Magento2_OrderItemInterface>;
    /** The name of the base product. */
    productName?: Maybe<Scalars['String']>;
    /** The sale price for the base product, including selected options. */
    productSalePrice: Magento2_Money;
    /** The SKU of the base product. */
    productSku: Scalars['String'];
    /** The number of refunded items. */
    quantityRefunded?: Maybe<Scalars['Float']>;
  };

export enum Magento2_DownloadableFileTypeEnum {
  /** @deprecated `sample_url` serves to get the downloadable sample */
  File = 'FILE',
  /** @deprecated `sample_url` serves to get the downloadable sample */
  Url = 'URL',
}

/** Defines downloadable product options for `InvoiceItemInterface`. */
export type Magento2_DownloadableInvoiceItem = Magento2_InvoiceItemInterface & {
  __typename?: 'Magento2_DownloadableInvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** A list of downloadable links that are invoiced from the downloadable product. */
  downloadableLinks?: Maybe<Array<Maybe<Magento2_DownloadableItemsLinks>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of invoiced items. */
  quantityInvoiced?: Maybe<Scalars['Float']>;
};

/** Defines characteristics of the links for downloadable product. */
export type Magento2_DownloadableItemsLinks = {
  __typename?: 'Magento2_DownloadableItemsLinks';
  /** A number indicating the sort order. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The display name of the link. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableItemsLinks` object. */
  uid: Scalars['ID'];
};

/** Defines downloadable product options for `OrderItemInterface`. */
export type Magento2_DownloadableOrderItem = Magento2_OrderItemInterface & {
  __typename?: 'Magento2_DownloadableOrderItem';
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** A list of downloadable links that are ordered from the downloadable product. */
  downloadableLinks?: Maybe<Array<Maybe<Magento2_DownloadableItemsLinks>>>;
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn?: Maybe<Scalars['Boolean']>;
  /** The entered option for the base product, such as a logo or image */
  enteredOptions?: Maybe<Array<Maybe<Magento2_OrderItemOption>>>;
  /** The selected gift message for the order item */
  giftMessage?: Maybe<Magento2_GiftMessage>;
  /** The selected gift wrapping for the order item. */
  giftWrapping?: Maybe<Magento2_GiftWrapping>;
  /** The unique identifier of the order item */
  id: Scalars['ID'];
  /** The path to product thumbnail */
  productImage?: Maybe<Scalars['String']>;
  /** The name of the base product */
  productName?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  productSalePrice: Magento2_Money;
  /** The sale price of the base product including tax, including selected options */
  productSalePriceIncludingTax: Magento2_Money;
  /** The serial system of the product, such as SDBS, IZO_DB, etc. */
  productSerialSystem?: Maybe<Scalars['String']>;
  /** The SKU of the base product */
  productSku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  productType?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  productUrlKey?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantityCanceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantityInvoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantityOrdered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantityRefunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantityReturned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantityShipped?: Maybe<Scalars['Float']>;
  /** The return item status */
  returnStatus?: Maybe<Scalars['String']>;
  /** The row total of the order item. */
  rowTotal: Magento2_Money;
  /** The row total including tax of the order item. */
  rowTotalIncludingTax: Magento2_Money;
  /** The selected options for the base product, such as color or size */
  selectedOptions?: Maybe<Array<Maybe<Magento2_OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
};

/** Defines a product that the shopper downloads. */
export type Magento2_DownloadableProduct =
  Magento2_CustomizableProductInterface &
    Magento2_ProductInterface &
    Magento2_RoutableInterface & {
      __typename?: 'Magento2_DownloadableProduct';
      /** Additional information about variant */
      additionalInfo?: Maybe<Scalars['String']>;
      /**
       * The attribute set assigned to the product.
       * @deprecated The field should not be used on the storefront.
       */
      attributeSetId?: Maybe<Scalars['Int']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      brand?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      bundleTotalPrice?: Maybe<Scalars['Float']>;
      /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
      canonicalUrl?: Maybe<Scalars['String']>;
      /** The categories assigned to a product. */
      categories?: Maybe<Array<Maybe<Magento2_CategoryInterface>>>;
      /** @deprecated Use the `custom_attributes` field instead. */
      color?: Maybe<Scalars['Int']>;
      /** The product's country of origin. */
      countryOfManufacture?: Maybe<Scalars['String']>;
      /**
       * Timestamp indicating when the product was created.
       * @deprecated The field should not be used on the storefront.
       */
      createdAt?: Maybe<Scalars['String']>;
      /** An array of cross-sell products. */
      crosssellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
      /** Detailed information about the product. The value can include simple HTML tags. */
      description?: Maybe<Magento2_ComplexTextValue>;
      /** @deprecated Use the `custom_attributes` field instead. */
      detailPage?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      dimension?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      downloadPage?: Maybe<Scalars['String']>;
      /** An array containing information about the links for this downloadable product. */
      downloadableProductLinks?: Maybe<
        Array<Maybe<Magento2_DownloadableProductLinks>>
      >;
      /** An array containing information about samples of this downloadable product. */
      downloadableProductSamples?: Maybe<
        Array<Maybe<Magento2_DownloadableProductSamples>>
      >;
      /** Indicates whether a gift message is available. */
      giftMessageAvailable?: Maybe<Scalars['String']>;
      /** Hide qualify message */
      hideQualifyMessage?: Maybe<Scalars['Boolean']>;
      /**
       * The ID number assigned to the product.
       * @deprecated Use the `uid` field instead.
       */
      id?: Maybe<Scalars['Int']>;
      /** The relative path to the main image on the product page. */
      image?: Maybe<Magento2_ProductImage>;
      /** Check default variant of configurable products. */
      isDefaultWithinAllVariants?: Maybe<Scalars['Boolean']>;
      /** Indicates whether the product can be returned. */
      isReturnable?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      itemType?: Maybe<Scalars['Int']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      label?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      licenseClass?: Maybe<Scalars['Int']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      licenseCode?: Maybe<Scalars['Int']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      licenseType?: Maybe<Scalars['Int']>;
      /** A value of 1 indicates that each link in the array must be purchased separately. */
      linksPurchasedSeparately?: Maybe<Scalars['Int']>;
      /** The heading above the list of downloadable products. */
      linksTitle?: Maybe<Scalars['String']>;
      /**
       * A number representing the product's manufacturer.
       * @deprecated Use the `custom_attributes` field instead.
       */
      manufacturer?: Maybe<Scalars['Int']>;
      /** An array of media gallery objects. */
      mediaGallery?: Maybe<Array<Maybe<Magento2_MediaGalleryInterface>>>;
      /**
       * An array of MediaGalleryEntry objects.
       * @deprecated Use `media_gallery` instead.
       */
      mediaGalleryEntries?: Maybe<Array<Maybe<Magento2_MediaGalleryEntry>>>;
      /** @deprecated Use the `custom_attributes` field instead. */
      mediaPage?: Maybe<Scalars['String']>;
      /** A brief overview of the product for search results listings, maximum 255 characters. */
      metaDescription?: Maybe<Scalars['String']>;
      /** A comma-separated list of keywords that are visible only to search engines. */
      metaKeyword?: Maybe<Scalars['String']>;
      /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
      metaTitle?: Maybe<Scalars['String']>;
      /** The product name. Customers use this name to identify the product. */
      name?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      nativeAccess?: Maybe<Scalars['Int']>;
      /** The beginning date for new product listings, and determines if the product is featured as a new product. */
      newFromDate?: Maybe<Scalars['String']>;
      /** The end date for new product listings. */
      newToDate?: Maybe<Scalars['String']>;
      /** Product stock only x left count */
      onlyXLeftInStock?: Maybe<Scalars['Float']>;
      /** An array of options for a customizable product. */
      options?: Maybe<Array<Maybe<Magento2_CustomizableOptionInterface>>>;
      /** If the product has multiple options, determines where they appear on the product page. */
      optionsContainer?: Maybe<Scalars['String']>;
      /**
       * Indicates the price of an item.
       * @deprecated Use `price_range` for product price information.
       */
      price?: Maybe<Magento2_ProductPrices>;
      /** The range of prices for the product */
      priceRange: Magento2_PriceRange;
      /** An array of `TierPrice` objects. */
      priceTiers?: Maybe<Array<Maybe<Magento2_TierPrice>>>;
      /** @deprecated Use the `custom_attributes` field instead. */
      pricingPage?: Maybe<Scalars['String']>;
      /** An array of `ProductLinks` objects. */
      productLinks?: Maybe<Array<Maybe<Magento2_ProductLinksInterface>>>;
      /** The average of all the ratings given to the product. */
      ratingSummary: Scalars['Float'];
      /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
      redirectCode: Scalars['Int'];
      /** An array of related products. */
      relatedProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
      /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
      relativeUrl?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      releaseDate?: Maybe<Scalars['String']>;
      /** Required Products Information about the product. The value can include simple HTML tags. */
      requiredProductsInformation?: Maybe<Magento2_ComplexTextValue>;
      /** The total count of all the reviews given to the product. */
      reviewCount: Scalars['Int'];
      /** The list of products reviews. */
      reviews: Magento2_ProductReviews;
      /** @deprecated Use the `custom_attributes` field instead. */
      sdbsId?: Maybe<Scalars['String']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      serialSystem?: Maybe<Scalars['Int']>;
      /** @deprecated Use the `custom_attributes` field instead. */
      serialSystemReference?: Maybe<Scalars['String']>;
      /** A short description of the product. Its use depends on the theme. */
      shortDescription?: Maybe<Magento2_ComplexTextValue>;
      /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
      sku?: Maybe<Scalars['String']>;
      /** The relative path to the small image, which is used on catalog pages. */
      smallImage?: Maybe<Magento2_ProductImage>;
      /**
       * The beginning date that a product has a special price.
       * @deprecated The field should not be used on the storefront.
       */
      specialFromDate?: Maybe<Scalars['String']>;
      /** The discounted price of the product. */
      specialPrice?: Maybe<Scalars['Float']>;
      /** The end date for a product with a special price. */
      specialToDate?: Maybe<Scalars['String']>;
      /** Indicates whether the product is staged for a future campaign. */
      staged: Scalars['Boolean'];
      /** Stock availability label. */
      stockAvailabilityLabel?: Maybe<Scalars['String']>;
      /** Stock status of the product */
      stockStatus?: Maybe<Magento2_ProductStockStatus>;
      /** The file name of a swatch image. */
      swatchImage?: Maybe<Scalars['String']>;
      /** The relative path to the product's thumbnail image. */
      thumbnail?: Maybe<Magento2_ProductImage>;
      /**
       * The price when tier pricing is in effect and the items purchased threshold has been reached.
       * @deprecated Use `price_tiers` for product tier price information.
       */
      tierPrice?: Maybe<Scalars['Float']>;
      /**
       * An array of ProductTierPrices objects.
       * @deprecated Use `price_tiers` for product tier price information.
       */
      tierPrices?: Maybe<Array<Maybe<Magento2_ProductTierPrices>>>;
      /** Time to stock custom attribute. */
      timeToStock?: Maybe<Scalars['String']>;
      /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
      type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
      /**
       * One of simple, virtual, bundle, downloadable, grouped, or configurable.
       * @deprecated Use `__typename` instead.
       */
      typeId?: Maybe<Scalars['String']>;
      /** The unique ID for a `ProductInterface` object. */
      uid: Scalars['ID'];
      /**
       * Timestamp indicating when the product was updated.
       * @deprecated The field should not be used on the storefront.
       */
      updatedAt?: Maybe<Scalars['String']>;
      /** An array of up-sell products. */
      upsellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
      /** The part of the URL that identifies the product */
      urlKey?: Maybe<Scalars['String']>;
      /** @deprecated Use product's `canonical_url` or url rewrites instead */
      urlPath?: Maybe<Scalars['String']>;
      /** URL rewrites list */
      urlRewrites?: Maybe<Array<Maybe<Magento2_UrlRewrite>>>;
      /** The part of the product URL that is appended after the url key */
      urlSuffix?: Maybe<Scalars['String']>;
      /**
       * An array of websites in which the product is available.
       * @deprecated The field should not be used on the storefront.
       */
      websites?: Maybe<Array<Maybe<Magento2_Website>>>;
    };

/** Defines a product that the shopper downloads. */
export type Magento2_DownloadableProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Defines a single downloadable product. */
export type Magento2_DownloadableProductCartItemInput = {
  /** The ID and value of the option. */
  customizable_options?: InputMaybe<
    Array<InputMaybe<Magento2_CustomizableOptionInput>>
  >;
  /** The quantity and SKU of the downloadable product. */
  data: Magento2_CartItemInput;
  /** An array of objects containing the link_id of the downloadable product link. */
  downloadable_product_links?: InputMaybe<
    Array<InputMaybe<Magento2_DownloadableProductLinksInput>>
  >;
};

/** Defines characteristics of a downloadable product. */
export type Magento2_DownloadableProductLinks = {
  __typename?: 'Magento2_DownloadableProductLinks';
  /** @deprecated This information should not be exposed on frontend. */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated This information should not be exposed on frontend. */
  isShareable?: Maybe<Scalars['Boolean']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  linkType?: Maybe<Magento2_DownloadableFileTypeEnum>;
  /** @deprecated This information should not be exposed on frontend. */
  numberOfDownloads?: Maybe<Scalars['Int']>;
  /** The price of the downloadable product. */
  price?: Maybe<Scalars['Float']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleFile?: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleType?: Maybe<Magento2_DownloadableFileTypeEnum>;
  /** The full URL to the downloadable sample. */
  sampleUrl?: Maybe<Scalars['String']>;
  /** A number indicating the sort order. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The display name of the link. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableProductLinks` object. */
  uid: Scalars['ID'];
};

/** Contains the link ID for the downloadable product. */
export type Magento2_DownloadableProductLinksInput = {
  /** The unique ID of the downloadable product link. */
  link_id: Scalars['Int'];
};

/** Defines characteristics of a downloadable product. */
export type Magento2_DownloadableProductSamples = {
  __typename?: 'Magento2_DownloadableProductSamples';
  /** @deprecated This information should not be exposed on frontend. */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleFile?: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleType?: Maybe<Magento2_DownloadableFileTypeEnum>;
  /** The full URL to the downloadable sample. */
  sampleUrl?: Maybe<Scalars['String']>;
  /** A number indicating the sort order. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The display name of the sample. */
  title?: Maybe<Scalars['String']>;
};

/** A downloadable product wish list item. */
export type Magento2_DownloadableWishlistItem =
  Magento2_WishlistItemInterface & {
    __typename?: 'Magento2_DownloadableWishlistItem';
    /** The date and time the item was added to the wish list. */
    addedAt: Scalars['String'];
    /** Custom options selected for the wish list item. */
    customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
    /** The description of the item. */
    description?: Maybe<Scalars['String']>;
    /** The unique ID for a `WishlistItemInterface` object. */
    id: Scalars['ID'];
    /** An array containing information about the selected links. */
    linksV2?: Maybe<Array<Maybe<Magento2_DownloadableProductLinks>>>;
    /** Product details of the wish list item. */
    product?: Maybe<Magento2_ProductInterface>;
    /** The quantity of this wish list item. */
    quantity: Scalars['Float'];
    /** An array containing information about the selected samples. */
    samples?: Maybe<Array<Maybe<Magento2_DownloadableProductSamples>>>;
  };

/** Contains a single dynamic block. */
export type Magento2_DynamicBlock = {
  __typename?: 'Magento2_DynamicBlock';
  /** The renderable HTML code of the dynamic block. */
  content: Magento2_ComplexTextValue;
  /** The unique ID of a `DynamicBlock` object. */
  uid: Scalars['ID'];
};

/** Indicates the locations the dynamic block can be placed. If this field is not specified, the query returns all locations. */
export enum Magento2_DynamicBlockLocationEnum {
  /** @deprecated  */
  Content = 'CONTENT',
  /** @deprecated  */
  Footer = 'FOOTER',
  /** @deprecated  */
  Header = 'HEADER',
  /** @deprecated  */
  Left = 'LEFT',
  /** @deprecated  */
  Right = 'RIGHT',
}

/** Indicates the selected Dynamic Blocks Rotator inline widget. */
export enum Magento2_DynamicBlockTypeEnum {
  /** @deprecated  */
  CartPriceRuleRelated = 'CART_PRICE_RULE_RELATED',
  /** @deprecated  */
  CatalogPriceRuleRelated = 'CATALOG_PRICE_RULE_RELATED',
  /** @deprecated  */
  Specified = 'SPECIFIED',
}

/** Contains an array of dynamic blocks. */
export type Magento2_DynamicBlocks = {
  __typename?: 'Magento2_DynamicBlocks';
  /** An array containing individual dynamic blocks. */
  items: Array<Maybe<Magento2_DynamicBlock>>;
  /** Metadata for pagination rendering. */
  pageInfo?: Maybe<Magento2_SearchResultPageInfo>;
  /** The number of returned dynamic blocks. */
  totalCount: Scalars['Int'];
};

/** Defines the dynamic block filter. The filter can identify the block type, location and IDs to return. */
export type Magento2_DynamicBlocksFilterInput = {
  /** An array of dynamic block UIDs to filter on. */
  dynamic_block_uids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array indicating the locations the dynamic block can be placed. */
  locations?: InputMaybe<Array<InputMaybe<Magento2_DynamicBlockLocationEnum>>>;
  /** A value indicating the type of dynamic block to filter on. */
  type: Magento2_DynamicBlockTypeEnum;
};

/** Contains details about a custom text attribute that the buyer entered. */
export type Magento2_EnteredCustomAttributeInput = {
  /** A string that identifies the entered custom attribute. */
  attribute_code: Scalars['String'];
  /** The text or other entered value. */
  value: Scalars['String'];
};

/** Defines a customer-entered option. */
export type Magento2_EnteredOptionInput = {
  /** The unique ID for a `CustomizableOptionInterface` object, such as a `CustomizableFieldOption`, `CustomizableFileOption`, or `CustomizableAreaOption` object. */
  uid: Scalars['ID'];
  /** Text the customer entered. */
  value: Scalars['String'];
};

/** Contains the `uid`, `relative_url`, and `type` attributes. */
export type Magento2_EntityUrl = {
  __typename?: 'Magento2_EntityUrl';
  /** @deprecated Use `relative_url` instead. */
  canonicalUrl?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, or similar object associated with the specified URL. This could be a product, category, or CMS page UID. */
  entityUid?: Maybe<Scalars['ID']>;
  /**
   * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
   * @deprecated Use `entity_uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode?: Maybe<Scalars['Int']>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
};

export type Magento2_ErrorInterface = {
  /** The returned error message. */
  message: Scalars['String'];
};

/** Lists the exchange rate. */
export type Magento2_ExchangeRate = {
  __typename?: 'Magento2_ExchangeRate';
  /** Specifies the store’s default currency to exchange to. */
  currencyTo?: Maybe<Scalars['String']>;
  /** The exchange rate for the store’s default currency. */
  rate?: Maybe<Scalars['Float']>;
};

/** Defines a filter that matches the input exactly. */
export type Magento2_FilterEqualTypeInput = {
  /** Use this attribute to exactly match the specified string. For example, to filter on a specific category ID, specify a value such as `5`. */
  eq?: InputMaybe<Scalars['String']>;
  /** Use this attribute to filter on an array of values. For example, to filter on category IDs 4, 5, and 6, specify a value of `["4", "5", "6"]`. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Defines a filter that performs a fuzzy search. */
export type Magento2_FilterMatchTypeInput = {
  /** Use this attribute to exactly match the specified string. For example, to filter on a specific SKU, specify a value such as `24-MB01`. */
  match?: InputMaybe<Scalars['String']>;
};

/** Defines a filter that matches a range of values, such as prices or dates. */
export type Magento2_FilterRangeTypeInput = {
  /** Use this attribute to specify the lowest possible value in the range. */
  from?: InputMaybe<Scalars['String']>;
  /** Use this attribute to specify the highest possible value in the range. */
  to?: InputMaybe<Scalars['String']>;
};

/** Defines a filter for an input string. */
export type Magento2_FilterStringTypeInput = {
  /** Filters items that are exactly the same as the specified string. */
  eq?: InputMaybe<Scalars['String']>;
  /** Filters items that are exactly the same as entries specified in an array of strings. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Defines a filter that performs a fuzzy search using the specified string. */
  match?: InputMaybe<Scalars['String']>;
};

/** Defines the comparison operators that can be used in a filter. */
export type Magento2_FilterTypeInput = {
  /** Equals. */
  eq?: InputMaybe<Scalars['String']>;
  finset?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** From. Must be used with the `to` field. */
  from?: InputMaybe<Scalars['String']>;
  /** Greater than. */
  gt?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to. */
  gteq?: InputMaybe<Scalars['String']>;
  /** In. The value can contain a set of comma-separated values. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Less than. */
  lt?: InputMaybe<Scalars['String']>;
  /** Less than or equal to. */
  lteq?: InputMaybe<Scalars['String']>;
  /** More than or equal to. */
  moreq?: InputMaybe<Scalars['String']>;
  /** Not equal to. */
  neq?: InputMaybe<Scalars['String']>;
  /** Not in. The value can contain a set of comma-separated values. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Not null. */
  notnull?: InputMaybe<Scalars['String']>;
  /** Is null. */
  null?: InputMaybe<Scalars['String']>;
  /** To. Must be used with the `from` field. */
  to?: InputMaybe<Scalars['String']>;
};

/** A single FPT that can be applied to a product price. */
export type Magento2_FixedProductTax = {
  __typename?: 'Magento2_FixedProductTax';
  /** The amount of the Fixed Product Tax. */
  amount?: Maybe<Magento2_Money>;
  /** The display label assigned to the Fixed Product Tax. */
  label?: Maybe<Scalars['String']>;
};

/** Lists display settings for the Fixed Product Tax. */
export enum Magento2_FixedProductTaxDisplaySettings {
  /**
   * The displayed price does not include the FPT amount. The values of `ProductPrice.fixed_product_taxes` and the price including the FPT are displayed separately. This value corresponds to 'Excluding FPT, Including FPT description and final price.'
   * @deprecated
   */
  ExcludeFptAndIncludeWithDetails = 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS',
  /**
   * The displayed price does not include the FPT amount. The values from `ProductPrice.fixed_product_taxes` are not displayed. This value corresponds to 'Excluding FPT'.
   * @deprecated
   */
  ExcludeFptWithoutDetails = 'EXCLUDE_FPT_WITHOUT_DETAILS',
  /**
   * The FPT feature is not enabled. You can omit `ProductPrice.fixed_product_taxes` from your query.
   * @deprecated
   */
  FptDisabled = 'FPT_DISABLED',
  /**
   * The displayed price includes the FPT amount without displaying the `ProductPrice.fixed_product_taxes` values. This value corresponds to 'Including FPT only'.
   * @deprecated
   */
  IncludeFptWithoutDetails = 'INCLUDE_FPT_WITHOUT_DETAILS',
  /**
   * The displayed price includes the FPT amount while displaying the values of `ProductPrice.fixed_product_taxes` separately. This value corresponds to 'Including FPT and FPT description'.
   * @deprecated
   */
  IncludeFptWithDetails = 'INCLUDE_FPT_WITH_DETAILS',
}

/** Contains details about the gift card account. */
export type Magento2_GiftCardAccount = {
  __typename?: 'Magento2_GiftCardAccount';
  /** The balance remaining on the gift card. */
  balance?: Maybe<Magento2_Money>;
  /** The gift card account code. */
  code?: Maybe<Scalars['String']>;
  /** The expiration date of the gift card. */
  expirationDate?: Maybe<Scalars['String']>;
};

/** Contains the gift card code. */
export type Magento2_GiftCardAccountInput = {
  /** The applied gift card code. */
  gift_card_code: Scalars['String'];
};

/** Contains the value of a gift card, the website that generated the card, and related information. */
export type Magento2_GiftCardAmounts = {
  __typename?: 'Magento2_GiftCardAmounts';
  /** An internal attribute ID. */
  attributeId?: Maybe<Scalars['Int']>;
  /** The unique ID for a `GiftCardAmounts` object. */
  uid: Scalars['ID'];
  /** The value of the gift card. */
  value?: Maybe<Scalars['Float']>;
  /**
   * An ID that is assigned to each unique gift card amount.
   * @deprecated Use `uid` instead
   */
  valueId?: Maybe<Scalars['Int']>;
  /** The ID of the website that generated the gift card. */
  websiteId?: Maybe<Scalars['Int']>;
  /** The value of the gift card. */
  websiteValue?: Maybe<Scalars['Float']>;
};

/** Contains details about a gift card that has been added to a cart. */
export type Magento2_GiftCardCartItem = Magento2_CartItemInterface & {
  __typename?: 'Magento2_GiftCardCartItem';
  /** The amount and currency of the gift card. */
  amount: Magento2_Money;
  /** An array of customizations applied to the gift card. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<Magento2_CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** The message from the sender to the recipient. */
  message?: Maybe<Scalars['String']>;
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<Magento2_CartItemPrices>;
  /** Details about an item in the cart. */
  product: Magento2_ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The email address of the person receiving the gift card. */
  recipientEmail?: Maybe<Scalars['String']>;
  /** The name of the person receiving the gift card. */
  recipientName: Scalars['String'];
  /** The email address of the sender. */
  senderEmail?: Maybe<Scalars['String']>;
  /** The name of the sender. */
  senderName: Scalars['String'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

export type Magento2_GiftCardCreditMemoItem =
  Magento2_CreditMemoItemInterface & {
    __typename?: 'Magento2_GiftCardCreditMemoItem';
    /** Details about the final discount amount for the base product, including discounts on options. */
    discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
    /** Selected gift card properties for a credit memo item. */
    giftCard?: Maybe<Magento2_GiftCardItem>;
    /** The unique ID for a `CreditMemoItemInterface` object. */
    id: Scalars['ID'];
    /** The order item the credit memo is applied to. */
    orderItem?: Maybe<Magento2_OrderItemInterface>;
    /** The name of the base product. */
    productName?: Maybe<Scalars['String']>;
    /** The sale price for the base product, including selected options. */
    productSalePrice: Magento2_Money;
    /** The SKU of the base product. */
    productSku: Scalars['String'];
    /** The number of refunded items. */
    quantityRefunded?: Maybe<Scalars['Float']>;
  };

export type Magento2_GiftCardInvoiceItem = Magento2_InvoiceItemInterface & {
  __typename?: 'Magento2_GiftCardInvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** Selected gift card properties for an invoice item. */
  giftCard?: Maybe<Magento2_GiftCardItem>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of invoiced items. */
  quantityInvoiced?: Maybe<Scalars['Float']>;
};

/** Contains details about a gift card. */
export type Magento2_GiftCardItem = {
  __typename?: 'Magento2_GiftCardItem';
  /** The message from the sender to the recipient. */
  message?: Maybe<Scalars['String']>;
  /** The email address of the receiver of a virtual gift card. */
  recipientEmail?: Maybe<Scalars['String']>;
  /** The name of the receiver of a physical or virtual gift card. */
  recipientName?: Maybe<Scalars['String']>;
  /** The email address of the sender of a virtual gift card. */
  senderEmail?: Maybe<Scalars['String']>;
  /** The name of the sender of a physical or virtual gift card. */
  senderName?: Maybe<Scalars['String']>;
};

/** Contains details about the sender, recipient, and amount of a gift card. */
export type Magento2_GiftCardOptions = {
  __typename?: 'Magento2_GiftCardOptions';
  /** The amount and currency of the gift card. */
  amount?: Maybe<Magento2_Money>;
  /** The custom amount and currency of the gift card. */
  customGiftcardAmount?: Maybe<Magento2_Money>;
  /** The message from the sender to the recipient. */
  message?: Maybe<Scalars['String']>;
  /** The email address of the person receiving the gift card. */
  recipientEmail?: Maybe<Scalars['String']>;
  /** The name of the person receiving the gift card. */
  recipientName?: Maybe<Scalars['String']>;
  /** The email address of the sender. */
  senderEmail?: Maybe<Scalars['String']>;
  /** The name of the sender. */
  senderName?: Maybe<Scalars['String']>;
};

export type Magento2_GiftCardOrderItem = Magento2_OrderItemInterface & {
  __typename?: 'Magento2_GiftCardOrderItem';
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn?: Maybe<Scalars['Boolean']>;
  /** The entered option for the base product, such as a logo or image */
  enteredOptions?: Maybe<Array<Maybe<Magento2_OrderItemOption>>>;
  /** Selected gift card properties for an order item. */
  giftCard?: Maybe<Magento2_GiftCardItem>;
  /** The selected gift message for the order item */
  giftMessage?: Maybe<Magento2_GiftMessage>;
  /** The selected gift wrapping for the order item. */
  giftWrapping?: Maybe<Magento2_GiftWrapping>;
  /** The unique identifier of the order item */
  id: Scalars['ID'];
  /** The path to product thumbnail */
  productImage?: Maybe<Scalars['String']>;
  /** The name of the base product */
  productName?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  productSalePrice: Magento2_Money;
  /** The sale price of the base product including tax, including selected options */
  productSalePriceIncludingTax: Magento2_Money;
  /** The serial system of the product, such as SDBS, IZO_DB, etc. */
  productSerialSystem?: Maybe<Scalars['String']>;
  /** The SKU of the base product */
  productSku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  productType?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  productUrlKey?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantityCanceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantityInvoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantityOrdered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantityRefunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantityReturned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantityShipped?: Maybe<Scalars['Float']>;
  /** The return item status */
  returnStatus?: Maybe<Scalars['String']>;
  /** The row total of the order item. */
  rowTotal: Magento2_Money;
  /** The row total including tax of the order item. */
  rowTotalIncludingTax: Magento2_Money;
  /** The selected options for the base product, such as color or size */
  selectedOptions?: Maybe<Array<Maybe<Magento2_OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
};

/** Defines properties of a gift card. */
export type Magento2_GiftCardProduct = Magento2_CustomizableProductInterface &
  Magento2_PhysicalProductInterface &
  Magento2_ProductInterface &
  Magento2_RoutableInterface & {
    __typename?: 'Magento2_GiftCardProduct';
    /** Additional information about variant */
    additionalInfo?: Maybe<Scalars['String']>;
    /** Indicates whether the customer can provide a message to accompany the gift card. */
    allowMessage?: Maybe<Scalars['Boolean']>;
    /** Indicates whether shoppers have the ability to set the value of the gift card. */
    allowOpenAmount?: Maybe<Scalars['Boolean']>;
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    brand?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    bundleTotalPrice?: Maybe<Scalars['Float']>;
    /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
    canonicalUrl?: Maybe<Scalars['String']>;
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<Magento2_CategoryInterface>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    color?: Maybe<Scalars['Int']>;
    /** The product's country of origin. */
    countryOfManufacture?: Maybe<Scalars['String']>;
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt?: Maybe<Scalars['String']>;
    /** An array of cross-sell products. */
    crosssellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<Magento2_ComplexTextValue>;
    /** @deprecated Use the `custom_attributes` field instead. */
    detailPage?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    dimension?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    downloadPage?: Maybe<Scalars['String']>;
    /** An array of customizable gift card options. */
    giftCardOptions: Array<Maybe<Magento2_CustomizableOptionInterface>>;
    /** Indicates whether a gift message is available. */
    giftMessageAvailable?: Maybe<Scalars['String']>;
    /** An array that contains information about the values and ID of a gift card. */
    giftcardAmounts?: Maybe<Array<Maybe<Magento2_GiftCardAmounts>>>;
    /** An enumeration that specifies the type of gift card. */
    giftcardType?: Maybe<Magento2_GiftCardTypeEnum>;
    /** Hide qualify message */
    hideQualifyMessage?: Maybe<Scalars['Boolean']>;
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']>;
    /** The relative path to the main image on the product page. */
    image?: Maybe<Magento2_ProductImage>;
    /** Check default variant of configurable products. */
    isDefaultWithinAllVariants?: Maybe<Scalars['Boolean']>;
    /** Indicates whether the customer can redeem the value on the card for cash. */
    isRedeemable?: Maybe<Scalars['Boolean']>;
    /** Indicates whether the product can be returned. */
    isReturnable?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    itemType?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    label?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseClass?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseCode?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseType?: Maybe<Scalars['Int']>;
    /** The number of days after purchase until the gift card expires. A null value means there is no limit. */
    lifetime?: Maybe<Scalars['Int']>;
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer?: Maybe<Scalars['Int']>;
    /** An array of media gallery objects. */
    mediaGallery?: Maybe<Array<Maybe<Magento2_MediaGalleryInterface>>>;
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries?: Maybe<Array<Maybe<Magento2_MediaGalleryEntry>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    mediaPage?: Maybe<Scalars['String']>;
    /** The maximum number of characters the gift message can contain. */
    messageMaxLength?: Maybe<Scalars['Int']>;
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription?: Maybe<Scalars['String']>;
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword?: Maybe<Scalars['String']>;
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle?: Maybe<Scalars['String']>;
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    nativeAccess?: Maybe<Scalars['Int']>;
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate?: Maybe<Scalars['String']>;
    /** The end date for new product listings. */
    newToDate?: Maybe<Scalars['String']>;
    /** Product stock only x left count */
    onlyXLeftInStock?: Maybe<Scalars['Float']>;
    /** The maximum acceptable value of an open amount gift card. */
    openAmountMax?: Maybe<Scalars['Float']>;
    /** The minimum acceptable value of an open amount gift card. */
    openAmountMin?: Maybe<Scalars['Float']>;
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<Magento2_CustomizableOptionInterface>>>;
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer?: Maybe<Scalars['String']>;
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price?: Maybe<Magento2_ProductPrices>;
    /** The range of prices for the product */
    priceRange: Magento2_PriceRange;
    /** An array of `TierPrice` objects. */
    priceTiers?: Maybe<Array<Maybe<Magento2_TierPrice>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    pricingPage?: Maybe<Scalars['String']>;
    /** An array of `ProductLinks` objects. */
    productLinks?: Maybe<Array<Maybe<Magento2_ProductLinksInterface>>>;
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float'];
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
    redirectCode: Scalars['Int'];
    /** An array of related products. */
    relatedProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    releaseDate?: Maybe<Scalars['String']>;
    /** Required Products Information about the product. The value can include simple HTML tags. */
    requiredProductsInformation?: Maybe<Magento2_ComplexTextValue>;
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int'];
    /** The list of products reviews. */
    reviews: Magento2_ProductReviews;
    /** @deprecated Use the `custom_attributes` field instead. */
    sdbsId?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    serialSystem?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    serialSystemReference?: Maybe<Scalars['String']>;
    /** A short description of the product. Its use depends on the theme. */
    shortDescription?: Maybe<Magento2_ComplexTextValue>;
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>;
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage?: Maybe<Magento2_ProductImage>;
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate?: Maybe<Scalars['String']>;
    /** The discounted price of the product. */
    specialPrice?: Maybe<Scalars['Float']>;
    /** The end date for a product with a special price. */
    specialToDate?: Maybe<Scalars['String']>;
    /** Indicates whether the product is staged for a future campaign. */
    staged: Scalars['Boolean'];
    /** Stock availability label. */
    stockAvailabilityLabel?: Maybe<Scalars['String']>;
    /** Stock status of the product */
    stockStatus?: Maybe<Magento2_ProductStockStatus>;
    /** The file name of a swatch image. */
    swatchImage?: Maybe<Scalars['String']>;
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<Magento2_ProductImage>;
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice?: Maybe<Scalars['Float']>;
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices?: Maybe<Array<Maybe<Magento2_ProductTierPrices>>>;
    /** Time to stock custom attribute. */
    timeToStock?: Maybe<Scalars['String']>;
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId?: Maybe<Scalars['String']>;
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID'];
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt?: Maybe<Scalars['String']>;
    /** An array of up-sell products. */
    upsellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** The part of the URL that identifies the product */
    urlKey?: Maybe<Scalars['String']>;
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath?: Maybe<Scalars['String']>;
    /** URL rewrites list */
    urlRewrites?: Maybe<Array<Maybe<Magento2_UrlRewrite>>>;
    /** The part of the product URL that is appended after the url key */
    urlSuffix?: Maybe<Scalars['String']>;
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Magento2_Website>>>;
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']>;
  };

/** Defines properties of a gift card. */
export type Magento2_GiftCardProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type Magento2_GiftCardShipmentItem = Magento2_ShipmentItemInterface & {
  __typename?: 'Magento2_GiftCardShipmentItem';
  /** Selected gift card properties for a shipment item. */
  giftCard?: Maybe<Magento2_GiftCardItem>;
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float'];
};

/** Specifies the gift card type. */
export enum Magento2_GiftCardTypeEnum {
  /** @deprecated  */
  Combined = 'COMBINED',
  /** @deprecated  */
  Physical = 'PHYSICAL',
  /** @deprecated  */
  Virtual = 'VIRTUAL',
}

/** A single gift card added to a wish list. */
export type Magento2_GiftCardWishlistItem = Magento2_WishlistItemInterface & {
  __typename?: 'Magento2_GiftCardWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** Details about a gift card. */
  giftCardOptions: Magento2_GiftCardOptions;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<Magento2_ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

/** Contains the text of a gift message, its sender, and recipient */
export type Magento2_GiftMessage = {
  __typename?: 'Magento2_GiftMessage';
  /** Sender name */
  from: Scalars['String'];
  /** Gift message text */
  message: Scalars['String'];
  /** Recipient name */
  to: Scalars['String'];
};

/** Defines a gift message. */
export type Magento2_GiftMessageInput = {
  /** The name of the sender. */
  from: Scalars['String'];
  /** The text of the gift message. */
  message: Scalars['String'];
  /** The name of the recepient. */
  to: Scalars['String'];
};

/** Contains prices for gift wrapping options. */
export type Magento2_GiftOptionsPrices = {
  __typename?: 'Magento2_GiftOptionsPrices';
  /** Price of the gift wrapping for all individual order items. */
  giftWrappingForItems?: Maybe<Magento2_Money>;
  /** Price of the gift wrapping for the whole order. */
  giftWrappingForOrder?: Maybe<Magento2_Money>;
  /** Price for the printed card. */
  printedCard?: Maybe<Magento2_Money>;
};

/** Contains details about a gift registry. */
export type Magento2_GiftRegistry = {
  __typename?: 'Magento2_GiftRegistry';
  /** The date on which the gift registry was created. Only the registry owner can access this attribute. */
  createdAt: Scalars['String'];
  /** An array of attributes that define elements of the gift registry. Each attribute is specified as a code-value pair. */
  dynamicAttributes?: Maybe<
    Array<Maybe<Magento2_GiftRegistryDynamicAttribute>>
  >;
  /** The name of the event. */
  eventName: Scalars['String'];
  /** An array of products added to the gift registry. */
  items?: Maybe<Array<Maybe<Magento2_GiftRegistryItemInterface>>>;
  /** The message text the customer entered to describe the event. */
  message: Scalars['String'];
  /** The customer who created the gift registry. */
  ownerName: Scalars['String'];
  /** An enum that states whether the gift registry is PRIVATE or PUBLIC. Only the registry owner can access this attribute. */
  privacySettings: Magento2_GiftRegistryPrivacySettings;
  /** Contains details about each registrant for the event. */
  registrants?: Maybe<Array<Maybe<Magento2_GiftRegistryRegistrant>>>;
  /** Contains the customer's shipping address. Only the registry owner can access this attribute. */
  shippingAddress?: Maybe<Magento2_CustomerAddress>;
  /** An enum that states whether the gift registry is ACTIVE or INACTIVE. Only the registry owner can access this attribute. */
  status: Magento2_GiftRegistryStatus;
  /** The type of gift registry. */
  type?: Maybe<Magento2_GiftRegistryType>;
  /** The unique ID assigned to the gift registry. */
  uid: Scalars['ID'];
};

export type Magento2_GiftRegistryDynamicAttribute =
  Magento2_GiftRegistryDynamicAttributeInterface & {
    __typename?: 'Magento2_GiftRegistryDynamicAttribute';
    /** The internal ID of the dynamic attribute. */
    code: Scalars['ID'];
    /** Indicates which group the dynamic attribute is a member of. */
    group: Magento2_GiftRegistryDynamicAttributeGroup;
    /** The display name of the dynamic attribute. */
    label: Scalars['String'];
    /** A corresponding value for the code. */
    value: Scalars['String'];
  };

/** Defines the group type of a gift registry dynamic attribute. */
export enum Magento2_GiftRegistryDynamicAttributeGroup {
  /** @deprecated  */
  DetailedInformation = 'DETAILED_INFORMATION',
  /** @deprecated  */
  EventInformation = 'EVENT_INFORMATION',
  /** @deprecated  */
  GeneralInformation = 'GENERAL_INFORMATION',
  /** @deprecated  */
  PrivacySettings = 'PRIVACY_SETTINGS',
  /** @deprecated  */
  Registrant = 'REGISTRANT',
  /** @deprecated  */
  ShippingAddress = 'SHIPPING_ADDRESS',
}

/** Defines a dynamic attribute. */
export type Magento2_GiftRegistryDynamicAttributeInput = {
  /** A unique key for an additional attribute of the event. */
  code: Scalars['ID'];
  /** A string that describes a dynamic attribute. */
  value: Scalars['String'];
};

export type Magento2_GiftRegistryDynamicAttributeInterface = {
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID'];
  /** The display name of the dynamic attribute. */
  label: Scalars['String'];
  /** A corresponding value for the code. */
  value: Scalars['String'];
};

export type Magento2_GiftRegistryDynamicAttributeMetadata =
  Magento2_GiftRegistryDynamicAttributeMetadataInterface & {
    __typename?: 'Magento2_GiftRegistryDynamicAttributeMetadata';
    /** Indicates which group the dynamic attribute a member of. */
    attributeGroup: Scalars['String'];
    /** The internal ID of the dynamic attribute. */
    code: Scalars['ID'];
    /** The selected input type for this dynamic attribute. The value can be one of several static or custom types. */
    inputType: Scalars['String'];
    /** Indicates whether the dynamic attribute is required. */
    isRequired: Scalars['Boolean'];
    /** The display name of the dynamic attribute. */
    label: Scalars['String'];
    /** The order in which to display the dynamic attribute. */
    sortOrder?: Maybe<Scalars['Int']>;
  };

export type Magento2_GiftRegistryDynamicAttributeMetadataInterface = {
  /** Indicates which group the dynamic attribute a member of. */
  attributeGroup: Scalars['String'];
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID'];
  /** The selected input type for this dynamic attribute. The value can be one of several static or custom types. */
  inputType: Scalars['String'];
  /** Indicates whether the dynamic attribute is required. */
  isRequired: Scalars['Boolean'];
  /** The display name of the dynamic attribute. */
  label: Scalars['String'];
  /** The order in which to display the dynamic attribute. */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type Magento2_GiftRegistryItem = Magento2_GiftRegistryItemInterface & {
  __typename?: 'Magento2_GiftRegistryItem';
  /** The date the product was added to the gift registry. */
  createdAt: Scalars['String'];
  /** A brief message about the gift registry item. */
  note?: Maybe<Scalars['String']>;
  /** Details about the gift registry item. */
  product?: Maybe<Magento2_ProductInterface>;
  /** The requested quantity of the product. */
  quantity: Scalars['Float'];
  /** The fulfilled quantity of the product. */
  quantityFulfilled: Scalars['Float'];
  /** The unique ID of a gift registry item. */
  uid: Scalars['ID'];
};

export type Magento2_GiftRegistryItemInterface = {
  /** The date the product was added to the gift registry. */
  createdAt: Scalars['String'];
  /** A brief message about the gift registry item. */
  note?: Maybe<Scalars['String']>;
  /** Details about the gift registry item. */
  product?: Maybe<Magento2_ProductInterface>;
  /** The requested quantity of the product. */
  quantity: Scalars['Float'];
  /** The fulfilled quantity of the product. */
  quantityFulfilled: Scalars['Float'];
  /** The unique ID of a gift registry item. */
  uid: Scalars['ID'];
};

/** Contains the status and any errors that encountered with the customer's gift register item. */
export type Magento2_GiftRegistryItemUserErrorInterface = {
  /** Indicates whether the attempt to move the cart items to the gift registry was successful. */
  status: Scalars['Boolean'];
  /** An array of errors encountered while moving items from the cart to the gift registry. */
  userErrors: Array<Maybe<Magento2_GiftRegistryItemsUserError>>;
};

/** Contains error information. */
export type Magento2_GiftRegistryItemUserErrors =
  Magento2_GiftRegistryItemUserErrorInterface & {
    __typename?: 'Magento2_GiftRegistryItemUserErrors';
    /** Indicates whether the attempt to move the cart items to the gift registry was successful. */
    status: Scalars['Boolean'];
    /** An array of errors encountered while moving items from the cart to the gift registry. */
    userErrors: Array<Maybe<Magento2_GiftRegistryItemsUserError>>;
  };

/** Contains details about an error that occurred when processing a gift registry item. */
export type Magento2_GiftRegistryItemsUserError = {
  __typename?: 'Magento2_GiftRegistryItemsUserError';
  /** An error code that describes the error encountered. */
  code: Magento2_GiftRegistryItemsUserErrorType;
  /** The unique ID of the gift registry item containing an error. */
  giftRegistryItemUid?: Maybe<Scalars['ID']>;
  /** The unique ID of the `GiftRegistry` object containing an error. */
  giftRegistryUid?: Maybe<Scalars['ID']>;
  /** A localized error message. */
  message: Scalars['String'];
  /** The unique ID of the product containing an error. */
  productUid?: Maybe<Scalars['ID']>;
};

/** Defines the error type. */
export enum Magento2_GiftRegistryItemsUserErrorType {
  /**
   * Used for exceptions like EntityNotFound.
   * @deprecated
   */
  NotFound = 'NOT_FOUND',
  /**
   * Used for handling out of stock products.
   * @deprecated
   */
  OutOfStock = 'OUT_OF_STOCK',
  /**
   * Used for other exceptions, such as database connection failures.
   * @deprecated
   */
  Undefined = 'UNDEFINED',
}

/** Contains details about the gift registry. */
export type Magento2_GiftRegistryOutput =
  Magento2_GiftRegistryOutputInterface & {
    __typename?: 'Magento2_GiftRegistryOutput';
    /** The gift registry. */
    giftRegistry?: Maybe<Magento2_GiftRegistry>;
  };

/** Contains the customer's gift registry. */
export type Magento2_GiftRegistryOutputInterface = {
  /** The gift registry. */
  giftRegistry?: Maybe<Magento2_GiftRegistry>;
};

/** Defines the privacy setting of the gift registry. */
export enum Magento2_GiftRegistryPrivacySettings {
  /** @deprecated  */
  Private = 'PRIVATE',
  /** @deprecated  */
  Public = 'PUBLIC',
}

/** Contains details about a registrant. */
export type Magento2_GiftRegistryRegistrant = {
  __typename?: 'Magento2_GiftRegistryRegistrant';
  /** An array of dynamic attributes assigned to the registrant. */
  dynamicAttributes?: Maybe<
    Array<Maybe<Magento2_GiftRegistryRegistrantDynamicAttribute>>
  >;
  /** The email address of the registrant. Only the registry owner can access this attribute. */
  email: Scalars['String'];
  /** The first name of the registrant. */
  firstname: Scalars['String'];
  /** The last name of the registrant. */
  lastname: Scalars['String'];
  /** The unique ID assigned to the registrant. */
  uid: Scalars['ID'];
};

export type Magento2_GiftRegistryRegistrantDynamicAttribute =
  Magento2_GiftRegistryDynamicAttributeInterface & {
    __typename?: 'Magento2_GiftRegistryRegistrantDynamicAttribute';
    /** The internal ID of the dynamic attribute. */
    code: Scalars['ID'];
    /** The display name of the dynamic attribute. */
    label: Scalars['String'];
    /** A corresponding value for the code. */
    value: Scalars['String'];
  };

/** Contains the results of a gift registry search. */
export type Magento2_GiftRegistrySearchResult = {
  __typename?: 'Magento2_GiftRegistrySearchResult';
  /** The date of the event. */
  eventDate?: Maybe<Scalars['String']>;
  /** The title given to the event. */
  eventTitle: Scalars['String'];
  /** The URL key of the gift registry. */
  giftRegistryUid: Scalars['ID'];
  /** The location of the event. */
  location?: Maybe<Scalars['String']>;
  /** The name of the gift registry owner. */
  name: Scalars['String'];
  /** The type of event being held. */
  type?: Maybe<Scalars['String']>;
};

/** Defines a shipping address for a gift registry. Specify either `address_data` or the `address_id`. If both are provided, validation will fail. */
export type Magento2_GiftRegistryShippingAddressInput = {
  /** Defines the shipping address for this gift registry. */
  address_data?: InputMaybe<Magento2_CustomerAddressInput>;
  /** The ID assigned to this customer address. */
  address_id?: InputMaybe<Scalars['ID']>;
};

/** Defines the status of the gift registry. */
export enum Magento2_GiftRegistryStatus {
  /** @deprecated  */
  Active = 'ACTIVE',
  /** @deprecated  */
  Inactive = 'INACTIVE',
}

/** Contains details about a gift registry type. */
export type Magento2_GiftRegistryType = {
  __typename?: 'Magento2_GiftRegistryType';
  /** An array of attributes that define elements of the gift registry. Each attribute is specified as a code-value pair. */
  dynamicAttributesMetadata?: Maybe<
    Array<Maybe<Magento2_GiftRegistryDynamicAttributeMetadataInterface>>
  >;
  /** The label assigned to the gift registry type on the Admin. */
  label: Scalars['String'];
  /** The unique ID assigned to the gift registry type. */
  uid: Scalars['ID'];
};

/** Contains details about the selected or available gift wrapping options. */
export type Magento2_GiftWrapping = {
  __typename?: 'Magento2_GiftWrapping';
  /** The name of the gift wrapping design. */
  design: Scalars['String'];
  /**
   * The unique ID for a `GiftWrapping` object.
   * @deprecated Use `uid` instead
   */
  id: Scalars['ID'];
  /** The preview image for a gift wrapping option. */
  image?: Maybe<Magento2_GiftWrappingImage>;
  /** The gift wrapping price. */
  price: Magento2_Money;
  /** The unique ID for a `GiftWrapping` object. */
  uid: Scalars['ID'];
};

/** Points to an image associated with a gift wrapping option. */
export type Magento2_GiftWrappingImage = {
  __typename?: 'Magento2_GiftWrappingImage';
  /** The gift wrapping preview image label. */
  label: Scalars['String'];
  /** The gift wrapping preview image URL. */
  url: Scalars['String'];
};

/** Contains details about each of the customer's orders */
export type Magento2_GlobalCustomerOrder = {
  __typename?: 'Magento2_GlobalCustomerOrder';
  /** The billing address for the order */
  billingAddress?: Maybe<Magento2_OrderAddress>;
  /** The shipping carrier for the order delivery */
  carrier?: Maybe<Scalars['String']>;
  /** Comments about the order */
  comments?: Maybe<Array<Maybe<Magento2_SalesCommentItem>>>;
  /** @deprecated Use the order_date attribute instead */
  createdAt?: Maybe<Scalars['String']>;
  /** A list of credit memos */
  creditMemos?: Maybe<Array<Maybe<Magento2_CreditMemo>>>;
  /** @deprecated Use the totals.grand_total attribute instead */
  grandTotal?: Maybe<Scalars['Float']>;
  /** Unique identifier for the order */
  id: Scalars['ID'];
  /** @deprecated Use the id attribute instead */
  incrementId?: Maybe<Scalars['String']>;
  /** A list of invoices for the order */
  invoices: Array<Maybe<Magento2_Invoice>>;
  /** Is Order Virtual */
  isVirtual?: Maybe<Scalars['Boolean']>;
  /** An array containing the items purchased in this order */
  items?: Maybe<Array<Maybe<Magento2_OrderItemInterface>>>;
  /** The order number */
  number: Scalars['String'];
  /** The date the order was placed */
  orderDate: Scalars['String'];
  /** @deprecated Use the number attribute instead */
  orderNumber: Scalars['String'];
  /** The order origin */
  origin: Scalars['String'];
  /** Payment details for the order */
  paymentMethods?: Maybe<Array<Maybe<Magento2_OrderPaymentMethod>>>;
  /** A list of shipments for the order */
  shipments?: Maybe<Array<Maybe<Magento2_OrderShipment>>>;
  /** The shipping address for the order */
  shippingAddress?: Maybe<Magento2_OrderAddress>;
  /** The delivery method for the order */
  shippingMethod?: Maybe<Scalars['String']>;
  /** The current status label of the order */
  status: Scalars['String'];
  /** The current status code of the order */
  statusCode: Scalars['String'];
  /** Store Code */
  storeCode?: Maybe<Scalars['String']>;
  /** Store ID */
  storeId: Scalars['ID'];
  /** Contains details about the calculated totals for this order */
  total?: Maybe<Magento2_OrderTotal>;
};

/** The collection of orders that match the conditions defined in the filter */
export type Magento2_GlobalCustomerOrders = {
  __typename?: 'Magento2_GlobalCustomerOrders';
  /** An array of customer orders */
  items: Array<Maybe<Magento2_GlobalCustomerOrder>>;
  /** An object that includes the current_page, page_info, and page_size values specified in the query */
  pageInfo?: Maybe<Magento2_SearchResultPageInfo>;
  /** The total count of customer orders */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Identifies the filter to use for filtering orders. */
export type Magento2_GlobalCustomerOrdersFilterInput = {
  /** Filters by order status code. */
  global_order_status_code?: InputMaybe<Magento2_FilterStringTypeInput>;
  /** Filters by order type. */
  is_virtual?: InputMaybe<Scalars['Boolean']>;
  /** Filters by order number. */
  number?: InputMaybe<Magento2_FilterStringTypeInput>;
  /** Filters by order origin. */
  origin?: InputMaybe<Magento2_FilterStringTypeInput>;
  /** Filters by order website. */
  website?: InputMaybe<Scalars['String']>;
};

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type Magento2_GroupedProduct = Magento2_PhysicalProductInterface &
  Magento2_ProductInterface &
  Magento2_RoutableInterface & {
    __typename?: 'Magento2_GroupedProduct';
    /** Additional information about variant */
    additionalInfo?: Maybe<Scalars['String']>;
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    brand?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    bundleTotalPrice?: Maybe<Scalars['Float']>;
    /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
    canonicalUrl?: Maybe<Scalars['String']>;
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<Magento2_CategoryInterface>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    color?: Maybe<Scalars['Int']>;
    /** The product's country of origin. */
    countryOfManufacture?: Maybe<Scalars['String']>;
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt?: Maybe<Scalars['String']>;
    /** An array of cross-sell products. */
    crosssellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<Magento2_ComplexTextValue>;
    /** @deprecated Use the `custom_attributes` field instead. */
    detailPage?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    dimension?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    downloadPage?: Maybe<Scalars['String']>;
    /** Indicates whether a gift message is available. */
    giftMessageAvailable?: Maybe<Scalars['String']>;
    /** Hide qualify message */
    hideQualifyMessage?: Maybe<Scalars['Boolean']>;
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']>;
    /** The relative path to the main image on the product page. */
    image?: Maybe<Magento2_ProductImage>;
    /** Check default variant of configurable products. */
    isDefaultWithinAllVariants?: Maybe<Scalars['Boolean']>;
    /** Indicates whether the product can be returned. */
    isReturnable?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    itemType?: Maybe<Scalars['Int']>;
    /** An array containing grouped product items. */
    items?: Maybe<Array<Maybe<Magento2_GroupedProductItem>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    label?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseClass?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseCode?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseType?: Maybe<Scalars['Int']>;
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer?: Maybe<Scalars['Int']>;
    /** An array of media gallery objects. */
    mediaGallery?: Maybe<Array<Maybe<Magento2_MediaGalleryInterface>>>;
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries?: Maybe<Array<Maybe<Magento2_MediaGalleryEntry>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    mediaPage?: Maybe<Scalars['String']>;
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription?: Maybe<Scalars['String']>;
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword?: Maybe<Scalars['String']>;
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle?: Maybe<Scalars['String']>;
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    nativeAccess?: Maybe<Scalars['Int']>;
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate?: Maybe<Scalars['String']>;
    /** The end date for new product listings. */
    newToDate?: Maybe<Scalars['String']>;
    /** Product stock only x left count */
    onlyXLeftInStock?: Maybe<Scalars['Float']>;
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer?: Maybe<Scalars['String']>;
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price?: Maybe<Magento2_ProductPrices>;
    /** The range of prices for the product */
    priceRange: Magento2_PriceRange;
    /** An array of `TierPrice` objects. */
    priceTiers?: Maybe<Array<Maybe<Magento2_TierPrice>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    pricingPage?: Maybe<Scalars['String']>;
    /** An array of `ProductLinks` objects. */
    productLinks?: Maybe<Array<Maybe<Magento2_ProductLinksInterface>>>;
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float'];
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
    redirectCode: Scalars['Int'];
    /** An array of related products. */
    relatedProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    releaseDate?: Maybe<Scalars['String']>;
    /** Required Products Information about the product. The value can include simple HTML tags. */
    requiredProductsInformation?: Maybe<Magento2_ComplexTextValue>;
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int'];
    /** The list of products reviews. */
    reviews: Magento2_ProductReviews;
    /** @deprecated Use the `custom_attributes` field instead. */
    sdbsId?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    serialSystem?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    serialSystemReference?: Maybe<Scalars['String']>;
    /** A short description of the product. Its use depends on the theme. */
    shortDescription?: Maybe<Magento2_ComplexTextValue>;
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>;
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage?: Maybe<Magento2_ProductImage>;
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate?: Maybe<Scalars['String']>;
    /** The discounted price of the product. */
    specialPrice?: Maybe<Scalars['Float']>;
    /** The end date for a product with a special price. */
    specialToDate?: Maybe<Scalars['String']>;
    /** Indicates whether the product is staged for a future campaign. */
    staged: Scalars['Boolean'];
    /** Stock availability label. */
    stockAvailabilityLabel?: Maybe<Scalars['String']>;
    /** Stock status of the product */
    stockStatus?: Maybe<Magento2_ProductStockStatus>;
    /** The file name of a swatch image. */
    swatchImage?: Maybe<Scalars['String']>;
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<Magento2_ProductImage>;
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice?: Maybe<Scalars['Float']>;
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices?: Maybe<Array<Maybe<Magento2_ProductTierPrices>>>;
    /** Time to stock custom attribute. */
    timeToStock?: Maybe<Scalars['String']>;
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId?: Maybe<Scalars['String']>;
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID'];
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt?: Maybe<Scalars['String']>;
    /** An array of up-sell products. */
    upsellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** The part of the URL that identifies the product */
    urlKey?: Maybe<Scalars['String']>;
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath?: Maybe<Scalars['String']>;
    /** URL rewrites list */
    urlRewrites?: Maybe<Array<Maybe<Magento2_UrlRewrite>>>;
    /** The part of the product URL that is appended after the url key */
    urlSuffix?: Maybe<Scalars['String']>;
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Magento2_Website>>>;
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']>;
  };

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type Magento2_GroupedProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Contains information about an individual grouped product item. */
export type Magento2_GroupedProductItem = {
  __typename?: 'Magento2_GroupedProductItem';
  /** The relative position of this item compared to the other group items. */
  position?: Maybe<Scalars['Int']>;
  /** Details about this product option. */
  product?: Maybe<Magento2_ProductInterface>;
  /** The quantity of this grouped product item. */
  qty?: Maybe<Scalars['Float']>;
};

/** A grouped product wish list item. */
export type Magento2_GroupedProductWishlistItem =
  Magento2_WishlistItemInterface & {
    __typename?: 'Magento2_GroupedProductWishlistItem';
    /** The date and time the item was added to the wish list. */
    addedAt: Scalars['String'];
    /** Custom options selected for the wish list item. */
    customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
    /** The description of the item. */
    description?: Maybe<Scalars['String']>;
    /** The unique ID for a `WishlistItemInterface` object. */
    id: Scalars['ID'];
    /** Product details of the wish list item. */
    product?: Maybe<Magento2_ProductInterface>;
    /** The quantity of this wish list item. */
    quantity: Scalars['Float'];
  };

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payments Pro Hosted Solution payment method. */
export type Magento2_HostedProInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. For example, if the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. For example, if the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
};

/** Contains the secure URL used for the Payments Pro Hosted Solution payment method. */
export type Magento2_HostedProUrl = {
  __typename?: 'Magento2_HostedProUrl';
  /** The secure URL generated by PayPal. */
  secureFormUrl?: Maybe<Scalars['String']>;
};

/** Contains the required input to request the secure URL for Payments Pro Hosted Solution payment. */
export type Magento2_HostedProUrlInput = {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
};

/** Contains target path parameters. */
export type Magento2_HttpQueryParameter = {
  __typename?: 'Magento2_HttpQueryParameter';
  /** A parameter name. */
  name?: Maybe<Scalars['String']>;
  /** A parameter value. */
  value?: Maybe<Scalars['String']>;
};

export type Magento2_ImageSwatchData = Magento2_SwatchDataInterface & {
  __typename?: 'Magento2_ImageSwatchData';
  /** The URL assigned to the thumbnail of the swatch image. */
  thumbnail?: Maybe<Scalars['String']>;
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

/** Contains an error message when an internal error occurred. */
export type Magento2_InternalError = Magento2_ErrorInterface & {
  __typename?: 'Magento2_InternalError';
  /** The returned error message. */
  message: Scalars['String'];
};

/** Contains invoice details. */
export type Magento2_Invoice = {
  __typename?: 'Magento2_Invoice';
  /** Comments on the invoice. */
  comments?: Maybe<Array<Maybe<Magento2_SalesCommentItem>>>;
  /** The unique ID for a `Invoice` object. */
  id: Scalars['ID'];
  /** Invoiced product details. */
  items?: Maybe<Array<Maybe<Magento2_InvoiceItemInterface>>>;
  /** Sequential invoice number. */
  number: Scalars['String'];
  /** Invoice total amount details. */
  total?: Maybe<Magento2_InvoiceTotal>;
};

export type Magento2_InvoiceItem = Magento2_InvoiceItemInterface & {
  __typename?: 'Magento2_InvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of invoiced items. */
  quantityInvoiced?: Maybe<Scalars['Float']>;
};

/** Contains detailes about invoiced items. */
export type Magento2_InvoiceItemInterface = {
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of invoiced items. */
  quantityInvoiced?: Maybe<Scalars['Float']>;
};

/** Contains price details from an invoice. */
export type Magento2_InvoiceTotal = {
  __typename?: 'Magento2_InvoiceTotal';
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Magento2_Money;
  /** The applied discounts to the invoice. */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Magento2_Money;
  /** Details about the shipping and handling costs for the invoice. */
  shippingHandling?: Maybe<Magento2_ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Magento2_Money;
  /** The invoice tax details. */
  taxes?: Maybe<Array<Maybe<Magento2_TaxItem>>>;
  /** The shipping amount for the invoice. */
  totalShipping: Magento2_Money;
  /** The amount of tax applied to the invoice. */
  totalTax: Magento2_Money;
};

/** Contains the result of the `isEmailAvailable` query. */
export type Magento2_IsEmailAvailableOutput = {
  __typename?: 'Magento2_IsEmailAvailableOutput';
  /** Indicates whether the specified email address can be used to create a customer. */
  isEmailAvailable?: Maybe<Scalars['Boolean']>;
};

/** A list of options of the selected bundle product. */
export type Magento2_ItemSelectedBundleOption = {
  __typename?: 'Magento2_ItemSelectedBundleOption';
  /**
   * The unique ID for a `ItemSelectedBundleOption` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID'];
  /** The label of the option. */
  label: Scalars['String'];
  /** The unique ID for a `ItemSelectedBundleOption` object. */
  uid: Scalars['ID'];
  /** A list of products that represent the values of the parent option. */
  values?: Maybe<Array<Maybe<Magento2_ItemSelectedBundleOptionValue>>>;
};

/** A list of values for the selected bundle product. */
export type Magento2_ItemSelectedBundleOptionValue = {
  __typename?: 'Magento2_ItemSelectedBundleOptionValue';
  /**
   * The unique ID for a `ItemSelectedBundleOptionValue` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID'];
  /** The price of the child bundle product. */
  price: Magento2_Money;
  /** The name of the child bundle product. */
  productName: Scalars['String'];
  /** The SKU of the child bundle product. */
  productSku: Scalars['String'];
  /** The number of this bundle product that were ordered. */
  quantity: Scalars['Float'];
  /** The unique ID for a `ItemSelectedBundleOptionValue` object. */
  uid: Scalars['ID'];
};

/** Contains a key-value pair. */
export type Magento2_KeyValue = {
  __typename?: 'Magento2_KeyValue';
  /** The name part of the key/value pair. */
  name?: Maybe<Scalars['String']>;
  /** The value part of the key/value pair. */
  value?: Maybe<Scalars['String']>;
};

/** Contains information for rendering layered navigation. */
export type Magento2_LayerFilter = {
  __typename?: 'Magento2_LayerFilter';
  /**
   * An array of filter items.
   * @deprecated Use `Aggregation.options` instead.
   */
  filterItems?: Maybe<Array<Maybe<Magento2_LayerFilterItemInterface>>>;
  /**
   * The count of filter items in filter group.
   * @deprecated Use `Aggregation.count` instead.
   */
  filterItemsCount?: Maybe<Scalars['Int']>;
  /**
   * The name of a layered navigation filter.
   * @deprecated Use `Aggregation.label` instead.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The request variable name for a filter query.
   * @deprecated Use `Aggregation.attribute_code` instead.
   */
  requestVar?: Maybe<Scalars['String']>;
};

export type Magento2_LayerFilterItem = Magento2_LayerFilterItemInterface & {
  __typename?: 'Magento2_LayerFilterItem';
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount?: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label?: Maybe<Scalars['String']>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString?: Maybe<Scalars['String']>;
};

export type Magento2_LayerFilterItemInterface = {
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount?: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label?: Maybe<Scalars['String']>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString?: Maybe<Scalars['String']>;
};

/** Defines characteristics about images and videos associated with a specific product. */
export type Magento2_MediaGalleryEntry = {
  __typename?: 'Magento2_MediaGalleryEntry';
  /** Details about the content of the media gallery item. */
  content?: Maybe<Magento2_ProductMediaGalleryEntriesContent>;
  /** Indicates whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The path of the image on the server. */
  file?: Maybe<Scalars['String']>;
  /**
   * The identifier assigned to the object.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The alt text displayed on the storefront when the user points to the image. */
  label?: Maybe<Scalars['String']>;
  /** Either `image` or `video`. */
  mediaType?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The unique ID for a `MediaGalleryEntry` object. */
  uid: Scalars['ID'];
  /** Details about the content of a video item. */
  videoContent?: Maybe<Magento2_ProductMediaGalleryEntriesVideoContent>;
};

/** Contains basic information about a product image or video. */
export type Magento2_MediaGalleryInterface = {
  /** Indicates whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
};

export type Magento2_ModuleConfiguration = {
  __typename?: 'Magento2_ModuleConfiguration';
  /** The Public Key of the Stripe payment. */
  apiKey?: Maybe<Scalars['String']>;
  /** Module Version and Partner ID etc */
  appInfo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Locale */
  locale?: Maybe<Scalars['String']>;
  /** Betas and API version */
  options?: Maybe<Magento2_ModuleOptions>;
};

export type Magento2_ModuleOptions = {
  __typename?: 'Magento2_ModuleOptions';
  /** API Version */
  apiVersion?: Maybe<Scalars['String']>;
  /** Betas. */
  betas?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Defines a monetary value, including a numeric value and a currency code. */
export type Magento2_Money = {
  __typename?: 'Magento2_Money';
  /** A three-letter currency code, such as USD or EUR. */
  currency?: Maybe<Magento2_CurrencyEnum>;
  /** A number expressing a monetary value. */
  value?: Maybe<Scalars['Float']>;
};

/** Contains the customer's gift registry and any errors encountered. */
export type Magento2_MoveCartItemsToGiftRegistryOutput =
  Magento2_GiftRegistryItemUserErrorInterface &
    Magento2_GiftRegistryOutputInterface & {
      __typename?: 'Magento2_MoveCartItemsToGiftRegistryOutput';
      /** The gift registry. */
      giftRegistry?: Maybe<Magento2_GiftRegistry>;
      /** Indicates whether the attempt to move the cart items to the gift registry was successful. */
      status: Scalars['Boolean'];
      /** An array of errors encountered while moving items from the cart to the gift registry. */
      userErrors: Array<Maybe<Magento2_GiftRegistryItemsUserError>>;
    };

/** Contains the source and target wish lists after moving products. */
export type Magento2_MoveProductsBetweenWishlistsOutput = {
  __typename?: 'Magento2_MoveProductsBetweenWishlistsOutput';
  /** The destination wish list after receiving products moved from the source wish list. */
  destinationWishlist: Magento2_Wishlist;
  /** The source wish list after moving products from it. */
  sourceWishlist: Magento2_Wishlist;
  /** An array of errors encountered while moving products to a wish list. */
  userErrors: Array<Maybe<Magento2_WishListUserInputError>>;
};

/** Contains an error message when an invalid UID was specified. */
export type Magento2_NoSuchEntityUidError = Magento2_ErrorInterface & {
  __typename?: 'Magento2_NoSuchEntityUidError';
  /** The returned error message. */
  message: Scalars['String'];
  /** The specified invalid unique ID of an object. */
  uid: Scalars['ID'];
};

/** Contains the order ID. */
export type Magento2_Order = {
  __typename?: 'Magento2_Order';
  /** The client secret of the PaymentIntent or SetupIntent that is associated with this order */
  clientSecret?: Maybe<Scalars['String']>;
  /** @deprecated Use `order_number` instead. */
  orderId?: Maybe<Scalars['String']>;
  /** The unique ID for an `Order` object. */
  orderNumber: Scalars['String'];
};

/** OrderAddress contains detailed information about an order's billing and shipping addresses */
export type Magento2_OrderAddress = {
  __typename?: 'Magento2_OrderAddress';
  /** The city or town. */
  city: Scalars['String'];
  /** The customer's company. */
  company?: Maybe<Scalars['String']>;
  /** The customer's country. */
  countryCode?: Maybe<Magento2_CountryCodeEnum>;
  /** The fax number. */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Scalars['String'];
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Scalars['String'];
  /** The middle name of the person associated with the shipping/billing address. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** The state or province name. */
  region?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object of a pre-defined region. */
  regionId?: Maybe<Scalars['ID']>;
  /** An array of strings that define the street number and name. */
  street: Array<Maybe<Scalars['String']>>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The telephone number */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vatId?: Maybe<Scalars['String']>;
};

export type Magento2_OrderItem = Magento2_OrderItemInterface & {
  __typename?: 'Magento2_OrderItem';
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn?: Maybe<Scalars['Boolean']>;
  /** The entered option for the base product, such as a logo or image */
  enteredOptions?: Maybe<Array<Maybe<Magento2_OrderItemOption>>>;
  /** The selected gift message for the order item */
  giftMessage?: Maybe<Magento2_GiftMessage>;
  /** The selected gift wrapping for the order item. */
  giftWrapping?: Maybe<Magento2_GiftWrapping>;
  /** The unique identifier of the order item */
  id: Scalars['ID'];
  /** The path to product thumbnail */
  productImage?: Maybe<Scalars['String']>;
  /** The name of the base product */
  productName?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  productSalePrice: Magento2_Money;
  /** The sale price of the base product including tax, including selected options */
  productSalePriceIncludingTax: Magento2_Money;
  /** The serial system of the product, such as SDBS, IZO_DB, etc. */
  productSerialSystem?: Maybe<Scalars['String']>;
  /** The SKU of the base product */
  productSku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  productType?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  productUrlKey?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantityCanceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantityInvoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantityOrdered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantityRefunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantityReturned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantityShipped?: Maybe<Scalars['Float']>;
  /** The return item status */
  returnStatus?: Maybe<Scalars['String']>;
  /** The row total of the order item. */
  rowTotal: Magento2_Money;
  /** The row total including tax of the order item. */
  rowTotalIncludingTax: Magento2_Money;
  /** The selected options for the base product, such as color or size */
  selectedOptions?: Maybe<Array<Maybe<Magento2_OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
};

/** Order item details */
export type Magento2_OrderItemInterface = {
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn?: Maybe<Scalars['Boolean']>;
  /** The entered option for the base product, such as a logo or image */
  enteredOptions?: Maybe<Array<Maybe<Magento2_OrderItemOption>>>;
  /** The selected gift message for the order item */
  giftMessage?: Maybe<Magento2_GiftMessage>;
  /** The selected gift wrapping for the order item. */
  giftWrapping?: Maybe<Magento2_GiftWrapping>;
  /** The unique identifier of the order item */
  id: Scalars['ID'];
  /** The path to product thumbnail */
  productImage?: Maybe<Scalars['String']>;
  /** The name of the base product */
  productName?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  productSalePrice: Magento2_Money;
  /** The sale price of the base product including tax, including selected options */
  productSalePriceIncludingTax: Magento2_Money;
  /** The serial system of the product, such as SDBS, IZO_DB, etc. */
  productSerialSystem?: Maybe<Scalars['String']>;
  /** The SKU of the base product */
  productSku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  productType?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  productUrlKey?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantityCanceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantityInvoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantityOrdered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantityRefunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantityReturned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantityShipped?: Maybe<Scalars['Float']>;
  /** The return item status */
  returnStatus?: Maybe<Scalars['String']>;
  /** The row total of the order item. */
  rowTotal: Magento2_Money;
  /** The row total including tax of the order item. */
  rowTotalIncludingTax: Magento2_Money;
  /** The selected options for the base product, such as color or size */
  selectedOptions?: Maybe<Array<Maybe<Magento2_OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
};

/** Represents order item options like selected or entered. */
export type Magento2_OrderItemOption = {
  __typename?: 'Magento2_OrderItemOption';
  /** The name of the option. */
  label: Scalars['String'];
  /** The value of the option. */
  value: Scalars['String'];
};

/** Contains details about the payment method used to pay for the order. */
export type Magento2_OrderPaymentMethod = {
  __typename?: 'Magento2_OrderPaymentMethod';
  /** Additional data per payment method type. */
  additionalData?: Maybe<Array<Maybe<Magento2_KeyValue>>>;
  /** The label that describes the payment method. */
  name: Scalars['String'];
  /** The payment method code that indicates how the order was paid for. */
  type: Scalars['String'];
};

/** Contains order shipment details. */
export type Magento2_OrderShipment = {
  __typename?: 'Magento2_OrderShipment';
  /** Comments added to the shipment. */
  comments?: Maybe<Array<Maybe<Magento2_SalesCommentItem>>>;
  /** The unique ID for a `OrderShipment` object. */
  id: Scalars['ID'];
  /** An array of items included in the shipment. */
  items?: Maybe<Array<Maybe<Magento2_ShipmentItemInterface>>>;
  /** The sequential credit shipment number. */
  number: Scalars['String'];
  /** An array of shipment tracking details. */
  tracking?: Maybe<Array<Maybe<Magento2_ShipmentTracking>>>;
};

/** Contains details about the sales total amounts used to calculate the final price */
export type Magento2_OrderTotal = {
  __typename?: 'Magento2_OrderTotal';
  /** The final base grand total amount in the base currency */
  baseGrandTotal: Magento2_Money;
  /** The applied discounts to the order */
  discounts?: Maybe<Array<Maybe<Magento2_Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes */
  grandTotal: Magento2_Money;
  /** Contains details about the shipping and handling costs for the order */
  shippingHandling?: Maybe<Magento2_ShippingHandling>;
  /** The subtotal of the order, excluding shipping, discounts, and taxes */
  subtotal: Magento2_Money;
  /** The subtotal of the order, including taxes */
  subtotalIncludingTax: Magento2_Money;
  /** The order tax details */
  taxes?: Maybe<Array<Maybe<Magento2_TaxItem>>>;
  /** The gift card balance applied to the order. */
  totalGiftcard?: Maybe<Magento2_Money>;
  /** The shipping amount for the order */
  totalShipping: Magento2_Money;
  /** The amount of tax applied to the order */
  totalTax: Magento2_Money;
};

/** Contains required input for Payflow Express Checkout payments. */
export type Magento2_PayflowExpressInput = {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String'];
  /** The token returned by the createPaypalExpressToken mutation. */
  token: Scalars['String'];
};

/** A set of relative URLs that PayPal uses in response to various actions during the authorization process. Adobe Commerce prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods. */
export type Magento2_PayflowLinkInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the transaction error page that PayPal redirects to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String'];
  /** The relative URL of the order confirmation page that PayPal redirects to when the payment is successful and additional confirmation is not needed. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
};

/** Indicates the mode for payment. Applies to the Payflow Link and Payments Advanced payment methods. */
export enum Magento2_PayflowLinkMode {
  /** @deprecated  */
  Live = 'LIVE',
  /** @deprecated  */
  Test = 'TEST',
}

/** Contains information used to generate PayPal iframe for transaction. Applies to Payflow Link and Payments Advanced payment methods. */
export type Magento2_PayflowLinkToken = {
  __typename?: 'Magento2_PayflowLinkToken';
  /** The mode for the Payflow transaction. */
  mode?: Maybe<Magento2_PayflowLinkMode>;
  /** The PayPal URL used for requesting a Payflow form. */
  paypalUrl?: Maybe<Scalars['String']>;
  /** The secure token generated by PayPal. */
  secureToken?: Maybe<Scalars['String']>;
  /** The secure token ID generated by PayPal. */
  secureTokenId?: Maybe<Scalars['String']>;
};

/** Contains information required to fetch payment token information for the Payflow Link and Payments Advanced payment methods. */
export type Magento2_PayflowLinkTokenInput = {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
};

/** Contains input for the Payflow Pro and Payments Pro payment methods. */
export type Magento2_PayflowProInput = {
  /** Required input for credit card related information. */
  cc_details: Magento2_CreditCardDetailsInput;
  /** Indicates whether details about the shopper's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for the PayPal Payflow Pro payment integration. */
  is_active_payment_token_enabler?: InputMaybe<Scalars['Boolean']>;
};

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export type Magento2_PayflowProResponseInput = {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
  /** The payload returned from PayPal. */
  paypal_payload: Scalars['String'];
};

export type Magento2_PayflowProResponseOutput = {
  __typename?: 'Magento2_PayflowProResponseOutput';
  /** The cart with the updated selected payment method. */
  cart: Magento2_Cart;
};

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type Magento2_PayflowProToken = {
  __typename?: 'Magento2_PayflowProToken';
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  responseMessage: Scalars['String'];
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int'];
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  resultCode: Scalars['Int'];
  /** A secure token generated by PayPal. */
  secureToken: Scalars['String'];
  /** A secure token ID generated by PayPal. */
  secureTokenId: Scalars['String'];
};

/** Contains input required to fetch payment token information for the Payflow Pro and Payments Pro payment methods. */
export type Magento2_PayflowProTokenInput = {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
  /** A set of relative URLs that PayPal uses for callback. */
  urls: Magento2_PayflowProUrlInput;
};

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for the Payflow Pro and Payment Pro payment methods. */
export type Magento2_PayflowProUrlInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the transaction error page that PayPal redirects to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String'];
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
};

/** Defines the payment method. */
export type Magento2_PaymentMethodInput = {
  /** The internal name for the payment method. */
  code: Scalars['String'];
  /** Required input for PayPal Hosted pro payments. */
  hosted_pro?: InputMaybe<Magento2_HostedProInput>;
  /** Required input for Payflow Express Checkout payments. */
  payflow_express?: InputMaybe<Magento2_PayflowExpressInput>;
  /** Required input for PayPal Payflow Link and Payments Advanced payments. */
  payflow_link?: InputMaybe<Magento2_PayflowLinkInput>;
  /** Required input for PayPal Payflow Pro and Payment Pro payments. */
  payflowpro?: InputMaybe<Magento2_PayflowProInput>;
  /** Required input for PayPal Payflow Pro vault payments. */
  payflowpro_cc_vault?: InputMaybe<Magento2_VaultTokenInput>;
  /** Required input for Express Checkout and Payments Standard payments. */
  paypal_express?: InputMaybe<Magento2_PaypalExpressInput>;
  /** The purchase order number. Optional for most payment methods. */
  purchase_order_number?: InputMaybe<Scalars['String']>;
  /** Required input for Stripe Payments */
  stripe_payments?: InputMaybe<Magento2_StripePaymentsInput>;
};

/** The stored payment method available to the customer. */
export type Magento2_PaymentToken = {
  __typename?: 'Magento2_PaymentToken';
  /** A description of the stored account details. */
  details?: Maybe<Scalars['String']>;
  /** The payment method code associated with the token. */
  paymentMethodCode: Scalars['String'];
  /** The public hash of the token. */
  publicHash: Scalars['String'];
  /** Specifies the payment token type. */
  type: Magento2_PaymentTokenTypeEnum;
};

/** The list of available payment token types. */
export enum Magento2_PaymentTokenTypeEnum {
  /**
   * phpcs:ignore Magento2.GraphQL.ValidArgumentName
   * @deprecated
   */
  Account = 'account',
  /**
   * phpcs:ignore Magento2.GraphQL.ValidArgumentName
   * @deprecated
   */
  Card = 'card',
}

/** Contains required input for Express Checkout and Payments Standard payments. */
export type Magento2_PaypalExpressInput = {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String'];
  /** The token returned by the `createPaypalExpressToken` mutation. */
  token: Scalars['String'];
};

/** Deprecated. Use `PaypalExpressTokenOutput` instead. */
export type Magento2_PaypalExpressToken = {
  __typename?: 'Magento2_PaypalExpressToken';
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details.
   * @deprecated Use `PaypalExpressTokenOutput.paypal_urls` instead.
   */
  paypalUrls?: Maybe<Magento2_PaypalExpressUrlList>;
  /**
   * The token returned by PayPal.
   * @deprecated Use `PaypalExpressTokenOutput.token` instead.
   */
  token?: Maybe<Scalars['String']>;
};

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export type Magento2_PaypalExpressTokenInput = {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
  /** The payment method code. */
  code: Scalars['String'];
  /** Indicates whether the buyer selected the quick checkout button. The default value is false. */
  express_button?: InputMaybe<Scalars['Boolean']>;
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process. */
  urls: Magento2_PaypalExpressUrlsInput;
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false. */
  use_paypal_credit?: InputMaybe<Scalars['Boolean']>;
};

/** Contains the token returned by PayPal and a set of URLs that allow the buyer to authorize payment and adjust checkout details. Applies to Express Checkout and Payments Standard payment methods. */
export type Magento2_PaypalExpressTokenOutput = {
  __typename?: 'Magento2_PaypalExpressTokenOutput';
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details. */
  paypalUrls?: Maybe<Magento2_PaypalExpressUrlList>;
  /** The token returned by PayPal. */
  token?: Maybe<Scalars['String']>;
};

/** Contains a set of URLs that allow the buyer to authorize payment and adjust checkout details for Express Checkout and Payments Standard transactions. */
export type Magento2_PaypalExpressUrlList = {
  __typename?: 'Magento2_PaypalExpressUrlList';
  /** The PayPal URL that allows the buyer to edit their checkout details. */
  edit?: Maybe<Scalars['String']>;
  /** The URL to the PayPal login page. */
  start?: Maybe<Scalars['String']>;
};

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Express Checkout and Payments Standard payment methods. */
export type Magento2_PaypalExpressUrlsInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the page that PayPal redirects to when the payment has been put on hold for additional review. This condition mostly applies to ACH transactions, and is not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success_pending.html, the relative URL is paypal/action/success_pending.html. */
  pending_url?: InputMaybe<Scalars['String']>;
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
  /** The relative URL of the order confirmation page that PayPal redirects to when the payment is successful and additional confirmation is not needed. Not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success.html, the relative URL is paypal/action/success.html. */
  success_url?: InputMaybe<Scalars['String']>;
};

/** Contains attributes specific to tangible products. */
export type Magento2_PhysicalProductInterface = {
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};

/** Defines Pickup Location information. */
export type Magento2_PickupLocation = {
  __typename?: 'Magento2_PickupLocation';
  city?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pickupLocationCode?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
};

/** PickupLocationFilterInput defines the list of attributes and filters for the search. */
export type Magento2_PickupLocationFilterInput = {
  /** Filter by city. */
  city?: InputMaybe<Magento2_FilterTypeInput>;
  /** Filter by country. */
  country_id?: InputMaybe<Magento2_FilterTypeInput>;
  /** Filter by pickup location name. */
  name?: InputMaybe<Magento2_FilterTypeInput>;
  /** Filter by pickup location code. */
  pickup_location_code?: InputMaybe<Magento2_FilterTypeInput>;
  /** Filter by postcode. */
  postcode?: InputMaybe<Magento2_FilterTypeInput>;
  /** Filter by region. */
  region?: InputMaybe<Magento2_FilterTypeInput>;
  /** Filter by region id. */
  region_id?: InputMaybe<Magento2_FilterTypeInput>;
  /** Filter by street. */
  street?: InputMaybe<Magento2_FilterTypeInput>;
};

/** PickupLocationSortInput specifies attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type Magento2_PickupLocationSortInput = {
  /** City where pickup location is placed. */
  city?: InputMaybe<Magento2_SortEnum>;
  /** Name of the contact person. */
  contact_name?: InputMaybe<Magento2_SortEnum>;
  /** Id of the country in two letters. */
  country_id?: InputMaybe<Magento2_SortEnum>;
  /** Description of the pickup location. */
  description?: InputMaybe<Magento2_SortEnum>;
  /** Distance to the address, requested by distance filter. Applicable only with distance filter. If distance sort order is present, all other sort orders will be ignored. */
  distance?: InputMaybe<Magento2_SortEnum>;
  /** Contact email of the pickup location. */
  email?: InputMaybe<Magento2_SortEnum>;
  /** Contact fax of the pickup location. */
  fax?: InputMaybe<Magento2_SortEnum>;
  /** Geographic latitude where pickup location is placed. */
  latitude?: InputMaybe<Magento2_SortEnum>;
  /** Geographic longitude where pickup location is placed. */
  longitude?: InputMaybe<Magento2_SortEnum>;
  /** The pickup location name. Customer use this to identify the pickup location. */
  name?: InputMaybe<Magento2_SortEnum>;
  /** Contact phone number of the pickup location. */
  phone?: InputMaybe<Magento2_SortEnum>;
  /** A code assigned to pickup location to identify the source. */
  pickup_location_code?: InputMaybe<Magento2_SortEnum>;
  /** Postcode where pickup location is placed. */
  postcode?: InputMaybe<Magento2_SortEnum>;
  /** Name of the region. */
  region?: InputMaybe<Magento2_SortEnum>;
  /** Id of the region. */
  region_id?: InputMaybe<Magento2_SortEnum>;
  /** Street where pickup location is placed. */
  street?: InputMaybe<Magento2_SortEnum>;
};

/** Top level object returned in a pickup locations search. */
export type Magento2_PickupLocations = {
  __typename?: 'Magento2_PickupLocations';
  /** An array of pickup locations that match the specific search request. */
  items?: Maybe<Array<Maybe<Magento2_PickupLocation>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  pageInfo?: Maybe<Magento2_SearchResultPageInfo>;
  /** The number of products returned. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Specifies the quote to be converted to an order. */
export type Magento2_PlaceOrderInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
};

/** Contains the results of the request to place an order. */
export type Magento2_PlaceOrderOutput = {
  __typename?: 'Magento2_PlaceOrderOutput';
  /** The ID of the order. */
  order: Magento2_Order;
};

export type Magento2_PlaceStripeOrderInput = {
  /** Customer Cart ID */
  cart_id?: InputMaybe<Scalars['String']>;
  /** Event required for order place */
  event: Scalars['String'];
  /** Event location (product, minicart, cart or checkout) */
  location: Scalars['String'];
  /** Stripe Payment Intent ID */
  pi_id: Scalars['String'];
  product?: InputMaybe<Magento2_StripeProductInput>;
};

export type Magento2_PlaceStripeOrderOutput = {
  __typename?: 'Magento2_PlaceStripeOrderOutput';
  /** Cart ID */
  cartId?: Maybe<Scalars['String']>;
  /** Redirect URL */
  redirectUrl?: Maybe<Scalars['String']>;
};

/** Deprecated. Use `ProductPrice` instead. Defines the price of a product as well as any tax-related adjustments. */
export type Magento2_Price = {
  __typename?: 'Magento2_Price';
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Use `ProductPrice` instead.
   */
  adjustments?: Maybe<Array<Maybe<Magento2_PriceAdjustment>>>;
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Use `ProductPrice` instead.
   */
  amount?: Maybe<Magento2_Money>;
};

/** Deprecated. Taxes will be included or excluded in the price. Defines the amount of money to apply as an adjustment, the type of adjustment to apply, and whether the item is included or excluded from the adjustment. */
export type Magento2_PriceAdjustment = {
  __typename?: 'Magento2_PriceAdjustment';
  /** The amount of the price adjustment and its currency code. */
  amount?: Maybe<Magento2_Money>;
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  code?: Maybe<Magento2_PriceAdjustmentCodesEnum>;
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  description?: Maybe<Magento2_PriceAdjustmentDescriptionEnum>;
};

/** `PriceAdjustment.code` is deprecated. */
export enum Magento2_PriceAdjustmentCodesEnum {
  /** @deprecated `PriceAdjustmentCodesEnum` is deprecated. Tax is included or excluded in the price. Tax is not shown separately in Catalog. */
  Tax = 'TAX',
  /** @deprecated WEEE code is deprecated. Use `fixed_product_taxes.label` instead. */
  Weee = 'WEEE',
  /** @deprecated Use `fixed_product_taxes` instead.  Tax is included or excluded in price. The tax is not shown separtely in Catalog. */
  WeeeTax = 'WEEE_TAX',
}

/** `PriceAdjustmentDescriptionEnum` is deprecated. States whether a price adjustment is included or excluded. */
export enum Magento2_PriceAdjustmentDescriptionEnum {
  /** @deprecated  */
  Excluded = 'EXCLUDED',
  /** @deprecated  */
  Included = 'INCLUDED',
}

/** Contains the price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export type Magento2_PriceRange = {
  __typename?: 'Magento2_PriceRange';
  /** The highest possible price for the product. */
  maximumPrice?: Maybe<Magento2_ProductPrice>;
  /** The lowest possible price for the product. */
  minimumPrice: Magento2_ProductPrice;
};

/** Defines the price type. */
export enum Magento2_PriceTypeEnum {
  /** @deprecated  */
  Dynamic = 'DYNAMIC',
  /** @deprecated  */
  Fixed = 'FIXED',
  /** @deprecated  */
  Percent = 'PERCENT',
}

/** Defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export enum Magento2_PriceViewEnum {
  /** @deprecated  */
  AsLowAs = 'AS_LOW_AS',
  /** @deprecated  */
  PriceRange = 'PRICE_RANGE',
}

/** Contains a product attribute code and value. */
export type Magento2_ProductAttribute = {
  __typename?: 'Magento2_ProductAttribute';
  /** The unique identifier for a product attribute code. */
  code: Scalars['String'];
  /** The display value of the attribute. */
  value: Scalars['String'];
};

/** Defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type Magento2_ProductAttributeFilterInput = {
  /** Attribute label: Brand */
  brand?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Bundle Total Price */
  bundle_total_price?: InputMaybe<Magento2_FilterRangeTypeInput>;
  /** Deprecated: use `category_uid` to filter product by category ID. */
  category_id?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Filter product by the unique ID for a `CategoryInterface` object. */
  category_uid?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Attribute label: Color */
  color?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Attribute label: Description */
  description?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Product Detail Page */
  detail_page?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Download Page */
  download_page?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Feature Description */
  feature_description?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Item Type */
  item_type?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Attribute label: Label */
  label?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: License Class */
  license_class?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Attribute label: License Code */
  license_code?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Attribute label: License Type */
  license_type?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Attribute label: Manufacturer */
  manufacturer?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Attribute label: Media Page */
  media_page?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Meta Description */
  meta_description?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Meta Keywords */
  meta_keyword?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Meta Title */
  meta_title?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Product Name */
  name?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Native Access */
  native_access?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Attribute label: Price */
  price?: InputMaybe<Magento2_FilterRangeTypeInput>;
  /** Attribute label: Pricing Page */
  pricing_page?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: SDBS ID */
  sdbs_id?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Serial System */
  serial_system?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** Attribute label: Serial System Reference */
  serial_system_reference?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: Short Description */
  short_description?: InputMaybe<Magento2_FilterMatchTypeInput>;
  /** Attribute label: SKU */
  sku?: InputMaybe<Magento2_FilterEqualTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<Magento2_FilterEqualTypeInput>;
};

/** Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. It's possible to sort products using searchable attributes with enabled 'Use in Filter Options' option */
export type Magento2_ProductAttributeSortInput = {
  /** Attribute label: License Type */
  license_type?: InputMaybe<Magento2_SortEnum>;
  /** Attribute label: Product Name */
  name?: InputMaybe<Magento2_SortEnum>;
  /** Sort by the position assigned to each product. */
  position?: InputMaybe<Magento2_SortEnum>;
  /** Attribute label: Price */
  price?: InputMaybe<Magento2_SortEnum>;
  /** Sort by the search relevance score (default). */
  relevance?: InputMaybe<Magento2_SortEnum>;
};

/** Contains the discount applied to a product price. */
export type Magento2_ProductDiscount = {
  __typename?: 'Magento2_ProductDiscount';
  /** The actual value of the discount. */
  amountOff?: Maybe<Scalars['Float']>;
  /** The discount expressed a percentage. */
  percentOff?: Maybe<Scalars['Float']>;
};

/** ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead. ProductFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type Magento2_ProductFilterInput = {
  /** The category ID the product belongs to. */
  category_id?: InputMaybe<Magento2_FilterTypeInput>;
  /** The product's country of origin. */
  country_of_manufacture?: InputMaybe<Magento2_FilterTypeInput>;
  /** The timestamp indicating when the product was created. */
  created_at?: InputMaybe<Magento2_FilterTypeInput>;
  /** The name of a custom layout. */
  custom_layout?: InputMaybe<Magento2_FilterTypeInput>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: InputMaybe<Magento2_FilterTypeInput>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<Magento2_FilterTypeInput>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: InputMaybe<Magento2_FilterTypeInput>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: InputMaybe<Magento2_FilterTypeInput>;
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<Magento2_FilterTypeInput>;
  /** The label assigned to a product image. */
  image_label?: InputMaybe<Magento2_FilterTypeInput>;
  /** Indicates whether the product can be returned. */
  is_returnable?: InputMaybe<Magento2_FilterTypeInput>;
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<Magento2_FilterTypeInput>;
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: InputMaybe<Magento2_FilterTypeInput>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: InputMaybe<Magento2_FilterTypeInput>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: InputMaybe<Magento2_FilterTypeInput>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: InputMaybe<Magento2_FilterTypeInput>;
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: InputMaybe<Magento2_FilterTypeInput>;
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<Magento2_FilterTypeInput>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: InputMaybe<Magento2_FilterTypeInput>;
  /** The end date for new product listings. */
  news_to_date?: InputMaybe<Magento2_FilterTypeInput>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: InputMaybe<Magento2_FilterTypeInput>;
  /** The keyword required to perform a logical OR comparison. */
  or?: InputMaybe<Magento2_ProductFilterInput>;
  /** The price of an item. */
  price?: InputMaybe<Magento2_FilterTypeInput>;
  /** Indicates whether the product has required options. */
  required_options?: InputMaybe<Magento2_FilterTypeInput>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: InputMaybe<Magento2_FilterTypeInput>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<Magento2_FilterTypeInput>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: InputMaybe<Magento2_FilterTypeInput>;
  /** The label assigned to a product's small image. */
  small_image_label?: InputMaybe<Magento2_FilterTypeInput>;
  /** The beginning date that a product has a special price. */
  special_from_date?: InputMaybe<Magento2_FilterTypeInput>;
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: InputMaybe<Magento2_FilterTypeInput>;
  /** The end date that a product has a special price. */
  special_to_date?: InputMaybe<Magento2_FilterTypeInput>;
  /** The file name of a swatch image. */
  swatch_image?: InputMaybe<Magento2_FilterTypeInput>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<Magento2_FilterTypeInput>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: InputMaybe<Magento2_FilterTypeInput>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: InputMaybe<Magento2_FilterTypeInput>;
  /** The timestamp indicating when the product was updated. */
  updated_at?: InputMaybe<Magento2_FilterTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<Magento2_FilterTypeInput>;
  url_path?: InputMaybe<Magento2_FilterTypeInput>;
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<Magento2_FilterTypeInput>;
};

/** Contains product image information, including the image URL and label. */
export type Magento2_ProductImage = Magento2_MediaGalleryInterface & {
  __typename?: 'Magento2_ProductImage';
  /** Indicates whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
};

/** Product Information used for Pickup Locations search. */
export type Magento2_ProductInfoInput = {
  /** Product SKU. */
  sku: Scalars['String'];
};

/** Contains fields that are common to all types of products. */
export type Magento2_ProductInterface = {
  /** Additional information about variant */
  additionalInfo?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  brand?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bundleTotalPrice?: Maybe<Scalars['Float']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl?: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<Magento2_CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  countryOfManufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt?: Maybe<Scalars['String']>;
  /** An array of cross-sell products. */
  crosssellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<Magento2_ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  detailPage?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  downloadPage?: Maybe<Scalars['String']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable?: Maybe<Scalars['String']>;
  /** Hide qualify message */
  hideQualifyMessage?: Maybe<Scalars['Boolean']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<Magento2_ProductImage>;
  /** Check default variant of configurable products. */
  isDefaultWithinAllVariants?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the product can be returned. */
  isReturnable?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  itemType?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  label?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licenseClass?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licenseCode?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licenseType?: Maybe<Scalars['Int']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  mediaGallery?: Maybe<Array<Maybe<Magento2_MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries?: Maybe<Array<Maybe<Magento2_MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mediaPage?: Maybe<Scalars['String']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  nativeAccess?: Maybe<Scalars['Int']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  newToDate?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  onlyXLeftInStock?: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer?: Maybe<Scalars['String']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<Magento2_ProductPrices>;
  /** The range of prices for the product */
  priceRange: Magento2_PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers?: Maybe<Array<Maybe<Magento2_TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pricingPage?: Maybe<Scalars['String']>;
  /** An array of `ProductLinks` objects. */
  productLinks?: Maybe<Array<Maybe<Magento2_ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float'];
  /** An array of related products. */
  relatedProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  releaseDate?: Maybe<Scalars['String']>;
  /** Required Products Information about the product. The value can include simple HTML tags. */
  requiredProductsInformation?: Maybe<Magento2_ComplexTextValue>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int'];
  /** The list of products reviews. */
  reviews: Magento2_ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  sdbsId?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  serialSystem?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  serialSystemReference?: Maybe<Scalars['String']>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription?: Maybe<Magento2_ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage?: Maybe<Magento2_ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  specialPrice?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  specialToDate?: Maybe<Scalars['String']>;
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean'];
  /** Stock availability label. */
  stockAvailabilityLabel?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stockStatus?: Maybe<Magento2_ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<Magento2_ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices?: Maybe<Array<Maybe<Magento2_ProductTierPrices>>>;
  /** Time to stock custom attribute. */
  timeToStock?: Maybe<Scalars['String']>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt?: Maybe<Scalars['String']>;
  /** An array of up-sell products. */
  upsellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  urlRewrites?: Maybe<Array<Maybe<Magento2_UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Magento2_Website>>>;
};

/** Contains fields that are common to all types of products. */
export type Magento2_ProductInterfaceReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** An implementation of `ProductLinksInterface`. */
export type Magento2_ProductLinks = Magento2_ProductLinksInterface & {
  __typename?: 'Magento2_ProductLinks';
  /** One of related, associated, upsell, or crosssell. */
  linkType?: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linkedProductSku?: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linkedProductType?: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>;
};

/** Contains information about linked products, including the link type and product type of each item. */
export type Magento2_ProductLinksInterface = {
  /** One of related, associated, upsell, or crosssell. */
  linkType?: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linkedProductSku?: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linkedProductType?: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>;
};

/** Contains an image in base64 format and basic information about the image. */
export type Magento2_ProductMediaGalleryEntriesContent = {
  __typename?: 'Magento2_ProductMediaGalleryEntriesContent';
  /** The image in base64 format. */
  base64EncodedData?: Maybe<Scalars['String']>;
  /** The file name of the image. */
  name?: Maybe<Scalars['String']>;
  /** The MIME type of the file, such as image/png. */
  type?: Maybe<Scalars['String']>;
};

/** Contains a link to a video file and basic information about the video. */
export type Magento2_ProductMediaGalleryEntriesVideoContent = {
  __typename?: 'Magento2_ProductMediaGalleryEntriesVideoContent';
  /** Must be external-video. */
  mediaType?: Maybe<Scalars['String']>;
  /** A description of the video. */
  videoDescription?: Maybe<Scalars['String']>;
  /** Optional data about the video. */
  videoMetadata?: Maybe<Scalars['String']>;
  /** Describes the video source. */
  videoProvider?: Maybe<Scalars['String']>;
  /** The title of the video. */
  videoTitle?: Maybe<Scalars['String']>;
  /** The URL to the video. */
  videoUrl?: Maybe<Scalars['String']>;
};

/** Represents a product price. */
export type Magento2_ProductPrice = {
  __typename?: 'Magento2_ProductPrice';
  /** The price discount. Represents the difference between the regular and final price. */
  discount?: Maybe<Magento2_ProductDiscount>;
  /** The final price of the product after applying discounts. */
  finalPrice: Magento2_Money;
  /** An array of the multiple Fixed Product Taxes that can be applied to a product price. */
  fixedProductTaxes?: Maybe<Array<Maybe<Magento2_FixedProductTax>>>;
  /** The regular price of the product. */
  regularPrice: Magento2_Money;
};

/** Deprecated. Use `PriceRange` instead. Contains the regular price of an item, as well as its minimum and maximum prices. Only composite products, which include bundle, configurable, and grouped products, can contain a minimum and maximum price. */
export type Magento2_ProductPrices = {
  __typename?: 'Magento2_ProductPrices';
  /**
   * The highest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the `to` value.
   * @deprecated Use `PriceRange.maximum_price` instead.
   */
  maximalPrice?: Maybe<Magento2_Price>;
  /**
   * The lowest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the `from` value.
   * @deprecated Use `PriceRange.minimum_price` instead.
   */
  minimalPrice?: Maybe<Magento2_Price>;
  /**
   * The base price of a product.
   * @deprecated Use `regular_price` from `PriceRange.minimum_price` or `PriceRange.maximum_price` instead.
   */
  regularPrice?: Maybe<Magento2_Price>;
};

/** Contains details of a product review. */
export type Magento2_ProductReview = {
  __typename?: 'Magento2_ProductReview';
  /** The average of all ratings for this product. */
  averageRating: Scalars['Float'];
  /** The date the review was created. */
  createdAt: Scalars['String'];
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String'];
  /** The reviewed product. */
  product: Magento2_ProductInterface;
  /** An array of ratings by rating category, such as quality, price, and value. */
  ratingsBreakdown: Array<Maybe<Magento2_ProductReviewRating>>;
  /** The summary (title) of the review. */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
};

/** Contains data about a single aspect of a product review. */
export type Magento2_ProductReviewRating = {
  __typename?: 'Magento2_ProductReviewRating';
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String'];
  /** The rating value given by customer. By default, possible values range from 1 to 5. */
  value: Scalars['String'];
};

/** Contains the reviewer's rating for a single aspect of a review. */
export type Magento2_ProductReviewRatingInput = {
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** An encoded rating value ID. */
  value_id: Scalars['String'];
};

/** Contains details about a single aspect of a product review. */
export type Magento2_ProductReviewRatingMetadata = {
  __typename?: 'Magento2_ProductReviewRatingMetadata';
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String'];
  /** List of product review ratings sorted by position. */
  values: Array<Maybe<Magento2_ProductReviewRatingValueMetadata>>;
};

/** Contains details about a single value in a product review. */
export type Magento2_ProductReviewRatingValueMetadata = {
  __typename?: 'Magento2_ProductReviewRatingValueMetadata';
  /** A ratings scale, such as the number of stars awarded. */
  value: Scalars['String'];
  /** An encoded rating value ID. */
  valueId: Scalars['String'];
};

/** Contains an array of metadata about each aspect of a product review. */
export type Magento2_ProductReviewRatingsMetadata = {
  __typename?: 'Magento2_ProductReviewRatingsMetadata';
  /** An array of product reviews sorted by position. */
  items: Array<Maybe<Magento2_ProductReviewRatingMetadata>>;
};

/** Contains an array of product reviews. */
export type Magento2_ProductReviews = {
  __typename?: 'Magento2_ProductReviews';
  /** An array of product reviews. */
  items: Array<Maybe<Magento2_ProductReview>>;
  /** Metadata for pagination rendering. */
  pageInfo: Magento2_SearchResultPageInfo;
};

/** Deprecated. Use `ProductAttributeSortInput` instead. Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type Magento2_ProductSortInput = {
  /** The product's country of origin. */
  country_of_manufacture?: InputMaybe<Magento2_SortEnum>;
  /** The timestamp indicating when the product was created. */
  created_at?: InputMaybe<Magento2_SortEnum>;
  /** The name of a custom layout. */
  custom_layout?: InputMaybe<Magento2_SortEnum>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: InputMaybe<Magento2_SortEnum>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<Magento2_SortEnum>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: InputMaybe<Magento2_SortEnum>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: InputMaybe<Magento2_SortEnum>;
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<Magento2_SortEnum>;
  /** The label assigned to a product image. */
  image_label?: InputMaybe<Magento2_SortEnum>;
  /** Indicates whether the product can be returned. */
  is_returnable?: InputMaybe<Magento2_SortEnum>;
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<Magento2_SortEnum>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: InputMaybe<Magento2_SortEnum>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: InputMaybe<Magento2_SortEnum>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: InputMaybe<Magento2_SortEnum>;
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<Magento2_SortEnum>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: InputMaybe<Magento2_SortEnum>;
  /** The end date for new product listings. */
  news_to_date?: InputMaybe<Magento2_SortEnum>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: InputMaybe<Magento2_SortEnum>;
  /** The price of the item. */
  price?: InputMaybe<Magento2_SortEnum>;
  /** Indicates whether the product has required options. */
  required_options?: InputMaybe<Magento2_SortEnum>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: InputMaybe<Magento2_SortEnum>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<Magento2_SortEnum>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: InputMaybe<Magento2_SortEnum>;
  /** The label assigned to a product's small image. */
  small_image_label?: InputMaybe<Magento2_SortEnum>;
  /** The beginning date that a product has a special price. */
  special_from_date?: InputMaybe<Magento2_SortEnum>;
  /** The discounted price of the product. */
  special_price?: InputMaybe<Magento2_SortEnum>;
  /** The end date that a product has a special price. */
  special_to_date?: InputMaybe<Magento2_SortEnum>;
  /** Indicates the criteria to sort swatches. */
  swatch_image?: InputMaybe<Magento2_SortEnum>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<Magento2_SortEnum>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: InputMaybe<Magento2_SortEnum>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: InputMaybe<Magento2_SortEnum>;
  /** The timestamp indicating when the product was updated. */
  updated_at?: InputMaybe<Magento2_SortEnum>;
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<Magento2_SortEnum>;
  url_path?: InputMaybe<Magento2_SortEnum>;
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<Magento2_SortEnum>;
};

/** This enumeration states whether a product stock status is in stock or out of stock */
export enum Magento2_ProductStockStatus {
  /** @deprecated  */
  InStock = 'IN_STOCK',
  /** @deprecated  */
  OutOfStock = 'OUT_OF_STOCK',
}

/** Deprecated. Use `TierPrice` instead. Defines a tier price, which is a quantity discount offered to a specific customer group. */
export type Magento2_ProductTierPrices = {
  __typename?: 'Magento2_ProductTierPrices';
  /**
   * The ID of the customer group.
   * @deprecated Not relevant for the storefront.
   */
  customerGroupId?: Maybe<Scalars['String']>;
  /**
   * The percentage discount of the item.
   * @deprecated Use `TierPrice.discount` instead.
   */
  percentageValue?: Maybe<Scalars['Float']>;
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated Use `TierPrice.quantity` instead.
   */
  qty?: Maybe<Scalars['Float']>;
  /**
   * The price of the fixed price item.
   * @deprecated Use `TierPrice.final_price` instead.
   */
  value?: Maybe<Scalars['Float']>;
  /**
   * The ID assigned to the website.
   * @deprecated Not relevant for the storefront.
   */
  websiteId?: Maybe<Scalars['Float']>;
};

/** Contains information about a product video. */
export type Magento2_ProductVideo = Magento2_MediaGalleryInterface & {
  __typename?: 'Magento2_ProductVideo';
  /** Indicates whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
  /** Contains a `ProductMediaGalleryEntriesVideoContent` object. */
  videoContent?: Maybe<Magento2_ProductMediaGalleryEntriesVideoContent>;
};

/** Contains the results of a `products` query. */
export type Magento2_Products = {
  __typename?: 'Magento2_Products';
  /** A bucket that contains the attribute code and label for each filterable option. */
  aggregations?: Maybe<Array<Maybe<Magento2_Aggregation>>>;
  /**
   * Layered navigation filters array.
   * @deprecated Use `aggregations` instead.
   */
  filters?: Maybe<Array<Maybe<Magento2_LayerFilter>>>;
  /** An array of products that match the specified search criteria. */
  items?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  pageInfo?: Maybe<Magento2_SearchResultPageInfo>;
  /** An object that includes the default sort field and all available sort fields. */
  sortFields?: Maybe<Magento2_SortFields>;
  /** An array of search suggestions for case when search query have no results. */
  suggestions?: Maybe<Array<Maybe<Magento2_SearchSuggestion>>>;
  /** The number of products that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Contains the results of a `products` query. */
export type Magento2_ProductsAggregationsArgs = {
  filter?: InputMaybe<Magento2_AggregationsFilterInput>;
};

export type Magento2_RedirectAndPlaceOrderInput = {
  /** Selected agreements ids. */
  agreement_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String'];
  /** Page where customer will be redirect after failed payment. */
  failed_url?: InputMaybe<Scalars['String']>;
  /** Order Origin */
  origin?: InputMaybe<Scalars['String']>;
  /** Checkout page url on frontend where customer can be returned to choose another payment. */
  return_url?: InputMaybe<Scalars['String']>;
  /** Page where customer will be redirect after success payment. */
  success_url?: InputMaybe<Scalars['String']>;
};

export type Magento2_RedirectAndPlaceOrderOutput = {
  __typename?: 'Magento2_RedirectAndPlaceOrderOutput';
  /** Payment page where customer should be redirected to pay and place order. */
  payRedirectUrl?: Maybe<Scalars['String']>;
};

export type Magento2_Region = {
  __typename?: 'Magento2_Region';
  /** The two-letter code for the region, such as TX for Texas. */
  code?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object. */
  id?: Maybe<Scalars['Int']>;
  /** The name of the region, such as Texas. */
  name?: Maybe<Scalars['String']>;
};

/** Specifies the cart from which to remove a coupon. */
export type Magento2_RemoveCouponFromCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
};

/** Contains details about the cart after removing a coupon. */
export type Magento2_RemoveCouponFromCartOutput = {
  __typename?: 'Magento2_RemoveCouponFromCartOutput';
  /** The cart after removing a coupon. */
  cart?: Maybe<Magento2_Cart>;
};

/** Defines the input required to run the `removeGiftCardFromCart` mutation. */
export type Magento2_RemoveGiftCardFromCartInput = {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
  /** The gift card code to be removed to the cart. */
  gift_card_code: Scalars['String'];
};

/** Defines the possible output for the `removeGiftCardFromCart` mutation. */
export type Magento2_RemoveGiftCardFromCartOutput = {
  __typename?: 'Magento2_RemoveGiftCardFromCartOutput';
  /** The contents of the specified shopping cart. */
  cart: Magento2_Cart;
};

/** Contains the results of a request to remove an item from a gift registry. */
export type Magento2_RemoveGiftRegistryItemsOutput = {
  __typename?: 'Magento2_RemoveGiftRegistryItemsOutput';
  /** The gift registry after removing items. */
  giftRegistry?: Maybe<Magento2_GiftRegistry>;
};

/** Contains the results of a request to delete a gift registry. */
export type Magento2_RemoveGiftRegistryOutput = {
  __typename?: 'Magento2_RemoveGiftRegistryOutput';
  /** Indicates whether the gift registry was successfully deleted. */
  success: Scalars['Boolean'];
};

/** Contains the results of a request to delete a registrant. */
export type Magento2_RemoveGiftRegistryRegistrantsOutput = {
  __typename?: 'Magento2_RemoveGiftRegistryRegistrantsOutput';
  /** The gift registry after deleting registrants. */
  giftRegistry?: Maybe<Magento2_GiftRegistry>;
};

/** Specifies which items to remove from the cart. */
export type Magento2_RemoveItemFromCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: InputMaybe<Scalars['Int']>;
  /** Required field. The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: InputMaybe<Scalars['ID']>;
};

/** Contains details about the cart after removing an item. */
export type Magento2_RemoveItemFromCartOutput = {
  __typename?: 'Magento2_RemoveItemFromCartOutput';
  /** The cart after removing an item. */
  cart: Magento2_Cart;
};

/** Defines which products to remove from a compare list. */
export type Magento2_RemoveProductsFromCompareListInput = {
  /** An array of product IDs to remove from the compare list. */
  products: Array<InputMaybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID'];
};

/** Contains the customer's wish list and any errors encountered. */
export type Magento2_RemoveProductsFromWishlistOutput = {
  __typename?: 'Magento2_RemoveProductsFromWishlistOutput';
  /** An array of errors encountered while deleting products from a wish list. */
  userErrors: Array<Maybe<Magento2_WishListUserInputError>>;
  /** Contains the wish list with after items were successfully deleted. */
  wishlist: Magento2_Wishlist;
};

/** Defines the tracking information to delete. */
export type Magento2_RemoveReturnTrackingInput = {
  /** The unique ID for a `ReturnShippingTracking` object. */
  return_shipping_tracking_uid: Scalars['ID'];
};

/** Contains the response after deleting tracking information. */
export type Magento2_RemoveReturnTrackingOutput = {
  __typename?: 'Magento2_RemoveReturnTrackingOutput';
  /** Contains details about the modified return. */
  return?: Maybe<Magento2_Return>;
};

/** Defines the input required to run the `removeStoreCreditFromCart` mutation. */
export type Magento2_RemoveStoreCreditFromCartInput = {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
};

/** Defines the possible output for the `removeStoreCreditFromCart` mutation. */
export type Magento2_RemoveStoreCreditFromCartOutput = {
  __typename?: 'Magento2_RemoveStoreCreditFromCartOutput';
  /** The contents of the specified shopping cart. */
  cart: Magento2_Cart;
};

/** Contains the cart and any errors after adding products. */
export type Magento2_ReorderItemsOutput = {
  __typename?: 'Magento2_ReorderItemsOutput';
  /** Detailed information about the customer's cart. */
  cart: Magento2_Cart;
  /** An array of reordering errors. */
  userInputErrors: Array<Maybe<Magento2_CheckoutUserInputError>>;
};

/** Contains information needed to start a return request. */
export type Magento2_RequestReturnInput = {
  /** Text the buyer entered that describes the reason for the refund request. */
  comment_text?: InputMaybe<Scalars['String']>;
  /** The email address the buyer enters to receive notifications about the status of the return. */
  contact_email?: InputMaybe<Scalars['String']>;
  /** An array of items to be returned. */
  items: Array<InputMaybe<Magento2_RequestReturnItemInput>>;
  /** The unique ID for a `Order` object. */
  order_uid: Scalars['ID'];
};

/** Contains details about an item to be returned. */
export type Magento2_RequestReturnItemInput = {
  /** Details about a custom attribute that was entered. */
  entered_custom_attributes?: InputMaybe<
    Array<InputMaybe<Magento2_EnteredCustomAttributeInput>>
  >;
  /** The unique ID for a `OrderItemInterface` object. */
  order_item_uid: Scalars['ID'];
  /** The quantity of the item to be returned. */
  quantity_to_return: Scalars['Float'];
  /** An array of selected custom option IDs associated with the item to be returned. For example, the IDs for the selected color and size of a configurable product. */
  selected_custom_attributes?: InputMaybe<
    Array<InputMaybe<Magento2_SelectedCustomAttributeInput>>
  >;
};

/** Contains the response to a return request. */
export type Magento2_RequestReturnOutput = {
  __typename?: 'Magento2_RequestReturnOutput';
  /** Details about a single return request. */
  return?: Maybe<Magento2_Return>;
  /** An array of return requests. */
  returns?: Maybe<Magento2_Returns>;
};

/** Contains the response to a return request. */
export type Magento2_RequestReturnOutputReturnsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type Magento2_RestApi_Money = {
  __typename?: 'Magento2_RestAPI_Money';
  currency: Scalars['String'];
  value: Scalars['Float'];
};

export type Magento2_RestApi_Product = {
  __typename?: 'Magento2_RestAPI_Product';
  description?: Maybe<Scalars['String']>;
  detail_page_url?: Maybe<Scalars['String']>;
  full_price: Magento2_RestApi_Money;
  id: Scalars['Int'];
  image_url: Scalars['String'];
  name: Scalars['String'];
  sku: Scalars['String'];
};

/** Contains details about a return. */
export type Magento2_Return = {
  __typename?: 'Magento2_Return';
  /** A list of shipping carriers available for returns. */
  availableShippingCarriers?: Maybe<
    Array<Maybe<Magento2_ReturnShippingCarrier>>
  >;
  /** A list of comments posted for the return request. */
  comments?: Maybe<Array<Maybe<Magento2_ReturnComment>>>;
  /** The date the return was requested. */
  createdAt: Scalars['String'];
  /** Data from the customer who created the return request. */
  customer: Magento2_ReturnCustomer;
  /** A list of items being returned. */
  items?: Maybe<Array<Maybe<Magento2_ReturnItem>>>;
  /** A human-readable return number. */
  number: Scalars['String'];
  /** The order associated with the return. */
  order?: Maybe<Magento2_CustomerOrder>;
  /** Shipping information for the return. */
  shipping?: Maybe<Magento2_ReturnShipping>;
  /** The status of the return request. */
  status?: Maybe<Magento2_ReturnStatus>;
  /** The unique ID for a `Return` object. */
  uid: Scalars['ID'];
};

/** Contains details about a return comment. */
export type Magento2_ReturnComment = {
  __typename?: 'Magento2_ReturnComment';
  /** The name or author who posted the comment. */
  authorName: Scalars['String'];
  /** The date and time the comment was posted. */
  createdAt: Scalars['String'];
  /** The contents of the comment. */
  text: Scalars['String'];
  /** The unique ID for a `ReturnComment` object. */
  uid: Scalars['ID'];
};

/** Contains details about a `ReturnCustomerAttribute` object. */
export type Magento2_ReturnCustomAttribute = {
  __typename?: 'Magento2_ReturnCustomAttribute';
  /** A description of the attribute. */
  label: Scalars['String'];
  /** The unique ID for a `ReturnCustomAttribute` object. */
  uid: Scalars['ID'];
  /** A JSON-encoded value of the attribute. */
  value: Scalars['String'];
};

/** The customer information for the return. */
export type Magento2_ReturnCustomer = {
  __typename?: 'Magento2_ReturnCustomer';
  /** The email address of the customer. */
  email: Scalars['String'];
  /** The first name of the customer. */
  firstname?: Maybe<Scalars['String']>;
  /** The last name of the customer. */
  lastname?: Maybe<Scalars['String']>;
};

/** Contains details about a product being returned. */
export type Magento2_ReturnItem = {
  __typename?: 'Magento2_ReturnItem';
  /** Return item custom attributes that are visible on the storefront. */
  customAttributes?: Maybe<Array<Maybe<Magento2_ReturnCustomAttribute>>>;
  /** Provides access to the product being returned, including information about selected and entered options. */
  orderItem: Magento2_OrderItemInterface;
  /** The quantity of the item the merchant authorized to be returned. */
  quantity: Scalars['Float'];
  /** The quantity of the item requested to be returned. */
  requestQuantity: Scalars['Float'];
  /** The return status of the item. */
  status: Magento2_ReturnItemStatus;
  /** The unique ID for a `ReturnItem` object. */
  uid: Scalars['ID'];
};

export enum Magento2_ReturnItemStatus {
  /** @deprecated  */
  Approved = 'APPROVED',
  /** @deprecated  */
  Authorized = 'AUTHORIZED',
  /** @deprecated  */
  Denied = 'DENIED',
  /** @deprecated  */
  Pending = 'PENDING',
  /** @deprecated  */
  Received = 'RECEIVED',
  /** @deprecated  */
  Rejected = 'REJECTED',
}

/** Contains details about an item to be returned. */
export type Magento2_ReturnRequestItemInput = {
  /** The unique ID for a `OrderItemInterface` object. */
  order_item_uid: Scalars['String'];
  /** The quantity of the item to be returned. */
  quantity_to_return: Scalars['Float'];
};

export type Magento2_ReturnRequestOutput = {
  __typename?: 'Magento2_ReturnRequestOutput';
  items?: Maybe<Array<Maybe<Magento2_OrderItemInterface>>>;
  /** The unique ID for an `Order` object. */
  orderUid?: Maybe<Scalars['String']>;
};

/** Contains details about the return shipping address. */
export type Magento2_ReturnShipping = {
  __typename?: 'Magento2_ReturnShipping';
  /** The merchant-defined return shipping address. */
  address?: Maybe<Magento2_ReturnShippingAddress>;
  /** The unique ID for a `ReturnShippingTracking` object. If a single UID is specified, the array contains a single tracking record. Otherwise, array contains all tracking information. */
  tracking?: Maybe<Array<Maybe<Magento2_ReturnShippingTracking>>>;
};

/** Contains details about the return shipping address. */
export type Magento2_ReturnShippingTrackingArgs = {
  uid?: InputMaybe<Scalars['ID']>;
};

/** Contains details about the shipping address used for receiving returned items. */
export type Magento2_ReturnShippingAddress = {
  __typename?: 'Magento2_ReturnShippingAddress';
  /** The city for product returns. */
  city: Scalars['String'];
  /** The merchant's contact person. */
  contactName?: Maybe<Scalars['String']>;
  /** An object that defines the country for product returns. */
  country: Magento2_Country;
  /** The postal code for product returns. */
  postcode: Scalars['String'];
  /** An object that defines the state or province for product returns. */
  region: Magento2_Region;
  /** The street address for product returns. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for product returns. */
  telephone?: Maybe<Scalars['String']>;
};

/** Contains details about the carrier on a return. */
export type Magento2_ReturnShippingCarrier = {
  __typename?: 'Magento2_ReturnShippingCarrier';
  /** A description of the shipping carrier. */
  label: Scalars['String'];
  /** The unique ID for a `ReturnShippingCarrier` object assigned to the shipping carrier. */
  uid: Scalars['ID'];
};

/** Contains shipping and tracking details. */
export type Magento2_ReturnShippingTracking = {
  __typename?: 'Magento2_ReturnShippingTracking';
  /** Contains details of a shipping carrier. */
  carrier: Magento2_ReturnShippingCarrier;
  /** Details about the status of a shipment. */
  status?: Maybe<Magento2_ReturnShippingTrackingStatus>;
  /** A tracking number assigned by the carrier. */
  trackingNumber: Scalars['String'];
  /** The unique ID for a `ReturnShippingTracking` object assigned to the tracking item. */
  uid: Scalars['ID'];
};

/** Contains the status of a shipment. */
export type Magento2_ReturnShippingTrackingStatus = {
  __typename?: 'Magento2_ReturnShippingTrackingStatus';
  /** Text that describes the status. */
  text: Scalars['String'];
  /** Indicates whether the status type is informational or an error. */
  type: Magento2_ReturnShippingTrackingStatusType;
};

export enum Magento2_ReturnShippingTrackingStatusType {
  /** @deprecated  */
  Error = 'ERROR',
  /** @deprecated  */
  Information = 'INFORMATION',
}

export enum Magento2_ReturnStatus {
  /** @deprecated  */
  Approved = 'APPROVED',
  /** @deprecated  */
  Authorized = 'AUTHORIZED',
  /** @deprecated  */
  Closed = 'CLOSED',
  /** @deprecated  */
  Denied = 'DENIED',
  /** @deprecated  */
  PartiallyApproved = 'PARTIALLY_APPROVED',
  /** @deprecated  */
  PartiallyAuthorized = 'PARTIALLY_AUTHORIZED',
  /** @deprecated  */
  PartiallyReceived = 'PARTIALLY_RECEIVED',
  /** @deprecated  */
  PartiallyRejected = 'PARTIALLY_REJECTED',
  /** @deprecated  */
  Pending = 'PENDING',
  /** @deprecated  */
  ProcessedAndClosed = 'PROCESSED_AND_CLOSED',
  /** @deprecated  */
  Received = 'RECEIVED',
  /** @deprecated  */
  Rejected = 'REJECTED',
}

/** Contains a list of customer return requests. */
export type Magento2_Returns = {
  __typename?: 'Magento2_Returns';
  /** A list of return requests. */
  items?: Maybe<Array<Maybe<Magento2_Return>>>;
  /** Pagination metadata. */
  pageInfo?: Maybe<Magento2_SearchResultPageInfo>;
  /** The total number of return requests. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Contains the result of a request to revoke a customer token. */
export type Magento2_RevokeCustomerTokenOutput = {
  __typename?: 'Magento2_RevokeCustomerTokenOutput';
  /** The result of a request to revoke a customer token. */
  result: Scalars['Boolean'];
};

/** Routable entities serve as the model for a rendered page. */
export type Magento2_RoutableInterface = {
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
};

/** Contains details about a comment. */
export type Magento2_SalesCommentItem = {
  __typename?: 'Magento2_SalesCommentItem';
  /** The text of the message. */
  message: Scalars['String'];
  /** The timestamp of the comment. */
  timestamp: Scalars['String'];
};

export type Magento2_SalesItemInterface = {
  __typename?: 'Magento2_SalesItemInterface';
  /** The entered gift message for the order item */
  giftMessage?: Maybe<Magento2_GiftMessage>;
};

/** This enumeration defines the scope type for customer orders. */
export enum Magento2_ScopeTypeEnum {
  /** @deprecated  */
  Global = 'GLOBAL',
  /** @deprecated  */
  Store = 'STORE',
  /** @deprecated  */
  Website = 'WEBSITE',
}

/** Provides navigation for the query response. */
export type Magento2_SearchResultPageInfo = {
  __typename?: 'Magento2_SearchResultPageInfo';
  /** The specific page to return. */
  currentPage?: Maybe<Scalars['Int']>;
  /** The maximum number of items to return per page of results. */
  pageSize?: Maybe<Scalars['Int']>;
  /** The total number of pages in the response. */
  totalPages?: Maybe<Scalars['Int']>;
};

/** A string that contains search suggestion */
export type Magento2_SearchSuggestion = {
  __typename?: 'Magento2_SearchSuggestion';
  /** The search suggestion of existing product. */
  search: Scalars['String'];
};

/** Contains details about a selected bundle option. */
export type Magento2_SelectedBundleOption = {
  __typename?: 'Magento2_SelectedBundleOption';
  /** @deprecated Use `uid` instead */
  id: Scalars['Int'];
  /** The display name of the selected bundle product option. */
  label: Scalars['String'];
  /** The type of selected bundle product option. */
  type: Scalars['String'];
  /** The unique ID for a `SelectedBundleOption` object */
  uid: Scalars['ID'];
  /** An array of selected bundle option values. */
  values: Array<Maybe<Magento2_SelectedBundleOptionValue>>;
};

/** Contains details about a value for a selected bundle option. */
export type Magento2_SelectedBundleOptionValue = {
  __typename?: 'Magento2_SelectedBundleOptionValue';
  /** Use `uid` instead */
  id: Scalars['Int'];
  /** The display name of the value for the selected bundle product option. */
  label: Scalars['String'];
  /** The price of the value for the selected bundle product option. */
  price: Scalars['Float'];
  /** The quantity of the value for the selected bundle product option. */
  quantity: Scalars['Float'];
  /** The unique ID for a `SelectedBundleOptionValue` object */
  uid: Scalars['ID'];
};

/** Contains details about a selected configurable option. */
export type Magento2_SelectedConfigurableOption = {
  __typename?: 'Magento2_SelectedConfigurableOption';
  /** The unique ID for a `ConfigurableProductOptions` object. */
  configurableProductOptionUid: Scalars['ID'];
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  configurableProductOptionValueUid: Scalars['ID'];
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_uid` instead. */
  id: Scalars['Int'];
  /** The display text for the option. */
  optionLabel: Scalars['String'];
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_value_uid` instead. */
  valueId: Scalars['Int'];
  /** The display name of the selected configurable option. */
  valueLabel: Scalars['String'];
};

/** Contains details about an attribute the buyer selected. */
export type Magento2_SelectedCustomAttributeInput = {
  /** A string that identifies the selected attribute. */
  attribute_code: Scalars['String'];
  /** The unique ID for a `CustomAttribute` object of a selected custom attribute. */
  value: Scalars['ID'];
};

/** Identifies a customized product that has been placed in a cart. */
export type Magento2_SelectedCustomizableOption = {
  __typename?: 'Magento2_SelectedCustomizableOption';
  /** The unique ID for a specific `CustomizableOptionInterface` object, such as a `CustomizableFieldOption`, `CustomizableFileOption`, or `CustomizableAreaOption` object. */
  customizableOptionUid: Scalars['ID'];
  /** @deprecated Use `SelectedCustomizableOption.customizable_option_uid` instead. */
  id: Scalars['Int'];
  /** Indicates whether the customizable option is required. */
  isRequired: Scalars['Boolean'];
  /** The display name of the selected customizable option. */
  label: Scalars['String'];
  /** A value indicating the order to display this option. */
  sortOrder: Scalars['Int'];
  /** The type of `CustomizableOptionInterface` object. */
  type: Scalars['String'];
  /** An array of selectable values. */
  values: Array<Maybe<Magento2_SelectedCustomizableOptionValue>>;
};

/** Identifies the value of the selected customized option. */
export type Magento2_SelectedCustomizableOptionValue = {
  __typename?: 'Magento2_SelectedCustomizableOptionValue';
  /** The unique ID for a value object that corresponds to the object represented by the `customizable_option_uid` attribute. */
  customizableOptionValueUid: Scalars['ID'];
  /** @deprecated Use `SelectedCustomizableOptionValue.customizable_option_value_uid` instead. */
  id: Scalars['Int'];
  /** The display name of the selected value. */
  label: Scalars['String'];
  /** The price of the selected customizable value. */
  price: Magento2_CartItemSelectedOptionValuePrice;
  /** The text identifying the selected value. */
  value: Scalars['String'];
};

/** Describes the payment method the shopper selected. */
export type Magento2_SelectedPaymentMethod = {
  __typename?: 'Magento2_SelectedPaymentMethod';
  /** The payment method code. */
  code: Scalars['String'];
  /** The purchase order number. */
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  /** The payment method title. */
  title: Scalars['String'];
};

/** Contains details about the selected shipping method and carrier. */
export type Magento2_SelectedShippingMethod = {
  __typename?: 'Magento2_SelectedShippingMethod';
  /** The cost of shipping using this shipping method. */
  amount: Magento2_Money;
  amountInclTax: Magento2_Money;
  /** @deprecated The field should not be used on the storefront. */
  baseAmount?: Maybe<Magento2_Money>;
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrierCode: Scalars['String'];
  /** The label for the carrier code. */
  carrierTitle: Scalars['String'];
  /** A shipping method code associated with a carrier. */
  methodCode: Scalars['String'];
  /** The label for the method code. */
  methodTitle: Scalars['String'];
  /** The cost of shipping using this shipping method, excluding tax. */
  priceExclTax: Magento2_Money;
  /** The cost of shipping using this shipping method, including tax. */
  priceInclTax: Magento2_Money;
};

/** Defines the referenced product and the email sender and recipients. */
export type Magento2_SendEmailToFriendInput = {
  /** The ID of the product that the sender is referencing. */
  product_id: Scalars['Int'];
  /** An array containing information about each recipient. */
  recipients: Array<InputMaybe<Magento2_SendEmailToFriendRecipientInput>>;
  /** Information about the customer and the content of the message. */
  sender: Magento2_SendEmailToFriendSenderInput;
};

/** Contains information about the sender and recipients. */
export type Magento2_SendEmailToFriendOutput = {
  __typename?: 'Magento2_SendEmailToFriendOutput';
  /** An array containing information about each recipient. */
  recipients?: Maybe<Array<Maybe<Magento2_SendEmailToFriendRecipient>>>;
  /** Information about the customer and the content of the message. */
  sender?: Maybe<Magento2_SendEmailToFriendSender>;
};

/** An output object that contains information about the recipient. */
export type Magento2_SendEmailToFriendRecipient = {
  __typename?: 'Magento2_SendEmailToFriendRecipient';
  /** The email address of the recipient. */
  email: Scalars['String'];
  /** The name of the recipient. */
  name: Scalars['String'];
};

/** Contains details about a recipient. */
export type Magento2_SendEmailToFriendRecipientInput = {
  /** The email address of the recipient. */
  email: Scalars['String'];
  /** The name of the recipient. */
  name: Scalars['String'];
};

/** An output object that contains information about the sender. */
export type Magento2_SendEmailToFriendSender = {
  __typename?: 'Magento2_SendEmailToFriendSender';
  /** The email address of the sender. */
  email: Scalars['String'];
  /** The text of the message to be sent. */
  message: Scalars['String'];
  /** The name of the sender. */
  name: Scalars['String'];
};

/** Contains details about the sender. */
export type Magento2_SendEmailToFriendSenderInput = {
  /** The email address of the sender. */
  email: Scalars['String'];
  /** The text of the message to be sent. */
  message: Scalars['String'];
  /** The name of the sender. */
  name: Scalars['String'];
};

/** Contains details about the configuration of the Email to a Friend feature. */
export type Magento2_SendFriendConfiguration = {
  __typename?: 'Magento2_SendFriendConfiguration';
  /** Indicates whether the Email to a Friend feature is enabled. */
  enabledForCustomers: Scalars['Boolean'];
  /** Indicates whether the Email to a Friend feature is enabled for guests. */
  enabledForGuests: Scalars['Boolean'];
};

/** Sets the billing address. */
export type Magento2_SetBillingAddressOnCartInput = {
  /** The billing address. */
  billing_address: Magento2_BillingAddressInput;
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
};

/** Contains details about the cart after setting the billing address. */
export type Magento2_SetBillingAddressOnCartOutput = {
  __typename?: 'Magento2_SetBillingAddressOnCartOutput';
  /** The cart after setting the billing address. */
  cart: Magento2_Cart;
};

/** Defines the gift options applied to the cart. */
export type Magento2_SetGiftOptionsOnCartInput = {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
  /** Gift message details for the cart. */
  gift_message?: InputMaybe<Magento2_GiftMessageInput>;
  /** Whether customer requested gift receipt for the cart. */
  gift_receipt_included: Scalars['Boolean'];
  /** The unique ID for a `GiftWrapping` object to be used for the cart. */
  gift_wrapping_id?: InputMaybe<Scalars['ID']>;
  /** Whether customer requested printed card for the cart. */
  printed_card_included: Scalars['Boolean'];
};

/** Contains the cart after gift options have been applied. */
export type Magento2_SetGiftOptionsOnCartOutput = {
  __typename?: 'Magento2_SetGiftOptionsOnCartOutput';
  /** The modified cart object. */
  cart: Magento2_Cart;
};

/** Defines the guest email and cart. */
export type Magento2_SetGuestEmailOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The email address of the guest. */
  email: Scalars['String'];
};

/** Contains details about the cart after setting the email of a guest. */
export type Magento2_SetGuestEmailOnCartOutput = {
  __typename?: 'Magento2_SetGuestEmailOnCartOutput';
  /** The cart after setting the guest email. */
  cart: Magento2_Cart;
};

/** Applies a payment method to the quote. */
export type Magento2_SetPaymentMethodAndPlaceOrderInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The payment method data to apply to the cart. */
  payment_method: Magento2_PaymentMethodInput;
};

/** Applies a payment method to the cart. */
export type Magento2_SetPaymentMethodOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The payment method data to apply to the cart. */
  payment_method: Magento2_PaymentMethodInput;
};

/** Contains details about the cart after setting the payment method. */
export type Magento2_SetPaymentMethodOnCartOutput = {
  __typename?: 'Magento2_SetPaymentMethodOnCartOutput';
  /** The cart after setting the payment method. */
  cart: Magento2_Cart;
};

/** Specifies an array of addresses to use for shipping. */
export type Magento2_SetShippingAddressesOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of shipping addresses. */
  shipping_addresses: Array<InputMaybe<Magento2_ShippingAddressInput>>;
};

/** Contains details about the cart after setting the shipping addresses. */
export type Magento2_SetShippingAddressesOnCartOutput = {
  __typename?: 'Magento2_SetShippingAddressesOnCartOutput';
  /** The cart after setting the shipping addresses. */
  cart: Magento2_Cart;
};

/** Applies one or shipping methods to the cart. */
export type Magento2_SetShippingMethodsOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of shipping methods. */
  shipping_methods: Array<InputMaybe<Magento2_ShippingMethodInput>>;
};

/** Contains details about the cart after setting the shipping methods. */
export type Magento2_SetShippingMethodsOnCartOutput = {
  __typename?: 'Magento2_SetShippingMethodsOnCartOutput';
  /** The cart after setting the shipping methods. */
  cart: Magento2_Cart;
};

/** Defines a gift registry invitee. */
export type Magento2_ShareGiftRegistryInviteeInput = {
  /** The email address of the gift registry invitee. */
  email: Scalars['String'];
  /** The name of the gift registry invitee. */
  name: Scalars['String'];
};

/** Contains the results of a request to share a gift registry. */
export type Magento2_ShareGiftRegistryOutput = {
  __typename?: 'Magento2_ShareGiftRegistryOutput';
  /** Indicates whether the gift registry was successfully shared. */
  isShared: Scalars['Boolean'];
};

/** Defines the sender of an invitation to view a gift registry. */
export type Magento2_ShareGiftRegistrySenderInput = {
  /** A brief message from the sender. */
  message: Scalars['String'];
  /** The sender of the gift registry invitation. */
  name: Scalars['String'];
};

/** Defines whether bundle items must be shipped together. */
export enum Magento2_ShipBundleItemsEnum {
  /** @deprecated  */
  Separately = 'SEPARATELY',
  /** @deprecated  */
  Together = 'TOGETHER',
}

export type Magento2_ShipmentItem = Magento2_ShipmentItemInterface & {
  __typename?: 'Magento2_ShipmentItem';
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float'];
};

/** Order shipment item details. */
export type Magento2_ShipmentItemInterface = {
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  orderItem?: Maybe<Magento2_OrderItemInterface>;
  /** The name of the base product. */
  productName?: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  productSalePrice: Magento2_Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float'];
};

/** Contains order shipment tracking details. */
export type Magento2_ShipmentTracking = {
  __typename?: 'Magento2_ShipmentTracking';
  /** The shipping carrier for the order delivery. */
  carrier: Scalars['String'];
  /** The tracking number of the order shipment. */
  number?: Maybe<Scalars['String']>;
  /** The shipment tracking title. */
  title: Scalars['String'];
};

/** Defines a single shipping address. */
export type Magento2_ShippingAddressInput = {
  /** Defines a shipping address. */
  address?: InputMaybe<Magento2_CartAddressInput>;
  /** An ID from the customer's address book that uniquely identifies the address to be used for shipping. */
  customer_address_id?: InputMaybe<Scalars['Int']>;
  /** Text provided by the shopper. */
  customer_notes?: InputMaybe<Scalars['String']>;
  /** The code of Pickup Location which will be used for In-Store Pickup. */
  pickup_location_code?: InputMaybe<Scalars['String']>;
};

/** Contains shipping addresses and methods. */
export type Magento2_ShippingCartAddress = Magento2_CartAddressInterface & {
  __typename?: 'Magento2_ShippingCartAddress';
  /** An array that lists the shipping methods that can be applied to the cart. */
  availableShippingMethods?: Maybe<
    Array<Maybe<Magento2_AvailableShippingMethod>>
  >;
  /** @deprecated Use `cart_items_v2` instead. */
  cartItems?: Maybe<Array<Maybe<Magento2_CartItemQuantity>>>;
  /** An array that lists the items in the cart. */
  cartItemsV2?: Maybe<Array<Maybe<Magento2_CartItemInterface>>>;
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country label and code. */
  country: Magento2_CartAddressCountry;
  /** Text provided by the shopper. */
  customerNotes?: Maybe<Scalars['String']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** @deprecated This information should not be exposed on the frontend. */
  itemsWeight?: Maybe<Scalars['Float']>;
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  pickupLocationCode?: Maybe<Scalars['String']>;
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  /** An object containing the region label and code. */
  region?: Maybe<Magento2_CartAddressRegion>;
  /** An object that describes the selected shipping method. */
  selectedShippingMethod?: Maybe<Magento2_SelectedShippingMethod>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
  /** The unique id of the customer address. */
  uid: Scalars['String'];
  /** The VAT company number for billing or shipping address. */
  vatId?: Maybe<Scalars['String']>;
};

/** Defines an individual shipping discount. This discount can be applied to shipping. */
export type Magento2_ShippingDiscount = {
  __typename?: 'Magento2_ShippingDiscount';
  /** The amount of the discount. */
  amount: Magento2_Money;
};

/** Contains details about shipping and handling costs. */
export type Magento2_ShippingHandling = {
  __typename?: 'Magento2_ShippingHandling';
  /** The shipping amount, excluding tax. */
  amountExcludingTax?: Maybe<Magento2_Money>;
  /** The shipping amount, including tax. */
  amountIncludingTax?: Maybe<Magento2_Money>;
  /** The applied discounts to the shipping. */
  discounts?: Maybe<Array<Maybe<Magento2_ShippingDiscount>>>;
  /** Details about taxes applied for shipping. */
  taxes?: Maybe<Array<Maybe<Magento2_TaxItem>>>;
  /** The total amount for shipping. */
  totalAmount: Magento2_Money;
};

/** Defines the shipping carrier and method. */
export type Magento2_ShippingMethodInput = {
  /** A string that identifies a commercial carrier or an offline delivery method. */
  carrier_code: Scalars['String'];
  /** A string that indicates which service a commercial carrier will use to ship items. For offline delivery methods, this value is similar to the label displayed on the checkout page. */
  method_code: Scalars['String'];
};

/** An implementation for simple product cart items. */
export type Magento2_SimpleCartItem = Magento2_CartItemInterface & {
  __typename?: 'Magento2_SimpleCartItem';
  /** The list of available gift wrapping options for the cart item. */
  availableGiftWrapping: Array<Maybe<Magento2_GiftWrapping>>;
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<Magento2_CartItemError>>>;
  /** The entered gift message for the cart item */
  giftMessage?: Maybe<Magento2_GiftMessage>;
  /** The selected gift wrapping for the cart item. */
  giftWrapping?: Maybe<Magento2_GiftWrapping>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<Magento2_CartItemPrices>;
  /** Details about an item in the cart. */
  product: Magento2_ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type Magento2_SimpleProduct = Magento2_CustomizableProductInterface &
  Magento2_PhysicalProductInterface &
  Magento2_ProductInterface &
  Magento2_RoutableInterface & {
    __typename?: 'Magento2_SimpleProduct';
    /** Additional information about variant */
    additionalInfo?: Maybe<Scalars['String']>;
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    brand?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    bundleTotalPrice?: Maybe<Scalars['Float']>;
    /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
    canonicalUrl?: Maybe<Scalars['String']>;
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<Magento2_CategoryInterface>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    color?: Maybe<Scalars['Int']>;
    /** The product's country of origin. */
    countryOfManufacture?: Maybe<Scalars['String']>;
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt?: Maybe<Scalars['String']>;
    /** An array of cross-sell products. */
    crosssellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<Magento2_ComplexTextValue>;
    /** @deprecated Use the `custom_attributes` field instead. */
    detailPage?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    dimension?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    downloadPage?: Maybe<Scalars['String']>;
    /** Indicates whether a gift message is available. */
    giftMessageAvailable?: Maybe<Scalars['String']>;
    /** Hide qualify message */
    hideQualifyMessage?: Maybe<Scalars['Boolean']>;
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']>;
    /** The relative path to the main image on the product page. */
    image?: Maybe<Magento2_ProductImage>;
    /** Check default variant of configurable products. */
    isDefaultWithinAllVariants?: Maybe<Scalars['Boolean']>;
    /** Indicates whether the product can be returned. */
    isReturnable?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    itemType?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    label?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseClass?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseCode?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseType?: Maybe<Scalars['Int']>;
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer?: Maybe<Scalars['Int']>;
    /** An array of media gallery objects. */
    mediaGallery?: Maybe<Array<Maybe<Magento2_MediaGalleryInterface>>>;
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries?: Maybe<Array<Maybe<Magento2_MediaGalleryEntry>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    mediaPage?: Maybe<Scalars['String']>;
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription?: Maybe<Scalars['String']>;
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword?: Maybe<Scalars['String']>;
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle?: Maybe<Scalars['String']>;
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    nativeAccess?: Maybe<Scalars['Int']>;
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate?: Maybe<Scalars['String']>;
    /** The end date for new product listings. */
    newToDate?: Maybe<Scalars['String']>;
    /** Product stock only x left count */
    onlyXLeftInStock?: Maybe<Scalars['Float']>;
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<Magento2_CustomizableOptionInterface>>>;
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer?: Maybe<Scalars['String']>;
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price?: Maybe<Magento2_ProductPrices>;
    /** The range of prices for the product */
    priceRange: Magento2_PriceRange;
    /** An array of `TierPrice` objects. */
    priceTiers?: Maybe<Array<Maybe<Magento2_TierPrice>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    pricingPage?: Maybe<Scalars['String']>;
    /** An array of `ProductLinks` objects. */
    productLinks?: Maybe<Array<Maybe<Magento2_ProductLinksInterface>>>;
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float'];
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
    redirectCode: Scalars['Int'];
    /** An array of related products. */
    relatedProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    releaseDate?: Maybe<Scalars['String']>;
    /** Required Products Information about the product. The value can include simple HTML tags. */
    requiredProductsInformation?: Maybe<Magento2_ComplexTextValue>;
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int'];
    /** The list of products reviews. */
    reviews: Magento2_ProductReviews;
    /** @deprecated Use the `custom_attributes` field instead. */
    sdbsId?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    serialSystem?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    serialSystemReference?: Maybe<Scalars['String']>;
    /** A short description of the product. Its use depends on the theme. */
    shortDescription?: Maybe<Magento2_ComplexTextValue>;
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>;
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage?: Maybe<Magento2_ProductImage>;
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate?: Maybe<Scalars['String']>;
    /** The discounted price of the product. */
    specialPrice?: Maybe<Scalars['Float']>;
    /** The end date for a product with a special price. */
    specialToDate?: Maybe<Scalars['String']>;
    /** Indicates whether the product is staged for a future campaign. */
    staged: Scalars['Boolean'];
    /** Stock availability label. */
    stockAvailabilityLabel?: Maybe<Scalars['String']>;
    /** Stock status of the product */
    stockStatus?: Maybe<Magento2_ProductStockStatus>;
    /** The file name of a swatch image. */
    swatchImage?: Maybe<Scalars['String']>;
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<Magento2_ProductImage>;
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice?: Maybe<Scalars['Float']>;
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices?: Maybe<Array<Maybe<Magento2_ProductTierPrices>>>;
    /** Time to stock custom attribute. */
    timeToStock?: Maybe<Scalars['String']>;
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId?: Maybe<Scalars['String']>;
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID'];
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt?: Maybe<Scalars['String']>;
    /** An array of up-sell products. */
    upsellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** The part of the URL that identifies the product */
    urlKey?: Maybe<Scalars['String']>;
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath?: Maybe<Scalars['String']>;
    /** URL rewrites list */
    urlRewrites?: Maybe<Array<Maybe<Magento2_UrlRewrite>>>;
    /** The part of the product URL that is appended after the url key */
    urlSuffix?: Maybe<Scalars['String']>;
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Magento2_Website>>>;
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']>;
  };

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type Magento2_SimpleProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Defines a single product to add to the cart. */
export type Magento2_SimpleProductCartItemInput = {
  /** An array that defines customizable options for the product. */
  customizable_options?: InputMaybe<
    Array<InputMaybe<Magento2_CustomizableOptionInput>>
  >;
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: Magento2_CartItemInput;
};

/** Contains a simple product wish list item. */
export type Magento2_SimpleWishlistItem = Magento2_WishlistItemInterface & {
  __typename?: 'Magento2_SimpleWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<Magento2_ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

/** Indicates whether to return results in ascending or descending order. */
export enum Magento2_SortEnum {
  /** @deprecated  */
  Asc = 'ASC',
  /** @deprecated  */
  Desc = 'DESC',
}

/** Defines a possible sort field. */
export type Magento2_SortField = {
  __typename?: 'Magento2_SortField';
  /** The label of the sort field. */
  label?: Maybe<Scalars['String']>;
  /** The attribute code of the sort field. */
  value?: Maybe<Scalars['String']>;
};

/** Contains a default value for sort fields and all available sort fields. */
export type Magento2_SortFields = {
  __typename?: 'Magento2_SortFields';
  /** The default sort field value. */
  default?: Maybe<Scalars['String']>;
  /** An array of possible sort fields. */
  options?: Maybe<Array<Maybe<Magento2_SortField>>>;
};

/** Contains information about a store's configuration. */
export type Magento2_StoreConfig = {
  __typename?: 'Magento2_StoreConfig';
  /** Contains scripts that must be included in the HTML before the closing `<body>` tag. */
  absoluteFooter?: Maybe<Scalars['String']>;
  /** Indicates if the gift sender has the option to send a gift receipt. Possible values: 1 (Yes) and 0 (No). */
  allowGiftReceipt?: Maybe<Scalars['String']>;
  /** Indicates whether gift wrapping can be added for the entire order. Possible values: 1 (Yes) and 0 (No). */
  allowGiftWrappingOnOrder?: Maybe<Scalars['String']>;
  /** Indicates whether gift wrapping can be added for individual order items. Possible values: 1 (Yes) and 0 (No). */
  allowGiftWrappingOnOrderItems?: Maybe<Scalars['String']>;
  /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No). */
  allowGuestsToWriteProductReviews?: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages for Order Items option */
  allowItems?: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages on Order Level option */
  allowOrder?: Maybe<Scalars['String']>;
  /** Indicates if a printed card can accompany an order. Possible values: 1 (Yes) and 0 (No). */
  allowPrintedCard?: Maybe<Scalars['String']>;
  /** Indicates whether to enable autocomplete on login and forgot password forms. */
  autocompleteOnStorefront?: Maybe<Scalars['Boolean']>;
  /** The base currency code. */
  baseCurrencyCode?: Maybe<Scalars['String']>;
  /** A fully-qualified URL that is used to create relative links to the `base_url`. */
  baseLinkUrl?: Maybe<Scalars['String']>;
  /** The fully-qualified URL that specifies the location of media files. */
  baseMediaUrl?: Maybe<Scalars['String']>;
  /** The fully-qualified URL that specifies the location of static view files. */
  baseStaticUrl?: Maybe<Scalars['String']>;
  /** The store’s fully-qualified base URL. */
  baseUrl?: Maybe<Scalars['String']>;
  /** Indicates if gift wrapping prices are displayed on the Shopping Cart page. Possible values: 1 (Yes) and 0 (No). */
  cartGiftWrapping?: Maybe<Scalars['String']>;
  /** Indicates if printed card prices are displayed on the Shopping Cart page. Possible values: 1 (Yes) and 0 (No). */
  cartPrintedCard?: Maybe<Scalars['String']>;
  /** The default sort order of the search results list. */
  catalogDefaultSortBy?: Maybe<Scalars['String']>;
  /** Corresponds to the 'Display Prices In Product Lists' field in the Admin. It indicates how FPT information is displayed on category pages. */
  categoryFixedProductTaxDisplaySetting?: Maybe<Magento2_FixedProductTaxDisplaySettings>;
  /** The suffix applied to category pages, such as `.htm` or `.html`. */
  categoryUrlSuffix?: Maybe<Scalars['String']>;
  /** Indicates whether only specific countries can use this payment method. */
  checkMoneyOrderEnableForSpecificCountries?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the Check/Money Order payment method is enabled. */
  checkMoneyOrderEnabled?: Maybe<Scalars['Boolean']>;
  /** The name of the party to whom the check must be payable. */
  checkMoneyOrderMakeCheckPayableTo?: Maybe<Scalars['String']>;
  /** The maximum order amount required to qualify for the Check/Money Order payment method. */
  checkMoneyOrderMaxOrderTotal?: Maybe<Scalars['String']>;
  /** The minimum order amount required to qualify for the Check/Money Order payment method. */
  checkMoneyOrderMinOrderTotal?: Maybe<Scalars['String']>;
  /** The status of new orders placed using the Check/Money Order payment method. */
  checkMoneyOrderNewOrderStatus?: Maybe<Scalars['String']>;
  /** A comma-separated list of specific countries allowed to use the Check/Money Order payment method. */
  checkMoneyOrderPaymentFromSpecificCountries?: Maybe<Scalars['String']>;
  /** The full street address or PO Box where the checks are mailed. */
  checkMoneyOrderSendCheckTo?: Maybe<Scalars['String']>;
  /** A number indicating the position of the Check/Money Order payment method in the list of available payment methods during checkout. */
  checkMoneyOrderSortOrder?: Maybe<Scalars['Int']>;
  /** The title of the Check/Money Order payment method displayed on the storefront. */
  checkMoneyOrderTitle?: Maybe<Scalars['String']>;
  /** The name of the CMS page that identifies the home page for the store. */
  cmsHomePage?: Maybe<Scalars['String']>;
  /** A specific CMS page that displays when cookies are not enabled for the browser. */
  cmsNoCookies?: Maybe<Scalars['String']>;
  /** A specific CMS page that displays when a 404 'Page Not Found' error occurs. */
  cmsNoRoute?: Maybe<Scalars['String']>;
  /**
   * A code assigned to the store to identify it.
   * @deprecated Use `store_code` instead.
   */
  code?: Maybe<Scalars['String']>;
  /** Indicates whether the `parent` or child (`itself`) thumbnail should be used in the cart for configurable products. */
  configurableThumbnailSource?: Maybe<Scalars['String']>;
  /** The copyright statement that appears at the bottom of each page. */
  copyright?: Maybe<Scalars['String']>;
  /** Expose state requirement for countries */
  countriesRequireState?: Maybe<Scalars['String']>;
  /** Query by customer_create_account_validate_empty_vat. */
  customerCreateAccountValidateEmptyVat?: Maybe<Scalars['String']>;
  /** The description that provides a summary of your site for search engine listings. It should not be more than 160 characters in length. */
  defaultDescription?: Maybe<Scalars['String']>;
  /** The default display currency code. */
  defaultDisplayCurrencyCode?: Maybe<Scalars['String']>;
  /** A series of keywords that describe your store, each separated by a comma. */
  defaultKeywords?: Maybe<Scalars['String']>;
  /** The title that appears at the title bar of each page when viewed in a browser. */
  defaultTitle?: Maybe<Scalars['String']>;
  /** Controls the display of the demo store notice at the top of the page. Options: 0 (No) or 1 (Yes). */
  demonotice?: Maybe<Scalars['Int']>;
  /** Indicates whether customers can have multiple wish lists. Possible values: 1 (Yes) and 0 (No). */
  enableMultipleWishlists?: Maybe<Scalars['String']>;
  /** Display Free Shipping Countries */
  freeShippingCountries?: Maybe<Scalars['String']>;
  /** The landing page that is associated with the base URL. */
  front?: Maybe<Scalars['String']>;
  /** The default number of products per page in Grid View. */
  gridPerPage?: Maybe<Scalars['Int']>;
  /** A list of numbers that define how many products can be displayed in Grid View. */
  gridPerPageValues?: Maybe<Scalars['String']>;
  /** Scripts that must be included in the HTML before the closing `<head>` tag. */
  headIncludes?: Maybe<Scalars['String']>;
  /** The small graphic image (favicon) that appears in the address bar and tab of the browser. */
  headShortcutIcon?: Maybe<Scalars['String']>;
  /** The path to the logo that appears in the header. */
  headerLogoSrc?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the store.
   * @deprecated Use `store_code` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether the store view has been designated as the default within the store group. */
  isDefaultStore?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the store group has been designated as the default within the website. */
  isDefaultStoreGroup?: Maybe<Scalars['Boolean']>;
  /** The format of the search results list. */
  listMode?: Maybe<Scalars['String']>;
  /** The default number of products per page in List View. */
  listPerPage?: Maybe<Scalars['Int']>;
  /** A list of numbers that define how many products can be displayed in List View. */
  listPerPageValues?: Maybe<Scalars['String']>;
  /** The store locale. */
  locale?: Maybe<Scalars['String']>;
  /** The Alt text that is associated with the logo. */
  logoAlt?: Maybe<Scalars['String']>;
  /** The height of the logo image, in pixels. */
  logoHeight?: Maybe<Scalars['Int']>;
  /** The width of the logo image, in pixels. */
  logoWidth?: Maybe<Scalars['Int']>;
  /** Indicates whether wishlists are enabled (1) or disabled (0). */
  magentoWishlistGeneralIsEnabled?: Maybe<Scalars['String']>;
  /** If multiple wish lists are enabled, the maximum number of wish lists the customer can have. */
  maximumNumberOfWishlists?: Maybe<Scalars['String']>;
  /** The minimum number of characters required for a valid password. */
  minimumPasswordLength?: Maybe<Scalars['String']>;
  /** The default page that displays when a 404 'Page not Found' error occurs. */
  noRoute?: Maybe<Scalars['String']>;
  /** Payflow Pro vault status. */
  paymentPayflowproCcVaultActive?: Maybe<Scalars['String']>;
  /** The default price of a printed card that accompanies an order. */
  printedCardPrice?: Maybe<Scalars['String']>;
  /** Corresponds to the 'Display Prices On Product View Page' field in the Admin. It indicates how FPT information is displayed on product pages. */
  productFixedProductTaxDisplaySetting?: Maybe<Magento2_FixedProductTaxDisplaySettings>;
  /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No). */
  productReviewsEnabled?: Maybe<Scalars['String']>;
  /** The suffix applied to product pages, such as `.htm` or `.html`. */
  productUrlSuffix?: Maybe<Scalars['String']>;
  /** The number of different character classes (lowercase, uppercase, digits, special characters) required in a password. */
  requiredCharacterClassesNumber?: Maybe<Scalars['String']>;
  /** Indicates whether RMA is enabled on the storefront. Possible values: enabled/disabled. */
  returnsEnabled: Scalars['String'];
  /**
   * The ID of the root category.
   * @deprecated Use `root_category_uid` instead.
   */
  rootCategoryId?: Maybe<Scalars['Int']>;
  /** The unique ID for a `CategoryInterface` object. */
  rootCategoryUid?: Maybe<Scalars['ID']>;
  /** Corresponds to the 'Display Prices In Sales Modules' field in the Admin. It indicates how FPT information is displayed on cart, checkout, and order pages. */
  salesFixedProductTaxDisplaySetting?: Maybe<Magento2_FixedProductTaxDisplaySettings>;
  /** Indicates if gift wrapping prices are displayed on the Orders page. Possible values: 1 (Yes) and 0 (No). */
  salesGiftWrapping?: Maybe<Scalars['String']>;
  /** Indicates if printed card prices are displayed on the Orders page. Possible values: 1 (Yes) and 0 (No). */
  salesPrintedCard?: Maybe<Scalars['String']>;
  /** A secure fully-qualified URL that is used to create relative links to the `base_url`. */
  secureBaseLinkUrl?: Maybe<Scalars['String']>;
  /** The secure fully-qualified URL that specifies the location of media files. */
  secureBaseMediaUrl?: Maybe<Scalars['String']>;
  /** The secure fully-qualified URL that specifies the location of static view files. */
  secureBaseStaticUrl?: Maybe<Scalars['String']>;
  /** The store’s fully-qualified secure base URL. */
  secureBaseUrl?: Maybe<Scalars['String']>;
  /** Email to a Friend configuration. */
  sendFriend?: Maybe<Magento2_SendFriendConfiguration>;
  /** Indicates whether a breadcrumb trail appears on all CMS pages in the catalog. 0 (No) or 1 (Yes). */
  showCmsBreadcrumbs?: Maybe<Scalars['Int']>;
  /** The unique ID of the store view. In the Admin, this is called the Store View Code. When making a GraphQL call, assign this value to the `Store` header to provide the scope. */
  storeCode?: Maybe<Scalars['ID']>;
  /** The unique ID assigned to the store group. In the Admin, this is called the Store Name. */
  storeGroupCode?: Maybe<Scalars['ID']>;
  /** The label assigned to the store group. */
  storeGroupName?: Maybe<Scalars['String']>;
  /** The label assigned to the store view. */
  storeName?: Maybe<Scalars['String']>;
  /** The store view sort order. */
  storeSortOrder?: Maybe<Scalars['Int']>;
  /** Display Full Tax Summary */
  taxSalesDisplayFullSummary?: Maybe<Scalars['Boolean']>;
  /** Include Tax In Order Total */
  taxSalesDisplayGrandtotal?: Maybe<Scalars['String']>;
  /** Display Prices */
  taxSalesDisplayPrice?: Maybe<Scalars['String']>;
  /** Display Shipping Amount */
  taxSalesDisplayShipping?: Maybe<Scalars['String']>;
  /** Display Subtotal */
  taxSalesDisplaySubtotal?: Maybe<Scalars['String']>;
  /** Display Zero Tax Subtotal */
  taxSalesDisplayZeroTax?: Maybe<Scalars['Boolean']>;
  /** The time zone of the store. */
  timezone?: Maybe<Scalars['String']>;
  /** A prefix that appears before the title to create a two- or three-part title. */
  titlePrefix?: Maybe<Scalars['String']>;
  /** The character that separates the category name and subcategory in the browser title bar. */
  titleSeparator?: Maybe<Scalars['String']>;
  /** A suffix that appears after the title to create a two- or three-part title. */
  titleSuffix?: Maybe<Scalars['String']>;
  /** Indicates whether the store code should be used in the URL. */
  useStoreInUrl?: Maybe<Scalars['Boolean']>;
  /** The unique ID for the website. */
  websiteCode?: Maybe<Scalars['ID']>;
  /**
   * The ID number assigned to the website store.
   * @deprecated The field should not be used on the storefront.
   */
  websiteId?: Maybe<Scalars['Int']>;
  /** The label assigned to the website. */
  websiteName?: Maybe<Scalars['String']>;
  /** The unit of weight. */
  weightUnit?: Maybe<Scalars['String']>;
  /** Text that appears in the header of the page and includes the name of the logged in customer. */
  welcome?: Maybe<Scalars['String']>;
  /** Indicates whether only specific countries can use this payment method. */
  zeroSubtotalEnableForSpecificCountries?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the Zero Subtotal payment method is enabled. */
  zeroSubtotalEnabled?: Maybe<Scalars['Boolean']>;
  /** The status of new orders placed using the Zero Subtotal payment method. */
  zeroSubtotalNewOrderStatus?: Maybe<Scalars['String']>;
  /** When the new order status is 'Processing', this can be set to `authorize_capture` to automatically invoice all items that have a zero balance. */
  zeroSubtotalPaymentAction?: Maybe<Scalars['String']>;
  /** A comma-separated list of specific countries allowed to use the Zero Subtotal payment method. */
  zeroSubtotalPaymentFromSpecificCountries?: Maybe<Scalars['String']>;
  /** A number indicating the position of the Zero Subtotal payment method in the list of available payment methods during checkout. */
  zeroSubtotalSortOrder?: Maybe<Scalars['Int']>;
  /** The title of the Zero Subtotal payment method displayed on the storefront. */
  zeroSubtotalTitle?: Maybe<Scalars['String']>;
};

export type Magento2_StoreRecord = {
  __typename?: 'Magento2_StoreRecord';
  /** Get Store Language */
  language?: Maybe<Scalars['String']>;
  /** Get Store Code */
  storeCode?: Maybe<Scalars['String']>;
  /** Get Store Id */
  storeId?: Maybe<Scalars['String']>;
};

/** Indicates where an attribute can be displayed. */
export type Magento2_StorefrontProperties = {
  __typename?: 'Magento2_StorefrontProperties';
  /** The relative position of the attribute in the layered navigation block. */
  position?: Maybe<Scalars['Int']>;
  /** Indicates whether the attribute is filterable with results, without results, or not at all. */
  useInLayeredNavigation?: Maybe<Magento2_UseInLayeredNavigationOptions>;
  /** Indicates whether the attribute is displayed in product listings. */
  useInProductListing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the attribute can be used in layered navigation on search results pages. */
  useInSearchResultsLayeredNavigation?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the attribute is displayed on product pages. */
  visibleOnCatalogPages?: Maybe<Scalars['Boolean']>;
};

export type Magento2_StripeParams = {
  __typename?: 'Magento2_StripeParams';
  /** Button Settings */
  button?: Maybe<Scalars['String']>;
  /** Stripe Parameters */
  params?: Maybe<Scalars['String']>;
};

export type Magento2_StripePaymentIntentInput = {
  /** Payment Amount */
  amount: Scalars['Int'];
  /** Payment Currency */
  currency?: InputMaybe<Scalars['String']>;
};

export type Magento2_StripePaymentIntentOutput = {
  __typename?: 'Magento2_StripePaymentIntentOutput';
  /** Client Secret */
  clientSecret?: Maybe<Scalars['String']>;
  /** Stripe Payment Intent ID */
  piId: Scalars['String'];
};

export type Magento2_StripePaymentMethod = {
  __typename?: 'Magento2_StripePaymentMethod';
  /** Card brand */
  brand?: Maybe<Scalars['String']>;
  /** UNIX timestamp representing the date that the payment method was created. */
  created?: Maybe<Scalars['Int']>;
  /** Indicates whether this saved payment method requires a CVC token to be submitted when placing an order. */
  cvc?: Maybe<Scalars['Boolean']>;
  /** Card expiration month */
  expMonth?: Maybe<Scalars['Int']>;
  /** Card expiration year */
  expYear?: Maybe<Scalars['Int']>;
  /** A unique identifier for the card number, tax id, bank account etc. */
  fingerprint?: Maybe<Scalars['String']>;
  /** A payment method icon URL that can be used at the front-end. */
  icon?: Maybe<Scalars['String']>;
  /** Payment method ID */
  id: Scalars['ID'];
  /** A formatted payment method label that you can display to the customer. */
  label?: Maybe<Scalars['String']>;
  /** The type of the payment method, i.e. card, klarna, sepa_debit. */
  type?: Maybe<Scalars['String']>;
};

export type Magento2_StripePaymentMethodId = {
  /** When this is passed, the action will be performed on all duplicate payment methods which match the fingerprint. */
  fingerprint?: InputMaybe<Scalars['String']>;
  /** The ID of a payment method object */
  payment_method: Scalars['String'];
};

export type Magento2_StripePaymentsInput = {
  /** Deprecated: Stripe.js generated payment method token. This flow only supports tokens from CardElement and Stripe Elements. */
  cc_stripejs_token?: InputMaybe<Scalars['String']>;
  /** When CVC is enabled for saved cards, pass the CVC token here to perform the verification. */
  cvc_token?: InputMaybe<Scalars['String']>;
  /** Advanced usage: Comma separated list of payment method types for which you intend to perform manual customer authentication at the front-end. When set, the order will not be placed until the customer is manually authenticated. */
  manual_authentication?: InputMaybe<Scalars['String']>;
  /** Set this to true if you will be using stripe.handleNextAction(client_secret) after the order placement, which handles all alternative payment methods offered by the PaymentElement. */
  payment_element?: InputMaybe<Scalars['Boolean']>;
  /** If payment_element is true, pass the payment method id here instead of using cc_stripejs_token */
  payment_method?: InputMaybe<Scalars['String']>;
  /** Specify whether the payment method should be saved */
  save_payment_method?: InputMaybe<Scalars['Boolean']>;
};

export type Magento2_StripeProductInput = {
  /** Quantity */
  qty: Scalars['String'];
  /** Selected Config Option */
  selected_configurable_option?: InputMaybe<Scalars['String']>;
  /** Product SKU */
  sku: Scalars['String'];
};

/** Describes the swatch type and a value. */
export type Magento2_SwatchData = {
  __typename?: 'Magento2_SwatchData';
  /** The type of swatch filter item: 1 - text; 2 - image. */
  type?: Maybe<Scalars['String']>;
  /** The value for the swatch item. It could be text or an image link. */
  value?: Maybe<Scalars['String']>;
};

export type Magento2_SwatchDataInterface = {
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

export type Magento2_SwatchLayerFilterItem = Magento2_LayerFilterItemInterface &
  Magento2_SwatchLayerFilterItemInterface & {
    __typename?: 'Magento2_SwatchLayerFilterItem';
    /**
     * The count of items per filter.
     * @deprecated Use `AggregationOption.count` instead.
     */
    itemsCount?: Maybe<Scalars['Int']>;
    /**
     * The label for a filter.
     * @deprecated Use `AggregationOption.label` instead.
     */
    label?: Maybe<Scalars['String']>;
    /** Data required to render a swatch filter item. */
    swatchData?: Maybe<Magento2_SwatchData>;
    /**
     * The value of a filter request variable to be used in query.
     * @deprecated Use `AggregationOption.value` instead.
     */
    valueString?: Maybe<Scalars['String']>;
  };

export type Magento2_SwatchLayerFilterItemInterface = {
  /** Data required to render a swatch filter item. */
  swatchData?: Maybe<Magento2_SwatchData>;
};

/** Contains tax item details. */
export type Magento2_TaxItem = {
  __typename?: 'Magento2_TaxItem';
  /** The amount of tax applied to the item. */
  amount: Magento2_Money;
  /** The rate used to calculate the tax. */
  rate: Scalars['Float'];
  /** A title that describes the tax. */
  title: Scalars['String'];
};

export type Magento2_TextSwatchData = Magento2_SwatchDataInterface & {
  __typename?: 'Magento2_TextSwatchData';
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

/** Defines a price based on the quantity purchased. */
export type Magento2_TierPrice = {
  __typename?: 'Magento2_TierPrice';
  /** The price discount that this tier represents. */
  discount?: Maybe<Magento2_ProductDiscount>;
  /** The price of the product at this tier. */
  finalPrice?: Maybe<Magento2_Money>;
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity?: Maybe<Scalars['Float']>;
};

/** Modifies the specified items in the cart. */
export type Magento2_UpdateCartItemsInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of items to be updated. */
  cart_items: Array<InputMaybe<Magento2_CartItemUpdateInput>>;
};

/** Contains details about the cart after updating items. */
export type Magento2_UpdateCartItemsOutput = {
  __typename?: 'Magento2_UpdateCartItemsOutput';
  /** The cart after updating products. */
  cart: Magento2_Cart;
};

/** Defines updates to a `GiftRegistry` object. */
export type Magento2_UpdateGiftRegistryInput = {
  /** Additional attributes specified as a code-value pair. Unspecified dynamic attributes are not changed. */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<Magento2_GiftRegistryDynamicAttributeInput>>
  >;
  /** The updated name of the event. */
  event_name?: InputMaybe<Scalars['String']>;
  /** The updated message describing the event. */
  message?: InputMaybe<Scalars['String']>;
  /** Indicates whether the gift registry is PRIVATE or PUBLIC. */
  privacy_settings?: InputMaybe<Magento2_GiftRegistryPrivacySettings>;
  /** The updated shipping address for all gift registry items. */
  shipping_address?: InputMaybe<Magento2_GiftRegistryShippingAddressInput>;
  /** Indicates whether the gift registry is ACTIVE or INACTIVE. */
  status?: InputMaybe<Magento2_GiftRegistryStatus>;
};

/** Defines updates to an item in a gift registry. */
export type Magento2_UpdateGiftRegistryItemInput = {
  /** The unique ID of a `giftRegistryItem` object. */
  gift_registry_item_uid: Scalars['ID'];
  /** The updated description of the item. */
  note?: InputMaybe<Scalars['String']>;
  /** The updated quantity of the gift registry item. */
  quantity: Scalars['Float'];
};

/** Contains the results of a request to update gift registry items. */
export type Magento2_UpdateGiftRegistryItemsOutput = {
  __typename?: 'Magento2_UpdateGiftRegistryItemsOutput';
  /** The gift registry after updating updating items. */
  giftRegistry?: Maybe<Magento2_GiftRegistry>;
};

/** Contains the results of a request to update a gift registry. */
export type Magento2_UpdateGiftRegistryOutput = {
  __typename?: 'Magento2_UpdateGiftRegistryOutput';
  /** The updated gift registry. */
  giftRegistry?: Maybe<Magento2_GiftRegistry>;
};

/** Defines updates to an existing registrant. */
export type Magento2_UpdateGiftRegistryRegistrantInput = {
  /** As a result of the update, only the values of provided attributes will be affected. If the attribute is missing in the request, its value will not be changed. */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<Magento2_GiftRegistryDynamicAttributeInput>>
  >;
  /** The updated email address of the registrant. */
  email?: InputMaybe<Scalars['String']>;
  /** The updated first name of the registrant. */
  firstname?: InputMaybe<Scalars['String']>;
  /** The unique ID of a `giftRegistryRegistrant` object. */
  gift_registry_registrant_uid: Scalars['ID'];
  /** The updated last name of the registrant. */
  lastname?: InputMaybe<Scalars['String']>;
};

/** Contains the results a request to update registrants. */
export type Magento2_UpdateGiftRegistryRegistrantsOutput = {
  __typename?: 'Magento2_UpdateGiftRegistryRegistrantsOutput';
  /** The gift registry after updating registrants. */
  giftRegistry?: Maybe<Magento2_GiftRegistry>;
};

/** Contains the customer's wish list and any errors encountered. */
export type Magento2_UpdateProductsInWishlistOutput = {
  __typename?: 'Magento2_UpdateProductsInWishlistOutput';
  /** An array of errors encountered while updating products in a wish list. */
  userErrors: Array<Maybe<Magento2_WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully updated. */
  wishlist: Magento2_Wishlist;
};

/** Contains the name and visibility of an updated wish list. */
export type Magento2_UpdateWishlistOutput = {
  __typename?: 'Magento2_UpdateWishlistOutput';
  /** The wish list name. */
  name: Scalars['String'];
  /** The unique ID of a `Wishlist` object. */
  uid: Scalars['ID'];
  /** Indicates whether the wish list is public or private. */
  visibility: Magento2_WishlistVisibilityEnum;
};

/** Contains URL rewrite details. */
export type Magento2_UrlRewrite = {
  __typename?: 'Magento2_UrlRewrite';
  /** An array of request parameters. */
  parameters?: Maybe<Array<Maybe<Magento2_HttpQueryParameter>>>;
  /** The request URL. */
  url?: Maybe<Scalars['String']>;
};

/** This enumeration defines the entity type. */
export enum Magento2_UrlRewriteEntityTypeEnum {
  /** @deprecated  */
  Category = 'CATEGORY',
  /** @deprecated  */
  CmsPage = 'CMS_PAGE',
  /** @deprecated  */
  Product = 'PRODUCT',
}

/** Defines whether the attribute is filterable in layered navigation. */
export enum Magento2_UseInLayeredNavigationOptions {
  /** @deprecated  */
  FilterableNoResult = 'FILTERABLE_NO_RESULT',
  /** @deprecated  */
  FilterableWithResults = 'FILTERABLE_WITH_RESULTS',
  /** @deprecated  */
  No = 'NO',
}

/** return validation result */
export type Magento2_VatNumberValidationResult = {
  __typename?: 'Magento2_VatNumberValidationResult';
  /** Validation result */
  isValid?: Maybe<Scalars['Boolean']>;
  /** Request Date */
  requestDate?: Maybe<Scalars['String']>;
  /** Request Message */
  requestMessage?: Maybe<Scalars['String']>;
  /** Is request success? */
  requestSuccess?: Maybe<Scalars['Boolean']>;
};

/** Contains required input for payment methods with Vault support. */
export type Magento2_VaultTokenInput = {
  /** The public hash of the payment token. */
  public_hash: Scalars['String'];
};

/** An implementation for virtual product cart items. */
export type Magento2_VirtualCartItem = Magento2_CartItemInterface & {
  __typename?: 'Magento2_VirtualCartItem';
  /** An array containing customizable options the shopper selected. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<Magento2_CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<Magento2_CartItemPrices>;
  /** Details about an item in the cart. */
  product: Magento2_ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type Magento2_VirtualProduct = Magento2_CustomizableProductInterface &
  Magento2_ProductInterface &
  Magento2_RoutableInterface & {
    __typename?: 'Magento2_VirtualProduct';
    /** Additional information about variant */
    additionalInfo?: Maybe<Scalars['String']>;
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    brand?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    bundleTotalPrice?: Maybe<Scalars['Float']>;
    /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
    canonicalUrl?: Maybe<Scalars['String']>;
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<Magento2_CategoryInterface>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    color?: Maybe<Scalars['Int']>;
    /** The product's country of origin. */
    countryOfManufacture?: Maybe<Scalars['String']>;
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt?: Maybe<Scalars['String']>;
    /** An array of cross-sell products. */
    crosssellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<Magento2_ComplexTextValue>;
    /** @deprecated Use the `custom_attributes` field instead. */
    detailPage?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    dimension?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    downloadPage?: Maybe<Scalars['String']>;
    /** Indicates whether a gift message is available. */
    giftMessageAvailable?: Maybe<Scalars['String']>;
    /** Hide qualify message */
    hideQualifyMessage?: Maybe<Scalars['Boolean']>;
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']>;
    /** The relative path to the main image on the product page. */
    image?: Maybe<Magento2_ProductImage>;
    /** Check default variant of configurable products. */
    isDefaultWithinAllVariants?: Maybe<Scalars['Boolean']>;
    /** Indicates whether the product can be returned. */
    isReturnable?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    itemType?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    label?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseClass?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseCode?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    licenseType?: Maybe<Scalars['Int']>;
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer?: Maybe<Scalars['Int']>;
    /** An array of media gallery objects. */
    mediaGallery?: Maybe<Array<Maybe<Magento2_MediaGalleryInterface>>>;
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries?: Maybe<Array<Maybe<Magento2_MediaGalleryEntry>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    mediaPage?: Maybe<Scalars['String']>;
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription?: Maybe<Scalars['String']>;
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword?: Maybe<Scalars['String']>;
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle?: Maybe<Scalars['String']>;
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    nativeAccess?: Maybe<Scalars['Int']>;
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate?: Maybe<Scalars['String']>;
    /** The end date for new product listings. */
    newToDate?: Maybe<Scalars['String']>;
    /** Product stock only x left count */
    onlyXLeftInStock?: Maybe<Scalars['Float']>;
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<Magento2_CustomizableOptionInterface>>>;
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer?: Maybe<Scalars['String']>;
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price?: Maybe<Magento2_ProductPrices>;
    /** The range of prices for the product */
    priceRange: Magento2_PriceRange;
    /** An array of `TierPrice` objects. */
    priceTiers?: Maybe<Array<Maybe<Magento2_TierPrice>>>;
    /** @deprecated Use the `custom_attributes` field instead. */
    pricingPage?: Maybe<Scalars['String']>;
    /** An array of `ProductLinks` objects. */
    productLinks?: Maybe<Array<Maybe<Magento2_ProductLinksInterface>>>;
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float'];
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
    redirectCode: Scalars['Int'];
    /** An array of related products. */
    relatedProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    releaseDate?: Maybe<Scalars['String']>;
    /** Required Products Information about the product. The value can include simple HTML tags. */
    requiredProductsInformation?: Maybe<Magento2_ComplexTextValue>;
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int'];
    /** The list of products reviews. */
    reviews: Magento2_ProductReviews;
    /** @deprecated Use the `custom_attributes` field instead. */
    sdbsId?: Maybe<Scalars['String']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    serialSystem?: Maybe<Scalars['Int']>;
    /** @deprecated Use the `custom_attributes` field instead. */
    serialSystemReference?: Maybe<Scalars['String']>;
    /** A short description of the product. Its use depends on the theme. */
    shortDescription?: Maybe<Magento2_ComplexTextValue>;
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>;
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage?: Maybe<Magento2_ProductImage>;
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate?: Maybe<Scalars['String']>;
    /** The discounted price of the product. */
    specialPrice?: Maybe<Scalars['Float']>;
    /** The end date for a product with a special price. */
    specialToDate?: Maybe<Scalars['String']>;
    /** Indicates whether the product is staged for a future campaign. */
    staged: Scalars['Boolean'];
    /** Stock availability label. */
    stockAvailabilityLabel?: Maybe<Scalars['String']>;
    /** Stock status of the product */
    stockStatus?: Maybe<Magento2_ProductStockStatus>;
    /** The file name of a swatch image. */
    swatchImage?: Maybe<Scalars['String']>;
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<Magento2_ProductImage>;
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice?: Maybe<Scalars['Float']>;
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices?: Maybe<Array<Maybe<Magento2_ProductTierPrices>>>;
    /** Time to stock custom attribute. */
    timeToStock?: Maybe<Scalars['String']>;
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<Magento2_UrlRewriteEntityTypeEnum>;
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId?: Maybe<Scalars['String']>;
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID'];
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt?: Maybe<Scalars['String']>;
    /** An array of up-sell products. */
    upsellProducts?: Maybe<Array<Maybe<Magento2_ProductInterface>>>;
    /** The part of the URL that identifies the product */
    urlKey?: Maybe<Scalars['String']>;
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath?: Maybe<Scalars['String']>;
    /** URL rewrites list */
    urlRewrites?: Maybe<Array<Maybe<Magento2_UrlRewrite>>>;
    /** The part of the product URL that is appended after the url key */
    urlSuffix?: Maybe<Scalars['String']>;
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Magento2_Website>>>;
  };

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type Magento2_VirtualProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Defines a single product to add to the cart. */
export type Magento2_VirtualProductCartItemInput = {
  /** An array that defines customizable options for the product. */
  customizable_options?: InputMaybe<
    Array<InputMaybe<Magento2_CustomizableOptionInput>>
  >;
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: Magento2_CartItemInput;
};

/** Contains a virtual product wish list item. */
export type Magento2_VirtualWishlistItem = Magento2_WishlistItemInterface & {
  __typename?: 'Magento2_VirtualWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<Magento2_ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

/** Deprecated. It should not be used on the storefront. Contains information about a website. */
export type Magento2_Website = {
  __typename?: 'Magento2_Website';
  /**
   * A code assigned to the website to identify it.
   * @deprecated The field should not be used on the storefront.
   */
  code?: Maybe<Scalars['String']>;
  /**
   * The default group ID of the website.
   * @deprecated The field should not be used on the storefront.
   */
  defaultGroupId?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the website.
   * @deprecated The field should not be used on the storefront.
   */
  id?: Maybe<Scalars['Int']>;
  /**
   * Indicates whether this is the default website.
   * @deprecated The field should not be used on the storefront.
   */
  isDefault?: Maybe<Scalars['Boolean']>;
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The attribute to use for sorting websites.
   * @deprecated The field should not be used on the storefront.
   */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** An error encountered while performing operations with WishList. */
export type Magento2_WishListUserInputError = {
  __typename?: 'Magento2_WishListUserInputError';
  /** A wish list-specific error code. */
  code: Magento2_WishListUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
};

/** A list of possible error types. */
export enum Magento2_WishListUserInputErrorType {
  /** @deprecated  */
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  /** @deprecated  */
  Undefined = 'UNDEFINED',
}

/** Contains a customer wish list. */
export type Magento2_Wishlist = {
  __typename?: 'Magento2_Wishlist';
  /** The unique ID for a `Wishlist` object. */
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Use the `items_v2` field instead. */
  items?: Maybe<Array<Maybe<Magento2_WishlistItem>>>;
  /** The number of items in the wish list. */
  itemsCount?: Maybe<Scalars['Int']>;
  /** An array of items in the customer's wish list. */
  itemsV2?: Maybe<Magento2_WishlistItems>;
  /** The name of the wish list. */
  name?: Maybe<Scalars['String']>;
  /** An encrypted code that Magento uses to link to the wish list. */
  sharingCode?: Maybe<Scalars['String']>;
  /** The time of the last modification to the wish list. */
  updatedAt?: Maybe<Scalars['String']>;
  /** Indicates whether the wish list is public or private. */
  visibility: Magento2_WishlistVisibilityEnum;
};

/** Contains a customer wish list. */
export type Magento2_WishlistItemsV2Args = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Contains details about errors encountered when a customer added wish list items to the cart. */
export type Magento2_WishlistCartUserInputError = {
  __typename?: 'Magento2_WishlistCartUserInputError';
  /** An error code that describes the error encountered. */
  code: Magento2_WishlistCartUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
  /** The unique ID of the `Wishlist` object containing an error. */
  wishlistId: Scalars['ID'];
  /** The unique ID of the wish list item containing an error. */
  wishlistItemId: Scalars['ID'];
};

/** A list of possible error types. */
export enum Magento2_WishlistCartUserInputErrorType {
  /** @deprecated  */
  InsufficientStock = 'INSUFFICIENT_STOCK',
  /** @deprecated  */
  NotSalable = 'NOT_SALABLE',
  /** @deprecated  */
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  /** @deprecated  */
  Undefined = 'UNDEFINED',
}

/** Contains details about a wish list item. */
export type Magento2_WishlistItem = {
  __typename?: 'Magento2_WishlistItem';
  /** The time when the customer added the item to the wish list. */
  addedAt?: Maybe<Scalars['String']>;
  /** The customer's comment about this item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItem` object. */
  id?: Maybe<Scalars['Int']>;
  /** Details about the wish list item. */
  product?: Maybe<Magento2_ProductInterface>;
  /** The quantity of this wish list item */
  qty?: Maybe<Scalars['Float']>;
};

/** Specifies the IDs of items to copy and their quantities. */
export type Magento2_WishlistItemCopyInput = {
  /** The quantity of this item to copy to the destination wish list. This value can't be greater than the quantity in the source wish list. */
  quantity?: InputMaybe<Scalars['Float']>;
  /** The unique ID of the `WishlistItemInterface` object to be copied. */
  wishlist_item_id: Scalars['ID'];
};

/** Defines the items to add to a wish list. */
export type Magento2_WishlistItemInput = {
  /** An array of options that the customer entered. */
  entered_options?: InputMaybe<Array<InputMaybe<Magento2_EnteredOptionInput>>>;
  /** For complex product types, the SKU of the parent product. */
  parent_sku?: InputMaybe<Scalars['String']>;
  /** The amount or number of items to add. */
  quantity: Scalars['Float'];
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The SKU of the product to add. For complex product types, specify the child product SKU. */
  sku: Scalars['String'];
};

/** The interface for wish list items. */
export type Magento2_WishlistItemInterface = {
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<Magento2_SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<Magento2_ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

/** Specifies the IDs of the items to move and their quantities. */
export type Magento2_WishlistItemMoveInput = {
  /** The quantity of this item to move to the destination wish list. This value can't be greater than the quantity in the source wish list. */
  quantity?: InputMaybe<Scalars['Float']>;
  /** The unique ID of the `WishlistItemInterface` object to be moved. */
  wishlist_item_id: Scalars['ID'];
};

/** Defines updates to items in a wish list. */
export type Magento2_WishlistItemUpdateInput = {
  /** Customer-entered comments about the item. */
  description?: InputMaybe<Scalars['String']>;
  /** An array of options that the customer entered. */
  entered_options?: InputMaybe<Array<InputMaybe<Magento2_EnteredOptionInput>>>;
  /** The new amount or number of this item. */
  quantity?: InputMaybe<Scalars['Float']>;
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The unique ID for a `WishlistItemInterface` object. */
  wishlist_item_id: Scalars['ID'];
};

/** Contains an array of items in a wish list. */
export type Magento2_WishlistItems = {
  __typename?: 'Magento2_WishlistItems';
  /** A list of items in the wish list. */
  items: Array<Maybe<Magento2_WishlistItemInterface>>;
  /** Contains pagination metadata. */
  pageInfo?: Maybe<Magento2_SearchResultPageInfo>;
};

/** Deprecated: Use the `Wishlist` type instead. */
export type Magento2_WishlistOutput = {
  __typename?: 'Magento2_WishlistOutput';
  /**
   * An array of items in the customer's wish list
   * @deprecated Use the `Wishlist.items` field instead.
   */
  items?: Maybe<Array<Maybe<Magento2_WishlistItem>>>;
  /**
   * The number of items in the wish list.
   * @deprecated Use the `Wishlist.items_count` field instead.
   */
  itemsCount?: Maybe<Scalars['Int']>;
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist.
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * An encrypted code that links to the wish list.
   * @deprecated Use the `Wishlist.sharing_code` field instead.
   */
  sharingCode?: Maybe<Scalars['String']>;
  /**
   * The time of the last modification to the wish list.
   * @deprecated Use the `Wishlist.updated_at` field instead.
   */
  updatedAt?: Maybe<Scalars['String']>;
};

/** Defines the wish list visibility types. */
export enum Magento2_WishlistVisibilityEnum {
  /** @deprecated  */
  Private = 'PRIVATE',
  /** @deprecated  */
  Public = 'PUBLIC',
}

/** Assigns a specific `cart_id` to the empty cart. */
export type Magento2_CreateEmptyCartInput = {
  /** The ID to assign to the cart. */
  cart_id?: InputMaybe<Scalars['String']>;
};

export type Media = {
  __typename?: 'Media';
  chapters?: Maybe<Array<Maybe<MediaChapter>>>;
  description?: Maybe<Scalars['String']>;
  displayTime?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MediaChapter = Mp3Chapter | YouTubeChapter;

export type MenuFilter = {
  __typename?: 'MenuFilter';
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<MenuOption>>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MenuOption = {
  __typename?: 'MenuOption';
  children?: Maybe<Array<Maybe<MenuOption>>>;
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MultiSelectFilter = {
  __typename?: 'MultiSelectFilter';
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<MultiSelectOption>>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MultiSelectOption = {
  __typename?: 'MultiSelectOption';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addProductBySerialNumber?: Maybe<AddproductBySerialNumberOutput>;
  authorizeIlok?: Maybe<AuthorizeIlokOutput>;
  authorizeOffline?: Maybe<OfflineActivationOutput>;
  createDigitalRiverSession?: Maybe<DigitalRiverSessionOutput>;
  magento2?: Maybe<Magento2Mutation>;
  registerDemoDownload?: Maybe<RegisterDownloadOutput>;
  setSubscriptionRenewalType?: Maybe<SubscriptionRenewalTypeOutput>;
  subscribeToNewsletter?: Maybe<SubscribeToNewsletterOutput>;
  updateAccountDetails?: Maybe<AccountDetailsOutput>;
  updateSubscriptionStatus: SubscriptionItem;
  user?: Maybe<UserMutation>;
  /** This is a unique identifier representing the current version of the application */
  version?: Maybe<Scalars['String']>;
};

export type MutationAddProductBySerialNumberArgs = {
  serialNumber: Scalars['String'];
};

export type MutationAuthorizeIlokArgs = {
  ilokUserId: Scalars['String'];
  licenseId: Scalars['Int'];
  orderItemId: Scalars['Int'];
  productId: Scalars['Int'];
  purchaseId: Scalars['Int'];
  serialNumber: Scalars['String'];
};

export type MutationAuthorizeOfflineArgs = {
  challenge: Scalars['String'];
  licenseId: Scalars['Int'];
  orderItemId: Scalars['Int'];
  productId: Scalars['Int'];
  purchaseId: Scalars['Int'];
};

export type MutationRegisterDemoDownloadArgs = {
  date: Scalars['String'];
  ipAddress: Scalars['String'];
  productId: Scalars['Int'];
};

export type MutationSetSubscriptionRenewalTypeArgs = {
  autoRenewal: Scalars['Boolean'];
  subscriptionId: Scalars['String'];
};

export type MutationSubscribeToNewsletterArgs = {
  email: Scalars['String'];
  referralUrl: Scalars['String'];
  utmValues: Array<Scalars['String']>;
};

export type MutationUpdateAccountDetailsArgs = {
  companyName?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateSubscriptionStatusArgs = {
  cancellationReason?: InputMaybe<Scalars['String']>;
  extraComment?: InputMaybe<Scalars['String']>;
  status: SubscriptionUpdateStatuses;
  subscriptionId: Scalars['Int'];
};

export type MutationUserArgs = {
  language?: InputMaybe<Scalars['Language']>;
  testmode?: InputMaybe<Scalars['Boolean']>;
};

export type NinComparison = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Array<InputMaybe<Scalars['Value']>>>;
};

export type OfflineActivationOutput = {
  __typename?: 'OfflineActivationOutput';
  activationFileContent?: Maybe<Scalars['String']>;
  authDate?: Maybe<Scalars['String']>;
  challenge?: Maybe<Scalars['String']>;
  orderItemId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  items?: Maybe<Array<Maybe<OrderHistoryItem>>>;
  pageInfo?: Maybe<IzoPageInfo>;
};

export type OrderHistoryItem = {
  __typename?: 'OrderHistoryItem';
  billingAddress?: Maybe<IzoOrderAddress>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<OrderItem>>>;
  orderDate?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<IzoOrderAddress>;
  tax?: Maybe<Scalars['Float']>;
  totalPaid?: Maybe<Scalars['Float']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  totalHits?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type PartnerProductSerialNumberOutput = {
  __typename?: 'PartnerProductSerialNumberOutput';
  productId?: Maybe<Scalars['Int']>;
  serialNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Price = {
  __typename?: 'Price';
  currency?: Maybe<Scalars['String']>;
  diffPrice?: Maybe<Scalars['String']>;
  itemType?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  licenseId?: Maybe<Scalars['String']>;
  minPrice?: Maybe<Scalars['String']>;
  originalPrice?: Maybe<Scalars['String']>;
  personalized?: Maybe<Scalars['Boolean']>;
  priceEur?: Maybe<Scalars['String']>;
  savePrice?: Maybe<Scalars['String']>;
};

export type PricePoint = {
  __typename?: 'PricePoint';
  currency?: Maybe<Scalars['String']>;
  itemPriceId?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Int']>;
  periodUnit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  systemTitle?: Maybe<Scalars['String']>;
};

export type ProductBase = {
  __typename?: 'ProductBase';
  registrationDate?: Maybe<Scalars['String']>;
  serialnumber?: Maybe<Scalars['String']>;
  subscribed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  upid: Scalars['ID'];
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  bundle?: Maybe<BundlePrice>;
  main?: Maybe<Price>;
  min?: Maybe<Price>;
  sortPrice?: Maybe<Scalars['Float']>;
};

export type ProductSearchPayload = {
  __typename?: 'ProductSearchPayload';
  items: Array<FinderProduct>;
  pageInfo: PageInfo;
};

export type ProductSearchResult = {
  __typename?: 'ProductSearchResult';
  filters?: Maybe<Array<Maybe<Filter>>>;
  result: ProductSearchPayload;
};

export type ProductUpgrade = {
  __typename?: 'ProductUpgrade';
  current_product: Scalars['String'];
  included_products?: Maybe<IncludedProducts>;
  prices?: Maybe<ProductUpgradePrices>;
  sdbs_id: Scalars['Int'];
  sku: Scalars['String'];
};

export type ProductUpgradeIncluded_ProductsArgs = {
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortOrders?: InputMaybe<Array<SortOrder>>;
};

export type ProductUpgradePrices = {
  __typename?: 'ProductUpgradePrices';
  full_price: Magento2_RestApi_Money;
  savings_price: Magento2_RestApi_Money;
  upgrade_price: Magento2_RestApi_Money;
};

export type Query = {
  __typename?: 'Query';
  abVisitorId: Scalars['String'];
  accountDetails?: Maybe<AccountDetailsOutput>;
  availableSerialNumber?: Maybe<AvailableSerialNumberOutput>;
  content?: Maybe<Content>;
  deviceType: Scalars['String'];
  filters?: Maybe<Array<Maybe<FiltersConfigItem>>>;
  getProducts: ProductSearchResult;
  izotopeSubscriptions?: Maybe<Array<Maybe<IzotopeSubscription>>>;
  localizationCountry: Scalars['String'];
  magento2?: Maybe<Magento2>;
  media?: Maybe<Media>;
  myLoyaltyOffers?: Maybe<LoyaltyOffersOutput>;
  orderHistory?: Maybe<OrderHistory>;
  orderHistoryItem?: Maybe<OrderHistoryItem>;
  parterProductSerialNumber?: Maybe<PartnerProductSerialNumberOutput>;
  referrer: Scalars['String'];
  referrerHost: Scalars['String'];
  sortingValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  subscription?: Maybe<SubscriptionInfo>;
  translations?: Maybe<Array<Maybe<Translation>>>;
  user?: Maybe<User>;
  userLookup?: Maybe<IzoUser>;
  userProducts?: Maybe<UserProducts>;
  userV2?: Maybe<UserV2>;
  /** This is a unique identifier representing the current version of the application */
  version?: Maybe<Scalars['String']>;
};

export type QueryAvailableSerialNumberArgs = {
  productId: Scalars['Int'];
};

export type QueryGetProductsArgs = {
  condition?: InputMaybe<Condition>;
  country?: InputMaybe<Scalars['String']>;
  hitsPerPage?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['Language']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SortBy>;
};

export type QueryMediaArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryMyLoyaltyOffersArgs = {
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QueryOrderHistoryArgs = {
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QueryOrderHistoryItemArgs = {
  orderId: Scalars['ID'];
};

export type QueryParterProductSerialNumberArgs = {
  productId: Scalars['Int'];
};

export type QueryTranslationsArgs = {
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language: Scalars['Language'];
  section: Scalars['String'];
};

export type QueryUserArgs = {
  language?: InputMaybe<Scalars['Language']>;
  testmode?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUserLookupArgs = {
  email: Scalars['String'];
};

export type QueryUserProductsArgs = {
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type RangeFilter = {
  __typename?: 'RangeFilter';
  id?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  step?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RegisterDownloadOutput = {
  __typename?: 'RegisterDownloadOutput';
  date?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum SortBy {
  DateDesc = 'date_desc',
  PriceAsc = 'price_asc',
  PriceDesc = 'price_desc',
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortOrder = {
  direction: SortDirection;
  field: Scalars['String'];
};

export type SubscribeToNewsletterOutput = {
  __typename?: 'SubscribeToNewsletterOutput';
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  userId?: Maybe<Scalars['Int']>;
};

export type SubscriptionCancellationReason = {
  __typename?: 'SubscriptionCancellationReason';
  display_name: Scalars['String'];
  order_id: Scalars['Int'];
  reason_code: Scalars['String'];
};

export type SubscriptionInfo = {
  __typename?: 'SubscriptionInfo';
  bundles: Array<LicensedProduct>;
  cancellationReasons: Array<SubscriptionCancellationReason>;
  getSubscriptionBySdbsId?: Maybe<GetSubscriptionResult>;
  plans: Array<PricePoint>;
};

export type SubscriptionInfoBundlesArgs = {
  licensedProductIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type SubscriptionInfoGetSubscriptionBySdbsIdArgs = {
  id: Scalars['Int'];
};

export type SubscriptionInfoPlansArgs = {
  productId: Scalars['Int'];
};

export type SubscriptionItem = {
  __typename?: 'SubscriptionItem';
  cancellationScheduledAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['String']>;
  expiresIn?: Maybe<Scalars['Int']>;
  externalSubscriptionId?: Maybe<Scalars['ID']>;
  licensedProduct?: Maybe<LicensedProduct>;
  product?: Maybe<ProductBase>;
  startedAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};

export type SubscriptionRenewalTypeOutput = {
  __typename?: 'SubscriptionRenewalTypeOutput';
  subscriptionId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionTerm = {
  __typename?: 'SubscriptionTerm';
  termLength?: Maybe<Scalars['Int']>;
  termUnit?: Maybe<Scalars['String']>;
};

export enum SubscriptionUpdateStatuses {
  Active = 'active',
  Cancel = 'cancel',
}

export type TokenInfo = {
  __typename?: 'TokenInfo';
  expires: Scalars['Int'];
  issuedAt: Scalars['Int'];
};

/** A Translation represents a single string in particular language */
export type Translation = {
  __typename?: 'Translation';
  /** The unique key which identifies the string */
  key?: Maybe<Scalars['String']>;
  /** The translated string */
  value?: Maybe<Scalars['String']>;
};

export type UpgradePath = {
  __typename?: 'UpgradePath';
  license_type: Scalars['String'];
  licensedproduct_id: Scalars['Int'];
  title: Scalars['String'];
};

export type UpgradePathBase = {
  __typename?: 'UpgradePathBase';
  bundle: Scalars['Boolean'];
  license_class: Scalars['String'];
  license_type: Scalars['String'];
  licensedproduct_id: Scalars['Int'];
  registered: Scalars['Boolean'];
  title: Scalars['String'];
  upgrade_path: Array<Maybe<UpgradePath>>;
};

export type User = {
  __typename?: 'User';
  auth: Auth;
  country: Scalars['String'];
  customer: Customer;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isAuthenticated: Scalars['Boolean'];
  language?: Maybe<Scalars['Language']>;
  nativeId: Scalars['String'];
  numberOfUpgrades: Scalars['Int'];
  region?: Maybe<Scalars['String']>;
  registrations?: Maybe<Array<Maybe<Scalars['Int']>>>;
  shownUpgrades?: Maybe<Array<Maybe<Scalars['String']>>>;
  testmode?: Maybe<Scalars['Boolean']>;
  upgradeOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  upgradePaths: Array<Maybe<UpgradePathBase>>;
  upgrades?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserMutation = {
  __typename?: 'UserMutation';
  id?: Maybe<Scalars['ID']>;
  language?: Maybe<Scalars['Language']>;
  testmode?: Maybe<Scalars['Boolean']>;
};

export type UserProductItems = {
  __typename?: 'UserProductItems';
  challenge?: Maybe<Scalars['String']>;
  ilok: Scalars['Boolean'];
  licenseId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  offlineAuthorization: Scalars['Boolean'];
  orderItemId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  purchaseDate?: Maybe<Scalars['String']>;
  purchaseId?: Maybe<Scalars['Int']>;
  serialNumber?: Maybe<Scalars['String']>;
  serialSystemReference: Scalars['String'];
  tags?: Maybe<Scalars['String']>;
};

export type UserProducts = {
  __typename?: 'UserProducts';
  items?: Maybe<Array<Maybe<UserProductItems>>>;
  pageInfo?: Maybe<IzoPageInfo>;
};

export type UserV2 = {
  auth: AuthData;
};

export type YouTubeChapter = {
  __typename?: 'YouTubeChapter';
  description?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type Big_Int_Filter_Operators = {
  _between?: InputMaybe<Array<InputMaybe<Scalars['GraphQLBigInt']>>>;
  _eq?: InputMaybe<Scalars['GraphQLBigInt']>;
  _gt?: InputMaybe<Scalars['GraphQLBigInt']>;
  _gte?: InputMaybe<Scalars['GraphQLBigInt']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['GraphQLBigInt']>>>;
  _lt?: InputMaybe<Scalars['GraphQLBigInt']>;
  _lte?: InputMaybe<Scalars['GraphQLBigInt']>;
  _nbetween?: InputMaybe<Array<InputMaybe<Scalars['GraphQLBigInt']>>>;
  _neq?: InputMaybe<Scalars['GraphQLBigInt']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['GraphQLBigInt']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Boolean_Filter_Operators = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Catalog_Content_Pages = {
  __typename?: 'catalog_content_pages';
  featured_product_sections?: Maybe<
    Array<Maybe<Catalog_Content_Pages_Featured_Product_Sections>>
  >;
  featured_product_sections_func?: Maybe<Count_Functions>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Catalog_Content_PagesFeatured_Product_SectionsArgs = {
  filter?: InputMaybe<Catalog_Content_Pages_Featured_Product_Sections_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Catalog_Content_Pages_Aggregated = {
  __typename?: 'catalog_content_pages_aggregated';
  count?: Maybe<Catalog_Content_Pages_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Catalog_Content_Pages_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Catalog_Content_Pages_Aggregated_Count = {
  __typename?: 'catalog_content_pages_aggregated_count';
  featured_product_sections?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
};

export type Catalog_Content_Pages_Featured_Product_Sections = {
  __typename?: 'catalog_content_pages_featured_product_sections';
  catalog_content_pages_id?: Maybe<Catalog_Content_Pages>;
  featured_product_sections_id?: Maybe<Featured_Product_Sections>;
  id: Scalars['ID'];
  sort?: Maybe<Scalars['Int']>;
};

export type Catalog_Content_Pages_Featured_Product_SectionsCatalog_Content_Pages_IdArgs =
  {
    filter?: InputMaybe<Catalog_Content_Pages_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Catalog_Content_Pages_Featured_Product_SectionsFeatured_Product_Sections_IdArgs =
  {
    filter?: InputMaybe<Featured_Product_Sections_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Catalog_Content_Pages_Featured_Product_Sections_Aggregated = {
  __typename?: 'catalog_content_pages_featured_product_sections_aggregated';
  avg?: Maybe<Catalog_Content_Pages_Featured_Product_Sections_Aggregated_Fields>;
  avgDistinct?: Maybe<Catalog_Content_Pages_Featured_Product_Sections_Aggregated_Fields>;
  count?: Maybe<Catalog_Content_Pages_Featured_Product_Sections_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Catalog_Content_Pages_Featured_Product_Sections_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Catalog_Content_Pages_Featured_Product_Sections_Aggregated_Fields>;
  min?: Maybe<Catalog_Content_Pages_Featured_Product_Sections_Aggregated_Fields>;
  sum?: Maybe<Catalog_Content_Pages_Featured_Product_Sections_Aggregated_Fields>;
  sumDistinct?: Maybe<Catalog_Content_Pages_Featured_Product_Sections_Aggregated_Fields>;
};

export type Catalog_Content_Pages_Featured_Product_Sections_Aggregated_Count = {
  __typename?: 'catalog_content_pages_featured_product_sections_aggregated_count';
  catalog_content_pages_id?: Maybe<Scalars['Int']>;
  featured_product_sections_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Catalog_Content_Pages_Featured_Product_Sections_Aggregated_Fields =
  {
    __typename?: 'catalog_content_pages_featured_product_sections_aggregated_fields';
    sort?: Maybe<Scalars['Float']>;
  };

export type Catalog_Content_Pages_Featured_Product_Sections_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Catalog_Content_Pages_Featured_Product_Sections_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Catalog_Content_Pages_Featured_Product_Sections_Filter>>
  >;
  catalog_content_pages_id?: InputMaybe<Catalog_Content_Pages_Filter>;
  featured_product_sections_id?: InputMaybe<Featured_Product_Sections_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Catalog_Content_Pages_Featured_Product_Sections_Mutated = {
  __typename?: 'catalog_content_pages_featured_product_sections_mutated';
  data?: Maybe<Catalog_Content_Pages_Featured_Product_Sections>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Catalog_Content_Pages_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Content_Pages_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Content_Pages_Filter>>>;
  featured_product_sections?: InputMaybe<Catalog_Content_Pages_Featured_Product_Sections_Filter>;
  featured_product_sections_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Content_Pages_Mutated = {
  __typename?: 'catalog_content_pages_mutated';
  data?: Maybe<Catalog_Content_Pages>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Category_Banners = {
  __typename?: 'category_banners';
  background_desktop?: Maybe<Directus_Files>;
  background_mobile?: Maybe<Directus_Files>;
  countdown_date?: Maybe<Scalars['Date']>;
  countdown_date_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  text_alignement?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Category_Banners_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
};

export type Category_BannersBackground_DesktopArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Category_BannersBackground_MobileArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Category_BannersTranslationsArgs = {
  filter?: InputMaybe<Category_Banners_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Category_Banners_Aggregated = {
  __typename?: 'category_banners_aggregated';
  count?: Maybe<Category_Banners_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Category_Banners_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Category_Banners_Aggregated_Count = {
  __typename?: 'category_banners_aggregated_count';
  background_desktop?: Maybe<Scalars['Int']>;
  background_mobile?: Maybe<Scalars['Int']>;
  countdown_date?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  text_alignement?: Maybe<Scalars['Int']>;
  theme?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type Category_Banners_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Category_Banners_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Category_Banners_Filter>>>;
  background_desktop?: InputMaybe<Directus_Files_Filter>;
  background_mobile?: InputMaybe<Directus_Files_Filter>;
  countdown_date?: InputMaybe<Date_Filter_Operators>;
  countdown_date_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  text_alignement?: InputMaybe<String_Filter_Operators>;
  theme?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Category_Banners_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Category_Banners_Mutated = {
  __typename?: 'category_banners_mutated';
  data?: Maybe<Category_Banners>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Category_Banners_Translations = {
  __typename?: 'category_banners_translations';
  body?: Maybe<Scalars['String']>;
  category_banners_id?: Maybe<Category_Banners>;
  countdown_template?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  status?: Maybe<Scalars['String']>;
};

export type Category_Banners_TranslationsCategory_Banners_IdArgs = {
  filter?: InputMaybe<Category_Banners_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Category_Banners_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Category_Banners_Translations_Aggregated = {
  __typename?: 'category_banners_translations_aggregated';
  count?: Maybe<Category_Banners_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Category_Banners_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Category_Banners_Translations_Aggregated_Count = {
  __typename?: 'category_banners_translations_aggregated_count';
  body?: Maybe<Scalars['Int']>;
  category_banners_id?: Maybe<Scalars['Int']>;
  countdown_template?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type Category_Banners_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Category_Banners_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Category_Banners_Translations_Filter>>>;
  body?: InputMaybe<String_Filter_Operators>;
  category_banners_id?: InputMaybe<Category_Banners_Filter>;
  countdown_template?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
};

export type Category_Banners_Translations_Mutated = {
  __typename?: 'category_banners_translations_mutated';
  data?: Maybe<Category_Banners_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Content = {
  __typename?: 'content';
  catalog_content_pages: Array<Catalog_Content_Pages>;
  catalog_content_pages_aggregated: Array<Catalog_Content_Pages_Aggregated>;
  catalog_content_pages_by_id?: Maybe<Catalog_Content_Pages>;
  catalog_content_pages_by_version?: Maybe<Version_Catalog_Content_Pages>;
  catalog_content_pages_featured_product_sections: Array<Catalog_Content_Pages_Featured_Product_Sections>;
  catalog_content_pages_featured_product_sections_aggregated: Array<Catalog_Content_Pages_Featured_Product_Sections_Aggregated>;
  catalog_content_pages_featured_product_sections_by_id?: Maybe<Catalog_Content_Pages_Featured_Product_Sections>;
  catalog_content_pages_featured_product_sections_by_version?: Maybe<Version_Catalog_Content_Pages_Featured_Product_Sections>;
  category_banners: Array<Category_Banners>;
  category_banners_aggregated: Array<Category_Banners_Aggregated>;
  category_banners_by_id?: Maybe<Category_Banners>;
  category_banners_by_version?: Maybe<Version_Category_Banners>;
  category_banners_translations: Array<Category_Banners_Translations>;
  category_banners_translations_aggregated: Array<Category_Banners_Translations_Aggregated>;
  category_banners_translations_by_id?: Maybe<Category_Banners_Translations>;
  category_banners_translations_by_version?: Maybe<Version_Category_Banners_Translations>;
  content_elements_1col: Array<Content_Elements_1col>;
  content_elements_1col_aggregated: Array<Content_Elements_1col_Aggregated>;
  content_elements_1col_by_id?: Maybe<Content_Elements_1col>;
  content_elements_1col_by_version?: Maybe<Version_Content_Elements_1col>;
  content_elements_1col_translations: Array<Content_Elements_1col_Translations>;
  content_elements_1col_translations_aggregated: Array<Content_Elements_1col_Translations_Aggregated>;
  content_elements_1col_translations_by_id?: Maybe<Content_Elements_1col_Translations>;
  content_elements_1col_translations_by_version?: Maybe<Version_Content_Elements_1col_Translations>;
  content_elements_2_col: Array<Content_Elements_2_Col>;
  content_elements_2_col_aggregated: Array<Content_Elements_2_Col_Aggregated>;
  content_elements_2_col_by_id?: Maybe<Content_Elements_2_Col>;
  content_elements_2_col_by_version?: Maybe<Version_Content_Elements_2_Col>;
  content_elements_2_col_translations: Array<Content_Elements_2_Col_Translations>;
  content_elements_2_col_translations_aggregated: Array<Content_Elements_2_Col_Translations_Aggregated>;
  content_elements_2_col_translations_by_id?: Maybe<Content_Elements_2_Col_Translations>;
  content_elements_2_col_translations_by_version?: Maybe<Version_Content_Elements_2_Col_Translations>;
  content_elements_2col_grid: Array<Content_Elements_2col_Grid>;
  content_elements_2col_grid_aggregated: Array<Content_Elements_2col_Grid_Aggregated>;
  content_elements_2col_grid_by_id?: Maybe<Content_Elements_2col_Grid>;
  content_elements_2col_grid_by_version?: Maybe<Version_Content_Elements_2col_Grid>;
  content_elements_2col_grid_translations: Array<Content_Elements_2col_Grid_Translations>;
  content_elements_2col_grid_translations_aggregated: Array<Content_Elements_2col_Grid_Translations_Aggregated>;
  content_elements_2col_grid_translations_by_id?: Maybe<Content_Elements_2col_Grid_Translations>;
  content_elements_2col_grid_translations_by_version?: Maybe<Version_Content_Elements_2col_Grid_Translations>;
  cta_buttons: Array<Cta_Buttons>;
  cta_buttons_aggregated: Array<Cta_Buttons_Aggregated>;
  cta_buttons_by_id?: Maybe<Cta_Buttons>;
  cta_buttons_by_version?: Maybe<Version_Cta_Buttons>;
  cta_buttons_translations: Array<Cta_Buttons_Translations>;
  cta_buttons_translations_aggregated: Array<Cta_Buttons_Translations_Aggregated>;
  cta_buttons_translations_by_id?: Maybe<Cta_Buttons_Translations>;
  cta_buttons_translations_by_version?: Maybe<Version_Cta_Buttons_Translations>;
  featured_product_sections: Array<Featured_Product_Sections>;
  featured_product_sections_aggregated: Array<Featured_Product_Sections_Aggregated>;
  featured_product_sections_by_id?: Maybe<Featured_Product_Sections>;
  featured_product_sections_by_version?: Maybe<Version_Featured_Product_Sections>;
  featured_product_sections_cta_buttons: Array<Featured_Product_Sections_Cta_Buttons>;
  featured_product_sections_cta_buttons_aggregated: Array<Featured_Product_Sections_Cta_Buttons_Aggregated>;
  featured_product_sections_cta_buttons_by_id?: Maybe<Featured_Product_Sections_Cta_Buttons>;
  featured_product_sections_cta_buttons_by_version?: Maybe<Version_Featured_Product_Sections_Cta_Buttons>;
  featured_product_sections_products: Array<Featured_Product_Sections_Products>;
  featured_product_sections_products_aggregated: Array<Featured_Product_Sections_Products_Aggregated>;
  featured_product_sections_products_by_id?: Maybe<Featured_Product_Sections_Products>;
  featured_product_sections_products_by_version?: Maybe<Version_Featured_Product_Sections_Products>;
  featured_product_sections_translations: Array<Featured_Product_Sections_Translations>;
  featured_product_sections_translations_aggregated: Array<Featured_Product_Sections_Translations_Aggregated>;
  featured_product_sections_translations_by_id?: Maybe<Featured_Product_Sections_Translations>;
  featured_product_sections_translations_by_version?: Maybe<Version_Featured_Product_Sections_Translations>;
  languages: Array<Languages>;
  languages_aggregated: Array<Languages_Aggregated>;
  languages_by_id?: Maybe<Languages>;
  languages_by_version?: Maybe<Version_Languages>;
  menu: Array<Menu>;
  menu_aggregated: Array<Menu_Aggregated>;
  menu_by_id?: Maybe<Menu>;
  menu_by_version?: Maybe<Version_Menu>;
  menu_categories: Array<Menu_Categories>;
  menu_categories_aggregated: Array<Menu_Categories_Aggregated>;
  menu_categories_by_id?: Maybe<Menu_Categories>;
  menu_categories_by_version?: Maybe<Version_Menu_Categories>;
  menu_categories_content_elements: Array<Menu_Categories_Content_Elements>;
  menu_categories_content_elements_aggregated: Array<Menu_Categories_Content_Elements_Aggregated>;
  menu_categories_content_elements_by_id?: Maybe<Menu_Categories_Content_Elements>;
  menu_categories_content_elements_by_version?: Maybe<Version_Menu_Categories_Content_Elements>;
  menu_categories_menu_categories: Array<Menu_Categories_Menu_Categories>;
  menu_categories_menu_categories_aggregated: Array<Menu_Categories_Menu_Categories_Aggregated>;
  menu_categories_menu_categories_by_id?: Maybe<Menu_Categories_Menu_Categories>;
  menu_categories_menu_categories_by_version?: Maybe<Version_Menu_Categories_Menu_Categories>;
  menu_categories_translations: Array<Menu_Categories_Translations>;
  menu_categories_translations_aggregated: Array<Menu_Categories_Translations_Aggregated>;
  menu_categories_translations_by_id?: Maybe<Menu_Categories_Translations>;
  menu_categories_translations_by_version?: Maybe<Version_Menu_Categories_Translations>;
  menu_menu_categories: Array<Menu_Menu_Categories>;
  menu_menu_categories_aggregated: Array<Menu_Menu_Categories_Aggregated>;
  menu_menu_categories_by_id?: Maybe<Menu_Menu_Categories>;
  menu_menu_categories_by_version?: Maybe<Version_Menu_Menu_Categories>;
  pricing_page_multi_layout_links: Array<Pricing_Page_Multi_Layout_Links>;
  pricing_page_multi_layout_links_aggregated: Array<Pricing_Page_Multi_Layout_Links_Aggregated>;
  pricing_page_multi_layout_links_by_id?: Maybe<Pricing_Page_Multi_Layout_Links>;
  pricing_page_multi_layout_links_by_version?: Maybe<Version_Pricing_Page_Multi_Layout_Links>;
  pricing_page_multi_layout_links_translations: Array<Pricing_Page_Multi_Layout_Links_Translations>;
  pricing_page_multi_layout_links_translations_aggregated: Array<Pricing_Page_Multi_Layout_Links_Translations_Aggregated>;
  pricing_page_multi_layout_links_translations_by_id?: Maybe<Pricing_Page_Multi_Layout_Links_Translations>;
  pricing_page_multi_layout_links_translations_by_version?: Maybe<Version_Pricing_Page_Multi_Layout_Links_Translations>;
  pricing_pages: Array<Pricing_Pages>;
  pricing_pages_aggregated: Array<Pricing_Pages_Aggregated>;
  pricing_pages_by_id?: Maybe<Pricing_Pages>;
  pricing_pages_by_version?: Maybe<Version_Pricing_Pages>;
  pricing_pages_layout: Array<Pricing_Pages_Layout>;
  pricing_pages_layout_aggregated: Array<Pricing_Pages_Layout_Aggregated>;
  pricing_pages_layout_by_id?: Maybe<Pricing_Pages_Layout>;
  pricing_pages_layout_by_version?: Maybe<Version_Pricing_Pages_Layout>;
  pricing_pages_multi_layout: Array<Pricing_Pages_Multi_Layout>;
  pricing_pages_multi_layout_aggregated: Array<Pricing_Pages_Multi_Layout_Aggregated>;
  pricing_pages_multi_layout_by_id?: Maybe<Pricing_Pages_Multi_Layout>;
  pricing_pages_multi_layout_by_version?: Maybe<Version_Pricing_Pages_Multi_Layout>;
  pricing_pages_multi_layout_products: Array<Pricing_Pages_Multi_Layout_Products>;
  pricing_pages_multi_layout_products_aggregated: Array<Pricing_Pages_Multi_Layout_Products_Aggregated>;
  pricing_pages_multi_layout_products_by_id?: Maybe<Pricing_Pages_Multi_Layout_Products>;
  pricing_pages_multi_layout_products_by_version?: Maybe<Version_Pricing_Pages_Multi_Layout_Products>;
  pricing_pages_multi_layout_translations: Array<Pricing_Pages_Multi_Layout_Translations>;
  pricing_pages_multi_layout_translations_aggregated: Array<Pricing_Pages_Multi_Layout_Translations_Aggregated>;
  pricing_pages_multi_layout_translations_by_id?: Maybe<Pricing_Pages_Multi_Layout_Translations>;
  pricing_pages_multi_layout_translations_by_version?: Maybe<Version_Pricing_Pages_Multi_Layout_Translations>;
  pricing_pages_single_layout: Array<Pricing_Pages_Single_Layout>;
  pricing_pages_single_layout_aggregated: Array<Pricing_Pages_Single_Layout_Aggregated>;
  pricing_pages_single_layout_by_id?: Maybe<Pricing_Pages_Single_Layout>;
  pricing_pages_single_layout_by_version?: Maybe<Version_Pricing_Pages_Single_Layout>;
  pricing_pages_single_layout_translations: Array<Pricing_Pages_Single_Layout_Translations>;
  pricing_pages_single_layout_translations_aggregated: Array<Pricing_Pages_Single_Layout_Translations_Aggregated>;
  pricing_pages_single_layout_translations_by_id?: Maybe<Pricing_Pages_Single_Layout_Translations>;
  pricing_pages_single_layout_translations_by_version?: Maybe<Version_Pricing_Pages_Single_Layout_Translations>;
  pricing_pages_translations: Array<Pricing_Pages_Translations>;
  pricing_pages_translations_aggregated: Array<Pricing_Pages_Translations_Aggregated>;
  pricing_pages_translations_by_id?: Maybe<Pricing_Pages_Translations>;
  pricing_pages_translations_by_version?: Maybe<Version_Pricing_Pages_Translations>;
  products: Array<Products>;
  products_aggregated: Array<Products_Aggregated>;
  products_by_id?: Maybe<Products>;
  products_by_version?: Maybe<Version_Products>;
  products_links: Array<Products_Links>;
  products_links_aggregated: Array<Products_Links_Aggregated>;
  products_links_by_id?: Maybe<Products_Links>;
  products_links_by_version?: Maybe<Version_Products_Links>;
  products_links_translations: Array<Products_Links_Translations>;
  products_links_translations_aggregated: Array<Products_Links_Translations_Aggregated>;
  products_links_translations_by_id?: Maybe<Products_Links_Translations>;
  products_links_translations_by_version?: Maybe<Version_Products_Links_Translations>;
  products_products_links: Array<Products_Products_Links>;
  products_products_links_aggregated: Array<Products_Products_Links_Aggregated>;
  products_products_links_by_id?: Maybe<Products_Products_Links>;
  products_products_links_by_version?: Maybe<Version_Products_Products_Links>;
  products_system_requirements: Array<Products_System_Requirements>;
  products_system_requirements_aggregated: Array<Products_System_Requirements_Aggregated>;
  products_system_requirements_by_id?: Maybe<Products_System_Requirements>;
  products_system_requirements_by_version?: Maybe<Version_Products_System_Requirements>;
  products_translations: Array<Products_Translations>;
  products_translations_aggregated: Array<Products_Translations_Aggregated>;
  products_translations_by_id?: Maybe<Products_Translations>;
  products_translations_by_version?: Maybe<Version_Products_Translations>;
  products_translations_product_features: Array<Products_Translations_Product_Features>;
  products_translations_product_features_aggregated: Array<Products_Translations_Product_Features_Aggregated>;
  products_translations_product_features_by_id?: Maybe<Products_Translations_Product_Features>;
  products_translations_product_features_by_version?: Maybe<Version_Products_Translations_Product_Features>;
  products_translations_products_links: Array<Products_Translations_Products_Links>;
  products_translations_products_links_aggregated: Array<Products_Translations_Products_Links_Aggregated>;
  products_translations_products_links_by_id?: Maybe<Products_Translations_Products_Links>;
  products_translations_products_links_by_version?: Maybe<Version_Products_Translations_Products_Links>;
  required_base_products: Array<Required_Base_Products>;
  required_base_products_aggregated: Array<Required_Base_Products_Aggregated>;
  required_base_products_by_id?: Maybe<Required_Base_Products>;
  required_base_products_by_version?: Maybe<Version_Required_Base_Products>;
  required_base_products_translations: Array<Required_Base_Products_Translations>;
  required_base_products_translations_aggregated: Array<Required_Base_Products_Translations_Aggregated>;
  required_base_products_translations_by_id?: Maybe<Required_Base_Products_Translations>;
  required_base_products_translations_by_version?: Maybe<Version_Required_Base_Products_Translations>;
  system_requirements: Array<System_Requirements>;
  system_requirements_aggregated: Array<System_Requirements_Aggregated>;
  system_requirements_by_id?: Maybe<System_Requirements>;
  system_requirements_by_version?: Maybe<Version_System_Requirements>;
  system_requirements_translations: Array<System_Requirements_Translations>;
  system_requirements_translations_aggregated: Array<System_Requirements_Translations_Aggregated>;
  system_requirements_translations_by_id?: Maybe<System_Requirements_Translations>;
  system_requirements_translations_by_version?: Maybe<Version_System_Requirements_Translations>;
};

export type ContentCatalog_Content_PagesArgs = {
  filter?: InputMaybe<Catalog_Content_Pages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCatalog_Content_Pages_AggregatedArgs = {
  filter?: InputMaybe<Catalog_Content_Pages_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCatalog_Content_Pages_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentCatalog_Content_Pages_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentCatalog_Content_Pages_Featured_Product_SectionsArgs = {
  filter?: InputMaybe<Catalog_Content_Pages_Featured_Product_Sections_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCatalog_Content_Pages_Featured_Product_Sections_AggregatedArgs =
  {
    filter?: InputMaybe<Catalog_Content_Pages_Featured_Product_Sections_Filter>;
    groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type ContentCatalog_Content_Pages_Featured_Product_Sections_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentCatalog_Content_Pages_Featured_Product_Sections_By_VersionArgs =
  {
    id: Scalars['ID'];
    version: Scalars['String'];
  };

export type ContentCategory_BannersArgs = {
  filter?: InputMaybe<Category_Banners_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCategory_Banners_AggregatedArgs = {
  filter?: InputMaybe<Category_Banners_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCategory_Banners_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentCategory_Banners_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentCategory_Banners_TranslationsArgs = {
  filter?: InputMaybe<Category_Banners_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCategory_Banners_Translations_AggregatedArgs = {
  filter?: InputMaybe<Category_Banners_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCategory_Banners_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentCategory_Banners_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentContent_Elements_1colArgs = {
  filter?: InputMaybe<Content_Elements_1col_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_1col_AggregatedArgs = {
  filter?: InputMaybe<Content_Elements_1col_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_1col_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentContent_Elements_1col_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentContent_Elements_1col_TranslationsArgs = {
  filter?: InputMaybe<Content_Elements_1col_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_1col_Translations_AggregatedArgs = {
  filter?: InputMaybe<Content_Elements_1col_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_1col_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentContent_Elements_1col_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentContent_Elements_2_ColArgs = {
  filter?: InputMaybe<Content_Elements_2_Col_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_2_Col_AggregatedArgs = {
  filter?: InputMaybe<Content_Elements_2_Col_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_2_Col_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentContent_Elements_2_Col_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentContent_Elements_2_Col_TranslationsArgs = {
  filter?: InputMaybe<Content_Elements_2_Col_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_2_Col_Translations_AggregatedArgs = {
  filter?: InputMaybe<Content_Elements_2_Col_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_2_Col_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentContent_Elements_2_Col_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentContent_Elements_2col_GridArgs = {
  filter?: InputMaybe<Content_Elements_2col_Grid_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_2col_Grid_AggregatedArgs = {
  filter?: InputMaybe<Content_Elements_2col_Grid_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_2col_Grid_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentContent_Elements_2col_Grid_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentContent_Elements_2col_Grid_TranslationsArgs = {
  filter?: InputMaybe<Content_Elements_2col_Grid_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_2col_Grid_Translations_AggregatedArgs = {
  filter?: InputMaybe<Content_Elements_2col_Grid_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentContent_Elements_2col_Grid_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentContent_Elements_2col_Grid_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentCta_ButtonsArgs = {
  filter?: InputMaybe<Cta_Buttons_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCta_Buttons_AggregatedArgs = {
  filter?: InputMaybe<Cta_Buttons_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCta_Buttons_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentCta_Buttons_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentCta_Buttons_TranslationsArgs = {
  filter?: InputMaybe<Cta_Buttons_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCta_Buttons_Translations_AggregatedArgs = {
  filter?: InputMaybe<Cta_Buttons_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentCta_Buttons_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentCta_Buttons_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentFeatured_Product_SectionsArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentFeatured_Product_Sections_AggregatedArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentFeatured_Product_Sections_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentFeatured_Product_Sections_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentFeatured_Product_Sections_Cta_ButtonsArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Cta_Buttons_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentFeatured_Product_Sections_Cta_Buttons_AggregatedArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Cta_Buttons_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentFeatured_Product_Sections_Cta_Buttons_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentFeatured_Product_Sections_Cta_Buttons_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentFeatured_Product_Sections_ProductsArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentFeatured_Product_Sections_Products_AggregatedArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Products_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentFeatured_Product_Sections_Products_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentFeatured_Product_Sections_Products_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentFeatured_Product_Sections_TranslationsArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentFeatured_Product_Sections_Translations_AggregatedArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentFeatured_Product_Sections_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentFeatured_Product_Sections_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentLanguagesArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentLanguages_AggregatedArgs = {
  filter?: InputMaybe<Languages_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentLanguages_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentLanguages_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentMenuArgs = {
  filter?: InputMaybe<Menu_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_AggregatedArgs = {
  filter?: InputMaybe<Menu_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentMenu_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentMenu_CategoriesArgs = {
  filter?: InputMaybe<Menu_Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_Categories_AggregatedArgs = {
  filter?: InputMaybe<Menu_Categories_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_Categories_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentMenu_Categories_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentMenu_Categories_Content_ElementsArgs = {
  filter?: InputMaybe<Menu_Categories_Content_Elements_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_Categories_Content_Elements_AggregatedArgs = {
  filter?: InputMaybe<Menu_Categories_Content_Elements_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_Categories_Content_Elements_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentMenu_Categories_Content_Elements_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentMenu_Categories_Menu_CategoriesArgs = {
  filter?: InputMaybe<Menu_Categories_Menu_Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_Categories_Menu_Categories_AggregatedArgs = {
  filter?: InputMaybe<Menu_Categories_Menu_Categories_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_Categories_Menu_Categories_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentMenu_Categories_Menu_Categories_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentMenu_Categories_TranslationsArgs = {
  filter?: InputMaybe<Menu_Categories_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_Categories_Translations_AggregatedArgs = {
  filter?: InputMaybe<Menu_Categories_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_Categories_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentMenu_Categories_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentMenu_Menu_CategoriesArgs = {
  filter?: InputMaybe<Menu_Menu_Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_Menu_Categories_AggregatedArgs = {
  filter?: InputMaybe<Menu_Menu_Categories_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMenu_Menu_Categories_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentMenu_Menu_Categories_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentPricing_Page_Multi_Layout_LinksArgs = {
  filter?: InputMaybe<Pricing_Page_Multi_Layout_Links_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Page_Multi_Layout_Links_AggregatedArgs = {
  filter?: InputMaybe<Pricing_Page_Multi_Layout_Links_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Page_Multi_Layout_Links_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentPricing_Page_Multi_Layout_Links_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentPricing_Page_Multi_Layout_Links_TranslationsArgs = {
  filter?: InputMaybe<Pricing_Page_Multi_Layout_Links_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Page_Multi_Layout_Links_Translations_AggregatedArgs =
  {
    filter?: InputMaybe<Pricing_Page_Multi_Layout_Links_Translations_Filter>;
    groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type ContentPricing_Page_Multi_Layout_Links_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentPricing_Page_Multi_Layout_Links_Translations_By_VersionArgs =
  {
    id: Scalars['ID'];
    version: Scalars['String'];
  };

export type ContentPricing_PagesArgs = {
  filter?: InputMaybe<Pricing_Pages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_AggregatedArgs = {
  filter?: InputMaybe<Pricing_Pages_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentPricing_Pages_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentPricing_Pages_LayoutArgs = {
  filter?: InputMaybe<Pricing_Pages_Layout_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Layout_AggregatedArgs = {
  filter?: InputMaybe<Pricing_Pages_Layout_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Layout_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentPricing_Pages_Layout_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentPricing_Pages_Multi_LayoutArgs = {
  filter?: InputMaybe<Pricing_Pages_Multi_Layout_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Multi_Layout_AggregatedArgs = {
  filter?: InputMaybe<Pricing_Pages_Multi_Layout_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Multi_Layout_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentPricing_Pages_Multi_Layout_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentPricing_Pages_Multi_Layout_ProductsArgs = {
  filter?: InputMaybe<Pricing_Pages_Multi_Layout_Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Multi_Layout_Products_AggregatedArgs = {
  filter?: InputMaybe<Pricing_Pages_Multi_Layout_Products_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Multi_Layout_Products_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentPricing_Pages_Multi_Layout_Products_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentPricing_Pages_Multi_Layout_TranslationsArgs = {
  filter?: InputMaybe<Pricing_Pages_Multi_Layout_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Multi_Layout_Translations_AggregatedArgs = {
  filter?: InputMaybe<Pricing_Pages_Multi_Layout_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Multi_Layout_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentPricing_Pages_Multi_Layout_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentPricing_Pages_Single_LayoutArgs = {
  filter?: InputMaybe<Pricing_Pages_Single_Layout_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Single_Layout_AggregatedArgs = {
  filter?: InputMaybe<Pricing_Pages_Single_Layout_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Single_Layout_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentPricing_Pages_Single_Layout_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentPricing_Pages_Single_Layout_TranslationsArgs = {
  filter?: InputMaybe<Pricing_Pages_Single_Layout_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Single_Layout_Translations_AggregatedArgs = {
  filter?: InputMaybe<Pricing_Pages_Single_Layout_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Single_Layout_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentPricing_Pages_Single_Layout_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentPricing_Pages_TranslationsArgs = {
  filter?: InputMaybe<Pricing_Pages_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Translations_AggregatedArgs = {
  filter?: InputMaybe<Pricing_Pages_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentPricing_Pages_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentPricing_Pages_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentProductsArgs = {
  filter?: InputMaybe<Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_AggregatedArgs = {
  filter?: InputMaybe<Products_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentProducts_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentProducts_LinksArgs = {
  filter?: InputMaybe<Products_Links_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Links_AggregatedArgs = {
  filter?: InputMaybe<Products_Links_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Links_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentProducts_Links_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentProducts_Links_TranslationsArgs = {
  filter?: InputMaybe<Products_Links_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Links_Translations_AggregatedArgs = {
  filter?: InputMaybe<Products_Links_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Links_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentProducts_Links_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentProducts_Products_LinksArgs = {
  filter?: InputMaybe<Products_Products_Links_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Products_Links_AggregatedArgs = {
  filter?: InputMaybe<Products_Products_Links_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Products_Links_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentProducts_Products_Links_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentProducts_System_RequirementsArgs = {
  filter?: InputMaybe<Products_System_Requirements_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_System_Requirements_AggregatedArgs = {
  filter?: InputMaybe<Products_System_Requirements_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_System_Requirements_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentProducts_System_Requirements_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentProducts_TranslationsArgs = {
  filter?: InputMaybe<Products_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Translations_AggregatedArgs = {
  filter?: InputMaybe<Products_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentProducts_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentProducts_Translations_Product_FeaturesArgs = {
  filter?: InputMaybe<Products_Translations_Product_Features_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Translations_Product_Features_AggregatedArgs = {
  filter?: InputMaybe<Products_Translations_Product_Features_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Translations_Product_Features_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentProducts_Translations_Product_Features_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentProducts_Translations_Products_LinksArgs = {
  filter?: InputMaybe<Products_Translations_Products_Links_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Translations_Products_Links_AggregatedArgs = {
  filter?: InputMaybe<Products_Translations_Products_Links_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentProducts_Translations_Products_Links_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentProducts_Translations_Products_Links_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentRequired_Base_ProductsArgs = {
  filter?: InputMaybe<Required_Base_Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentRequired_Base_Products_AggregatedArgs = {
  filter?: InputMaybe<Required_Base_Products_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentRequired_Base_Products_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentRequired_Base_Products_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentRequired_Base_Products_TranslationsArgs = {
  filter?: InputMaybe<Required_Base_Products_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentRequired_Base_Products_Translations_AggregatedArgs = {
  filter?: InputMaybe<Required_Base_Products_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentRequired_Base_Products_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentRequired_Base_Products_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentSystem_RequirementsArgs = {
  filter?: InputMaybe<System_Requirements_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentSystem_Requirements_AggregatedArgs = {
  filter?: InputMaybe<System_Requirements_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentSystem_Requirements_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentSystem_Requirements_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type ContentSystem_Requirements_TranslationsArgs = {
  filter?: InputMaybe<System_Requirements_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentSystem_Requirements_Translations_AggregatedArgs = {
  filter?: InputMaybe<System_Requirements_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentSystem_Requirements_Translations_By_IdArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type ContentSystem_Requirements_Translations_By_VersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type Content_Elements_1col = {
  __typename?: 'content_elements_1col';
  background?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Directus_Files>;
  image_style?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  text_style?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Content_Elements_1col_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
};

export type Content_Elements_1colImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_1colTranslationsArgs = {
  filter?: InputMaybe<Content_Elements_1col_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_1col_Aggregated = {
  __typename?: 'content_elements_1col_aggregated';
  count?: Maybe<Content_Elements_1col_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Content_Elements_1col_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Content_Elements_1col_Aggregated_Count = {
  __typename?: 'content_elements_1col_aggregated_count';
  background?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Int']>;
  image_style?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  text_style?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type Content_Elements_1col_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Content_Elements_1col_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Content_Elements_1col_Filter>>>;
  background?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  image?: InputMaybe<Directus_Files_Filter>;
  image_style?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  text_style?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Content_Elements_1col_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Content_Elements_1col_Mutated = {
  __typename?: 'content_elements_1col_mutated';
  data?: Maybe<Content_Elements_1col>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Content_Elements_1col_Translations = {
  __typename?: 'content_elements_1col_translations';
  content?: Maybe<Scalars['String']>;
  content_elements_1col_id?: Maybe<Content_Elements_1col>;
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  status?: Maybe<Scalars['String']>;
};

export type Content_Elements_1col_TranslationsContent_Elements_1col_IdArgs = {
  filter?: InputMaybe<Content_Elements_1col_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_1col_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_1col_Translations_Aggregated = {
  __typename?: 'content_elements_1col_translations_aggregated';
  count?: Maybe<Content_Elements_1col_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Content_Elements_1col_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Content_Elements_1col_Translations_Aggregated_Count = {
  __typename?: 'content_elements_1col_translations_aggregated_count';
  content?: Maybe<Scalars['Int']>;
  content_elements_1col_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type Content_Elements_1col_Translations_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Content_Elements_1col_Translations_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Content_Elements_1col_Translations_Filter>>
  >;
  content?: InputMaybe<String_Filter_Operators>;
  content_elements_1col_id?: InputMaybe<Content_Elements_1col_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
};

export type Content_Elements_1col_Translations_Mutated = {
  __typename?: 'content_elements_1col_translations_mutated';
  data?: Maybe<Content_Elements_1col_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Content_Elements_2_Col = {
  __typename?: 'content_elements_2_col';
  background?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Directus_Files>;
  image_style?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  text_position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Content_Elements_2_Col_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
};

export type Content_Elements_2_ColImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_2_ColTranslationsArgs = {
  filter?: InputMaybe<Content_Elements_2_Col_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_2_Col_Aggregated = {
  __typename?: 'content_elements_2_col_aggregated';
  count?: Maybe<Content_Elements_2_Col_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Content_Elements_2_Col_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Content_Elements_2_Col_Aggregated_Count = {
  __typename?: 'content_elements_2_col_aggregated_count';
  background?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Int']>;
  image_style?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  text_position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type Content_Elements_2_Col_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Content_Elements_2_Col_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Content_Elements_2_Col_Filter>>>;
  background?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  image?: InputMaybe<Directus_Files_Filter>;
  image_style?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  text_position?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Content_Elements_2_Col_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Content_Elements_2_Col_Mutated = {
  __typename?: 'content_elements_2_col_mutated';
  data?: Maybe<Content_Elements_2_Col>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Content_Elements_2_Col_Translations = {
  __typename?: 'content_elements_2_col_translations';
  content?: Maybe<Scalars['String']>;
  content_elements_2_col_id?: Maybe<Content_Elements_2_Col>;
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  status?: Maybe<Scalars['String']>;
};

export type Content_Elements_2_Col_TranslationsContent_Elements_2_Col_IdArgs = {
  filter?: InputMaybe<Content_Elements_2_Col_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_2_Col_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_2_Col_Translations_Aggregated = {
  __typename?: 'content_elements_2_col_translations_aggregated';
  count?: Maybe<Content_Elements_2_Col_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Content_Elements_2_Col_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Content_Elements_2_Col_Translations_Aggregated_Count = {
  __typename?: 'content_elements_2_col_translations_aggregated_count';
  content?: Maybe<Scalars['Int']>;
  content_elements_2_col_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type Content_Elements_2_Col_Translations_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Content_Elements_2_Col_Translations_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Content_Elements_2_Col_Translations_Filter>>
  >;
  content?: InputMaybe<String_Filter_Operators>;
  content_elements_2_col_id?: InputMaybe<Content_Elements_2_Col_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
};

export type Content_Elements_2_Col_Translations_Mutated = {
  __typename?: 'content_elements_2_col_translations_mutated';
  data?: Maybe<Content_Elements_2_Col_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Content_Elements_2col_Grid = {
  __typename?: 'content_elements_2col_grid';
  background?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Directus_Files>;
  image_left?: Maybe<Directus_Files>;
  image_right?: Maybe<Directus_Files>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Content_Elements_2col_Grid_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
};

export type Content_Elements_2col_GridImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_2col_GridImage_LeftArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_2col_GridImage_RightArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_2col_GridTranslationsArgs = {
  filter?: InputMaybe<Content_Elements_2col_Grid_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_2col_Grid_Aggregated = {
  __typename?: 'content_elements_2col_grid_aggregated';
  count?: Maybe<Content_Elements_2col_Grid_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Content_Elements_2col_Grid_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Content_Elements_2col_Grid_Aggregated_Count = {
  __typename?: 'content_elements_2col_grid_aggregated_count';
  background?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Int']>;
  image_left?: Maybe<Scalars['Int']>;
  image_right?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type Content_Elements_2col_Grid_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Content_Elements_2col_Grid_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Content_Elements_2col_Grid_Filter>>>;
  background?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  image?: InputMaybe<Directus_Files_Filter>;
  image_left?: InputMaybe<Directus_Files_Filter>;
  image_right?: InputMaybe<Directus_Files_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Content_Elements_2col_Grid_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Content_Elements_2col_Grid_Mutated = {
  __typename?: 'content_elements_2col_grid_mutated';
  data?: Maybe<Content_Elements_2col_Grid>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Content_Elements_2col_Grid_Translations = {
  __typename?: 'content_elements_2col_grid_translations';
  content_elements_2col_grid_id?: Maybe<Content_Elements_2col_Grid>;
  content_left?: Maybe<Scalars['String']>;
  content_right?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  main_content?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Content_Elements_2col_Grid_TranslationsContent_Elements_2col_Grid_IdArgs =
  {
    filter?: InputMaybe<Content_Elements_2col_Grid_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Content_Elements_2col_Grid_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Content_Elements_2col_Grid_Translations_Aggregated = {
  __typename?: 'content_elements_2col_grid_translations_aggregated';
  count?: Maybe<Content_Elements_2col_Grid_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Content_Elements_2col_Grid_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Content_Elements_2col_Grid_Translations_Aggregated_Count = {
  __typename?: 'content_elements_2col_grid_translations_aggregated_count';
  content_elements_2col_grid_id?: Maybe<Scalars['Int']>;
  content_left?: Maybe<Scalars['Int']>;
  content_right?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  main_content?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type Content_Elements_2col_Grid_Translations_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Content_Elements_2col_Grid_Translations_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Content_Elements_2col_Grid_Translations_Filter>>
  >;
  content_elements_2col_grid_id?: InputMaybe<Content_Elements_2col_Grid_Filter>;
  content_left?: InputMaybe<String_Filter_Operators>;
  content_right?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  main_content?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
};

export type Content_Elements_2col_Grid_Translations_Mutated = {
  __typename?: 'content_elements_2col_grid_translations_mutated';
  data?: Maybe<Content_Elements_2col_Grid_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Count_Function_Filter_Operators = {
  count?: InputMaybe<Number_Filter_Operators>;
};

export type Count_Functions = {
  __typename?: 'count_functions';
  count?: Maybe<Scalars['Int']>;
};

export type Cta_Buttons = {
  __typename?: 'cta_buttons';
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Cta_Buttons_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
};

export type Cta_ButtonsTranslationsArgs = {
  filter?: InputMaybe<Cta_Buttons_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Cta_Buttons_Aggregated = {
  __typename?: 'cta_buttons_aggregated';
  count?: Maybe<Cta_Buttons_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Cta_Buttons_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Cta_Buttons_Aggregated_Count = {
  __typename?: 'cta_buttons_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type Cta_Buttons_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Cta_Buttons_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Cta_Buttons_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  link?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Cta_Buttons_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Cta_Buttons_Mutated = {
  __typename?: 'cta_buttons_mutated';
  data?: Maybe<Cta_Buttons>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Cta_Buttons_Translations = {
  __typename?: 'cta_buttons_translations';
  cta_buttons_id?: Maybe<Cta_Buttons>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  languages_code?: Maybe<Languages>;
};

export type Cta_Buttons_TranslationsCta_Buttons_IdArgs = {
  filter?: InputMaybe<Cta_Buttons_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Cta_Buttons_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Cta_Buttons_Translations_Aggregated = {
  __typename?: 'cta_buttons_translations_aggregated';
  count?: Maybe<Cta_Buttons_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Cta_Buttons_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Cta_Buttons_Translations_Aggregated_Count = {
  __typename?: 'cta_buttons_translations_aggregated_count';
  cta_buttons_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
};

export type Cta_Buttons_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Cta_Buttons_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Cta_Buttons_Translations_Filter>>>;
  cta_buttons_id?: InputMaybe<Cta_Buttons_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  label?: InputMaybe<String_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Cta_Buttons_Translations_Mutated = {
  __typename?: 'cta_buttons_translations_mutated';
  data?: Maybe<Cta_Buttons_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Date_Filter_Operators = {
  _between?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _nbetween?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _neq?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Datetime_Function_Filter_Operators = {
  day?: InputMaybe<Number_Filter_Operators>;
  hour?: InputMaybe<Number_Filter_Operators>;
  minute?: InputMaybe<Number_Filter_Operators>;
  month?: InputMaybe<Number_Filter_Operators>;
  second?: InputMaybe<Number_Filter_Operators>;
  week?: InputMaybe<Number_Filter_Operators>;
  weekday?: InputMaybe<Number_Filter_Operators>;
  year?: InputMaybe<Number_Filter_Operators>;
};

export type Datetime_Functions = {
  __typename?: 'datetime_functions';
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  weekday?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type Directus_Files = {
  __typename?: 'directus_files';
  charset?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  embed?: Maybe<Scalars['String']>;
  filename_disk?: Maybe<Scalars['String']>;
  filename_download: Scalars['String'];
  filesize?: Maybe<Scalars['GraphQLBigInt']>;
  focal_point_x?: Maybe<Scalars['Int']>;
  focal_point_y?: Maybe<Scalars['Int']>;
  folder?: Maybe<Scalars['String']>;
  full_url?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  metadata_func?: Maybe<Count_Functions>;
  modified_by?: Maybe<Scalars['String']>;
  modified_on?: Maybe<Scalars['Date']>;
  modified_on_func?: Maybe<Datetime_Functions>;
  storage: Scalars['String'];
  tags?: Maybe<Scalars['JSON']>;
  tags_func?: Maybe<Count_Functions>;
  title?: Maybe<Scalars['String']>;
  tus_data?: Maybe<Scalars['JSON']>;
  tus_data_func?: Maybe<Count_Functions>;
  tus_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploaded_by?: Maybe<Scalars['String']>;
  uploaded_on?: Maybe<Scalars['Date']>;
  uploaded_on_func?: Maybe<Datetime_Functions>;
  width?: Maybe<Scalars['Int']>;
};

export type Directus_Files_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Files_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Files_Filter>>>;
  charset?: InputMaybe<String_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  duration?: InputMaybe<Number_Filter_Operators>;
  embed?: InputMaybe<String_Filter_Operators>;
  filename_disk?: InputMaybe<String_Filter_Operators>;
  filename_download?: InputMaybe<String_Filter_Operators>;
  filesize?: InputMaybe<Big_Int_Filter_Operators>;
  focal_point_x?: InputMaybe<Number_Filter_Operators>;
  focal_point_y?: InputMaybe<Number_Filter_Operators>;
  folder?: InputMaybe<String_Filter_Operators>;
  height?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  location?: InputMaybe<String_Filter_Operators>;
  metadata?: InputMaybe<String_Filter_Operators>;
  metadata_func?: InputMaybe<Count_Function_Filter_Operators>;
  modified_by?: InputMaybe<String_Filter_Operators>;
  modified_on?: InputMaybe<Date_Filter_Operators>;
  modified_on_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  storage?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<String_Filter_Operators>;
  tags_func?: InputMaybe<Count_Function_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  tus_data?: InputMaybe<String_Filter_Operators>;
  tus_data_func?: InputMaybe<Count_Function_Filter_Operators>;
  tus_id?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  uploaded_by?: InputMaybe<String_Filter_Operators>;
  uploaded_on?: InputMaybe<Date_Filter_Operators>;
  uploaded_on_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  width?: InputMaybe<Number_Filter_Operators>;
};

export type Directus_Files_Mutated = {
  __typename?: 'directus_files_mutated';
  data?: Maybe<Directus_Files>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Featured_Product_Sections = {
  __typename?: 'featured_product_sections';
  content?: Maybe<Array<Maybe<Featured_Product_Sections_Translations>>>;
  content_func?: Maybe<Count_Functions>;
  cta_buttons?: Maybe<Array<Maybe<Featured_Product_Sections_Cta_Buttons>>>;
  cta_buttons_func?: Maybe<Count_Functions>;
  id: Scalars['ID'];
  products?: Maybe<Array<Maybe<Featured_Product_Sections_Products>>>;
  products_func?: Maybe<Count_Functions>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Featured_Product_SectionsContentArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Featured_Product_SectionsCta_ButtonsArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Cta_Buttons_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Featured_Product_SectionsProductsArgs = {
  filter?: InputMaybe<Featured_Product_Sections_Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Featured_Product_Sections_Aggregated = {
  __typename?: 'featured_product_sections_aggregated';
  count?: Maybe<Featured_Product_Sections_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Featured_Product_Sections_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Featured_Product_Sections_Aggregated_Count = {
  __typename?: 'featured_product_sections_aggregated_count';
  content?: Maybe<Scalars['Int']>;
  cta_buttons?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  products?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
};

export type Featured_Product_Sections_Cta_Buttons = {
  __typename?: 'featured_product_sections_cta_buttons';
  cta_buttons_id?: Maybe<Cta_Buttons>;
  featured_product_sections_id?: Maybe<Featured_Product_Sections>;
  id: Scalars['ID'];
  sort?: Maybe<Scalars['Int']>;
};

export type Featured_Product_Sections_Cta_ButtonsCta_Buttons_IdArgs = {
  filter?: InputMaybe<Cta_Buttons_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Featured_Product_Sections_Cta_ButtonsFeatured_Product_Sections_IdArgs =
  {
    filter?: InputMaybe<Featured_Product_Sections_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Featured_Product_Sections_Cta_Buttons_Aggregated = {
  __typename?: 'featured_product_sections_cta_buttons_aggregated';
  avg?: Maybe<Featured_Product_Sections_Cta_Buttons_Aggregated_Fields>;
  avgDistinct?: Maybe<Featured_Product_Sections_Cta_Buttons_Aggregated_Fields>;
  count?: Maybe<Featured_Product_Sections_Cta_Buttons_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Featured_Product_Sections_Cta_Buttons_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Featured_Product_Sections_Cta_Buttons_Aggregated_Fields>;
  min?: Maybe<Featured_Product_Sections_Cta_Buttons_Aggregated_Fields>;
  sum?: Maybe<Featured_Product_Sections_Cta_Buttons_Aggregated_Fields>;
  sumDistinct?: Maybe<Featured_Product_Sections_Cta_Buttons_Aggregated_Fields>;
};

export type Featured_Product_Sections_Cta_Buttons_Aggregated_Count = {
  __typename?: 'featured_product_sections_cta_buttons_aggregated_count';
  cta_buttons_id?: Maybe<Scalars['Int']>;
  featured_product_sections_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Featured_Product_Sections_Cta_Buttons_Aggregated_Fields = {
  __typename?: 'featured_product_sections_cta_buttons_aggregated_fields';
  sort?: Maybe<Scalars['Float']>;
};

export type Featured_Product_Sections_Cta_Buttons_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Featured_Product_Sections_Cta_Buttons_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Featured_Product_Sections_Cta_Buttons_Filter>>
  >;
  cta_buttons_id?: InputMaybe<Cta_Buttons_Filter>;
  featured_product_sections_id?: InputMaybe<Featured_Product_Sections_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Featured_Product_Sections_Cta_Buttons_Mutated = {
  __typename?: 'featured_product_sections_cta_buttons_mutated';
  data?: Maybe<Featured_Product_Sections_Cta_Buttons>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Featured_Product_Sections_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Featured_Product_Sections_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Featured_Product_Sections_Filter>>>;
  content?: InputMaybe<Featured_Product_Sections_Translations_Filter>;
  content_func?: InputMaybe<Count_Function_Filter_Operators>;
  cta_buttons?: InputMaybe<Featured_Product_Sections_Cta_Buttons_Filter>;
  cta_buttons_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  products?: InputMaybe<Featured_Product_Sections_Products_Filter>;
  products_func?: InputMaybe<Count_Function_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
};

export type Featured_Product_Sections_Mutated = {
  __typename?: 'featured_product_sections_mutated';
  data?: Maybe<Featured_Product_Sections>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Featured_Product_Sections_Products = {
  __typename?: 'featured_product_sections_products';
  featured_product_sections_id?: Maybe<Featured_Product_Sections>;
  id: Scalars['ID'];
  products_id?: Maybe<Products>;
  sort?: Maybe<Scalars['Int']>;
};

export type Featured_Product_Sections_ProductsFeatured_Product_Sections_IdArgs =
  {
    filter?: InputMaybe<Featured_Product_Sections_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Featured_Product_Sections_ProductsProducts_IdArgs = {
  filter?: InputMaybe<Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Featured_Product_Sections_Products_Aggregated = {
  __typename?: 'featured_product_sections_products_aggregated';
  avg?: Maybe<Featured_Product_Sections_Products_Aggregated_Fields>;
  avgDistinct?: Maybe<Featured_Product_Sections_Products_Aggregated_Fields>;
  count?: Maybe<Featured_Product_Sections_Products_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Featured_Product_Sections_Products_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Featured_Product_Sections_Products_Aggregated_Fields>;
  min?: Maybe<Featured_Product_Sections_Products_Aggregated_Fields>;
  sum?: Maybe<Featured_Product_Sections_Products_Aggregated_Fields>;
  sumDistinct?: Maybe<Featured_Product_Sections_Products_Aggregated_Fields>;
};

export type Featured_Product_Sections_Products_Aggregated_Count = {
  __typename?: 'featured_product_sections_products_aggregated_count';
  featured_product_sections_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  products_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Featured_Product_Sections_Products_Aggregated_Fields = {
  __typename?: 'featured_product_sections_products_aggregated_fields';
  products_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Featured_Product_Sections_Products_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Featured_Product_Sections_Products_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Featured_Product_Sections_Products_Filter>>
  >;
  featured_product_sections_id?: InputMaybe<Featured_Product_Sections_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  products_id?: InputMaybe<Products_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Featured_Product_Sections_Products_Mutated = {
  __typename?: 'featured_product_sections_products_mutated';
  data?: Maybe<Featured_Product_Sections_Products>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Featured_Product_Sections_Translations = {
  __typename?: 'featured_product_sections_translations';
  body?: Maybe<Scalars['String']>;
  featured_product_sections_id?: Maybe<Featured_Product_Sections>;
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
};

export type Featured_Product_Sections_TranslationsFeatured_Product_Sections_IdArgs =
  {
    filter?: InputMaybe<Featured_Product_Sections_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Featured_Product_Sections_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Featured_Product_Sections_Translations_Aggregated = {
  __typename?: 'featured_product_sections_translations_aggregated';
  count?: Maybe<Featured_Product_Sections_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Featured_Product_Sections_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Featured_Product_Sections_Translations_Aggregated_Count = {
  __typename?: 'featured_product_sections_translations_aggregated_count';
  body?: Maybe<Scalars['Int']>;
  featured_product_sections_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
};

export type Featured_Product_Sections_Translations_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Featured_Product_Sections_Translations_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Featured_Product_Sections_Translations_Filter>>
  >;
  body?: InputMaybe<String_Filter_Operators>;
  featured_product_sections_id?: InputMaybe<Featured_Product_Sections_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Featured_Product_Sections_Translations_Mutated = {
  __typename?: 'featured_product_sections_translations_mutated';
  data?: Maybe<Featured_Product_Sections_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Languages = {
  __typename?: 'languages';
  code: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Languages_Aggregated = {
  __typename?: 'languages_aggregated';
  avg?: Maybe<Languages_Aggregated_Fields>;
  avgDistinct?: Maybe<Languages_Aggregated_Fields>;
  count?: Maybe<Languages_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Languages_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Languages_Aggregated_Fields>;
  min?: Maybe<Languages_Aggregated_Fields>;
  sum?: Maybe<Languages_Aggregated_Fields>;
  sumDistinct?: Maybe<Languages_Aggregated_Fields>;
};

export type Languages_Aggregated_Count = {
  __typename?: 'languages_aggregated_count';
  code?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Languages_Aggregated_Fields = {
  __typename?: 'languages_aggregated_fields';
  sort?: Maybe<Scalars['Float']>;
};

export type Languages_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Languages_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Languages_Filter>>>;
  code?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Languages_Mutated = {
  __typename?: 'languages_mutated';
  data?: Maybe<Languages>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Menu = {
  __typename?: 'menu';
  id: Scalars['ID'];
  menu_categories?: Maybe<Array<Maybe<Menu_Menu_Categories>>>;
  menu_categories_func?: Maybe<Count_Functions>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type MenuMenu_CategoriesArgs = {
  filter?: InputMaybe<Menu_Menu_Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_Aggregated = {
  __typename?: 'menu_aggregated';
  count?: Maybe<Menu_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Menu_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Menu_Aggregated_Count = {
  __typename?: 'menu_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  menu_categories?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  variant?: Maybe<Scalars['Int']>;
};

export type Menu_Categories = {
  __typename?: 'menu_categories';
  category_banner?: Maybe<Category_Banners>;
  children?: Maybe<Array<Maybe<Menu_Categories_Menu_Categories>>>;
  children_func?: Maybe<Count_Functions>;
  content_elements?: Maybe<Array<Maybe<Menu_Categories_Content_Elements>>>;
  content_elements_func?: Maybe<Count_Functions>;
  content_page?: Maybe<Catalog_Content_Pages>;
  /** History Paths in format ["path1", "path2"] */
  history_paths?: Maybe<Scalars['JSON']>;
  history_paths_func?: Maybe<Count_Functions>;
  id: Scalars['ID'];
  is_protected?: Maybe<Scalars['Boolean']>;
  meta_image?: Maybe<Directus_Files>;
  meta_image_alt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['JSON']>;
  query_func?: Maybe<Count_Functions>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Menu_Categories_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
};

export type Menu_CategoriesCategory_BannerArgs = {
  filter?: InputMaybe<Category_Banners_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_CategoriesChildrenArgs = {
  filter?: InputMaybe<Menu_Categories_Menu_Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_CategoriesContent_ElementsArgs = {
  filter?: InputMaybe<Menu_Categories_Content_Elements_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_CategoriesContent_PageArgs = {
  filter?: InputMaybe<Catalog_Content_Pages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_CategoriesMeta_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_CategoriesTranslationsArgs = {
  filter?: InputMaybe<Menu_Categories_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_Categories_Aggregated = {
  __typename?: 'menu_categories_aggregated';
  count?: Maybe<Menu_Categories_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Menu_Categories_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Menu_Categories_Aggregated_Count = {
  __typename?: 'menu_categories_aggregated_count';
  category_banner?: Maybe<Scalars['Int']>;
  children?: Maybe<Scalars['Int']>;
  content_elements?: Maybe<Scalars['Int']>;
  content_page?: Maybe<Scalars['Int']>;
  /** History Paths in format ["path1", "path2"] */
  history_paths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_protected?: Maybe<Scalars['Int']>;
  meta_image?: Maybe<Scalars['Int']>;
  meta_image_alt?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type Menu_Categories_Content_Elements = {
  __typename?: 'menu_categories_content_elements';
  collection?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  item?: Maybe<Menu_Categories_Content_Elements_Item_Union>;
  menu_categories_id?: Maybe<Menu_Categories>;
  sort?: Maybe<Scalars['Int']>;
};

export type Menu_Categories_Content_ElementsMenu_Categories_IdArgs = {
  filter?: InputMaybe<Menu_Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_Categories_Content_Elements_Aggregated = {
  __typename?: 'menu_categories_content_elements_aggregated';
  avg?: Maybe<Menu_Categories_Content_Elements_Aggregated_Fields>;
  avgDistinct?: Maybe<Menu_Categories_Content_Elements_Aggregated_Fields>;
  count?: Maybe<Menu_Categories_Content_Elements_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Menu_Categories_Content_Elements_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Menu_Categories_Content_Elements_Aggregated_Fields>;
  min?: Maybe<Menu_Categories_Content_Elements_Aggregated_Fields>;
  sum?: Maybe<Menu_Categories_Content_Elements_Aggregated_Fields>;
  sumDistinct?: Maybe<Menu_Categories_Content_Elements_Aggregated_Fields>;
};

export type Menu_Categories_Content_Elements_Aggregated_Count = {
  __typename?: 'menu_categories_content_elements_aggregated_count';
  collection?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  item?: Maybe<Scalars['Int']>;
  menu_categories_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Menu_Categories_Content_Elements_Aggregated_Fields = {
  __typename?: 'menu_categories_content_elements_aggregated_fields';
  sort?: Maybe<Scalars['Float']>;
};

export type Menu_Categories_Content_Elements_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Menu_Categories_Content_Elements_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Menu_Categories_Content_Elements_Filter>>>;
  collection?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  item__content_elements_1col?: InputMaybe<Content_Elements_1col_Filter>;
  item__content_elements_2_col?: InputMaybe<Content_Elements_2_Col_Filter>;
  item__content_elements_2col_grid?: InputMaybe<Content_Elements_2col_Grid_Filter>;
  menu_categories_id?: InputMaybe<Menu_Categories_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Menu_Categories_Content_Elements_Item_Union =
  | Content_Elements_1col
  | Content_Elements_2_Col
  | Content_Elements_2col_Grid;

export type Menu_Categories_Content_Elements_Mutated = {
  __typename?: 'menu_categories_content_elements_mutated';
  data?: Maybe<Menu_Categories_Content_Elements>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Menu_Categories_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Menu_Categories_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Menu_Categories_Filter>>>;
  category_banner?: InputMaybe<Category_Banners_Filter>;
  children?: InputMaybe<Menu_Categories_Menu_Categories_Filter>;
  children_func?: InputMaybe<Count_Function_Filter_Operators>;
  content_elements?: InputMaybe<Menu_Categories_Content_Elements_Filter>;
  content_elements_func?: InputMaybe<Count_Function_Filter_Operators>;
  content_page?: InputMaybe<Catalog_Content_Pages_Filter>;
  history_paths?: InputMaybe<String_Filter_Operators>;
  history_paths_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  is_protected?: InputMaybe<Boolean_Filter_Operators>;
  meta_image?: InputMaybe<Directus_Files_Filter>;
  meta_image_alt?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  query?: InputMaybe<String_Filter_Operators>;
  query_func?: InputMaybe<Count_Function_Filter_Operators>;
  slug?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Menu_Categories_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Menu_Categories_Menu_Categories = {
  __typename?: 'menu_categories_menu_categories';
  category?: Maybe<Menu_Categories>;
  id: Scalars['ID'];
  parent?: Maybe<Menu_Categories>;
  sort?: Maybe<Scalars['Int']>;
};

export type Menu_Categories_Menu_CategoriesCategoryArgs = {
  filter?: InputMaybe<Menu_Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_Categories_Menu_CategoriesParentArgs = {
  filter?: InputMaybe<Menu_Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_Categories_Menu_Categories_Aggregated = {
  __typename?: 'menu_categories_menu_categories_aggregated';
  avg?: Maybe<Menu_Categories_Menu_Categories_Aggregated_Fields>;
  avgDistinct?: Maybe<Menu_Categories_Menu_Categories_Aggregated_Fields>;
  count?: Maybe<Menu_Categories_Menu_Categories_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Menu_Categories_Menu_Categories_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Menu_Categories_Menu_Categories_Aggregated_Fields>;
  min?: Maybe<Menu_Categories_Menu_Categories_Aggregated_Fields>;
  sum?: Maybe<Menu_Categories_Menu_Categories_Aggregated_Fields>;
  sumDistinct?: Maybe<Menu_Categories_Menu_Categories_Aggregated_Fields>;
};

export type Menu_Categories_Menu_Categories_Aggregated_Count = {
  __typename?: 'menu_categories_menu_categories_aggregated_count';
  category?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Menu_Categories_Menu_Categories_Aggregated_Fields = {
  __typename?: 'menu_categories_menu_categories_aggregated_fields';
  sort?: Maybe<Scalars['Float']>;
};

export type Menu_Categories_Menu_Categories_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Menu_Categories_Menu_Categories_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Menu_Categories_Menu_Categories_Filter>>>;
  category?: InputMaybe<Menu_Categories_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  parent?: InputMaybe<Menu_Categories_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Menu_Categories_Menu_Categories_Mutated = {
  __typename?: 'menu_categories_menu_categories_mutated';
  data?: Maybe<Menu_Categories_Menu_Categories>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Menu_Categories_Mutated = {
  __typename?: 'menu_categories_mutated';
  data?: Maybe<Menu_Categories>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Menu_Categories_Translations = {
  __typename?: 'menu_categories_translations';
  category?: Maybe<Menu_Categories>;
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Menu_Categories_TranslationsCategoryArgs = {
  filter?: InputMaybe<Menu_Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_Categories_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_Categories_Translations_Aggregated = {
  __typename?: 'menu_categories_translations_aggregated';
  count?: Maybe<Menu_Categories_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Menu_Categories_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
};

export type Menu_Categories_Translations_Aggregated_Count = {
  __typename?: 'menu_categories_translations_aggregated_count';
  category?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  meta_description?: Maybe<Scalars['Int']>;
  meta_title?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
};

export type Menu_Categories_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Menu_Categories_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Menu_Categories_Translations_Filter>>>;
  category?: InputMaybe<Menu_Categories_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  meta_description?: InputMaybe<String_Filter_Operators>;
  meta_title?: InputMaybe<String_Filter_Operators>;
  subtitle?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
};

export type Menu_Categories_Translations_Mutated = {
  __typename?: 'menu_categories_translations_mutated';
  data?: Maybe<Menu_Categories_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Menu_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Menu_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Menu_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  menu_categories?: InputMaybe<Menu_Menu_Categories_Filter>;
  menu_categories_func?: InputMaybe<Count_Function_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  variant?: InputMaybe<String_Filter_Operators>;
};

export type Menu_Menu_Categories = {
  __typename?: 'menu_menu_categories';
  category?: Maybe<Menu_Categories>;
  id: Scalars['ID'];
  menu_id?: Maybe<Menu>;
  sort?: Maybe<Scalars['Int']>;
};

export type Menu_Menu_CategoriesCategoryArgs = {
  filter?: InputMaybe<Menu_Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_Menu_CategoriesMenu_IdArgs = {
  filter?: InputMaybe<Menu_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Menu_Menu_Categories_Aggregated = {
  __typename?: 'menu_menu_categories_aggregated';
  avg?: Maybe<Menu_Menu_Categories_Aggregated_Fields>;
  avgDistinct?: Maybe<Menu_Menu_Categories_Aggregated_Fields>;
  count?: Maybe<Menu_Menu_Categories_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Menu_Menu_Categories_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Menu_Menu_Categories_Aggregated_Fields>;
  min?: Maybe<Menu_Menu_Categories_Aggregated_Fields>;
  sum?: Maybe<Menu_Menu_Categories_Aggregated_Fields>;
  sumDistinct?: Maybe<Menu_Menu_Categories_Aggregated_Fields>;
};

export type Menu_Menu_Categories_Aggregated_Count = {
  __typename?: 'menu_menu_categories_aggregated_count';
  category?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  menu_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Menu_Menu_Categories_Aggregated_Fields = {
  __typename?: 'menu_menu_categories_aggregated_fields';
  sort?: Maybe<Scalars['Float']>;
};

export type Menu_Menu_Categories_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Menu_Menu_Categories_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Menu_Menu_Categories_Filter>>>;
  category?: InputMaybe<Menu_Categories_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  menu_id?: InputMaybe<Menu_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Menu_Menu_Categories_Mutated = {
  __typename?: 'menu_menu_categories_mutated';
  data?: Maybe<Menu_Menu_Categories>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Menu_Mutated = {
  __typename?: 'menu_mutated';
  data?: Maybe<Menu>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Number_Filter_Operators = {
  _between?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _eq?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _gt?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _gte?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _lt?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _lte?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _nbetween?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _neq?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Pricing_Page_Multi_Layout_Links = {
  __typename?: 'pricing_page_multi_layout_links';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  pricing_page_multi_layout_id?: Maybe<Pricing_Pages_Multi_Layout>;
  sort?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  translations?: Maybe<
    Array<Maybe<Pricing_Page_Multi_Layout_Links_Translations>>
  >;
  translations_func?: Maybe<Count_Functions>;
};

export type Pricing_Page_Multi_Layout_LinksPricing_Page_Multi_Layout_IdArgs = {
  filter?: InputMaybe<Pricing_Pages_Multi_Layout_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Page_Multi_Layout_LinksTranslationsArgs = {
  filter?: InputMaybe<Pricing_Page_Multi_Layout_Links_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Page_Multi_Layout_Links_Aggregated = {
  __typename?: 'pricing_page_multi_layout_links_aggregated';
  avg?: Maybe<Pricing_Page_Multi_Layout_Links_Aggregated_Fields>;
  avgDistinct?: Maybe<Pricing_Page_Multi_Layout_Links_Aggregated_Fields>;
  count?: Maybe<Pricing_Page_Multi_Layout_Links_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Pricing_Page_Multi_Layout_Links_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Pricing_Page_Multi_Layout_Links_Aggregated_Fields>;
  min?: Maybe<Pricing_Page_Multi_Layout_Links_Aggregated_Fields>;
  sum?: Maybe<Pricing_Page_Multi_Layout_Links_Aggregated_Fields>;
  sumDistinct?: Maybe<Pricing_Page_Multi_Layout_Links_Aggregated_Fields>;
};

export type Pricing_Page_Multi_Layout_Links_Aggregated_Count = {
  __typename?: 'pricing_page_multi_layout_links_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  pricing_page_multi_layout_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type Pricing_Page_Multi_Layout_Links_Aggregated_Fields = {
  __typename?: 'pricing_page_multi_layout_links_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  pricing_page_multi_layout_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Pricing_Page_Multi_Layout_Links_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Pricing_Page_Multi_Layout_Links_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Pricing_Page_Multi_Layout_Links_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  pricing_page_multi_layout_id?: InputMaybe<Pricing_Pages_Multi_Layout_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Pricing_Page_Multi_Layout_Links_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Pricing_Page_Multi_Layout_Links_Mutated = {
  __typename?: 'pricing_page_multi_layout_links_mutated';
  data?: Maybe<Pricing_Page_Multi_Layout_Links>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Pricing_Page_Multi_Layout_Links_Translations = {
  __typename?: 'pricing_page_multi_layout_links_translations';
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  pricing_page_multi_layout_links_id?: Maybe<Pricing_Page_Multi_Layout_Links>;
  status?: Maybe<Scalars['String']>;
  /** the link's text to display */
  title?: Maybe<Scalars['String']>;
  /** a valid url, eg. https://www.example.com */
  url?: Maybe<Scalars['String']>;
};

export type Pricing_Page_Multi_Layout_Links_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Page_Multi_Layout_Links_TranslationsPricing_Page_Multi_Layout_Links_IdArgs =
  {
    filter?: InputMaybe<Pricing_Page_Multi_Layout_Links_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Pricing_Page_Multi_Layout_Links_Translations_Aggregated = {
  __typename?: 'pricing_page_multi_layout_links_translations_aggregated';
  avg?: Maybe<Pricing_Page_Multi_Layout_Links_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Pricing_Page_Multi_Layout_Links_Translations_Aggregated_Fields>;
  count?: Maybe<Pricing_Page_Multi_Layout_Links_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Pricing_Page_Multi_Layout_Links_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Pricing_Page_Multi_Layout_Links_Translations_Aggregated_Fields>;
  min?: Maybe<Pricing_Page_Multi_Layout_Links_Translations_Aggregated_Fields>;
  sum?: Maybe<Pricing_Page_Multi_Layout_Links_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Pricing_Page_Multi_Layout_Links_Translations_Aggregated_Fields>;
};

export type Pricing_Page_Multi_Layout_Links_Translations_Aggregated_Count = {
  __typename?: 'pricing_page_multi_layout_links_translations_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  pricing_page_multi_layout_links_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  /** the link's text to display */
  title?: Maybe<Scalars['Int']>;
  /** a valid url, eg. https://www.example.com */
  url?: Maybe<Scalars['Int']>;
};

export type Pricing_Page_Multi_Layout_Links_Translations_Aggregated_Fields = {
  __typename?: 'pricing_page_multi_layout_links_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  pricing_page_multi_layout_links_id?: Maybe<Scalars['Float']>;
};

export type Pricing_Page_Multi_Layout_Links_Translations_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Pricing_Page_Multi_Layout_Links_Translations_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Pricing_Page_Multi_Layout_Links_Translations_Filter>>
  >;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  pricing_page_multi_layout_links_id?: InputMaybe<Pricing_Page_Multi_Layout_Links_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  url?: InputMaybe<String_Filter_Operators>;
};

export type Pricing_Page_Multi_Layout_Links_Translations_Mutated = {
  __typename?: 'pricing_page_multi_layout_links_translations_mutated';
  data?: Maybe<Pricing_Page_Multi_Layout_Links_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Pricing_Pages = {
  __typename?: 'pricing_pages';
  /** History Paths in format ["path1", "path2"] */
  history_paths?: Maybe<Scalars['JSON']>;
  history_paths_func?: Maybe<Count_Functions>;
  id: Scalars['ID'];
  layout?: Maybe<Array<Maybe<Pricing_Pages_Layout>>>;
  layout_func?: Maybe<Count_Functions>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_color?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Pricing_Pages_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
  url_key: Scalars['String'];
};

export type Pricing_PagesLayoutArgs = {
  filter?: InputMaybe<Pricing_Pages_Layout_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_PagesTranslationsArgs = {
  filter?: InputMaybe<Pricing_Pages_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Aggregated = {
  __typename?: 'pricing_pages_aggregated';
  avg?: Maybe<Pricing_Pages_Aggregated_Fields>;
  avgDistinct?: Maybe<Pricing_Pages_Aggregated_Fields>;
  count?: Maybe<Pricing_Pages_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Pricing_Pages_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Pricing_Pages_Aggregated_Fields>;
  min?: Maybe<Pricing_Pages_Aggregated_Fields>;
  sum?: Maybe<Pricing_Pages_Aggregated_Fields>;
  sumDistinct?: Maybe<Pricing_Pages_Aggregated_Fields>;
};

export type Pricing_Pages_Aggregated_Count = {
  __typename?: 'pricing_pages_aggregated_count';
  /** History Paths in format ["path1", "path2"] */
  history_paths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** define the pricing page layout */
  layout?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  title_color?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
  url_key?: Maybe<Scalars['Int']>;
};

export type Pricing_Pages_Aggregated_Fields = {
  __typename?: 'pricing_pages_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Pricing_Pages_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Pricing_Pages_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Pricing_Pages_Filter>>>;
  history_paths?: InputMaybe<String_Filter_Operators>;
  history_paths_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  layout?: InputMaybe<Pricing_Pages_Layout_Filter>;
  layout_func?: InputMaybe<Count_Function_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  title_color?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Pricing_Pages_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
  url_key?: InputMaybe<String_Filter_Operators>;
};

export type Pricing_Pages_Layout = {
  __typename?: 'pricing_pages_layout';
  collection?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  item?: Maybe<Pricing_Pages_Layout_Item_Union>;
  pricing_pages_id?: Maybe<Pricing_Pages>;
};

export type Pricing_Pages_LayoutPricing_Pages_IdArgs = {
  filter?: InputMaybe<Pricing_Pages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Layout_Aggregated = {
  __typename?: 'pricing_pages_layout_aggregated';
  avg?: Maybe<Pricing_Pages_Layout_Aggregated_Fields>;
  avgDistinct?: Maybe<Pricing_Pages_Layout_Aggregated_Fields>;
  count?: Maybe<Pricing_Pages_Layout_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Pricing_Pages_Layout_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Pricing_Pages_Layout_Aggregated_Fields>;
  min?: Maybe<Pricing_Pages_Layout_Aggregated_Fields>;
  sum?: Maybe<Pricing_Pages_Layout_Aggregated_Fields>;
  sumDistinct?: Maybe<Pricing_Pages_Layout_Aggregated_Fields>;
};

export type Pricing_Pages_Layout_Aggregated_Count = {
  __typename?: 'pricing_pages_layout_aggregated_count';
  collection?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  item?: Maybe<Scalars['Int']>;
  pricing_pages_id?: Maybe<Scalars['Int']>;
};

export type Pricing_Pages_Layout_Aggregated_Fields = {
  __typename?: 'pricing_pages_layout_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  pricing_pages_id?: Maybe<Scalars['Float']>;
};

export type Pricing_Pages_Layout_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Pricing_Pages_Layout_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Pricing_Pages_Layout_Filter>>>;
  collection?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  item__pricing_pages_multi_layout?: InputMaybe<Pricing_Pages_Multi_Layout_Filter>;
  item__pricing_pages_single_layout?: InputMaybe<Pricing_Pages_Single_Layout_Filter>;
  pricing_pages_id?: InputMaybe<Pricing_Pages_Filter>;
};

export type Pricing_Pages_Layout_Item_Union =
  | Pricing_Pages_Multi_Layout
  | Pricing_Pages_Single_Layout;

export type Pricing_Pages_Layout_Mutated = {
  __typename?: 'pricing_pages_layout_mutated';
  data?: Maybe<Pricing_Pages_Layout>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Pricing_Pages_Multi_Layout = {
  __typename?: 'pricing_pages_multi_layout';
  /** Set the number of the columns in the grid layout */
  grid_columns?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  links?: Maybe<Array<Maybe<Pricing_Page_Multi_Layout_Links>>>;
  links_func?: Maybe<Count_Functions>;
  /** For internal use */
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Pricing_Pages_Multi_Layout_Products>>>;
  products_func?: Maybe<Count_Functions>;
  show_retailer_button: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Pricing_Pages_Multi_Layout_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
};

export type Pricing_Pages_Multi_LayoutLinksArgs = {
  filter?: InputMaybe<Pricing_Page_Multi_Layout_Links_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Multi_LayoutProductsArgs = {
  filter?: InputMaybe<Pricing_Pages_Multi_Layout_Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Multi_LayoutTranslationsArgs = {
  filter?: InputMaybe<Pricing_Pages_Multi_Layout_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Multi_Layout_Aggregated = {
  __typename?: 'pricing_pages_multi_layout_aggregated';
  avg?: Maybe<Pricing_Pages_Multi_Layout_Aggregated_Fields>;
  avgDistinct?: Maybe<Pricing_Pages_Multi_Layout_Aggregated_Fields>;
  count?: Maybe<Pricing_Pages_Multi_Layout_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Pricing_Pages_Multi_Layout_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Pricing_Pages_Multi_Layout_Aggregated_Fields>;
  min?: Maybe<Pricing_Pages_Multi_Layout_Aggregated_Fields>;
  sum?: Maybe<Pricing_Pages_Multi_Layout_Aggregated_Fields>;
  sumDistinct?: Maybe<Pricing_Pages_Multi_Layout_Aggregated_Fields>;
};

export type Pricing_Pages_Multi_Layout_Aggregated_Count = {
  __typename?: 'pricing_pages_multi_layout_aggregated_count';
  /** Set the number of the columns in the grid layout */
  grid_columns?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  links?: Maybe<Scalars['Int']>;
  /** For internal use */
  name?: Maybe<Scalars['Int']>;
  products?: Maybe<Scalars['Int']>;
  show_retailer_button?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type Pricing_Pages_Multi_Layout_Aggregated_Fields = {
  __typename?: 'pricing_pages_multi_layout_aggregated_fields';
  /** Set the number of the columns in the grid layout */
  grid_columns?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Pricing_Pages_Multi_Layout_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Pricing_Pages_Multi_Layout_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Pricing_Pages_Multi_Layout_Filter>>>;
  grid_columns?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  links?: InputMaybe<Pricing_Page_Multi_Layout_Links_Filter>;
  links_func?: InputMaybe<Count_Function_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  products?: InputMaybe<Pricing_Pages_Multi_Layout_Products_Filter>;
  products_func?: InputMaybe<Count_Function_Filter_Operators>;
  show_retailer_button?: InputMaybe<Boolean_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Pricing_Pages_Multi_Layout_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Pricing_Pages_Multi_Layout_Mutated = {
  __typename?: 'pricing_pages_multi_layout_mutated';
  data?: Maybe<Pricing_Pages_Multi_Layout>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Pricing_Pages_Multi_Layout_Products = {
  __typename?: 'pricing_pages_multi_layout_products';
  id: Scalars['ID'];
  pricing_pages_multi_layout_id?: Maybe<Pricing_Pages_Multi_Layout>;
  products_id?: Maybe<Products>;
  sort?: Maybe<Scalars['Int']>;
};

export type Pricing_Pages_Multi_Layout_ProductsPricing_Pages_Multi_Layout_IdArgs =
  {
    filter?: InputMaybe<Pricing_Pages_Multi_Layout_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Pricing_Pages_Multi_Layout_ProductsProducts_IdArgs = {
  filter?: InputMaybe<Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Multi_Layout_Products_Aggregated = {
  __typename?: 'pricing_pages_multi_layout_products_aggregated';
  avg?: Maybe<Pricing_Pages_Multi_Layout_Products_Aggregated_Fields>;
  avgDistinct?: Maybe<Pricing_Pages_Multi_Layout_Products_Aggregated_Fields>;
  count?: Maybe<Pricing_Pages_Multi_Layout_Products_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Pricing_Pages_Multi_Layout_Products_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Pricing_Pages_Multi_Layout_Products_Aggregated_Fields>;
  min?: Maybe<Pricing_Pages_Multi_Layout_Products_Aggregated_Fields>;
  sum?: Maybe<Pricing_Pages_Multi_Layout_Products_Aggregated_Fields>;
  sumDistinct?: Maybe<Pricing_Pages_Multi_Layout_Products_Aggregated_Fields>;
};

export type Pricing_Pages_Multi_Layout_Products_Aggregated_Count = {
  __typename?: 'pricing_pages_multi_layout_products_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  pricing_pages_multi_layout_id?: Maybe<Scalars['Int']>;
  products_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Pricing_Pages_Multi_Layout_Products_Aggregated_Fields = {
  __typename?: 'pricing_pages_multi_layout_products_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  pricing_pages_multi_layout_id?: Maybe<Scalars['Float']>;
  products_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Pricing_Pages_Multi_Layout_Products_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Pricing_Pages_Multi_Layout_Products_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Pricing_Pages_Multi_Layout_Products_Filter>>
  >;
  id?: InputMaybe<Number_Filter_Operators>;
  pricing_pages_multi_layout_id?: InputMaybe<Pricing_Pages_Multi_Layout_Filter>;
  products_id?: InputMaybe<Products_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Pricing_Pages_Multi_Layout_Products_Mutated = {
  __typename?: 'pricing_pages_multi_layout_products_mutated';
  data?: Maybe<Pricing_Pages_Multi_Layout_Products>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Pricing_Pages_Multi_Layout_Translations = {
  __typename?: 'pricing_pages_multi_layout_translations';
  content_bottom?: Maybe<Scalars['String']>;
  content_top?: Maybe<Scalars['String']>;
  extra_information?: Maybe<Scalars['String']>;
  find_dealer_text?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  pricing_pages_multi_layout_id?: Maybe<Pricing_Pages_Multi_Layout>;
};

export type Pricing_Pages_Multi_Layout_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Multi_Layout_TranslationsPricing_Pages_Multi_Layout_IdArgs =
  {
    filter?: InputMaybe<Pricing_Pages_Multi_Layout_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Pricing_Pages_Multi_Layout_Translations_Aggregated = {
  __typename?: 'pricing_pages_multi_layout_translations_aggregated';
  avg?: Maybe<Pricing_Pages_Multi_Layout_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Pricing_Pages_Multi_Layout_Translations_Aggregated_Fields>;
  count?: Maybe<Pricing_Pages_Multi_Layout_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Pricing_Pages_Multi_Layout_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Pricing_Pages_Multi_Layout_Translations_Aggregated_Fields>;
  min?: Maybe<Pricing_Pages_Multi_Layout_Translations_Aggregated_Fields>;
  sum?: Maybe<Pricing_Pages_Multi_Layout_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Pricing_Pages_Multi_Layout_Translations_Aggregated_Fields>;
};

export type Pricing_Pages_Multi_Layout_Translations_Aggregated_Count = {
  __typename?: 'pricing_pages_multi_layout_translations_aggregated_count';
  content_bottom?: Maybe<Scalars['Int']>;
  content_top?: Maybe<Scalars['Int']>;
  extra_information?: Maybe<Scalars['Int']>;
  find_dealer_text?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  pricing_pages_multi_layout_id?: Maybe<Scalars['Int']>;
};

export type Pricing_Pages_Multi_Layout_Translations_Aggregated_Fields = {
  __typename?: 'pricing_pages_multi_layout_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  pricing_pages_multi_layout_id?: Maybe<Scalars['Float']>;
};

export type Pricing_Pages_Multi_Layout_Translations_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Pricing_Pages_Multi_Layout_Translations_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Pricing_Pages_Multi_Layout_Translations_Filter>>
  >;
  content_bottom?: InputMaybe<String_Filter_Operators>;
  content_top?: InputMaybe<String_Filter_Operators>;
  extra_information?: InputMaybe<String_Filter_Operators>;
  find_dealer_text?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  pricing_pages_multi_layout_id?: InputMaybe<Pricing_Pages_Multi_Layout_Filter>;
};

export type Pricing_Pages_Multi_Layout_Translations_Mutated = {
  __typename?: 'pricing_pages_multi_layout_translations_mutated';
  data?: Maybe<Pricing_Pages_Multi_Layout_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Pricing_Pages_Mutated = {
  __typename?: 'pricing_pages_mutated';
  data?: Maybe<Pricing_Pages>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Pricing_Pages_Single_Layout = {
  __typename?: 'pricing_pages_single_layout';
  content?: Maybe<Array<Maybe<Pricing_Pages_Single_Layout_Translations>>>;
  content_func?: Maybe<Count_Functions>;
  id: Scalars['ID'];
  /** For internal use */
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Products>;
  show_retailer_button: Scalars['Boolean'];
};

export type Pricing_Pages_Single_LayoutContentArgs = {
  filter?: InputMaybe<Pricing_Pages_Single_Layout_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Single_LayoutProductArgs = {
  filter?: InputMaybe<Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Single_Layout_Aggregated = {
  __typename?: 'pricing_pages_single_layout_aggregated';
  avg?: Maybe<Pricing_Pages_Single_Layout_Aggregated_Fields>;
  avgDistinct?: Maybe<Pricing_Pages_Single_Layout_Aggregated_Fields>;
  count?: Maybe<Pricing_Pages_Single_Layout_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Pricing_Pages_Single_Layout_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Pricing_Pages_Single_Layout_Aggregated_Fields>;
  min?: Maybe<Pricing_Pages_Single_Layout_Aggregated_Fields>;
  sum?: Maybe<Pricing_Pages_Single_Layout_Aggregated_Fields>;
  sumDistinct?: Maybe<Pricing_Pages_Single_Layout_Aggregated_Fields>;
};

export type Pricing_Pages_Single_Layout_Aggregated_Count = {
  __typename?: 'pricing_pages_single_layout_aggregated_count';
  content?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** For internal use */
  name?: Maybe<Scalars['Int']>;
  product?: Maybe<Scalars['Int']>;
  show_retailer_button?: Maybe<Scalars['Int']>;
};

export type Pricing_Pages_Single_Layout_Aggregated_Fields = {
  __typename?: 'pricing_pages_single_layout_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  product?: Maybe<Scalars['Float']>;
};

export type Pricing_Pages_Single_Layout_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Pricing_Pages_Single_Layout_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Pricing_Pages_Single_Layout_Filter>>>;
  content?: InputMaybe<Pricing_Pages_Single_Layout_Translations_Filter>;
  content_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  product?: InputMaybe<Products_Filter>;
  show_retailer_button?: InputMaybe<Boolean_Filter_Operators>;
};

export type Pricing_Pages_Single_Layout_Mutated = {
  __typename?: 'pricing_pages_single_layout_mutated';
  data?: Maybe<Pricing_Pages_Single_Layout>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Pricing_Pages_Single_Layout_Translations = {
  __typename?: 'pricing_pages_single_layout_translations';
  body?: Maybe<Scalars['String']>;
  bottom_content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  pricing_pages_single_layout_id?: Maybe<Pricing_Pages_Single_Layout>;
};

export type Pricing_Pages_Single_Layout_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Single_Layout_TranslationsPricing_Pages_Single_Layout_IdArgs =
  {
    filter?: InputMaybe<Pricing_Pages_Single_Layout_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Pricing_Pages_Single_Layout_Translations_Aggregated = {
  __typename?: 'pricing_pages_single_layout_translations_aggregated';
  avg?: Maybe<Pricing_Pages_Single_Layout_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Pricing_Pages_Single_Layout_Translations_Aggregated_Fields>;
  count?: Maybe<Pricing_Pages_Single_Layout_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Pricing_Pages_Single_Layout_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Pricing_Pages_Single_Layout_Translations_Aggregated_Fields>;
  min?: Maybe<Pricing_Pages_Single_Layout_Translations_Aggregated_Fields>;
  sum?: Maybe<Pricing_Pages_Single_Layout_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Pricing_Pages_Single_Layout_Translations_Aggregated_Fields>;
};

export type Pricing_Pages_Single_Layout_Translations_Aggregated_Count = {
  __typename?: 'pricing_pages_single_layout_translations_aggregated_count';
  body?: Maybe<Scalars['Int']>;
  bottom_content?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  pricing_pages_single_layout_id?: Maybe<Scalars['Int']>;
};

export type Pricing_Pages_Single_Layout_Translations_Aggregated_Fields = {
  __typename?: 'pricing_pages_single_layout_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  pricing_pages_single_layout_id?: Maybe<Scalars['Float']>;
};

export type Pricing_Pages_Single_Layout_Translations_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Pricing_Pages_Single_Layout_Translations_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Pricing_Pages_Single_Layout_Translations_Filter>>
  >;
  body?: InputMaybe<String_Filter_Operators>;
  bottom_content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  pricing_pages_single_layout_id?: InputMaybe<Pricing_Pages_Single_Layout_Filter>;
};

export type Pricing_Pages_Single_Layout_Translations_Mutated = {
  __typename?: 'pricing_pages_single_layout_translations_mutated';
  data?: Maybe<Pricing_Pages_Single_Layout_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Pricing_Pages_Translations = {
  __typename?: 'pricing_pages_translations';
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  /** <meta name="description"/> tag content */
  meta_description?: Maybe<Scalars['String']>;
  /** <meta name="title"/> tag content */
  meta_title?: Maybe<Scalars['String']>;
  /** <title/> tag content */
  page_title?: Maybe<Scalars['String']>;
  pricing_pages_id?: Maybe<Pricing_Pages>;
};

export type Pricing_Pages_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_TranslationsPricing_Pages_IdArgs = {
  filter?: InputMaybe<Pricing_Pages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing_Pages_Translations_Aggregated = {
  __typename?: 'pricing_pages_translations_aggregated';
  avg?: Maybe<Pricing_Pages_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Pricing_Pages_Translations_Aggregated_Fields>;
  count?: Maybe<Pricing_Pages_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Pricing_Pages_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Pricing_Pages_Translations_Aggregated_Fields>;
  min?: Maybe<Pricing_Pages_Translations_Aggregated_Fields>;
  sum?: Maybe<Pricing_Pages_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Pricing_Pages_Translations_Aggregated_Fields>;
};

export type Pricing_Pages_Translations_Aggregated_Count = {
  __typename?: 'pricing_pages_translations_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  /** <meta name="description"/> tag content */
  meta_description?: Maybe<Scalars['Int']>;
  /** <meta name="title"/> tag content */
  meta_title?: Maybe<Scalars['Int']>;
  /** <title/> tag content */
  page_title?: Maybe<Scalars['Int']>;
  pricing_pages_id?: Maybe<Scalars['Int']>;
};

export type Pricing_Pages_Translations_Aggregated_Fields = {
  __typename?: 'pricing_pages_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  pricing_pages_id?: Maybe<Scalars['Float']>;
};

export type Pricing_Pages_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Pricing_Pages_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Pricing_Pages_Translations_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  meta_description?: InputMaybe<String_Filter_Operators>;
  meta_title?: InputMaybe<String_Filter_Operators>;
  page_title?: InputMaybe<String_Filter_Operators>;
  pricing_pages_id?: InputMaybe<Pricing_Pages_Filter>;
};

export type Pricing_Pages_Translations_Mutated = {
  __typename?: 'pricing_pages_translations_mutated';
  data?: Maybe<Pricing_Pages_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Products = {
  __typename?: 'products';
  id: Scalars['ID'];
  image?: Maybe<Directus_Files>;
  links?: Maybe<Array<Maybe<Products_Products_Links>>>;
  links_func?: Maybe<Count_Functions>;
  sku: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  system_requirements?: Maybe<Array<Maybe<Products_System_Requirements>>>;
  system_requirements_func?: Maybe<Count_Functions>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Products_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
};

export type ProductsImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProductsLinksArgs = {
  filter?: InputMaybe<Products_Products_Links_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProductsSystem_RequirementsArgs = {
  filter?: InputMaybe<Products_System_Requirements_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProductsTranslationsArgs = {
  filter?: InputMaybe<Products_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_Aggregated = {
  __typename?: 'products_aggregated';
  avg?: Maybe<Products_Aggregated_Fields>;
  avgDistinct?: Maybe<Products_Aggregated_Fields>;
  count?: Maybe<Products_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Products_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Products_Aggregated_Fields>;
  min?: Maybe<Products_Aggregated_Fields>;
  sum?: Maybe<Products_Aggregated_Fields>;
  sumDistinct?: Maybe<Products_Aggregated_Fields>;
};

export type Products_Aggregated_Count = {
  __typename?: 'products_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Int']>;
  links?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  system_requirements?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type Products_Aggregated_Fields = {
  __typename?: 'products_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Products_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Products_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Products_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  image?: InputMaybe<Directus_Files_Filter>;
  links?: InputMaybe<Products_Products_Links_Filter>;
  links_func?: InputMaybe<Count_Function_Filter_Operators>;
  sku?: InputMaybe<String_Filter_Operators>;
  sort?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  system_requirements?: InputMaybe<Products_System_Requirements_Filter>;
  system_requirements_func?: InputMaybe<Count_Function_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Products_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Products_Links = {
  __typename?: 'products_links';
  id: Scalars['ID'];
  link_type?: Maybe<Scalars['String']>;
  /** For internal use */
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Products_Links_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
};

export type Products_LinksTranslationsArgs = {
  filter?: InputMaybe<Products_Links_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_Links_Aggregated = {
  __typename?: 'products_links_aggregated';
  avg?: Maybe<Products_Links_Aggregated_Fields>;
  avgDistinct?: Maybe<Products_Links_Aggregated_Fields>;
  count?: Maybe<Products_Links_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Products_Links_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Products_Links_Aggregated_Fields>;
  min?: Maybe<Products_Links_Aggregated_Fields>;
  sum?: Maybe<Products_Links_Aggregated_Fields>;
  sumDistinct?: Maybe<Products_Links_Aggregated_Fields>;
};

export type Products_Links_Aggregated_Count = {
  __typename?: 'products_links_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  link_type?: Maybe<Scalars['Int']>;
  /** For internal use */
  name?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type Products_Links_Aggregated_Fields = {
  __typename?: 'products_links_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Products_Links_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Products_Links_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Products_Links_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  link_type?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Products_Links_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Products_Links_Mutated = {
  __typename?: 'products_links_mutated';
  data?: Maybe<Products_Links>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Products_Links_Translations = {
  __typename?: 'products_links_translations';
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  products_links_id?: Maybe<Products_Links>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Products_Links_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_Links_TranslationsProducts_Links_IdArgs = {
  filter?: InputMaybe<Products_Links_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_Links_Translations_Aggregated = {
  __typename?: 'products_links_translations_aggregated';
  avg?: Maybe<Products_Links_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Products_Links_Translations_Aggregated_Fields>;
  count?: Maybe<Products_Links_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Products_Links_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Products_Links_Translations_Aggregated_Fields>;
  min?: Maybe<Products_Links_Translations_Aggregated_Fields>;
  sum?: Maybe<Products_Links_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Products_Links_Translations_Aggregated_Fields>;
};

export type Products_Links_Translations_Aggregated_Count = {
  __typename?: 'products_links_translations_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  products_links_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['Int']>;
};

export type Products_Links_Translations_Aggregated_Fields = {
  __typename?: 'products_links_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  products_links_id?: Maybe<Scalars['Float']>;
};

export type Products_Links_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Products_Links_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Products_Links_Translations_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  products_links_id?: InputMaybe<Products_Links_Filter>;
  title?: InputMaybe<String_Filter_Operators>;
  url?: InputMaybe<String_Filter_Operators>;
};

export type Products_Links_Translations_Mutated = {
  __typename?: 'products_links_translations_mutated';
  data?: Maybe<Products_Links_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Products_Mutated = {
  __typename?: 'products_mutated';
  data?: Maybe<Products>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Products_Products_Links = {
  __typename?: 'products_products_links';
  id: Scalars['ID'];
  products_id?: Maybe<Products>;
  products_links_id?: Maybe<Products_Links>;
  sort?: Maybe<Scalars['Int']>;
};

export type Products_Products_LinksProducts_IdArgs = {
  filter?: InputMaybe<Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_Products_LinksProducts_Links_IdArgs = {
  filter?: InputMaybe<Products_Links_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_Products_Links_Aggregated = {
  __typename?: 'products_products_links_aggregated';
  avg?: Maybe<Products_Products_Links_Aggregated_Fields>;
  avgDistinct?: Maybe<Products_Products_Links_Aggregated_Fields>;
  count?: Maybe<Products_Products_Links_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Products_Products_Links_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Products_Products_Links_Aggregated_Fields>;
  min?: Maybe<Products_Products_Links_Aggregated_Fields>;
  sum?: Maybe<Products_Products_Links_Aggregated_Fields>;
  sumDistinct?: Maybe<Products_Products_Links_Aggregated_Fields>;
};

export type Products_Products_Links_Aggregated_Count = {
  __typename?: 'products_products_links_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  products_id?: Maybe<Scalars['Int']>;
  products_links_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Products_Products_Links_Aggregated_Fields = {
  __typename?: 'products_products_links_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  products_id?: Maybe<Scalars['Float']>;
  products_links_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Products_Products_Links_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Products_Products_Links_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Products_Products_Links_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  products_id?: InputMaybe<Products_Filter>;
  products_links_id?: InputMaybe<Products_Links_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Products_Products_Links_Mutated = {
  __typename?: 'products_products_links_mutated';
  data?: Maybe<Products_Products_Links>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Products_System_Requirements = {
  __typename?: 'products_system_requirements';
  id: Scalars['ID'];
  products_id?: Maybe<Products>;
  sort?: Maybe<Scalars['Int']>;
  system_requirements_id?: Maybe<System_Requirements>;
};

export type Products_System_RequirementsProducts_IdArgs = {
  filter?: InputMaybe<Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_System_RequirementsSystem_Requirements_IdArgs = {
  filter?: InputMaybe<System_Requirements_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_System_Requirements_Aggregated = {
  __typename?: 'products_system_requirements_aggregated';
  avg?: Maybe<Products_System_Requirements_Aggregated_Fields>;
  avgDistinct?: Maybe<Products_System_Requirements_Aggregated_Fields>;
  count?: Maybe<Products_System_Requirements_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Products_System_Requirements_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Products_System_Requirements_Aggregated_Fields>;
  min?: Maybe<Products_System_Requirements_Aggregated_Fields>;
  sum?: Maybe<Products_System_Requirements_Aggregated_Fields>;
  sumDistinct?: Maybe<Products_System_Requirements_Aggregated_Fields>;
};

export type Products_System_Requirements_Aggregated_Count = {
  __typename?: 'products_system_requirements_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  products_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  system_requirements_id?: Maybe<Scalars['Int']>;
};

export type Products_System_Requirements_Aggregated_Fields = {
  __typename?: 'products_system_requirements_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  products_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  system_requirements_id?: Maybe<Scalars['Float']>;
};

export type Products_System_Requirements_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Products_System_Requirements_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Products_System_Requirements_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  products_id?: InputMaybe<Products_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
  system_requirements_id?: InputMaybe<System_Requirements_Filter>;
};

export type Products_System_Requirements_Mutated = {
  __typename?: 'products_system_requirements_mutated';
  data?: Maybe<Products_System_Requirements>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Products_Translations = {
  __typename?: 'products_translations';
  additional_information?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  /** Define content to show for this product in the multi-products layout for pricing page */
  multiproduct_layout_content?: Maybe<Scalars['String']>;
  product_includes?: Maybe<Scalars['String']>;
  products_id?: Maybe<Products>;
  status?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  system_requirments?: Maybe<Scalars['String']>;
};

export type Products_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_TranslationsProducts_IdArgs = {
  filter?: InputMaybe<Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_Translations_Aggregated = {
  __typename?: 'products_translations_aggregated';
  avg?: Maybe<Products_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Products_Translations_Aggregated_Fields>;
  count?: Maybe<Products_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Products_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Products_Translations_Aggregated_Fields>;
  min?: Maybe<Products_Translations_Aggregated_Fields>;
  sum?: Maybe<Products_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Products_Translations_Aggregated_Fields>;
};

export type Products_Translations_Aggregated_Count = {
  __typename?: 'products_translations_aggregated_count';
  additional_information?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  /** Define content to show for this product in the multi-products layout for pricing page */
  multiproduct_layout_content?: Maybe<Scalars['Int']>;
  product_includes?: Maybe<Scalars['Int']>;
  products_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['Int']>;
  system_requirments?: Maybe<Scalars['Int']>;
};

export type Products_Translations_Aggregated_Fields = {
  __typename?: 'products_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  products_id?: Maybe<Scalars['Float']>;
};

export type Products_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Products_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Products_Translations_Filter>>>;
  additional_information?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  multiproduct_layout_content?: InputMaybe<String_Filter_Operators>;
  product_includes?: InputMaybe<String_Filter_Operators>;
  products_id?: InputMaybe<Products_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
  subtitle?: InputMaybe<String_Filter_Operators>;
  system_requirments?: InputMaybe<String_Filter_Operators>;
};

export type Products_Translations_Mutated = {
  __typename?: 'products_translations_mutated';
  data?: Maybe<Products_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Products_Translations_Product_Features = {
  __typename?: 'products_translations_product_features';
  id: Scalars['ID'];
  products_translations_id?: Maybe<Products_Translations>;
  sort?: Maybe<Scalars['Int']>;
};

export type Products_Translations_Product_FeaturesProducts_Translations_IdArgs =
  {
    filter?: InputMaybe<Products_Translations_Filter>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  };

export type Products_Translations_Product_Features_Aggregated = {
  __typename?: 'products_translations_product_features_aggregated';
  avg?: Maybe<Products_Translations_Product_Features_Aggregated_Fields>;
  avgDistinct?: Maybe<Products_Translations_Product_Features_Aggregated_Fields>;
  count?: Maybe<Products_Translations_Product_Features_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Products_Translations_Product_Features_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Products_Translations_Product_Features_Aggregated_Fields>;
  min?: Maybe<Products_Translations_Product_Features_Aggregated_Fields>;
  sum?: Maybe<Products_Translations_Product_Features_Aggregated_Fields>;
  sumDistinct?: Maybe<Products_Translations_Product_Features_Aggregated_Fields>;
};

export type Products_Translations_Product_Features_Aggregated_Count = {
  __typename?: 'products_translations_product_features_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  products_translations_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Products_Translations_Product_Features_Aggregated_Fields = {
  __typename?: 'products_translations_product_features_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  products_translations_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Products_Translations_Product_Features_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Products_Translations_Product_Features_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Products_Translations_Product_Features_Filter>>
  >;
  id?: InputMaybe<Number_Filter_Operators>;
  products_translations_id?: InputMaybe<Products_Translations_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Products_Translations_Product_Features_Mutated = {
  __typename?: 'products_translations_product_features_mutated';
  data?: Maybe<Products_Translations_Product_Features>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Products_Translations_Products_Links = {
  __typename?: 'products_translations_products_links';
  id: Scalars['ID'];
  products_links_id?: Maybe<Products_Links>;
  products_translations_id?: Maybe<Products_Translations>;
  sort?: Maybe<Scalars['Int']>;
};

export type Products_Translations_Products_LinksProducts_Links_IdArgs = {
  filter?: InputMaybe<Products_Links_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_Translations_Products_LinksProducts_Translations_IdArgs = {
  filter?: InputMaybe<Products_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Products_Translations_Products_Links_Aggregated = {
  __typename?: 'products_translations_products_links_aggregated';
  avg?: Maybe<Products_Translations_Products_Links_Aggregated_Fields>;
  avgDistinct?: Maybe<Products_Translations_Products_Links_Aggregated_Fields>;
  count?: Maybe<Products_Translations_Products_Links_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Products_Translations_Products_Links_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Products_Translations_Products_Links_Aggregated_Fields>;
  min?: Maybe<Products_Translations_Products_Links_Aggregated_Fields>;
  sum?: Maybe<Products_Translations_Products_Links_Aggregated_Fields>;
  sumDistinct?: Maybe<Products_Translations_Products_Links_Aggregated_Fields>;
};

export type Products_Translations_Products_Links_Aggregated_Count = {
  __typename?: 'products_translations_products_links_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  products_links_id?: Maybe<Scalars['Int']>;
  products_translations_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Products_Translations_Products_Links_Aggregated_Fields = {
  __typename?: 'products_translations_products_links_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  products_links_id?: Maybe<Scalars['Float']>;
  products_translations_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Products_Translations_Products_Links_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Products_Translations_Products_Links_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Products_Translations_Products_Links_Filter>>
  >;
  id?: InputMaybe<Number_Filter_Operators>;
  products_links_id?: InputMaybe<Products_Links_Filter>;
  products_translations_id?: InputMaybe<Products_Translations_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Products_Translations_Products_Links_Mutated = {
  __typename?: 'products_translations_products_links_mutated';
  data?: Maybe<Products_Translations_Products_Links>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Required_Base_Products = {
  __typename?: 'required_base_products';
  id: Scalars['ID'];
  messages?: Maybe<Array<Maybe<Required_Base_Products_Translations>>>;
  messages_func?: Maybe<Count_Functions>;
  sku: Scalars['String'];
};

export type Required_Base_ProductsMessagesArgs = {
  filter?: InputMaybe<Required_Base_Products_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Required_Base_Products_Aggregated = {
  __typename?: 'required_base_products_aggregated';
  avg?: Maybe<Required_Base_Products_Aggregated_Fields>;
  avgDistinct?: Maybe<Required_Base_Products_Aggregated_Fields>;
  count?: Maybe<Required_Base_Products_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Required_Base_Products_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Required_Base_Products_Aggregated_Fields>;
  min?: Maybe<Required_Base_Products_Aggregated_Fields>;
  sum?: Maybe<Required_Base_Products_Aggregated_Fields>;
  sumDistinct?: Maybe<Required_Base_Products_Aggregated_Fields>;
};

export type Required_Base_Products_Aggregated_Count = {
  __typename?: 'required_base_products_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  messages?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['Int']>;
};

export type Required_Base_Products_Aggregated_Fields = {
  __typename?: 'required_base_products_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Required_Base_Products_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Required_Base_Products_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Required_Base_Products_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  messages?: InputMaybe<Required_Base_Products_Translations_Filter>;
  messages_func?: InputMaybe<Count_Function_Filter_Operators>;
  sku?: InputMaybe<String_Filter_Operators>;
};

export type Required_Base_Products_Mutated = {
  __typename?: 'required_base_products_mutated';
  data?: Maybe<Required_Base_Products>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Required_Base_Products_Translations = {
  __typename?: 'required_base_products_translations';
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  required_base_products?: Maybe<Scalars['String']>;
  required_base_products_id?: Maybe<Required_Base_Products>;
};

export type Required_Base_Products_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Required_Base_Products_TranslationsRequired_Base_Products_IdArgs = {
  filter?: InputMaybe<Required_Base_Products_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Required_Base_Products_Translations_Aggregated = {
  __typename?: 'required_base_products_translations_aggregated';
  avg?: Maybe<Required_Base_Products_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Required_Base_Products_Translations_Aggregated_Fields>;
  count?: Maybe<Required_Base_Products_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Required_Base_Products_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Required_Base_Products_Translations_Aggregated_Fields>;
  min?: Maybe<Required_Base_Products_Translations_Aggregated_Fields>;
  sum?: Maybe<Required_Base_Products_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Required_Base_Products_Translations_Aggregated_Fields>;
};

export type Required_Base_Products_Translations_Aggregated_Count = {
  __typename?: 'required_base_products_translations_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  required_base_products?: Maybe<Scalars['Int']>;
  required_base_products_id?: Maybe<Scalars['Int']>;
};

export type Required_Base_Products_Translations_Aggregated_Fields = {
  __typename?: 'required_base_products_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  required_base_products_id?: Maybe<Scalars['Float']>;
};

export type Required_Base_Products_Translations_Filter = {
  _and?: InputMaybe<
    Array<InputMaybe<Required_Base_Products_Translations_Filter>>
  >;
  _or?: InputMaybe<
    Array<InputMaybe<Required_Base_Products_Translations_Filter>>
  >;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  required_base_products?: InputMaybe<String_Filter_Operators>;
  required_base_products_id?: InputMaybe<Required_Base_Products_Filter>;
};

export type Required_Base_Products_Translations_Mutated = {
  __typename?: 'required_base_products_translations_mutated';
  data?: Maybe<Required_Base_Products_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type String_Filter_Operators = {
  _contains?: InputMaybe<Scalars['String']>;
  _empty?: InputMaybe<Scalars['Boolean']>;
  _ends_with?: InputMaybe<Scalars['String']>;
  _eq?: InputMaybe<Scalars['String']>;
  _icontains?: InputMaybe<Scalars['String']>;
  _iends_with?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _istarts_with?: InputMaybe<Scalars['String']>;
  _ncontains?: InputMaybe<Scalars['String']>;
  _nempty?: InputMaybe<Scalars['Boolean']>;
  _nends_with?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _niends_with?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _nistarts_with?: InputMaybe<Scalars['String']>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _nstarts_with?: InputMaybe<Scalars['String']>;
  _null?: InputMaybe<Scalars['Boolean']>;
  _starts_with?: InputMaybe<Scalars['String']>;
};

export type System_Requirements = {
  __typename?: 'system_requirements';
  /** helper field for migration script */
  content_hash?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<System_Requirements_Translations>>>;
  translations_func?: Maybe<Count_Functions>;
};

export type System_RequirementsTranslationsArgs = {
  filter?: InputMaybe<System_Requirements_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type System_Requirements_Aggregated = {
  __typename?: 'system_requirements_aggregated';
  avg?: Maybe<System_Requirements_Aggregated_Fields>;
  avgDistinct?: Maybe<System_Requirements_Aggregated_Fields>;
  count?: Maybe<System_Requirements_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<System_Requirements_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<System_Requirements_Aggregated_Fields>;
  min?: Maybe<System_Requirements_Aggregated_Fields>;
  sum?: Maybe<System_Requirements_Aggregated_Fields>;
  sumDistinct?: Maybe<System_Requirements_Aggregated_Fields>;
};

export type System_Requirements_Aggregated_Count = {
  __typename?: 'system_requirements_aggregated_count';
  /** helper field for migration script */
  content_hash?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['Int']>;
};

export type System_Requirements_Aggregated_Fields = {
  __typename?: 'system_requirements_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type System_Requirements_Filter = {
  _and?: InputMaybe<Array<InputMaybe<System_Requirements_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<System_Requirements_Filter>>>;
  content_hash?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<System_Requirements_Translations_Filter>;
  translations_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type System_Requirements_Mutated = {
  __typename?: 'system_requirements_mutated';
  data?: Maybe<System_Requirements>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type System_Requirements_Translations = {
  __typename?: 'system_requirements_translations';
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Languages>;
  status?: Maybe<Scalars['String']>;
  system_requirements_id?: Maybe<System_Requirements>;
};

export type System_Requirements_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type System_Requirements_TranslationsSystem_Requirements_IdArgs = {
  filter?: InputMaybe<System_Requirements_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type System_Requirements_Translations_Aggregated = {
  __typename?: 'system_requirements_translations_aggregated';
  avg?: Maybe<System_Requirements_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<System_Requirements_Translations_Aggregated_Fields>;
  count?: Maybe<System_Requirements_Translations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<System_Requirements_Translations_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<System_Requirements_Translations_Aggregated_Fields>;
  min?: Maybe<System_Requirements_Translations_Aggregated_Fields>;
  sum?: Maybe<System_Requirements_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<System_Requirements_Translations_Aggregated_Fields>;
};

export type System_Requirements_Translations_Aggregated_Count = {
  __typename?: 'system_requirements_translations_aggregated_count';
  content?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  languages_code?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  system_requirements_id?: Maybe<Scalars['Int']>;
};

export type System_Requirements_Translations_Aggregated_Fields = {
  __typename?: 'system_requirements_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  system_requirements_id?: Maybe<Scalars['Float']>;
};

export type System_Requirements_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<System_Requirements_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<System_Requirements_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
  system_requirements_id?: InputMaybe<System_Requirements_Filter>;
};

export type System_Requirements_Translations_Mutated = {
  __typename?: 'system_requirements_translations_mutated';
  data?: Maybe<System_Requirements_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Version_Catalog_Content_Pages = {
  __typename?: 'version_catalog_content_pages';
  featured_product_sections?: Maybe<Scalars['JSON']>;
  featured_product_sections_func?: Maybe<Count_Functions>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Version_Catalog_Content_Pages_Featured_Product_Sections = {
  __typename?: 'version_catalog_content_pages_featured_product_sections';
  catalog_content_pages_id?: Maybe<Scalars['JSON']>;
  featured_product_sections_id?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Category_Banners = {
  __typename?: 'version_category_banners';
  background_desktop?: Maybe<Scalars['JSON']>;
  background_mobile?: Maybe<Scalars['JSON']>;
  countdown_date?: Maybe<Scalars['Date']>;
  countdown_date_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  text_alignement?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_Category_Banners_Translations = {
  __typename?: 'version_category_banners_translations';
  body?: Maybe<Scalars['String']>;
  category_banners_id?: Maybe<Scalars['JSON']>;
  countdown_template?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
};

export type Version_Content_Elements_1col = {
  __typename?: 'version_content_elements_1col';
  background?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['JSON']>;
  image_style?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  text_style?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_Content_Elements_1col_Translations = {
  __typename?: 'version_content_elements_1col_translations';
  content?: Maybe<Scalars['String']>;
  content_elements_1col_id?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
};

export type Version_Content_Elements_2_Col = {
  __typename?: 'version_content_elements_2_col';
  background?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['JSON']>;
  image_style?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  text_position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_Content_Elements_2_Col_Translations = {
  __typename?: 'version_content_elements_2_col_translations';
  content?: Maybe<Scalars['String']>;
  content_elements_2_col_id?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
};

export type Version_Content_Elements_2col_Grid = {
  __typename?: 'version_content_elements_2col_grid';
  background?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['JSON']>;
  image_left?: Maybe<Scalars['JSON']>;
  image_right?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_Content_Elements_2col_Grid_Translations = {
  __typename?: 'version_content_elements_2col_grid_translations';
  content_elements_2col_grid_id?: Maybe<Scalars['JSON']>;
  content_left?: Maybe<Scalars['String']>;
  content_right?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  main_content?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Version_Cta_Buttons = {
  __typename?: 'version_cta_buttons';
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_Cta_Buttons_Translations = {
  __typename?: 'version_cta_buttons_translations';
  cta_buttons_id?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  languages_code?: Maybe<Scalars['JSON']>;
};

export type Version_Featured_Product_Sections = {
  __typename?: 'version_featured_product_sections';
  content?: Maybe<Scalars['JSON']>;
  content_func?: Maybe<Count_Functions>;
  cta_buttons?: Maybe<Scalars['JSON']>;
  cta_buttons_func?: Maybe<Count_Functions>;
  id: Scalars['ID'];
  products?: Maybe<Scalars['JSON']>;
  products_func?: Maybe<Count_Functions>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Version_Featured_Product_Sections_Cta_Buttons = {
  __typename?: 'version_featured_product_sections_cta_buttons';
  cta_buttons_id?: Maybe<Scalars['JSON']>;
  featured_product_sections_id?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Featured_Product_Sections_Products = {
  __typename?: 'version_featured_product_sections_products';
  featured_product_sections_id?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  products_id?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Featured_Product_Sections_Translations = {
  __typename?: 'version_featured_product_sections_translations';
  body?: Maybe<Scalars['String']>;
  featured_product_sections_id?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
};

export type Version_Languages = {
  __typename?: 'version_languages';
  code: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Menu = {
  __typename?: 'version_menu';
  id: Scalars['ID'];
  menu_categories?: Maybe<Scalars['JSON']>;
  menu_categories_func?: Maybe<Count_Functions>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type Version_Menu_Categories = {
  __typename?: 'version_menu_categories';
  category_banner?: Maybe<Scalars['JSON']>;
  children?: Maybe<Scalars['JSON']>;
  children_func?: Maybe<Count_Functions>;
  content_elements?: Maybe<Scalars['JSON']>;
  content_elements_func?: Maybe<Count_Functions>;
  content_page?: Maybe<Scalars['JSON']>;
  /** History Paths in format ["path1", "path2"] */
  history_paths?: Maybe<Scalars['JSON']>;
  history_paths_func?: Maybe<Count_Functions>;
  id: Scalars['ID'];
  is_protected?: Maybe<Scalars['Boolean']>;
  meta_image?: Maybe<Scalars['JSON']>;
  meta_image_alt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['JSON']>;
  query_func?: Maybe<Count_Functions>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_Menu_Categories_Content_Elements = {
  __typename?: 'version_menu_categories_content_elements';
  collection?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  item?: Maybe<Scalars['String']>;
  menu_categories_id?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Menu_Categories_Menu_Categories = {
  __typename?: 'version_menu_categories_menu_categories';
  category?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  parent?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Menu_Categories_Translations = {
  __typename?: 'version_menu_categories_translations';
  category?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Version_Menu_Menu_Categories = {
  __typename?: 'version_menu_menu_categories';
  category?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  menu_id?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Pricing_Page_Multi_Layout_Links = {
  __typename?: 'version_pricing_page_multi_layout_links';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  pricing_page_multi_layout_id?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_Pricing_Page_Multi_Layout_Links_Translations = {
  __typename?: 'version_pricing_page_multi_layout_links_translations';
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  pricing_page_multi_layout_links_id?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  /** the link's text to display */
  title?: Maybe<Scalars['String']>;
  /** a valid url, eg. https://www.example.com */
  url?: Maybe<Scalars['String']>;
};

export type Version_Pricing_Pages = {
  __typename?: 'version_pricing_pages';
  /** History Paths in format ["path1", "path2"] */
  history_paths?: Maybe<Scalars['JSON']>;
  history_paths_func?: Maybe<Count_Functions>;
  id: Scalars['ID'];
  layout?: Maybe<Scalars['JSON']>;
  layout_func?: Maybe<Count_Functions>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_color?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
  url_key: Scalars['String'];
};

export type Version_Pricing_Pages_Layout = {
  __typename?: 'version_pricing_pages_layout';
  collection?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  item?: Maybe<Scalars['String']>;
  pricing_pages_id?: Maybe<Scalars['JSON']>;
};

export type Version_Pricing_Pages_Multi_Layout = {
  __typename?: 'version_pricing_pages_multi_layout';
  /** Set the number of the columns in the grid layout */
  grid_columns?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  links?: Maybe<Scalars['JSON']>;
  links_func?: Maybe<Count_Functions>;
  /** For internal use */
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['JSON']>;
  products_func?: Maybe<Count_Functions>;
  show_retailer_button: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_Pricing_Pages_Multi_Layout_Products = {
  __typename?: 'version_pricing_pages_multi_layout_products';
  id: Scalars['ID'];
  pricing_pages_multi_layout_id?: Maybe<Scalars['JSON']>;
  products_id?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Pricing_Pages_Multi_Layout_Translations = {
  __typename?: 'version_pricing_pages_multi_layout_translations';
  content_bottom?: Maybe<Scalars['String']>;
  content_top?: Maybe<Scalars['String']>;
  extra_information?: Maybe<Scalars['String']>;
  find_dealer_text?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  pricing_pages_multi_layout_id?: Maybe<Scalars['JSON']>;
};

export type Version_Pricing_Pages_Single_Layout = {
  __typename?: 'version_pricing_pages_single_layout';
  content?: Maybe<Scalars['JSON']>;
  content_func?: Maybe<Count_Functions>;
  id: Scalars['ID'];
  /** For internal use */
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['JSON']>;
  show_retailer_button: Scalars['Boolean'];
};

export type Version_Pricing_Pages_Single_Layout_Translations = {
  __typename?: 'version_pricing_pages_single_layout_translations';
  body?: Maybe<Scalars['String']>;
  bottom_content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  pricing_pages_single_layout_id?: Maybe<Scalars['JSON']>;
};

export type Version_Pricing_Pages_Translations = {
  __typename?: 'version_pricing_pages_translations';
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  /** <meta name="description"/> tag content */
  meta_description?: Maybe<Scalars['String']>;
  /** <meta name="title"/> tag content */
  meta_title?: Maybe<Scalars['String']>;
  /** <title/> tag content */
  page_title?: Maybe<Scalars['String']>;
  pricing_pages_id?: Maybe<Scalars['JSON']>;
};

export type Version_Products = {
  __typename?: 'version_products';
  id: Scalars['ID'];
  image?: Maybe<Scalars['JSON']>;
  links?: Maybe<Scalars['JSON']>;
  links_func?: Maybe<Count_Functions>;
  sku: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  system_requirements?: Maybe<Scalars['JSON']>;
  system_requirements_func?: Maybe<Count_Functions>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_Products_Links = {
  __typename?: 'version_products_links';
  id: Scalars['ID'];
  link_type?: Maybe<Scalars['String']>;
  /** For internal use */
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_Products_Links_Translations = {
  __typename?: 'version_products_links_translations';
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  products_links_id?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Version_Products_Products_Links = {
  __typename?: 'version_products_products_links';
  id: Scalars['ID'];
  products_id?: Maybe<Scalars['JSON']>;
  products_links_id?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Products_System_Requirements = {
  __typename?: 'version_products_system_requirements';
  id: Scalars['ID'];
  products_id?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['Int']>;
  system_requirements_id?: Maybe<Scalars['JSON']>;
};

export type Version_Products_Translations = {
  __typename?: 'version_products_translations';
  additional_information?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  /** Define content to show for this product in the multi-products layout for pricing page */
  multiproduct_layout_content?: Maybe<Scalars['String']>;
  product_includes?: Maybe<Scalars['String']>;
  products_id?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  system_requirments?: Maybe<Scalars['String']>;
};

export type Version_Products_Translations_Product_Features = {
  __typename?: 'version_products_translations_product_features';
  id: Scalars['ID'];
  products_translations_id?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Products_Translations_Products_Links = {
  __typename?: 'version_products_translations_products_links';
  id: Scalars['ID'];
  products_links_id?: Maybe<Scalars['JSON']>;
  products_translations_id?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Version_Required_Base_Products = {
  __typename?: 'version_required_base_products';
  id: Scalars['ID'];
  messages?: Maybe<Scalars['JSON']>;
  messages_func?: Maybe<Count_Functions>;
  sku: Scalars['String'];
};

export type Version_Required_Base_Products_Translations = {
  __typename?: 'version_required_base_products_translations';
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  required_base_products?: Maybe<Scalars['String']>;
  required_base_products_id?: Maybe<Scalars['JSON']>;
};

export type Version_System_Requirements = {
  __typename?: 'version_system_requirements';
  /** helper field for migration script */
  content_hash?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  translations?: Maybe<Scalars['JSON']>;
  translations_func?: Maybe<Count_Functions>;
};

export type Version_System_Requirements_Translations = {
  __typename?: 'version_system_requirements_translations';
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages_code?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  system_requirements_id?: Maybe<Scalars['JSON']>;
};
